import { ServiceResponse } from '@nx-smartmonkey/shared/hooks';
import { useQuery } from '@tanstack/react-query';

import { sessionService } from '../../application/SessionService';
import { useShowSnackbarMessage } from '../snackbarMessage/useShowSnackbarMessage';

interface UseSessionLoginCodeProps {
  otpCode: string;
}

type UseSessionLoginCodeResponseProps = ServiceResponse<Function, void> & {
  isLogged: boolean;
};

// Hooks act as 'Adapter' layer.
export const useSessionLoginCode = ({ otpCode }: UseSessionLoginCodeProps): UseSessionLoginCodeResponseProps => {
  const { showSnackbarMessage } = useShowSnackbarMessage();

  const {
    isInitialLoading: isLoading,
    isRefetching,
    isSuccess,
    isError,
    refetch,
  } = useQuery(
    [`supervisors_session_login_code`],
    () => sessionService.verifySessionToken({ otpCode: otpCode.replace(/ /g, ``) }),
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        console.info(`Supervisor Session Login Phone Error: `, err?.response);
        showSnackbarMessage({
          messageIntlId: `supervisors.login_otp.error`,
        });
      },
    }
  );

  return {
    error: isError,
    success: isSuccess,
    isLogged: sessionService.isLogged(),
    isLoading: isLoading || isRefetching,
    run: refetch,
  };
};
