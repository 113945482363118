import { LocationMapper } from '@nx-smartmonkey/shared/domain';
import { Project } from '../../domain/project/Project';
import { ProjectDTO } from '../../domain/project/ProjectDTO';
import { ProjectUnits } from '../../domain/project/ProjectUnits';
import { ProjectCustomFieldsMapper } from './ProjectCustomFieldsMapper';
import { ProjectStyleMapper } from './ProjectStyleMapper';

export class ProjectMapper {
  static toDomain(props: ProjectDTO): Project {
    const { id, organization_id, public_key, project_slug, style, units, location, custom_fields } = props;

    const projectUnitsDomain = ProjectUnits.create(units);
    const projectCustomFieldsDomain = ProjectCustomFieldsMapper.toDomain(custom_fields);
    const projectStyleDomain = style ? ProjectStyleMapper.toDomain(style) : undefined;
    const locationDomain = location ? LocationMapper.toDomain(location) : undefined;

    return Project.create(
      {
        organization_id,
        public_key,
        project_slug,
        style: projectStyleDomain,
        units: projectUnitsDomain,
        custom_fields: projectCustomFieldsDomain,
        location: locationDomain,
      },
      id
    );
  }
}
