import { NotificationProps } from './reducer';

export enum NotificationsActionType {
  SET_NOTIFICATIONS = `SET_NOTIFICATIONS`,
  REMOVE_NOTIFICATIONS = `REMOVE_NOTIFICATIONS`,
  CLEAR_NEW_NOTIFICATIONS = `CLEAR_NEW_NOTIFICATIONS`,
}

export interface SetNotificationsAction {
  type: NotificationsActionType.SET_NOTIFICATIONS;
  payload: {
    notifications: NotificationProps[];
  };
}

export interface RemoveNotificationsAction {
  type: NotificationsActionType.REMOVE_NOTIFICATIONS;
}

export interface ClearNewNotificationsAction {
  type: NotificationsActionType.CLEAR_NEW_NOTIFICATIONS;
}

export type NotificationsActions = SetNotificationsAction | RemoveNotificationsAction | ClearNewNotificationsAction;
