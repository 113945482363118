import { Events } from './Events';

export class EventsService implements Events {
  constructor(private services: Array<Events>) {}

  track({ eventName, params }: { eventName: string; params?: Record<string, any> }) {
    this.services.forEach((service) => service.track({ eventName, params }));
  }

  register(params: Record<string, any>) {
    this.services.forEach((service) => service.register(params));
  }
}
