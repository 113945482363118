/* eslint-disable camelcase */
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid } from '@mui/material';
import React from 'react';

import HSpacer from '../components/HSpacer';
import VSpacer from '../components/VSpacer';
import { RoutalPalette } from './Colors';
import { RoutalButton } from './RoutalButton';
import RoutalLoader from './RoutalLoader';
import { RoutalMobileButton } from './RoutalMobileButton';
import { CrossIcon } from './assets/svg/CrossIcon';

type DialogWidthTypes = `xs` | `sm` | `md` | `lg` | `xl`;

type RoutalDialogProps = {
  id?: string;
  open: boolean;
  dialogProps?: {
    fullWidth?: boolean;
    fullScreen?: boolean;
    maxWidth?: DialogWidthTypes;
    disableBackdropClick?: boolean;
    disableEscapeKeyDown?: boolean;
    style?: React.CSSProperties;
    dialogBGColor?: string;
    dialogHeaderBGColor?: string;
    hideScroll?: boolean;
  };
  loading?: boolean;
  showLoader?: boolean;
  showCrossCloseButton?: boolean;
  showHeader?: boolean;
  showFooter?: boolean;
  titleContent?: React.ReactElement | string;
  titleContentStyles?: React.CSSProperties;
  titleRightContent?: React.ReactElement;
  dialogContentWarning?: React.ReactElement;
  contentWithOverflow?: boolean;
  contentStyles?: React.CSSProperties;
  footerContent?: React.ReactElement;
  cancelButtonText?: string | React.ReactElement;
  cancelButtonId?: string;
  confirmButtonText?: string | React.ReactElement;
  confirmButtonId?: string;
  isMobile?: boolean;
  onCloseDialog: (event: any) => any;
  onConfirm?: (...args: any[]) => any;
  onCancel?: (...args: any[]) => any;
};

const defaultDialogProps = {
  fullScreen: false,
  disableBackdropClick: false,
  disableEscapeKeyDown: false,
  fullWidth: true,
  maxWidth: `sm` as DialogWidthTypes,
  style: {},
  dialogBGColor: RoutalPalette.neutral02n,
  dialogHeaderBGColor: RoutalPalette.neutral02n,
  hideScroll: false,
};

const RoutalDialog = ({
  id,
  open,
  dialogProps,
  loading,
  showLoader = true,
  showCrossCloseButton = true,
  showHeader = true,
  showFooter = true,
  children,
  titleContent,
  titleContentStyles,
  titleRightContent,
  dialogContentWarning,
  contentWithOverflow = true,
  contentStyles = {},
  footerContent,
  cancelButtonText,
  cancelButtonId,
  confirmButtonText,
  confirmButtonId,
  isMobile = false,
  onCloseDialog,
  onConfirm,
  onCancel = onCloseDialog,
}: React.PropsWithChildren<RoutalDialogProps>) => {
  const dialogPropsWithDefaults = { ...defaultDialogProps, ...dialogProps };

  const getFooterContent = () => {
    if (isMobile) {
      return (
        <Grid container justifyContent="flex-end" style={{}}>
          <RoutalMobileButton id={cancelButtonId} variant="flat" onClick={onCancel} disabled={loading}>
            {cancelButtonText}
          </RoutalMobileButton>

          <HSpacer small />

          <RoutalMobileButton id={confirmButtonId} onClick={onConfirm} disabled={loading}>
            {loading && !showLoader ? (
              <Grid container justifyContent="center" alignItems="center" style={{ width: `55px`, height: `19px` }}>
                <RoutalLoader width={20} style={{ height: `100%` }} />
              </Grid>
            ) : (
              confirmButtonText
            )}
          </RoutalMobileButton>
        </Grid>
      );
    }

    return (
      <Grid container justifyContent="flex-end" style={{}}>
        <RoutalButton size="small" id={cancelButtonId} variant="flat" onClick={onCancel} disabled={loading}>
          {cancelButtonText}
        </RoutalButton>

        <HSpacer small />

        <RoutalButton size="small" id={confirmButtonId} onClick={onConfirm} disabled={loading}>
          {loading && !showLoader ? (
            <Grid container justifyContent="center" alignItems="center" style={{ width: `55px`, height: `19px` }}>
              <RoutalLoader width={20} style={{ height: `100%` }} />
            </Grid>
          ) : (
            confirmButtonText
          )}
        </RoutalButton>
      </Grid>
    );
  };

  return (
    <Dialog
      id={id}
      open={open}
      onClose={(event: any, reason: `escapeKeyDown` | `backdropClick`) => {
        if (dialogPropsWithDefaults.disableBackdropClick && reason !== `backdropClick`) {
          return;
        }
        if (dialogPropsWithDefaults.disableEscapeKeyDown && reason !== `escapeKeyDown`) {
          return;
        }
        onCloseDialog(event);
      }}
      fullScreen={dialogPropsWithDefaults.fullScreen ?? false}
      fullWidth={dialogPropsWithDefaults.fullWidth ?? true}
      maxWidth={dialogPropsWithDefaults.maxWidth ?? `sm`}
      style={dialogPropsWithDefaults.style ?? {}}
      onClick={(e: any) => {
        e.stopPropagation();
      }}
      onContextMenu={(e: any) => {
        e.stopPropagation();
      }}
      PaperProps={{
        ...(dialogPropsWithDefaults.hideScroll
          ? {
              style: {
                overflow: `hidden`,
              },
            }
          : {}),
      }}
    >
      <Grid style={{ backgroundColor: dialogPropsWithDefaults.dialogBGColor }}>
        {showHeader ? (
          <>
            <DialogTitle style={{ backgroundColor: dialogPropsWithDefaults.dialogHeaderBGColor }}>
              <Grid container direction="row" alignItems="center" wrap="nowrap" justifyContent="space-between">
                <Grid item style={titleContentStyles}>
                  {titleContent}
                </Grid>

                <Grid item>
                  <Grid container direction="row" alignItems="center" wrap="nowrap">
                    {titleRightContent}

                    {showCrossCloseButton ? (
                      <>
                        <HSpacer small />
                        <CrossIcon
                          style={{
                            color: `#211915`,
                            fontSize: `14px`,
                            cursor: loading ? `default` : `pointer`,
                          }}
                          onClick={(e: any) => {
                            if (!loading) onCloseDialog(e);
                          }}
                        />
                      </>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </DialogTitle>

            <Divider />
          </>
        ) : null}

        <>
          {dialogContentWarning ?? <VSpacer small />}

          <DialogContent
            style={{
              ...(contentWithOverflow ? {} : { overflow: `hidden` }),
              paddingBottom: `16px`,
              ...contentStyles,
            }}
          >
            {loading && showLoader ? (
              <Grid
                container
                justifyContent="center"
                style={{ height: `100%`, overflow: `hidden` }}
                alignItems="center"
              >
                <Grid container justifyContent="center" alignItems="center" style={{ width: `200px`, height: `200px` }}>
                  <RoutalLoader />
                </Grid>
              </Grid>
            ) : (
              <Grid container direction="column" style={{ height: `100%` }}>
                {children}
              </Grid>
            )}
          </DialogContent>
        </>

        {showFooter ? (
          <DialogActions style={{ padding: `12px 20px`, borderTop: `1px solid ${RoutalPalette.neutral10}` }}>
            {footerContent ?? getFooterContent()}
          </DialogActions>
        ) : null}
      </Grid>
    </Dialog>
  );
};

export default RoutalDialog;
