/* eslint-disable camelcase */
import queryString from 'query-string';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { sessionService } from '../application/SessionService';

import { Grid } from '@mui/material';
import { LoaderPage } from '../components/LoaderPage';
import * as routing from '../constants/Routing';

export const SupervisorsAdmin = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    const asSupervisor = sessionService.getAsSupervisor();
    const searchParams = queryString.parse(search ? search : ``);
    const { token, as_supervisor } = searchParams;

    if (!asSupervisor || asSupervisor !== as_supervisor) {
      if (!token || !as_supervisor) {
        navigate(routing.AUTH);
        return;
      }

      sessionService.initializeAdmin({ token: token as string, asSupervisor: as_supervisor as string });
    }
    navigate(routing.ROOT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  // App is loading
  return (
    <div
      style={{
        position: `absolute`,
        top: 0,
        height: `100%`,
        width: `100%`,
      }}
    >
      <Grid
        container
        style={{
          height: `100%`,
          width: `100%`,
        }}
        wrap="nowrap"
        sx={{ flexDirection: { xs: `column`, md: `row` }, rowGap: { xs: `16px`, md: 0 } }}
      >
        <Grid item style={{ height: `100%`, width: `100%` }}>
          <LoaderPage />
        </Grid>
      </Grid>
    </div>
  );
};
