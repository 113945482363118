import { Project } from '../domain/project/Project';
import { projectRepository } from '../repositories/project';
import { ProjectRepository } from '../repositories/project/ProjectRepository';

class ProjectService {
  constructor(private projectRepository: ProjectRepository) {}

  async findByHost({ host, isExampleURL = false }: { host: string; isExampleURL?: boolean }): Promise<Project> {
    const project = await this.projectRepository.findByHost({ host, isExampleURL: isExampleURL });
    return this.projectRepository.retrieveFavicon({ project });
  }
}

export const projectService = new ProjectService(projectRepository);
