import { Chip, Grid } from '@mui/material';

import React from 'react';
import styled from 'styled-components';
import { getStopStatusColor } from '../constants/Colors';
import { StopStatus } from '../domain/stop/StopStatus';
import { Text } from '../react-components/Text';

const RoutalChip = styled(Chip)`
  .MuiChip-label {
    padding: 0;
  }
`;

interface DotMarkerProps {
  color?: string;
}

const DotMarker = styled(Grid)<DotMarkerProps>`
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: ${(props) => (props.color ? props.color : `transparent`)};
`;

interface StatusChipProps {
  chipStyles: React.CSSProperties;
  marker?: React.ReactElement;
  label: React.ReactElement;
}

const StatusChip = ({ chipStyles, marker, label }: StatusChipProps) => {
  return (
    <RoutalChip
      sx={{
        height: `26px`,
        padding: `6px 8px`,
        // width: `120px`,
        borderRadius: `3px`,
      }}
      style={{
        ...chipStyles,
      }}
      label={
        marker ? (
          <Grid display="flex" flexDirection="row" alignItems="center">
            {marker}
            <div style={{ minWidth: `6px` }} />
            <div style={{ marginTop: `1px` }}>{label}</div>
          </Grid>
        ) : (
          label
        )
      }
    />
  );
};

export const StopStatusChip = (props: {
  status: StopStatus;
  chipStyles?: React.CSSProperties;
  labelStyles?: React.CSSProperties;
}) => {
  const { status, chipStyles, labelStyles } = props;
  const borderColor = getStopStatusColor(status, `medium`);
  const backgroundColor = getStopStatusColor(status, `light`);
  const textColor = getStopStatusColor(status, `dark`);

  return (
    <StatusChip
      marker={<DotMarker color={textColor} />}
      label={<Text variant="body" style={{ ...labelStyles, color: textColor }} intlId={`stop.status.${status}`} />}
      chipStyles={{
        ...chipStyles,
        border: `1px solid ${borderColor}`,
        backgroundColor,
      }}
    />
  );
};
