import { decode } from '@mapbox/polyline';
import { withStyles } from '@mui/styles';
import { getDistance } from '@nx-smartmonkey/shared/helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { Pane, Polyline } from 'react-leaflet';
import { RoutalPalette } from '../../new_components';

const styles = {
  polyline: {
    strokeWidth: 10,
    'box-shadow': `inset 0 1px 2px rgba(0, 0, 0, 0.25)`,
  },
};

type RouteDriversProps = {
  id?: string;
  path?:
    | string
    | {
        lat: number;
        lng: number;
      }[];
  nextStop?: {
    lat: number | undefined;
    lng: number | undefined;
  };
  currentPosition?: {
    lat: number | undefined;
    lng: number | undefined;
  };
  previousStop?: {
    lat: number | undefined;
    lng: number | undefined;
  };
  onClick?: (...args: any[]) => any;
  onDoubleClick?: (...args: any[]) => any;
};

const RouteDrivers = ({
  id,
  children,
  onClick,
  onDoubleClick,
  path,
  previousStop,
  currentPosition,
  nextStop,
}: React.PropsWithChildren<RouteDriversProps>) => {
  const childArray = React.Children.toArray(children);
  const [polyline, setPolyline] = useState<{ lat: number; lng: number }[] | undefined>(undefined);

  const parserProps = useCallback(() => {
    if (path) {
      setPolyline(
        typeof path === `string`
          ? decode(path).map((pathPoint: number[]) => ({ lat: pathPoint[0], lng: pathPoint[1] }))
          : path
      );
    }
  }, [path]);

  const setBounds = useCallback(() => {
    if (polyline === undefined) {
      return;
    }
    let [minLat, minLng, maxLat, maxLng]: number[] | undefined[] = [undefined, undefined, undefined, undefined];

    polyline.forEach((c: any) => {
      minLat = Math.min(minLat || c.lat, c.lat);
      minLng = Math.min(minLng || c.lng, c.lng);
      maxLat = Math.max(maxLat || c.lat, c.lat);
      maxLng = Math.max(maxLng || c.lng, c.lng);
    });
  }, [polyline, id]);

  useEffect(() => {
    setBounds();
  }, [polyline, setBounds]);

  useEffect(() => {
    parserProps();
  }, [parserProps, path]);

  const handleOnClick = (event: any) => {
    if (onClick) onClick(event);
  };

  const handleOnDoubleClick = (event: any) => {
    if (onDoubleClick) onDoubleClick(event);
  };

  //If it is a normal route, it does not require any more logic
  if (!previousStop && !nextStop) {
    return (
      <>
        {polyline && (
          <Polyline
            key={`${`route-polyline-${id}`}`}
            positions={polyline}
            pathOptions={{
              color: RoutalPalette.routeBaseColor,
            }}
            className={``}
            eventHandlers={{
              click: handleOnClick,
              dblclick: handleOnDoubleClick,
            }}
          />
        )}
        {React.Children.map(
          (childArray || []).filter(
            // @ts-expect-error
            (ch) => ch !== undefined && ch !== null && ch !== false
          ),
          (ch: any, _idx) => {
            return React.cloneElement(ch, { color: RoutalPalette.routeBaseColor });
          }
        )}
      </>
    );
  }

  const new_next_stop = { lat: nextStop?.lat ? nextStop.lat : -1, lng: nextStop?.lng ? nextStop.lng : -1 };
  const previous_stop_location = {
    lat: previousStop?.lat ? previousStop.lat : -1,
    lng: previousStop?.lng ? previousStop.lng : -1,
  };
  const currentPositionLocation = {
    lat: currentPosition?.lat ? currentPosition.lat : -1,
    lng: currentPosition?.lng ? currentPosition.lng : -1,
  };

  const index_previous =
    previous_stop_location.lat === -1
      ? undefined
      : polyline?.reduce(
          (minIndex, point, index) => {
            const distance = getDistance(point, previous_stop_location);
            if (distance < minIndex.distance) {
              return { index, distance };
            }
            return minIndex;
          },
          { index: -1, distance: Infinity }
        ).index;

  const index_current = polyline?.reduce(
    (minIndex, point, index) => {
      const distance = getDistance(point, currentPositionLocation);
      if (distance < minIndex.distance) {
        return { index, distance };
      }
      return minIndex;
    },
    { index: -1, distance: Infinity }
  ).index;

  const index_end = polyline?.reduce(
    (minIndex, point, index) => {
      const distance = getDistance(point, new_next_stop);
      if (distance < minIndex.distance) {
        return { index, distance };
      }
      return minIndex;
    },
    { index: -1, distance: Infinity }
  ).index;

  let next_poly;
  let future_poly;
  let previous_poly;
  if (polyline) {
    if (
      index_current &&
      index_end !== undefined &&
      index_previous !== undefined &&
      index_current <= index_end &&
      index_current > index_previous
    ) {
      next_poly =
        index_current && polyline[index_current ?? 0] && polyline[index_end ?? 0]
          ? polyline.slice(index_current, index_end && index_end !== 0 ? index_end : undefined)
          : polyline;
      previous_poly = polyline[index_current ?? 0] ? polyline.slice(undefined, index_current) : polyline;
    } else {
      next_poly =
        index_previous !== undefined && polyline[index_previous ?? 0] && polyline[index_end ?? 0]
          ? polyline.slice(index_previous, index_end && index_end !== 0 ? index_end : undefined)
          : polyline;

      previous_poly = polyline[index_previous ?? 0] ? polyline.slice(undefined, index_previous) : polyline;
    }

    future_poly =
      index_end !== undefined && polyline[index_end ?? 0] ? polyline.slice(index_end - 1, undefined) : polyline;
  }

  return (
    <>
      {polyline && (
        <>
          {previous_poly && (
            <Pane name="previous" style={{ zIndex: 1000 }}>
              <Polyline // Previous
                key={`route-completed-polyline-${id}`}
                positions={previous_poly}
                pathOptions={{
                  color: RoutalPalette.secondary20n,
                }}
                className={``}
                eventHandlers={{
                  click: handleOnClick,
                  dblclick: handleOnDoubleClick,
                }}
                weight={3}
              />
            </Pane>
          )}
          {next_poly && (
            <Pane name="next" style={{ zIndex: 1003 }}>
              <Polyline // Next
                key={`route-next-polyline-${id}`}
                positions={next_poly}
                pathOptions={{
                  color: RoutalPalette.primary40,
                }}
                className={``}
                eventHandlers={{
                  click: handleOnClick,
                  dblclick: handleOnDoubleClick,
                }}
                weight={5}
              />
            </Pane>
          )}
          {future_poly && (
            <Pane name="future" style={{ zIndex: 1002 }}>
              <Polyline // Future
                key={`route-future-polyline-${id}`}
                positions={future_poly}
                pathOptions={{
                  color: RoutalPalette.primary00,
                }}
                className={``}
                eventHandlers={{
                  click: handleOnClick,
                  dblclick: handleOnDoubleClick,
                }}
                weight={4}
              />
            </Pane>
          )}
        </>
      )}
      <Pane name="markers" style={{ zIndex: 1004 }}>
        {React.Children.map(
          (childArray || []).filter(
            // @ts-expect-error
            (ch) => ch !== undefined && ch !== null && ch !== false
          ),
          (ch: any, _idx) => {
            return React.cloneElement(ch, { color: RoutalPalette.routeBaseColor });
          }
        )}
      </Pane>
    </>
  );
};

export default withStyles(styles)(RouteDrivers);
