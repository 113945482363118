import { ValueObject } from './ValueObject';

export interface CoordinateProps {
  lat?: number;
  lng?: number;
}

export class Coordinate extends ValueObject<CoordinateProps> {
  get lat(): number | undefined {
    return this.props.lat;
  }

  set lat(lat: number | undefined) {
    this.props.lat = lat;
  }

  get lng(): number | undefined {
    return this.props.lng;
  }

  set lng(lng: number | undefined) {
    this.props.lng = lng;
  }

  isValid(): boolean {
    return !!this.lat && !!this.lng && Math.abs(this.lat) <= 90 && Math.abs(this.lng) <= 180;
  }

  static areCoordinatesValid(lat: number, lng: number): boolean {
    return !!lat && !!lng && Math.abs(lat) <= 90 && Math.abs(lng) <= 180;
  }

  override equals(coordinate: Coordinate): boolean {
    return this.lat === coordinate.lat && this.lng === coordinate.lng;
  }

  override toString(): string {
    if (!this.isValid()) {
      throw new Error(`Lat or Lng are not defined`);
    }
    return `${this.props.lat}, ${this.props.lng}`;
  }

  toObject(): { lat: number; lng: number } {
    if (!this.isValid()) {
      throw new Error(`Lat or Lng are not defined`);
    }
    return { lat: this.props.lat!, lng: this.props.lng! };
  }

  toArray(): [number, number] {
    if (!this.isValid()) {
      throw new Error(`Lat or Lng are not defined`);
    }
    return [this.props.lat!, this.props.lng!];
  }

  static fromArray(coords: [number, number]): Coordinate {
    const coord = new Coordinate({ lat: coords[0], lng: coords[1] });
    if (!coord.isValid()) {
      throw new Error(`Lat or Lng are not defined`);
    }
    return coord;
  }

  private constructor(props: CoordinateProps) {
    super(props);
  }

  static create(props: CoordinateProps): Coordinate {
    return new Coordinate(props);
  }
}
