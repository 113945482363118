/* eslint-disable no-underscore-dangle */
const isEntity = (v: any): v is Entity<any> => v instanceof Entity;

export abstract class Entity<T> {
  protected readonly _id: string | undefined;

  readonly props: T;

  constructor(props: T, id?: string) {
    this._id = id;
    this.props = props;
  }

  equals(object?: Entity<T>): boolean {
    if (object == null || object === undefined) {
      return false;
    }

    if (this === object) {
      return true;
    }

    if (!isEntity(object)) {
      return false;
    }

    return this._id === object._id;
  }

  getValue() {
    return this.props;
  }
}

export default {};
