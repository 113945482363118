import React from 'react';
import styled from 'styled-components';

type VariantType = `text` | `h1` | `h2` | `h3` | `h4` | `label` | `button` | `small`;
type TagType = `p` | `div` | `span`;

export interface RCTextProps {
  id?: string;
  variant?: VariantType;
  tagName?: TagType;
  isBold?: boolean;
  style?: React.CSSProperties;
  noWrap?: boolean;
}

const Element = styled.div`
  font-family: Nunito Sans;
  color: #393939;
  ${(props: RCTextProps) => {
    if (props.noWrap) {
      return `
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      `;
    }
    return ``;
  }}
  ${(props: RCTextProps) => {
    switch (props.variant) {
      case `h1`:
        return `
            color: #393939;
            font-size: 24px;
            font-weight: 600;
            cursor: default;
          `;
      case `h2`:
        return `
            color: #393939;
            font-size: 18px;
            font-weight: 400;
            cursor: default;
          `;
      case `h3`:
        return `
            color: #393939;
            font-size: 16px;
            font-weight: 400;
            cursor: default;
          `;
      case `h4`:
        return `
            color: #393939;
            font-size: 14px;
            font-weight: 400;
            cursor: default;
          `;
      case `label`:
        return `
            color: #AAAAAA;
            font-size: 12px;
            cursor: default;
          `;
      case `button`:
        return `
            color: #F8671D;
            font-size: 13px;
            cursor: pointer;
          `;
      case `small`:
        return `
            color: #393939;
            font-size: 10px;
            cursor: default;
          `;
      case `text`:
      default:
        return `
            color: #393939;
            // This was set by Jordi, but it's not the same as the current designs -> padding: 4px;
            font-size: 13px;
            cursor: default;
          `;
    }
  }}
  ${(props: RCTextProps) => {
    if (props.isBold) {
      return `font-weight: 600;`;
    }
    return ``;
  }}
` as any; // TODO: This should be typed correctly.

/**
 * General purpose text component. Allows different types and tagNames.
 * component.
 */
const RCText = ({
  id,
  variant = `text`,
  tagName = `div`,
  children,
  noWrap = false,
  isBold = false,
  style,
  onClick,
}: React.PropsWithChildren<RCTextProps> & React.DOMAttributes<RCTextProps>) => {
  return (
    <Element
      id={id}
      as={tagName}
      variant={variant}
      noWrap={noWrap}
      isBold={isBold}
      onClick={onClick}
      style={{ ...style }}
    >
      {children}
    </Element>
  );
};

export default RCText;
