import { Grid } from '@mui/material';
import { HSpacer, RoutalPalette } from '@nx-smartmonkey/ui';

import BadCondition from '../assets/svg/customers-survey-rating-tags/BadCondition';
import BadDriver from '../assets/svg/customers-survey-rating-tags/BadDriver';
import BadInfo from '../assets/svg/customers-survey-rating-tags/BadInfo';
import BadTime from '../assets/svg/customers-survey-rating-tags/BadTime';
import GoodCondition from '../assets/svg/customers-survey-rating-tags/GoodCondition';
import GoodDriver from '../assets/svg/customers-survey-rating-tags/GoodDriver';
import GoodInfo from '../assets/svg/customers-survey-rating-tags/GoodInfo';
import GoodTime from '../assets/svg/customers-survey-rating-tags/GoodTime';
import { CustomersSurvey, RatingTags } from '../domain/customersSurvey/CustomersSurvey';
import { Text } from '../react-components/Text';

export const getStopSurveyRatingIcon = ({ rating = 0, ratingTag }: { rating?: number; ratingTag?: RatingTags }) => {
  const isBad = rating <= 2;
  switch (ratingTag) {
    case `time`:
      return isBad ? BadTime : GoodTime;
    case `condition`:
      return isBad ? BadCondition : GoodCondition;
    case `driver`:
      return isBad ? BadDriver : GoodDriver;
    case `info`:
      return isBad ? BadInfo : GoodInfo;
    default:
      return null;
  }
};

export const getStopSurveyRatingTagText = ({
  rating = 0,
  ratingTag,
}: {
  rating?: number;
  ratingTag?: RatingTags;
}): string => {
  const isBad = rating <= 2;
  return ratingTag ? `stop.survey.rating_tag.${isBad ? `bad` : `good`}.${ratingTag}` : ``;
};

export const getStopSurveyRatingTagInfo = (survey: CustomersSurvey) => {
  const Icon = getStopSurveyRatingIcon({ rating: survey.rating, ratingTag: survey.rating_tag });
  return (
    <Grid container direction="row" alignItems="center">
      {Icon ? <Icon style={{ width: `24px`, color: RoutalPalette.neutral40 }} /> : null}
      <HSpacer small />
      <Text intlId={getStopSurveyRatingTagText({ rating: survey.rating, ratingTag: survey.rating_tag })} />
    </Grid>
  );
};
