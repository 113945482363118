import { PropsWithChildren, useReducer } from 'react';

import { TranslationsContext } from './context';
import { TranslationsReducer, getInitialState } from './reducer';

export const TranslationsContextProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const initialState = getInitialState();
  const [state, dispatch] = useReducer(TranslationsReducer, initialState);
  return (
    <TranslationsContext.Provider
      value={{
        translationsState: state,
        translationsDispatch: dispatch,
      }}
    >
      {children}
    </TranslationsContext.Provider>
  );
};
