import { Grid, Radio } from '@mui/material';
import { Language } from '@nx-smartmonkey/shared/domain';
import { dateFormatLongDate } from '@nx-smartmonkey/shared/helpers';
import { RoutalPalette, VSpacer } from '@nx-smartmonkey/ui';
import { debounce } from 'lodash';

import React from 'react';
import { intl } from '../../../helpers/IntGlobalProvider';
import InputText from '../../../react-components/InputText';
import { Text } from '../../../react-components/Text';
import { InputDatePicker } from '../../../react-components/input-date-picker/InputDatePicker';
import { SearchFilterComponent } from '../filter';
import { SearchFilterOperators } from '../filter/SearchFilter';
import { SearchFilterDate, SearchFilterDateValue } from './SearchFilterDate';

const TextSpacer = () => <div style={{ width: `4px` }} />;

interface GetSearchFilterDateRelativeOptionsProps {
  operator: SearchFilterOperators;
  text: string;
  formatText: (value: any, changeSign: () => void) => React.ReactElement;
  hasValue: boolean;
  isSelected: boolean;
}

const getSearchFilterRelativeDateOptions = (filter: SearchFilterDate): GetSearchFilterDateRelativeOptionsProps[] => [
  {
    operator: `gt`,
    text: intl.formatMessage({ id: `search_filters.gt` }),
    formatText: (value, changeSign) => {
      return (
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Text
              intlId={`search_filters.date.relative.${filter.isPositive() ? `positive` : `negative`}.part_1`}
              variant="button"
              onClick={() => changeSign()}
            />
          </Grid>

          <TextSpacer />

          <Grid item>{value}</Grid>

          <TextSpacer />

          <Grid item>
            <Text
              intlId={`search_filters.date.relative.${filter.isPositive() ? `positive` : `negative`}.part_2`}
              variant="button"
              onClick={() => changeSign()}
            />
          </Grid>
        </Grid>
      );
    },
    hasValue: true,
    isSelected: filter.isRelative() && filter.isOperator(`gt`),
  },
  {
    operator: `lt`,
    text: intl.formatMessage({ id: `search_filters.lt` }),
    formatText: (value, changeSign) => {
      return (
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Text
              intlId={`search_filters.date.relative.${filter.isPositive() ? `positive` : `negative`}.part_1`}
              variant="button"
              onClick={() => changeSign()}
            />
          </Grid>

          <TextSpacer />

          <Grid item>{value}</Grid>

          <TextSpacer />

          <Grid item>
            <Text
              intlId={`search_filters.date.relative.${filter.isPositive() ? `positive` : `negative`}.part_2`}
              variant="button"
              onClick={() => changeSign()}
            />
          </Grid>
        </Grid>
      );
    },
    hasValue: true,
    isSelected: filter.isRelative() && filter.isOperator(`lt`),
  },
  {
    operator: `eq`,
    text: intl.formatMessage({ id: `search_filters.exactly` }),
    formatText: (value, changeSign) => {
      return (
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Text
              intlId={`search_filters.date.relative.${filter.isPositive() ? `positive` : `negative`}.part_1`}
              variant="button"
              onClick={() => changeSign()}
            />
          </Grid>

          <TextSpacer />

          <Grid item>{value}</Grid>

          <TextSpacer />

          <Grid item>
            <Text
              intlId={`search_filters.date.relative.${filter.isPositive() ? `positive` : `negative`}.part_2`}
              variant="button"
              onClick={() => changeSign()}
            />
          </Grid>
        </Grid>
      );
    },
    hasValue: true,
    isSelected: filter.isRelative() && filter.isOperator(`eq`),
  },
];

interface GetSearchFilterDateAbsoluteOptionsProps {
  operator: SearchFilterOperators;
  text: string;
  hasValue: boolean;
  isSelected: boolean;
}

const getSearchFilterAbsoluteDateOptions = (filter: SearchFilterDate): GetSearchFilterDateAbsoluteOptionsProps[] => [
  {
    operator: `gt`,
    text: intl.formatMessage({ id: `search_filters.gt` }),
    hasValue: true,
    isSelected: filter.isAbsolute() && filter.isOperator(`gt`),
  },
  {
    operator: `lt`,
    text: intl.formatMessage({ id: `search_filters.lt` }),
    hasValue: true,
    isSelected: filter.isAbsolute() && filter.isOperator(`lt`),
  },
  {
    operator: `eq`,
    text: intl.formatMessage({ id: `search_filters.eq` }),
    hasValue: true,
    isSelected: filter.isAbsolute() && filter.isOperator(`eq`),
  },
  {
    operator: `unknown`,
    text: intl.formatMessage({ id: `search_filters.unknown` }),
    hasValue: false,
    isSelected: filter.isAbsolute() && filter.isOperator(`unknown`),
  },
  {
    operator: `known`,
    text: intl.formatMessage({ id: `search_filters.known` }),
    hasValue: false,
    isSelected: filter.isAbsolute() && filter.isOperator(`known`),
  },
];

interface SearchFilterDateComponentProps {
  filter: SearchFilterDate;
  filterPosition: number;
  language?: Language;
  colors: {
    primaryColor: string;
    secondaryColor: string;
  };
  onChange: (filter: SearchFilterDate) => void;
  onRemoveFilter: () => void;
}

export const SearchFilterDateComponent = ({
  filter,
  filterPosition,
  language,
  colors,
  onChange,
  onRemoveFilter,
}: SearchFilterDateComponentProps) => {
  return (
    <SearchFilterComponent
      filter={filter}
      buttonId={`filter-date-${filterPosition}`}
      popoverContainerId={`filter-date-${filterPosition}-popover-container`}
      colors={colors}
      onRemoveFilter={onRemoveFilter}
      customResult={(_filter) => {
        const filter = _filter as SearchFilterDate;

        const operator = filter.getOperator();

        if (!filter.isValueValid()) {
          return (
            <Text
              style={{
                fontWeight: `normal`,
                cursor: `pointer`,
                color: `${RoutalPalette.error.medium}`,
              }}
              intlId={`search_filters.${operator}.result`}
              intlValues={{
                field: intl.formatMessage({ id: `search_filters.filter.${filter.getField()}` }),
                value: `"${intl.formatMessage({ id: `search_filters.missing_value` })}"`,
              }}
            />
          );
        }

        if (filter.isRelative()) {
          return (
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Text
                  style={{ cursor: `pointer` }}
                  intlId={`search_filters.${operator === `eq` ? `exactly` : operator}`}
                />
              </Grid>

              <TextSpacer />

              <Grid item>
                <Text style={{ cursor: `pointer` }}>
                  {`"${intl.formatMessage({
                    id: `search_filters.date.relative.${filter.isPositive() ? `positive` : `negative`}.part_1`,
                  })}`}
                </Text>
              </Grid>

              <TextSpacer />

              <Grid item>{Math.abs(filter.getValue())}</Grid>

              <TextSpacer />

              <Grid item>
                <Text style={{ cursor: `pointer` }}>
                  {`${intl.formatMessage({
                    id: `search_filters.date.relative.${filter.isPositive() ? `positive` : `negative`}.part_2`,
                  })}"`}
                </Text>
              </Grid>
            </Grid>
          );
        } else {
          return (
            <Text
              style={{
                fontWeight: `normal`,
                cursor: `pointer`,
              }}
              intlId={`search_filters.${operator}.result`}
              intlValues={{
                field: intl.formatMessage({ id: `search_filters.filter.${filter.getField()}` }),
                value: `"${dateFormatLongDate(filter.getValue())}"`,
              }}
            />
          );
        }
      }}
    >
      <Grid container direction="column" style={{ padding: `16px 0` }}>
        <Grid item style={{ padding: `0 16px` }}>
          <Text isBold intlId="search_filter.date.relative" />
        </Grid>

        <VSpacer small />

        {getSearchFilterRelativeDateOptions(filter).map((option) => {
          return (
            <Grid container direction="row" wrap="nowrap" style={{ padding: `0 16px`, minWidth: `250px` }}>
              <Grid
                item
                container
                direction="row"
                wrap="nowrap"
                alignItems="center"
                style={{
                  cursor: `pointer`,
                }}
                onClick={(event: any) => {
                  event.stopPropagation();
                  filter.setOperator(option.operator);
                  if (filter.isAbsolute()) {
                    filter.setValue(1 as SearchFilterDateValue);
                  }
                  onChange(filter);
                }}
              >
                <Grid item>
                  <Radio
                    style={{
                      color: colors.primaryColor,
                    }}
                    checked={option.isSelected}
                    onChange={(event: any) => {
                      event.stopPropagation();
                      filter.setOperator(option.operator);
                      if (filter.isAbsolute()) {
                        filter.setValue(1 as SearchFilterDateValue);
                      }
                      onChange(filter);
                    }}
                  />
                </Grid>

                <Grid item>
                  <Text style={{ cursor: `pointer` }}>{option.text}</Text>
                </Grid>
              </Grid>

              {option.isSelected ? (
                <>
                  <TextSpacer />

                  {option.formatText(
                    <InputText
                      autoFocus
                      type="number"
                      inputContainerStyles={{
                        width: `50px`,
                        backgroundColor: RoutalPalette.neutral00,
                      }}
                      focusedBorderColor={colors.primaryColor}
                      inputStyles={{
                        color: RoutalPalette.secondary40,
                      }}
                      inputProps={{
                        min: 1,
                        step: 1,
                      }}
                      fieldValue={Math.abs(filter.getValue() as number)}
                      onChange={debounce((number: any) => {
                        filter.setValue((filter.isPositive() ? number : -number) as SearchFilterDateValue);
                        onChange(filter);
                      }, 300)}
                    />,
                    () => {
                      if (filter.isPositive()) {
                        filter.setValue(-filter.getValue() as SearchFilterDateValue);
                      } else {
                        filter.setValue(Math.abs(filter.getValue() as number) as SearchFilterDateValue);
                      }
                      onChange(filter);
                    }
                  )}
                </>
              ) : null}
            </Grid>
          );
        })}
      </Grid>

      <VSpacer medium />

      <Grid container direction="column">
        <Grid item style={{ padding: `0 16px` }}>
          <Text isBold intlId="search_filter.date.absolute" />
        </Grid>

        <VSpacer small />

        {getSearchFilterAbsoluteDateOptions(filter).map((option) => {
          return (
            <Grid container direction="column" wrap="nowrap" style={{ padding: `0 16px`, minWidth: `250px` }}>
              <Grid
                item
                container
                direction="row"
                wrap="nowrap"
                alignItems="center"
                style={{
                  cursor: `pointer`,
                }}
                onClick={(event: any) => {
                  event.stopPropagation();
                  filter.setOperator(option.operator);
                  filter.setValue(new Date().toString() as SearchFilterDateValue);
                  onChange(filter);
                }}
              >
                <Grid item>
                  <Radio
                    style={{
                      color: colors.primaryColor,
                    }}
                    checked={option.isSelected}
                    onChange={(event: any) => {
                      event.stopPropagation();
                      filter.setOperator(option.operator);
                      filter.setValue(new Date().toString() as SearchFilterDateValue);
                      onChange(filter);
                    }}
                  />
                </Grid>

                <Grid item>
                  <Text style={{ cursor: `pointer` }}>{option.text}</Text>
                </Grid>
              </Grid>

              {option.isSelected && option.hasValue ? (
                <Grid item>
                  <InputDatePicker
                    portalId={`filter-date-${filterPosition}-popover-container`}
                    dateValue={new Date(filter.getValue())}
                    onChange={(date: Date) => {
                      filter.setValue(date.toString() as SearchFilterDateValue);
                      onChange(filter);
                    }}
                    locale={language?.value}
                  />
                </Grid>
              ) : null}
            </Grid>
          );
        })}
      </Grid>
    </SearchFilterComponent>
  );
};
