import { CustomersSurvey } from '../../domain/customersSurvey/CustomersSurvey';
import { CustomersSurveyDTO } from '../../domain/customersSurvey/CustomersSurveyDTO';

export class CustomersSurveyMapper {
  static toDomain(props: CustomersSurveyDTO): CustomersSurvey {
    const { id, stop_id, driver_id, rating, rating_tag, comments, deleted, created_at, updated_at, deleted_at } = props;

    return CustomersSurvey.create(
      {
        stop_id,
        driver_id,
        rating,
        rating_tag,
        comments,
        deleted,
        created_at,
        updated_at,
        deleted_at,
      },
      id
    );
  }
  static toDTO(survey: CustomersSurvey): CustomersSurveyDTO {
    return {
      id: survey.id,
      stop_id: survey.stop_id,
      driver_id: survey.driver_id,
      rating: survey.rating,
      rating_tag: survey.rating_tag,
      comments: survey.comments,
      deleted: survey.deleted,
      created_at: survey.created_at,
      updated_at: survey.updated_at,
      deleted_at: survey.deleted_at,
    };
  }
}
