import { SvgIcon } from '@mui/material';
import React from 'react';

interface NoStopsSupervisorsProps {
  color?: string;
  style?: React.CSSProperties;
}

export const NoStopsSupervisors = ({ color = `#CDCED0`, style = {}, ...props }: NoStopsSupervisorsProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 337.48 217.83" style={{ width: `127px`, height: `190px`, ...style }}>
      <g>
        <g>
          <path
            fill={color}
            strokeWidth="0"
            d="m92.7,111.87c-7.72,0-14,6.28-14,14s6.28,14,14,14,14-6.28,14-14-6.28-14-14-14Zm0,24c-5.51,0-10-4.49-10-10s4.49-10,10-10,10,4.49,10,10-4.49,10-10,10Z"
          />
          <path
            fill={color}
            strokeWidth="0"
            d="m179.7,51.87c0-7.72-6.28-14-14-14s-14,6.28-14,14,6.28,14,14,14,14-6.28,14-14Zm-24,0c0-5.51,4.49-10,10-10s10,4.49,10,10-4.49,10-10,10-10-4.49-10-10Z"
          />
          <path
            fill={color}
            strokeWidth="0"
            d="m179.7,169.87c-7.72,0-14,6.28-14,14s6.28,14,14,14,14-6.28,14-14-6.28-14-14-14Zm0,24c-5.51,0-10-4.49-10-10s4.49-10,10-10,10,4.49,10,10-4.49,10-10,10Z"
          />
          <path
            fill={color}
            strokeWidth="0"
            d="m282.7,80.84c-7.72,0-14,6.28-14,14s6.28,14,14,14,14-6.28,14-14-6.28-14-14-14Zm0,24c-5.51,0-10-4.49-10-10s4.49-10,10-10,10,4.49,10,10-4.49,10-10,10Z"
          />
        </g>
        <text
          fill={color}
          fontFamily="Geist-Bold, Geist"
          fontSize="12px"
          fontWeight="700"
          transform="translate(89.94 129.38)"
        >
          <tspan x="0" y="0">
            ?
          </tspan>
        </text>
        <text
          fill={color}
          fontFamily="Geist-Bold, Geist"
          fontSize="12px"
          fontWeight="700"
          transform="translate(162.94 55.97)"
        >
          <tspan x="0" y="0">
            ?
          </tspan>
        </text>
        <text
          fill={color}
          fontFamily="Geist-Bold, Geist"
          fontSize="12px"
          fontWeight="700"
          transform="translate(279.94 98.94)"
        >
          <tspan x="0" y="0">
            ?
          </tspan>
        </text>
        <text
          fill={color}
          fontFamily="Geist-Bold, Geist"
          fontSize="12px"
          fontWeight="700"
          transform="translate(176.11 187.97)"
        >
          <tspan x="0" y="0">
            ?
          </tspan>
        </text>
        <path
          fill={color}
          strokeWidth="0"
          d="m313.75,4.6H23.76c-5.61,0-10.63,2.58-13.94,6.61-2.58,3.11-4.13,7.11-4.13,11.45v26.74s.02,0,.03,0v145.8c0,9.94,8.09,18.03,18.03,18.03h289.99c9.94,0,18.03-8.09,18.03-18.03V22.63c0-9.94-8.09-18.03-18.03-18.03Zm0,4.06c2.39,0,4.65.61,6.62,1.67l-35.21,29.88-31.06-31.55h59.65Zm-143.95,0l-68.26,62.06L44.65,8.66h125.15ZM51.56,209.02l65.32-57.6,21.11,21.6-41.82,36h-44.61ZM327.75,48.9l-37.23,34.32c1.12.75,2.12,1.66,2.97,2.7l34.25-31.58v112.73l-19.3,18.31-58.92-58.92,23.42-21.59c-.96-.93-1.79-2.01-2.45-3.18l-26.74,24.66,64.62,64.62,19.38-18.38v22.44c0,7.72-6.28,14-14,14h-24.31l-67.56-64.67-33.07,28.91c1.01.87,1.9,1.89,2.63,3.01l30.31-26.5,61.89,59.25h-129.66l16.61-14.52c-1.02-.87-1.9-1.88-2.64-3.01l-20.05,17.53h-45.61l41.53-35.75-26.75-27.37-70.4,63.33h-22.92c-6.55,0-12.04-4.51-13.59-10.59l74.74-63.12-2.59-3.03L9.73,194.82V32.94l54.42,60.21h0l19.74,21.84c1.05-.85,2.24-1.56,3.51-2.08l-20.43-22.61h0S9.73,26.97,9.73,26.97v-4.34c0-3.37,1.19-6.46,3.18-8.88,2.57-3.11,6.45-5.09,10.79-5.09h15.52l62.05,67.7L175.74,8.66h35.81l-37.56,31.95c1.08.8,2.04,1.75,2.86,2.82l39.04-33.21,49.53,48.78-95.43,85.19-49.01-53.22,34.6-29.43c-.92-.97-1.71-2.07-2.33-3.27l-38.03,32.35,54.49,59.18,101.55-90.65-51.26-50.48h28.48l36.43,37,38.74-32.88c2.53,2.53,4.09,6.03,4.09,9.88v26.24Z"
        />
      </g>
    </SvgIcon>
  );
};
