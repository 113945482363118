import { Grid, Radio } from '@mui/material';
import { RoutalPalette } from '@nx-smartmonkey/ui';
import { debounce } from 'lodash';
import { intl } from '../../../helpers/IntGlobalProvider';
import InputText from '../../../react-components/InputText';
import { Text } from '../../../react-components/Text';
import { SearchFilterComponent } from '../filter';
import { SearchFilterOperators } from '../filter/SearchFilter';
import { SearchFilterNumber } from './SearchFilterNumber';

interface GetSearchFilterNumberOptionsProps {
  operator: SearchFilterOperators;
  text: string;
  hasValue: boolean;
  isSelected: boolean;
}

const getSearchFilterNumberOptions = (filter: SearchFilterNumber): GetSearchFilterNumberOptionsProps[] => [
  {
    operator: `gt`,
    text: intl.formatMessage({ id: `search_filters.gt` }),
    hasValue: true,
    isSelected: filter.isOperator(`gt`),
  },
  {
    operator: `lt`,
    text: intl.formatMessage({ id: `search_filters.lt` }),
    hasValue: true,
    isSelected: filter.isOperator(`lt`),
  },
  {
    operator: `eq`,
    text: intl.formatMessage({ id: `search_filters.eq` }),
    hasValue: true,
    isSelected: filter.isOperator(`eq`),
  },
  {
    operator: `ne`,
    text: intl.formatMessage({ id: `search_filters.ne` }),
    hasValue: true,
    isSelected: filter.isOperator(`ne`),
  },
  {
    operator: `unknown`,
    text: intl.formatMessage({ id: `search_filters.unknown` }),
    hasValue: false,
    isSelected: filter.isOperator(`unknown`),
  },
  {
    operator: `known`,
    text: intl.formatMessage({ id: `search_filters.known` }),
    hasValue: false,
    isSelected: filter.isOperator(`known`),
  },
];

interface SearchFilterNumberComponentProps {
  filter: SearchFilterNumber;
  filterPosition: number;
  colors: {
    primaryColor: string;
    secondaryColor: string;
  };
  onChange: (filter: SearchFilterNumber) => void;
  onRemoveFilter: () => void;
}

export const SearchFilterNumberComponent = ({
  filter,
  filterPosition,
  colors,
  onChange,
  onRemoveFilter,
}: SearchFilterNumberComponentProps) => {
  return (
    <SearchFilterComponent
      filter={filter}
      buttonId={`filter-number-${filterPosition}`}
      colors={colors}
      onRemoveFilter={onRemoveFilter}
    >
      {getSearchFilterNumberOptions(filter).map((option) => {
        return (
          <Grid container direction="column" wrap="nowrap" style={{ padding: `0 16px`, minWidth: `250px` }}>
            <Grid
              item
              container
              direction="row"
              wrap="nowrap"
              alignItems="center"
              style={{
                cursor: `pointer`,
              }}
              onClick={(event: any) => {
                event.stopPropagation();
                filter.setOperator(option.operator);
                onChange(filter);
              }}
            >
              <Grid item>
                <Radio
                  style={{
                    color: colors.primaryColor,
                  }}
                  checked={option.isSelected}
                  onChange={(event: any) => {
                    event.stopPropagation();
                    filter.setOperator(option.operator);
                    onChange(filter);
                  }}
                />
              </Grid>

              <Grid item>
                <Text style={{ cursor: `pointer` }}>{option.text}</Text>
              </Grid>
            </Grid>

            {option.isSelected && option.hasValue ? (
              <Grid item>
                <InputText
                  autoFocus
                  type="number"
                  inputContainerStyles={{
                    width: `100%`,
                    backgroundColor: RoutalPalette.neutral00,
                  }}
                  focusedBorderColor={colors.primaryColor}
                  inputStyles={{
                    color: RoutalPalette.secondary40,
                  }}
                  fieldValue={filter.getComputedValue() ?? ``}
                  onChange={debounce((text: any) => {
                    filter.formatValue(text !== `` ? text : null);
                    onChange(filter);
                  }, 300)}
                />
              </Grid>
            ) : null}
          </Grid>
        );
      })}
    </SearchFilterComponent>
  );
};
