import { Entity, HexColor } from '@nx-smartmonkey/shared/domain';
import { removeEmpty } from '@nx-smartmonkey/shared/helpers';
import { StopStatus } from './StopStatus';
import { StopTypes } from './StopTypes';

export interface StopUpdateProps {
  label?: string;
  status?: StopStatus;
  lat?: number;
  lng?: number;
  created_at?: Date;
}

export interface StopFlatProps {
  label?: string;
  type: StopTypes;
  status: StopStatus;
  lat?: number;
  lng?: number;
  color?: HexColor;
  created_at?: Date;
}

export class StopFlat extends Entity<StopFlatProps> {
  get id(): string {
    return this._id!;
  }
  get type(): StopTypes {
    return this.props.type;
  }
  get status(): StopStatus {
    return this.props.status;
  }
  get label(): string | undefined {
    return this.props.label;
  }
  get lat(): number | undefined {
    return this.props.lat;
  }
  get lng(): number | undefined {
    return this.props.lng;
  }
  get color(): HexColor | undefined {
    return this.props.color;
  }
  get created_at(): Date | undefined {
    return this.props.created_at;
  }
  private constructor(props: StopFlatProps, id?: string) {
    super(props, id);
  }
  static create(props: StopFlatProps, id?: string): StopFlat {
    return new StopFlat(props, id);
  }
  isValidLocation(): boolean {
    return !!(this.lat && this.lng);
  }

  update(props: StopUpdateProps) {
    removeEmpty(props);

    if (props.label !== undefined) this.props.label = props.label;
    if (props.status !== undefined) this.props.status = props.status;
    if (props.lat !== undefined) this.props.lat = props.lat;
    if (props.lng !== undefined) this.props.lng = props.lng;
    if (props.created_at !== undefined) this.props.created_at = props.created_at;
  }
}
