import CloseIcon from '@mui/icons-material/Close';
import {
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Button as MaterialButton,
  Dialog as MaterialDialog,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';

const styles = (_theme: any) => ({
  modalButton: {
    padding: `7px !important`,
    position: `absolute !important` as any,
    width: `28px !important`,
    height: `28px !important`,
    top: `0px !important`,
    right: `0px !important`,
    color: `#57637C !important`,
    minWidth: `20px`,
  },
  actions: {},
});

type ScrollableDialogProps = {
  classes: {
    modalButton: string;
    actions: string;
  };
  modal?: boolean;
  dialogActions?: React.ReactElement;
  dialogTitle?: React.ReactElement;
  dialogContent?: React.ReactElement;
};

const ScrollableDialog = ({
  classes,
  modal,
  dialogActions,
  dialogTitle,
  dialogContent,
  onClose,
  ...other
}: ScrollableDialogProps & DialogProps) => {
  const handleOnClose = () => {
    // @ts-expect-error
    if (onClose) onClose();
  };

  return (
    <MaterialDialog {...other} onClose={handleOnClose}>
      {modal && (
        <MaterialButton onClick={handleOnClose} className={classes.modalButton}>
          <CloseIcon />
        </MaterialButton>
      )}
      {dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
      {dialogContent && <DialogContent>{dialogContent}</DialogContent>}

      {dialogActions && <DialogActions className={classes.actions}>{dialogActions}</DialogActions>}
    </MaterialDialog>
  );
};

export default withStyles(styles)(ScrollableDialog);
