import { Language } from '@nx-smartmonkey/shared/domain';
import { IntlShape } from 'react-intl';
import { sessionService } from '../../application/SessionService';
import { TranslationsActionType } from '../../context/translations/actions';
import { useTranslationsContext } from '../../context/translations/context';
import { useRetrieveTranslation } from './useRetrieveTranslation';

type UseSetActiveLanguageProps = {
  language: Language;
};

type UseSetActiveLanguageResponse = {
  run: ({ language }: UseSetActiveLanguageProps) => void;
  data?: IntlShape;
};

// Hooks act as 'Adapter' layer.
export const useSetActiveLanguage = (): UseSetActiveLanguageResponse => {
  const { translationsState, translationsDispatch } = useTranslationsContext();
  const { run: retrieveTranslation } = useRetrieveTranslation();

  return {
    run: ({ language }: UseSetActiveLanguageProps) => {
      if (translationsState.translations[language.value]) {
        sessionService.setLanguage({ language });
        translationsDispatch({ type: TranslationsActionType.SET_ACTIVE_LANGUAGE, payload: { language } });
      } else {
        retrieveTranslation({ language });
      }
    },
    data: translationsState.activeLanguage,
  };
};
