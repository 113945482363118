import { ServiceResponse } from '@nx-smartmonkey/shared/hooks';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { stopService } from '../../application/StopService';
import { StopsActionType } from '../../context/stops/actions';
import { useStopsContext } from '../../context/stops/context';
import { StopFlat } from '../../domain/stop/StopFlat';
import useErrorMiddleware from '../errorMiddleware/useErrorMiddleware';
import { useRetrieveProject } from '../project/useRetrieveProject';
import { useShowSnackbarMessage } from '../snackbarMessage/useShowSnackbarMessage';
import { useIsExampleURL } from '../useIsExampleURL';

type UseRetrieveFlatStopsResponse = ServiceResponse<Function, StopFlat[]>;

// Hooks act as 'Adapter' layer.
export const useRetrieveFlatStops = (): UseRetrieveFlatStopsResponse => {
  const { isExampleURL } = useIsExampleURL();
  const { data: project } = useRetrieveProject();
  const { showSnackbarMessage } = useShowSnackbarMessage();
  const { stopsState, stopsDispatch } = useStopsContext();
  const { sessionExpiredLogout } = useErrorMiddleware();

  const {
    isInitialLoading: isLoading,
    refetch,
    isError,
    isSuccess,
  } = useQuery(
    [`supervisors_flat_stops`, stopsState.stops.predicatesBase64],
    (): Promise<Array<StopFlat>> => {
      const predicates = [
        ...stopsState.stops.predicates.map((predicate) => {
          if (predicate.field === `vehicle_id` && predicate.value === `unassigned`) {
            predicate.setValue(null);
          }
          return predicate;
        }),
      ];

      return stopService.fetchFlatStops({
        projectId: project!.id!,
        predicates,
        isExampleURL,
      });
    },
    {
      retry: false,
      staleTime: Infinity,
      enabled: project !== undefined,
      onSuccess: (flatStops) => {
        stopsDispatch({
          type: StopsActionType.SEARCH_FLAT_STOPS_SUCCESS,
          payload: {
            flatStops,
          },
        });
      },
      onError: (err: any) => {
        console.info(`Supervisors Retrieve Flat Stops Error: `, err?.response);

        if (err?.response?.status === 401) {
          sessionExpiredLogout();
          return;
        }

        showSnackbarMessage({
          messageIntlId: `infoerror.${err?.response?.data?.messageId ?? `network_error`}`,
        });
      },
    }
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopsState.stops.predicates]);

  return {
    error: isError,
    isLoading,
    success: isSuccess,
    run: refetch,
    data: stopsState.stops.flat ?? [],
  };
};
