import '../../styles/leaflet.css';

import L, { Browser as LBrowser, Map as LMapProps, LatLng, LatLngTuple, LeafletMouseEvent } from 'leaflet';
import _ from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { MapContainer as LMap, MapContainerProps, TileLayer, useMapEvents } from 'react-leaflet';

interface MapCanvasProps {
  map: LMapProps;
  dispatch?: React.Dispatch<Action>;
  zoomLevel?: number;
  onContextualMenu?: (...args: any[]) => any;
  showIfSelected?: boolean;
}

const MapCanvas = ({
  children,
  map,
  dispatch,
  zoomLevel,
  onContextualMenu,
  showIfSelected,
}: React.PropsWithChildren<MapCanvasProps>) => {
  useMapEvents({
    contextmenu(event) {
      if (onContextualMenu) onContextualMenu(event, map);
    },
  });

  // This method is in charge of rendering the children of the map.
  // Every time a new children is added 'add_bounds' will be called.
  const childArray = React.Children.toArray(children);
  const filteredArray = (childArray || []).filter((ch: any) => ch !== undefined && ch !== null && ch !== false);

  return (
    <>
      {React.Children.map(filteredArray, (ch: any) => {
        return React.cloneElement(ch, {
          map,
          dispatch,
          zoomLevel,
          showIfSelected,
        });
      })}
    </>
  );
};

MapCanvas.defaultProps = {
  onContextualMenu: () => {},
};

type MapState = {
  centerOffsetY: number;
  map: LMapProps | undefined;
  zoom: number;
  center: LatLngTuple | undefined;
  bounds: [LatLngTuple, LatLngTuple] | undefined;
  renderedElements: Set<string>;
  flyToAnimation: boolean;
};

export type Action =
  | {
      type: `SET_MAP`;
      map: LMapProps;
    }
  | {
      type: `SET_FLY_TO_ANIMATION`;
      flyToAnimation: boolean;
    }
  | {
      type: `ADD_BOUNDS`;
      bounds: [LatLngTuple, LatLngTuple];
      id?: string;
    }
  | {
      type: `SET_CENTER`;
      center: LatLngTuple | undefined;
    }
  | {
      type: `SET_CENTER_OFFSET_ZOOM`;
      center: LatLngTuple;
      centerOffsetY: number;
      zoom: number;
    }
  | {
      type: `SET_OFFSETS`;
      centerOffsetY: number;
    };

const combineBounds = (
  bounds1: [LatLngTuple, LatLngTuple],
  bounds2: [LatLngTuple, LatLngTuple]
): [LatLngTuple, LatLngTuple] => {
  return [
    [Math.min(bounds1[0][0], bounds2[0][0]), Math.min(bounds1[0][1], bounds2[0][1])],
    [Math.max(bounds1[1][0], bounds2[1][0]), Math.max(bounds1[1][1], bounds2[1][1])],
  ];
};

const reducer = (state: MapState, action: Action): MapState => {
  let targetLatLng: L.LatLng;
  let targetPoint: L.Point;
  switch (action.type) {
    case `SET_MAP`:
      if (state.center) action.map?.setView(state.center, state.zoom);
      return {
        ...state,
        map: action.map,
      };
    case `SET_FLY_TO_ANIMATION`:
      return {
        ...state,
        flyToAnimation: action.flyToAnimation,
      };

    //This case is in charge of setting the offset of the map.
    case `SET_OFFSETS`:
      return {
        ...state,
        centerOffsetY: action.centerOffsetY,
      };
    case `SET_CENTER`:
      if (action.center) {
        if (state.flyToAnimation) state.map?.flyTo(action.center, state.zoom);
        else state.map?.setView(action.center, state.zoom);
      }

      return {
        ...state,
        center: action.center,
      };
    //This case is in charge of centering the map on a stop and zooming in on it. It uses the offset to center it at the top.
    case `SET_CENTER_OFFSET_ZOOM`:
      targetLatLng = L.latLng(action.center);
      // Project into map coordinates so we can substract the offset
      if (!state.map) return state;
      targetPoint = state.map.project(targetLatLng, action.zoom).subtract([0, action.centerOffsetY || 0]);
      // Unproject into LatLng coordinates
      targetLatLng = state.map.unproject(targetPoint, action.zoom);
      if (state.flyToAnimation) state.map.flyTo(targetLatLng, action.zoom, { animate: true, duration: 1 });
      else state.map.setView(targetLatLng, action.zoom);

      return {
        ...state,
        center: action.center,
      };
    case `ADD_BOUNDS`:
      if (!action.id || !state.renderedElements.has(action.id)) {
        const bounds = state.bounds ? combineBounds(state.bounds, action.bounds) : action.bounds;
        const center: LatLngTuple = [(bounds[0][0] + bounds[1][0]) / 2, (bounds[0][1] + bounds[1][1]) / 2];
        if (state.map) debounceFitBounds(state.map, bounds, state.centerOffsetY || 0, state.flyToAnimation || false);
        return {
          ...state,
          bounds,
          center,
          renderedElements: action.id ? state.renderedElements.add(action.id) : state.renderedElements,
          zoom: state.map?.getZoom() || state.zoom,
        };
      }
      return state;
    default:
      console.info(`Unkown state action \${}`);
      return state;
  }
};

// This method takes care of adjusting all the stops and the route so that it is visible on the map.
// It is passed the centerOffsetY so that the centre of the map is not the centre of the map, but is shifted upwards.
// This method, being inside the ADD_BOUNDS, is executed every time a new element is added to the map.
const debounceFitBounds = _.debounce(
  (map: LMapProps, bounds: [LatLngTuple, LatLngTuple], centerOffsetY: number, flyToAnimation: boolean) => {
    try {
      if (flyToAnimation)
        map.flyToBounds(bounds, { paddingBottomRight: [0, -2 * centerOffsetY], paddingTopLeft: [0, 0] });
      else map.fitBounds(bounds, { paddingBottomRight: [0, -2 * centerOffsetY], paddingTopLeft: [0, 0] });
    } catch (error) {
      console.warn(`Error fitting bounds:`, error);
    }
  },
  300
);

const initialState = (): MapState => {
  return {
    map: undefined,
    zoom: 11,
    center: [41.3952165, 2.1258438],
    bounds: undefined,
    renderedElements: new Set(),
    centerOffsetY: 0,
    flyToAnimation: false,
  };
};

const MAP_MIN_ZOOM = 1;
const MAP_MAX_ZOOM = 19;

type MapProps = {
  center?: LatLngTuple | undefined; // Use a center if you want to zoom in on a stop, use undefined if you want to see the entire route.
  height?: number | string;
  scrollWheelZoom?: boolean;
  zoomLevel?: number;
  keyboard?: boolean;
  touchZoom?: boolean | `center`;
  loadingComponent?: React.ReactElement;
  centerOffsetY?: number; // Use this parameter to center the map at the the screen in Y axis. If positive the center will be over the actual center
  centerZoomLevel?: number;
  dragging?: boolean;
  flyToAnimation?: boolean;
  onContextualMenu?: (...args: any[]) => any;
  onLongClick?: (latlng: LatLng) => any;
  mapStyles?: React.CSSProperties;
  routeBounds?: [LatLngTuple, LatLngTuple];
};

/**
 * ======================= WARNING =======================
 * <StrictMode> renders twice in development mode.
 * This causes the map to be rendered twice, so the reducer
 * state is reset and map is lost.
 *
 * Please disable <StrictMode> when modifying map things.
 * Remember to enable it again when you are done.
 * ======================= WARNING =======================
 */
export const Map = ({
  children,
  center = undefined,
  height = 500,
  zoomLevel = 11,
  scrollWheelZoom = true,
  keyboard = true,
  touchZoom = false,
  loadingComponent,
  centerOffsetY,
  dragging,
  flyToAnimation,
  centerZoomLevel,
  onContextualMenu = () => {},
  onLongClick,
  mapStyles,
  routeBounds,
}: MapProps & MapContainerProps) => {
  const [map, setMap] = useState<LMapProps | null>(null);
  const [state, dispatch] = useReducer(reducer, null, initialState);

  // Store the map value in the reducer state
  useEffect(() => {
    if (map && !state.map) {
      dispatch({ type: `SET_MAP`, map });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    if (flyToAnimation && state.flyToAnimation !== flyToAnimation) {
      dispatch({ type: `SET_FLY_TO_ANIMATION`, flyToAnimation });
    }
  }, [flyToAnimation, state.flyToAnimation]);

  // Store the center Y offset value in the reducer state
  useEffect(() => {
    if (centerOffsetY) {
      dispatch({
        type: `SET_OFFSETS`,
        centerOffsetY: centerOffsetY || 0,
      });
    }
  }, [centerOffsetY]);

  useEffect(() => {
    // Center property is not set, so fits the bounds of the children.
    if (!center) {
      debounceFitBounds(
        state.map as LMapProps,
        state.bounds as [LatLngTuple, LatLngTuple],
        centerOffsetY || 0,
        flyToAnimation || false
      );
      dispatch({ type: `SET_CENTER`, center });
    }

    if (
      // There is no center in the reducer current state. It means that map was centered using the bounds of its children and now there is a specific center.
      (!state.center && center) ||
      // There is a center in the reducer current state. It means that map was centered using a previous center property but now has been changed.
      (state.center && center && state.center[0] !== center[0] && state.center[1] !== center[1])
    ) {
      dispatch({
        type: `SET_CENTER_OFFSET_ZOOM`,
        center,
        centerOffsetY: centerOffsetY || 0,
        zoom: centerZoomLevel || state.zoom,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center, centerOffsetY]);

  // Fit bounds on first render
  useEffect(() => {
    if (map && routeBounds) {
      debounceFitBounds(map, routeBounds, centerOffsetY || 0, flyToAnimation || false);
    }
  }, [map]);

  const handleContextMenu = function (e: LeafletMouseEvent) {
    if (onLongClick) onLongClick(e.latlng);
  };

  useEffect(() => {
    if (map && onLongClick) {
      map.on(`contextmenu`, handleContextMenu);
    }
  }, [map]);

  return (
    <div
      style={{
        position: `relative`,
        width: `100%`,
        height: typeof height === `number` ? `${height}px` : height,
      }}
    >
      {!map ? (
        <div
          style={{
            display: `flex`,
            position: `absolute`,
            width: `100%`,
            height: `100%`,
            flexDirection: `column`,
            alignItems: `center`,
            justifyContent: `center`,
            zIndex: 100,
            backgroundColor: `#fcf2e35e`,
          }}
        >
          {loadingComponent}
        </div>
      ) : null}

      <LMap
        doubleClickZoom={false}
        style={{ height: typeof height === `number` ? `${height}px` : height, ...mapStyles }}
        center={state.center}
        bounds={state.bounds}
        zoom={state.zoom}
        scrollWheelZoom={scrollWheelZoom}
        maxZoom={MAP_MAX_ZOOM}
        minZoom={MAP_MIN_ZOOM}
        zoomControl={false}
        keyboard={keyboard}
        touchZoom={touchZoom}
        ref={setMap}
        dragging={dragging}
        attributionControl={false}
      >
        <TileLayer
          attribution="&copy; <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> &copy; <a href='https://carto.com/attributions'>CARTO</a>"
          url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
          detectRetina
          maxZoom={MAP_MAX_ZOOM + 1}
          maxNativeZoom={LBrowser.retina ? MAP_MAX_ZOOM - 1 : MAP_MAX_ZOOM}
        />
        {state.map && (
          <MapCanvas dispatch={dispatch} map={state.map} zoomLevel={state.zoom} onContextualMenu={onContextualMenu}>
            {children}
          </MapCanvas>
        )}
      </LMap>
    </div>
  );
};
