import { RoutalSnackbar } from '@nx-smartmonkey/ui';
import { useEffect, useState } from 'react';

import { InfoErrorActionType } from '../context/infoError/actions';
import { useInfoErrorContext } from '../context/infoError/context';
import { intl } from '../helpers/IntGlobalProvider';
import { Text } from '../react-components/Text';

export const SnackbarComponent = () => {
  const { infoErrorState, infoErrorDispatch } = useInfoErrorContext();
  const [openSnack, setOpenSnack] = useState<boolean>(false);

  const { type, autoHideDuration, message } = infoErrorState;

  useEffect(() => {
    setOpenSnack(!!message);
  }, [message]);

  const onClose = () => {
    infoErrorDispatch({ type: InfoErrorActionType.INFOERROR_CLOSE_SNACKBAR });
  };

  return (
    <RoutalSnackbar
      variant={type}
      open={openSnack && !!message}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      containerStyle={{
        bottom: `12px`,
        left: `12px`,
        width: `100%`,
        zIndex: 9999,
        minWidth: `400px`,
        borderRadius: `10px`,
      }}
      showCloseButton
    >
      {message && <Text>{intl.formatMessage({ id: message, defaultMessage: message })}</Text>}
    </RoutalSnackbar>
  );
};
