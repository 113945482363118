import { GoogleMap, GoogleMapProps, RoutalLoader } from '@nx-smartmonkey/ui';

import configuration from '../services/configuration';

export const Map = (mapProps: Omit<GoogleMapProps, `apiKey`>) => {
  return (
    <div style={{ width: `100%`, height: `100%` }}>
      <GoogleMap
        apiKey={configuration.GOOGLE_MAPS_API_KEY}
        loadingComponent={<RoutalLoader width={60} />}
        {...mapProps}
      />
    </div>
  );
};
