import { SvgIcon } from '@mui/material';
import { RoutalPalette } from '@nx-smartmonkey/ui';
import React from 'react';

interface ReportIconProps extends React.SVGProps<SVGSVGElement> {
  isSelected?: boolean;
}

export default function ReportIcon({ isSelected = false, style, onClick }: ReportIconProps) {
  return (
    <SvgIcon onClick={onClick} viewBox="0 0 24 25" style={{ width: `24px`, height: `25px`, ...(style ?? {}) }}>
      <g>
        {!isSelected ? (
          <>
            <path
              d="M22.5559 1.40735V23.1922V23.309L19.921 21.7717L17.286 23.309L14.6407 21.7717L12.0058 23.309L9.37083 21.7717L6.73588 23.309L4.0906 21.7717L1.44531 23.309V23.1922V1.40735V1.24194L4.08026 2.77924L6.72554 1.24194L9.36049 2.77924L11.9954 1.24194L14.6407 2.77924L17.2757 1.24194L19.921 2.77924L22.5559 1.24194V1.40735Z"
              stroke={RoutalPalette.neutral60}
              fill="none"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.00195 7.84839H18.0091"
              stroke={RoutalPalette.neutral60}
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M6.00195 12.3047H18.0091"
              stroke={RoutalPalette.neutral60}
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M6.00195 16.7607H18.0091"
              stroke={RoutalPalette.neutral60}
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </>
        ) : (
          <>
            <path
              d="M22.5559 1.40723V23.1921V23.3088L19.921 21.7716L17.286 23.3088L14.6407 21.7716L12.0058 23.3088L9.37083 21.7716L6.73588 23.3088L4.0906 21.7716L1.44531 23.3088V23.1921V1.40723V1.24182L4.08026 2.77912L6.72554 1.24182L9.36049 2.77912L11.9954 1.24182L14.6407 2.77912L17.2757 1.24182L19.921 2.77912L22.5559 1.24182V1.40723Z"
              fill={RoutalPalette.primary40}
              stroke={RoutalPalette.primary40}
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.00195 7.84839H18.0091"
              stroke={RoutalPalette.primary00}
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M6.00195 12.3046H18.0091"
              stroke={RoutalPalette.primary00}
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M6.00195 16.7607H18.0091"
              stroke={RoutalPalette.primary00}
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </>
        )}
      </g>
    </SvgIcon>
  );
}
