import { useEffect, useRef } from 'react';

type Delay = number | null;
type TimerHandler = (...args: any[]) => void;

/**
 * Provides a declarative useInterval
 *
 * @param callback - Function that will be called every `delay` ms.
 * @param delay - Boolean that enables or disables the interval.
 * @param delay - Number representing the delay in ms. Set to `null` to "pause" the interval.
 */
export const useInterval = ({
  callback,
  enabled,
  delay,
}: {
  callback: TimerHandler;
  enabled: boolean;
  delay: Delay;
}) => {
  const savedCallbackRef = useRef<TimerHandler>();

  useEffect(() => {
    savedCallbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (enabled) {
      const handler = (...args: any[]) => savedCallbackRef.current!(...args);

      if (delay !== null) {
        const intervalId = setInterval(handler, delay);
        return () => clearInterval(intervalId);
      }
    }

    return () => {};
  }, [delay, enabled]);
};
