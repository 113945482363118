import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <>
    <path d="m12 7.9c2.3 0 4.1 1.8 4.1 4.1s-1.8 4.1-4.1 4.1-4.1-1.8-4.1-4.1 1.8-4.1 4.1-4.1m0-1.9c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6z" />
    <path d="M3.2,8.9V3.1h5.7V1.2H2.3c-0.5,0-1,0.4-1,1v6.7H3.2z" />
    <path d="M15,3.1h5.7v5.8h1.9V2.2c0-0.6-0.5-1-1-1H15V3.1z" />
    <path d="M8.9,20.7H3.2V15H1.3v6.6c0,0.5,0.5,1,1,1h6.6V20.7z" />
    <path d="M20.8,15v5.7H15v1.9h6.6c0.5,0,1-0.5,1-1V15H20.8z" />
  </>,
  `MapCrosshair`
);
