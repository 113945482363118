const classes = {
  large: {
    height: `32px`,
  },
  medium: {
    height: `16px`,
  },
  small: {
    height: `8px`,
  },
};

interface IVSpacerProps {
  small?: boolean;
  medium?: boolean;
  large?: boolean;
}

const VSpacer = ({ small, medium }: IVSpacerProps) => {
  let definedClass;
  if (small) {
    definedClass = classes.small;
  } else if (medium) {
    definedClass = classes.medium;
  } else {
    definedClass = classes.large;
  }
  return <div style={{ ...definedClass }} />;
};

export default VSpacer;
