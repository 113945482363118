import { PropsWithChildren, useReducer } from 'react';

import { NotificationsContext } from './context';
import { NotificationsReducer, getInitialState } from './reducer';

export const NotificationsContextProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const initialState = getInitialState();
  const [state, dispatch] = useReducer(NotificationsReducer, initialState);
  return (
    <NotificationsContext.Provider
      value={{
        notificationsState: state,
        notificationsDispatch: dispatch,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
