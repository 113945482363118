/* eslint-disable camelcase */
import { saveAs } from 'file-saver';

const s2ab = (s: any) => {
  const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
  const view = new Uint8Array(buf); // create uint8array as viewer
  // eslint-disable-next-line
  for (let i = 0; i < s.length; i += 1) view[i] = s.charCodeAt(i) & 0xff; // convert to octet
  return buf;
};

export const saveData = (() => {
  const a = document.createElement(`a`);
  document.body.appendChild(a);
  return (wbout: any, name: string) => {
    if (wbout !== null) {
      const blob = new Blob([s2ab(wbout)], { type: `application/octet-stream` });
      saveAs(blob, `${name}.xlsx`);
    }
  };
})();

export const isNumeric = (num: any) => {
  return !isNaN(num);
};

export const toCoords = (string: string): [number, number] | null => {
  try {
    const coords = string.split(`,`);

    // eslint-disable-next-line
    if (coords.length === 2 && coords[0] !== `` && !isNaN(+coords[0]) && coords[1] !== `` && !isNaN(+coords[1])) {
      const lat = parseFloat(coords[0]);
      const lng = parseFloat(coords[1]);
      return [lat, lng];
    }
  } catch (error) {
    return null;
  }
  return null;
};

const toSecondsExpRegex = `^0*(?:[1-9]|[1-3][0-9]|4[0-8]):[0-5][0-9]$`;

export const toSeconds = (hour: any) => {
  const regex1 = new RegExp(toSecondsExpRegex);
  if (hour.match(regex1) === null) {
    return null;
  }
  const hhmm = hour.split(`:`);
  return Number.parseInt(hhmm[0], 10) * 60 * 60 + Number.parseInt(hhmm[1], 10) * 60;
};

/**
 * This functions adds modifications to a given text. Modifications are:
 * - Trim
 * - Normalize accents/diacritics
 * - UpperCase
 * @param {*} text
 */
export const normalizeString = (text: any): string => {
  const trimmedText = text.trim();
  const normalizedText = trimmedText.normalize(`NFD`).replace(/[\u0300-\u036f]/g, ``);
  const upperedText = normalizedText.toUpperCase();
  return upperedText;
};

export const floatToFixedDecimals = (value: number, decimals: number) => {
  const multiplier = Math.pow(10, decimals);
  return Math.round(value * multiplier) / multiplier;
};
