export class EventEmitter extends EventTarget {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  on<T extends EventType>(
    type: T,
    listener: (e: CustomEvent<EventTypeToDetailMap[T]>) => void | EventListenerOrEventListenerObject | null
  ) {
    return this.addEventListener(type, listener as EventListener);
  }
  off<T extends EventType>(
    type: T,
    listener: (e: CustomEvent<EventTypeToDetailMap[T]>) => void | EventListenerOrEventListenerObject | null
  ) {
    return this.removeEventListener(type, listener as EventListener);
  }

  emit<T extends EventType>(type: T, detail: EventTypeToDetailMap[T]) {
    const event = new CustomEvent(type, { detail });
    return this.dispatchEvent(event);
  }
}
type EventType = keyof EventTypeToDetailMap;

type websocketEvent = `connected`; // | `disconnected` | `message` | `error`;

type EventTypeToDetailMap = {
  connection_event: websocketEvent;
};
