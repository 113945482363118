import * as ReactDOM from 'react-dom/client';

import { App } from './app/App';
import { sentryService } from './app/application/SentryService';

sentryService.init();

const root = ReactDOM.createRoot(document.getElementById(`root`) as HTMLElement);
console.log(`Rendering app...`);
root.render(
  /**
   * This is not working due to a fail in the google-map-react library.
   * See: https://github.com/google-map-react/google-map-react/issues/1116
   *
   */
  // <StrictMode>
  <App />
  // </StrictMode>
);
