import { sessionService } from '../../application/SessionService';

type UseSessionLoginCodeResponseProps = {
  isLogged: boolean;
};

// Hooks act as 'Adapter' layer.
export const useSession = (): UseSessionLoginCodeResponseProps => {
  return {
    isLogged: sessionService.isLogged(),
  };
};
