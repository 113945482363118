import { SvgIcon } from '@mui/material';
import { RoutalPalette } from '@nx-smartmonkey/ui';
import React from 'react';

interface MapViewProps {
  color?: string;
  isSelected?: boolean;
  style?: React.CSSProperties;
}

export const MapView = ({
  color = RoutalPalette.primary20,
  isSelected = false,
  style = {},
  ...props
}: MapViewProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 45 37" style={{ width: `45px`, height: `37px`, ...style }}>
      <g>
        <path
          d="M11.5 12.389V28.389L18.5 24.389L26.5 28.389L33.5 24.389V8.38904L26.5 12.389L18.5 8.38904L11.5 12.389Z"
          fill={isSelected ? `white` : color}
          stroke={isSelected ? `white` : color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.5 7.83533L18.5 25.9066"
          stroke={!isSelected ? `white` : color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.5 10.9799L26.5 29.1647"
          stroke={!isSelected ? `white` : color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </SvgIcon>
  );
};
