import { withStyles } from '@mui/styles';
import { booleanPointInPolygon, point, polygon, Position } from '@turf/turf';
import { DivIcon } from 'leaflet';
import lodash from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Marker as LMarker, Popup, Tooltip } from 'react-leaflet';
import tinycolor from 'tinycolor2';

import { getColorByIndex, SMPalette } from '../Colors';
import { Action } from './Map';

import styled from 'styled-components';
import { RoutalPalette } from '../../new_components';
import InstructionIcon from '../assets/svg/InstructionIcon';
import TimeIcon from '../assets/svg/TimeIcon';
import WarningIcon from '../assets/svg/WarningIcon';

export const isMarkerInsideCoordinates = ({
  markerCoords,
  coordinates,
}: {
  markerCoords: { lat: number; lng: number };
  coordinates: { lat?: number; lng?: number }[];
}) => {
  // IMPORTANT: We use lng/lat instead of lat/lng because of the GeoJSON format. See: https://macwright.com/lonlat/
  const markerPoint = point([markerCoords.lng, markerCoords.lat]);
  const data = [coordinates.map((coordinate) => [coordinate.lng, coordinate.lat] as Position)];
  const poly = polygon(data);
  const selected = booleanPointInPolygon(markerPoint, poly);

  return selected;
};

const styles = {
  marker: {
    boxSizing: `border-box` as any,
    height: `25px`,
    width: `25px`,
    border: `2px solid ${SMPalette[`grey2`]}`,
    borderRadius: `50%`,
    boxShadow: `0 0 0 2px white`,
    backgroundColor: `#DFE6EF`,
    fontWeight: `bold` as any,
    fontSize: `10px`,
  },
};

type GetTruckSVGIconProps = {
  color: string;
  selectedColor?: string;
  selected?: boolean;
  orientation?: number;
  hasDelay?: boolean;
  delayLabel?: string;
  avatarUrl?: string;
  routal?: boolean;
};

const getTruckSVGIcon = ({
  color,
  selected,
  orientation,
  hasDelay,
  delayLabel,
  avatarUrl,
  routal = true,
}: GetTruckSVGIconProps) => {
  const fillColor = selected ? (routal ? RoutalPalette.white : `white`) : color;
  const _backgroundColor = selected ? color : routal ? RoutalPalette.white : `white`;
  const _svgWhite = routal ? RoutalPalette.white : `white`;
  return `
    <div style="position:relative;">
      <div style="position:relative;height:48px;width:48px;">
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
        >
          <circle cx="24" cy="24" r="17" fill="${_backgroundColor}" stroke="${
    selected ? _backgroundColor : fillColor
  }" stroke-width="3"/>
        </svg>
        ${
          avatarUrl
            ? `<span
            style="
              position: absolute;
              left: 8px;
              top: 8px;
              width: 32px;
              height: 32px;
              border-radius: 50%;
              background: url(${avatarUrl}) no-repeat;
              background-size: cover;
            "
          />`
            : routal
            ? `<svg
          width="48"
          height="48"
          viewBox="-17 -20 58 58"
          fill="none"
          style="position:absolute;left:0;fill:${fillColor};"
        >
        
        <path d="M1.92426 7.75562H22.635" fill="none" stroke="${fillColor}" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.0382 1.94946V7.75575" fill="none"  stroke="${fillColor}" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill="none" d="M6.4648 16.0505C7.91332 16.0505 9.08758 14.9365 9.08758 13.5621C9.08758 12.1878 7.91332 11.0737 6.4648 11.0737C5.01627 11.0737 3.84201 12.1878 3.84201 13.5621C3.84201 14.9365 5.01627 16.0505 6.4648 16.0505Z" stroke="${fillColor}" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill="none" d="M16.9559 16.0505C18.4044 16.0505 19.5787 14.9365 19.5787 13.5621C19.5787 12.1878 18.4044 11.0737 16.9559 11.0737C15.5074 11.0737 14.3331 12.1878 14.3331 13.5621C14.3331 14.9365 15.5074 16.0505 16.9559 16.0505Z" stroke="${fillColor}" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill="none" d="M20.3035 3.32472C19.8593 2.48198 18.951 1.94946 17.9578 1.94946H3.67278C2.70672 1.94946 1.92426 2.69184 1.92426 3.6084V12.7326C1.92426 13.1904 2.31592 13.562 2.79852 13.562H3.84199C3.84199 12.1876 5.01612 11.0736 6.46477 11.0736C7.91343 11.0736 9.08756 12.1876 9.08756 13.562H14.3331C14.3331 12.1876 15.5073 11.0736 16.9559 11.0736C18.4046 11.0736 19.5787 12.1876 19.5787 13.562H22.2015C22.6841 13.562 23.0757 13.1904 23.0757 12.7326V8.58522L20.3035 3.32472Z" stroke="${fillColor}" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        
          </svg>`
            : `<svg
        width="48"
        height="48"
        viewBox="0 0 54 54"
        fill="none"
        style="position:absolute;left:0;fill:${fillColor};"
      >
        <path d="M37.7532 25.9249L34.7189 22.8894C34.4915 22.662 34.0411 22.476 33.7198 22.476H31.0861V20.8016C31.0861 19.8112 30.2758 19 29.284 19H18.8018C17.8105 18.9998 17 19.8112 17 20.8016V31.9503C17 32.5705 17.5064 33.0772 18.1256 33.0772H19.3738C19.3738 31.5218 20.6338 30.2621 22.1886 30.2621C23.7435 30.2621 25.0035 31.5213 25.0035 33.0772H31.1748C31.1748 31.5218 32.434 30.2621 33.9898 30.2621C35.5442 30.2621 36.8046 31.5213 36.8046 33.0772H37.0424C37.6617 33.0772 38.1681 32.5705 38.1681 31.9503V26.924C38.1676 26.6018 37.9816 26.1523 37.7532 25.9249ZM35.9871 27.8316H33.2666C33.0239 27.8316 32.8256 27.6336 32.8256 27.3906V24.6708C32.8256 24.4278 33.0239 24.2279 33.2666 24.2279H33.538C33.6594 24.2279 33.8287 24.2987 33.9153 24.3846L36.2719 26.7412C36.3582 26.8275 36.4281 26.9981 36.4281 27.1185L36.429 27.3906C36.4283 27.6336 36.2296 27.8316 35.9871 27.8316Z"/>
        <path d="M22.1891 35.2163C23.3705 35.2163 24.3282 34.2585 24.3282 33.0772C24.3282 31.8958 23.3705 30.938 22.1891 30.938C21.0077 30.938 20.05 31.8958 20.05 33.0772C20.05 34.2585 21.0077 35.2163 22.1891 35.2163Z"/>
        <path d="M33.9891 35.2167C35.1708 35.2167 36.1287 34.2588 36.1287 33.0771C36.1287 31.8955 35.1708 30.9376 33.9891 30.9376C32.8075 30.9376 31.8495 31.8955 31.8495 33.0771C31.8495 34.2588 32.8075 35.2167 33.9891 35.2167Z"/>
      </svg>`
        }
      </div>
      
      ${
        orientation
          ? `<div
        style="
          position: absolute;
          top: calc(50% - 42px);
          left: calc(50% - 42px);
          width: 84px;
          height: 84px;
        "
      >
        <svg
          viewBox="0 0 67 54"
          fill="${_svgWhite}"
          style="
            height:100%;
            width:100%;
            transform:rotate(${orientation}deg);
          "
        >
          <path style="transform:translate(4px, 0px);" d="M46.4003 32.9616L46.3961 20.8372L56.8982 26.8957L46.4003 32.9616Z" fill="${_backgroundColor}" stroke="${
              selected ? _backgroundColor : fillColor
            }" stroke-width="2"/>
        </svg>
      </div>`
          : ``
      }
      ${
        hasDelay
          ? `<span
        style="
          position:absolute;
          top:${orientation && (orientation > 180 || orientation < 0) ? `calc(50% + 32px)` : `-8px`};
          right:50%;
          transform:translate(50%,-50%);
          border-radius:4px;
          padding:2px 4px;
          color:${_svgWhite};
          background-color:#E03030;
          font-size:10px;
          font-weight:bold;
          width:100%;
          text-align:center;
          z-index:-1;
        "
      >
        ${delayLabel}
      </span>`
          : ``
      }
    </div>
  `;
};

type GetColorProps = {
  done?: boolean;
  error?: boolean;
  incomplete?: boolean;
  selected?: boolean;
  color?: string | number;
};

const getColor = ({ done, error, incomplete, selected, color }: GetColorProps): string => {
  let iconColor;
  if (color === undefined) {
    iconColor = SMPalette[`grey2`];
  } else if (typeof color === `number`) {
    iconColor = getColorByIndex(color);
  } else {
    iconColor = color;
  }
  if (!selected) {
    if (error) {
      iconColor = `#E03030`;
    } else if (done) {
      iconColor = `#70D292`;
    } else if (incomplete) {
      iconColor = `#FFB300`;
    }
  }
  return iconColor;
};

export type MarkerTypes =
  | `default`
  | `routal_default`
  | `routal_created_stop`
  | `routal_pickup`
  | `routal_truck`
  | `start`
  | `end`
  | `avatar`
  | `report_position`;

interface MarkerProps {
  id?: string;
  lat: number;
  lng: number;
  keyMarker?: any;
  color?: string | number;
  label?: string | React.ReactElement;
  kind?: MarkerTypes;
  selected?: boolean;
  done?: boolean;
  error?: boolean;
  incomplete?: boolean;
  reorder?: boolean;
  number?: number;
  orientation?: number;
  pointer?: boolean;
  hasDelay?: boolean;
  delayLabel?: string;
  avatarUrl?: string; //avatarURL is deprecated, it doesn't use the url, it requires the image instead. Use avatar instead.
  avatar?: string;
  showAlert?: boolean;
  showInProgress?: boolean;
  showWarning?: boolean;
  onClick?: (...args: any[]) => any;
  onDoubleClick?: (...args: any[]) => any;
  doNotRefreshBounds?: boolean;
  dispatch?: React.Dispatch<Action>;
  pane?: string;
}

const Marker: FunctionComponent<MarkerProps> = ({
  id,
  lat,
  lng,
  keyMarker,
  color,
  label,
  kind,
  selected,
  done,
  error,
  incomplete,
  reorder,
  number,
  orientation,
  pointer,
  hasDelay,
  delayLabel,
  avatarUrl,
  avatar,
  showAlert,
  showInProgress,
  showWarning,
  onClick,
  onDoubleClick,
  doNotRefreshBounds,
  dispatch,
  pane,
}) => {
  const [position, setPosition] = useState<[number, number]>([0, 0]);
  const [markerColor, setMarkerColor] = useState<string | number | undefined>();
  const [markerSelected, setMarkerSelected] = useState<boolean>(false);

  if (avatar && !avatarUrl) {
    //In order to use avatar instead and do not removing avatarUrl
    avatarUrl = avatar;
  }

  const setBounds = useCallback(() => {
    if (lat !== undefined && lng !== undefined) {
      const [minLat, minLng, maxLat, maxLng] = [lat, lng, lat, lng];
      if (dispatch) {
        dispatch({
          type: `ADD_BOUNDS`,
          bounds: [
            [minLat, minLng],
            [maxLat, maxLng],
          ],
          ...(id ? { id } : {}),
        });
      }
    }
  }, [dispatch, id, lat, lng]);

  useEffect(() => {
    if (!doNotRefreshBounds && (!lodash.isEqual(position[0], lat) || !lodash.isEqual(position[1], lng))) {
      setPosition([lat, lng]);
      setBounds();
    }
    if (!lodash.isEqual(markerColor, color)) {
      setMarkerColor(color);
    }
    if (selected && !lodash.isEqual(markerSelected, selected)) {
      setMarkerSelected(selected);
    }
  }, [color, doNotRefreshBounds, lat, lng, markerColor, markerSelected, position, selected, setBounds]);

  const handleOnClick = (event: any) => {
    if (onClick) {
      onClick(event);
    }
  };

  const handleOnDoubleClick = (event: any) => {
    if (onDoubleClick) {
      onDoubleClick(event);
    }
  };

  const getMarkerIcon = (_markerColor: string) => {
    let anchor: [number, number];
    let iconSize: [number, number] | null;
    let iconHtml;
    const tColor = tinycolor(_markerColor);

    switch (kind) {
      case `report_position`:
        anchor = [12, 20];
        iconSize = [24, 24];
        iconHtml = `
        <svg viewBox="0 0 17.76 25.44" style="padding:0px;width:100%;height:100%;position:relative;">
          <ellipse fill="white" stroke="${_markerColor}" stroke-miterlimit="10" stroke-width="2" cx="8.88" cy="21.11" rx="7.63" ry="2.58" />
          <line fill="white" stroke="${_markerColor}" stroke-miterlimit="10" stroke-width="2" strokeLinecap="round" x1="8.88" y1="10.85" x2="8.88" y2="20.9" />
          <circle fill="white" stroke="${_markerColor}" stroke-miterlimit="10" stroke-width="2" cx="8.88" cy="6.36" r="4.48" />
        </svg>`;
        break;
      case `avatar`:
      case `routal_truck`:
        anchor = [24, 24];
        iconSize = null;
        iconHtml = getTruckSVGIcon({
          color: _markerColor,
          selected,
          orientation,
          hasDelay,
          delayLabel,
          avatarUrl,
        });
        break;
      case `start`:
        anchor = [0, 0];
        iconSize = [22, 22];
        iconHtml = `
        <svg viewBox="0 0 22 22" style="padding:0px;width:100%;height:100%;position:relative;">
        <circle
          cx="11"
          cy="11"
          r="10"
          fill="${RoutalPalette.primary40}"
          stroke="${RoutalPalette.primary40}"
        /> 
        <path 
        transform="translate(5,5) scale(1)"
        fill="${RoutalPalette.white}"
        stroke="${RoutalPalette.white}"
          d="M.46,6.3c2.89,0,5.24,2.35,5.24,5.24.16.18.44.18.61,0,0-2.89,2.35-5.24,5.24-5.24.18-.16.18-.44,0-.61-2.89,0-5.24-2.35-5.24-5.24-.16-.18-.44-.18-.61,0C5.7,3.35,3.35,5.7.46,5.7c-.18.16-.18.44,0,.61Z"/>
        
          
         </svg>`;
        break;

      case `end`:
        anchor = [7, 7];
        iconSize = [22, 22];
        iconHtml = `
        <svg viewBox="0 0 80 80" style="padding:0px;width:100%;height:100%;position:relative;">
        <circle
          cx="40"
          cy="40"
          r="40"
          fill="${RoutalPalette.primary40}"
          stroke="${RoutalPalette.primary40}"
        /> 
        <path
        transform="translate(16,14) scale(0.9)" 
        fill="${RoutalPalette.white}"
        
        d="M50.82,5.02c-.33-.17-.72-.15-1.03,.06-.37,.25-9.11,6.04-17.19,.02-9.05-6.74-19-.37-19.5-.03-.01,0-.02,.01-.02,.01-.11,.08-.2,.18-.27,.29-.03,.05-.06,.09-.08,.14-.05,.12-.08,.26-.08,.4V61c0,.55,.45,1,1,1s1-.45,1-1V30.72c1.92-1.14,9.59-5.08,16.75,.26,3.1,2.31,6.31,3.08,9.21,3.08,5.73,0,10.24-3.01,10.31-3.06,.27-.19,.43-.49,.43-.83V5.91c0-.37-.2-.71-.53-.89Z" />
    
        </svg>`;
        break;

      case `routal_created_stop`:
        anchor = [7, 7];
        iconSize = [26, 26];
        iconHtml = `
<svg  viewBox="0 0 26 26" fill="none">
<circle cx="12.9624" cy="12.4736" r="11.5" fill="#3E4959" stroke="#3E4959"/>
<path d="M12.9985 6.78809V18.233" stroke="#FAFAFA" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.57812 12.5098H18.4207" stroke="#FAFAFA" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
        break;
      case `routal_pickup`:
      case `routal_default`:
        if (done || error || incomplete) {
          const tColor = tinycolor(
            RoutalPalette.markerStopColors[done ? `completed` : error ? `canceled` : `incomplete`].medium
          );
          _markerColor = tColor.toString(); //.brighten(15).toString();

          anchor = pointer ? [30, 30] : [12, 12];
          iconSize = [24, 24];
          iconHtml = `
        <div style="padding:0px;width:100%;height:100%;position:relative;">
        ${
          kind === `routal_pickup`
            ? `
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <rect x="1.5" y="1.5" width="21" height="21" rx="3.5" fill="${_markerColor}"></rect>
              </svg>
            `
            : `
            <svg viewBox="0 0 48 48" style="position:relative;">
              <circle
                cx="24"
                cy="24"
                r="23"
                fill="${_markerColor}"
              />
            </svg>
            `
        }
        </div>
        ${
          number
            ? `<span
          style="
            position: absolute;
            font-size: ${number > 99 ? `${number > 999 ? `8px` : `9px`}` : `inherit`};
            font-weight: bold;
            top: 50%;
            right: 50%;
            color: ${RoutalPalette.white};
            transform: translate(50%,-50%);
            z-index: 200;
          "
        >${number > 999 ? `+999` : number}</span>`
            : ``
        }

        ${
          pointer
            ? `<div
          style="
            position: absolute;
            top: calc(50% - 20px);
            left: calc(50% - 20px);
            width: 40px;
            height: 40px;
          "
        >
          <svg
            viewBox="0 0 37.45 47.45"
            fill="white"
            style="
              height:100%;
              width:100%;
            "
          >
            <path fill="${_markerColor}" d="M18.04,47.18l-11.82-11.47s6.25,4.25,12.5,4.25,12.5-4.25,12.5-4.25l-11.82,11.47c-.38,.37-.99,.37-1.37,0Z" />
          </svg>
        </div>`
            : ``
        }        
        `;
        } else {
          if (reorder) _markerColor = RoutalPalette.secondary40;
          anchor = pointer ? [30, 30] : [12, 12];
          iconSize = [24, 24];
          iconHtml = `
        <div style="padding:0px;width:100%;height:100%;position:relative;">
          ${
            kind === `routal_pickup`
              ? `
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <rect x="1.5" y="1.5" width="21" height="21" rx="3.5" fill="${
                    selected ? _markerColor : tColor.lighten(35).toString()
                  }" stroke="${_markerColor}" stroke-width="2"></rect>
                </svg>
              `
              : `
                <svg viewBox="0 0 48 48" style="position:relative;">
              <circle
                cx="24"
                cy="24"
                r="22"
                fill="${selected ? _markerColor : tColor.lighten(35).toString()}"
                stroke="${_markerColor}"
                stroke-width="4"
              />
              </svg>
            `
          }
        </div>
        ${
          number
            ? `<span
          style="
            position: absolute;
            font-size: ${number > 99 ? `${number > 999 ? `8px` : `9px`}` : `inherit`};
            font-weight: bold;
            top: 50%;
            right: 50%;
            color:${selected ? `white` : _markerColor};
            transform: translate(50%,-50%);
            z-index: 200;
          "
        >${number > 999 ? `+999` : number}</span>`
            : ``
        }

        ${
          pointer
            ? `<div
          style="
            position: absolute;
            top: calc(50% - 20px);
            left: calc(50% - 20px);
            width: 40px;
            height: 40px;
          "
        >
          <svg
            viewBox="0 0 37.45 47.45"
            fill="white"
            style="
              height:100%;
              width:100%;
            "
          >
            <path fill="${_markerColor}" d="M18.04,47.18l-11.82-11.47s6.25,4.25,12.5,4.25,12.5-4.25,12.5-4.25l-11.82,11.47c-.38,.37-.99,.37-1.37,0Z" />
          </svg>
        </div>`
            : ``
        }        
        `;
        }
        break;

      case `default`:
      default:
        anchor = pointer ? [30, 30] : [12, 12];
        iconSize = [24, 24];
        iconHtml = `
        <div style="padding:0px;width:100%;height:100%;position:relative;">
          <svg viewBox="0 0 48 48" style="position:relative;">
            <circle
              cx="24"
              cy="24"
              r="22"
              fill="${selected ? _markerColor : tColor.lighten(35).toString()}"
              stroke="${_markerColor}"
              stroke-width="5"
            />
          </svg>
        </div>
        ${
          number
            ? `<span
          style="
            position: absolute;
            font-size: ${number > 99 ? `${number > 999 ? `8px` : `9px`}` : `inherit`};
            font-weight: bold;
            top: 50%;
            right: 50%;
            color:${selected ? `white` : _markerColor};
            transform: translate(50%,-50%);
            z-index: 200;
          "
        >${number > 999 ? `+999` : number}</span>`
            : ``
        }

        ${
          pointer
            ? `<div
          style="
            position: absolute;
            top: calc(50% - 20px);
            left: calc(50% - 20px);
            width: 40px;
            height: 40px;
          "
        >
          <svg
            viewBox="0 0 37.45 47.45"
            fill="white"
            style="
              height:100%;
              width:100%;
            "
          >
            <path fill="${_markerColor}" d="M18.04,47.18l-11.82-11.47s6.25,4.25,12.5,4.25,12.5-4.25,12.5-4.25l-11.82,11.47c-.38,.37-.99,.37-1.37,0Z" />
          </svg>
        </div>`
            : ``
        }        
        `;
        break;
    }
    return new DivIcon({
      html: iconHtml,
      className: `marker`,
      iconSize: iconSize as any,
      iconAnchor: anchor,
    });
  };

  const iconColor = getColor({
    done,
    error,
    incomplete,
    selected,
    color: reorder ? RoutalPalette.neutral40 : markerColor,
  });

  const getExtraIcon = () => {
    if (!showAlert && !showInProgress && !showWarning) {
      return null;
    }

    let backgroundColor, icon;

    if (showInProgress) {
      backgroundColor = RoutalPalette.success.medium;
      icon = <TimeIcon style={{ width: `12px`, height: `12px`, color: RoutalPalette.white }} />;
    } else if (showAlert) {
      backgroundColor = RoutalPalette.warning.dark;
      icon = <InstructionIcon style={{ width: `12px`, height: `12px`, color: RoutalPalette.white }} />;
    } else if (showWarning) {
      backgroundColor = RoutalPalette.error.medium;
      icon = <WarningIcon color={RoutalPalette.white} style={{ width: `12px`, height: `12px` }} />;
    }

    return (
      <CustomTooltip direction="right" offset={[-10, -10]} opacity={1} permanent>
        <div
          style={{
            background: backgroundColor,
            borderRadius: `50%`,
            height: `18px`,
            width: `18px`,
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
          }}
        >
          {icon}
        </div>
      </CustomTooltip>
    );
  };

  return (
    <LMarker
      key={keyMarker}
      position={[lat, lng]}
      icon={getMarkerIcon(iconColor)}
      eventHandlers={{
        click: handleOnClick,
        dblclick: handleOnDoubleClick,
      }}
      // pane={pane ?? undefined}
    >
      {label && <Popup>{label}</Popup>}
      {getExtraIcon()}
      {/* {(showAlert || showInProgress) && (
        <CustomTooltip direction="right" offset={[-10, -10]} opacity={1} permanent>
          <div
            style={{
              background: showInProgress ? RoutalPalette.success : RoutalPalette.warning,
              borderRadius: `50%`,
              height: `18px`,
              width: `18px`,
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`,
            }}
          >
            {showInProgress ? (
              <TimeIcon alt="" style={{ width: `12px`, height: `12px`, color: RoutalPalette.white }} />
            ) : (
              <InstructionIcon alt="" style={{ width: `12px`, height: `12px`, color: RoutalPalette.white }} />
            )}
          </div>
        </CustomTooltip>
      )} */}
    </LMarker>
  );
};

export default withStyles(styles)(Marker);

const CustomTooltip = styled(Tooltip)`
  /* Add your custom styles for .custom-tooltip here */
  color: transparent;
  background-color: transparent;
  border: none;
  font-size: 16px;
  box-shadow: none;
  z-index: 999;

  &.leaflet-tooltip-right::before {
    opacity: 0;
  }
  &.leaflet-tooltip-left::before {
    opacity: 0;
  }
`;
