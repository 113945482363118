import { Entity } from '@nx-smartmonkey/shared/domain';

export type RatingTags = `time` | `condition` | `driver` | `info`;
interface CustomersSurveyProps {
  stop_id: string;
  driver_id: string;
  rating: number;
  rating_tag?: RatingTags;
  comments?: string;
  deleted?: boolean;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

export class CustomersSurvey extends Entity<CustomersSurveyProps> {
  get id(): string {
    return this._id!;
  }
  get stop_id(): string {
    return this.props.stop_id;
  }
  get driver_id(): string {
    return this.props.driver_id;
  }
  get rating(): number {
    return this.props.rating;
  }
  set rating(rating: number) {
    this.props.rating = rating;
  }
  get rating_tag(): RatingTags | undefined {
    return this.props.rating_tag;
  }
  set rating_tag(rating_tag: RatingTags | undefined) {
    this.props.rating_tag = rating_tag;
  }
  get comments(): string | undefined {
    return this.props.comments;
  }
  set comments(comments: string | undefined) {
    this.props.comments = comments;
  }
  get deleted(): boolean | undefined {
    return this.props.deleted;
  }
  get created_at(): Date | undefined {
    return this.props.created_at;
  }
  get updated_at(): Date | undefined {
    return this.props.updated_at;
  }
  get deleted_at(): Date | undefined {
    return this.props.deleted_at;
  }

  private constructor(props: CustomersSurveyProps, id?: string) {
    super(props, id);
  }

  static create(props: CustomersSurveyProps, id?: string) {
    return new CustomersSurvey(props, id);
  }
}
