import { GPSTrack } from './GPSTrack';
import { GPSTrackDTO } from './GPSTrackDTO';

export class GPSTrackMapper {
  static toDomain(props: GPSTrackDTO): GPSTrack {
    const { lat, lng, orientation, created_at } = props;

    return GPSTrack.create({
      lat,
      lng,
      orientation,
      created_at,
    });
  }

  static toDTO = (gpsTrack: GPSTrack): GPSTrackDTO => ({
    lat: gpsTrack.lat,
    lng: gpsTrack.lng,
    orientation: gpsTrack.orientation,
    created_at: gpsTrack.created_at,
  });
}
