import { useSessionLogout } from '../session/useSessionLogout';
import { useShowSnackbarMessage } from '../snackbarMessage/useShowSnackbarMessage';

interface UseShowInfoSnackbarMessageResponseProps {
  sessionExpiredLogout: () => void;
}

// Hooks act as 'Adapter' layer.
const useErrorMiddleware = (): UseShowInfoSnackbarMessageResponseProps => {
  const { showSnackbarMessage } = useShowSnackbarMessage();
  const { run: logout } = useSessionLogout();

  const sessionExpiredLogout = () => {
    logout();
    showSnackbarMessage({
      messageIntlId: `highway.user.error.invalid_token`,
    });
  };

  return {
    sessionExpiredLogout,
  };
};

export default useErrorMiddleware;
