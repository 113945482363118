export type SearchFilterTypes = `string` | `date` | `boolean` | `number` | `list` | `survey` | `time` | `date_time`;

export type SearchFilterOperators =
  | `lt`
  | `gt`
  | `eq`
  | `ne`
  | `unknown`
  | `known`
  | `true`
  | `false`
  | `starts_with`
  | `ends_with`
  | `contains`
  | `contains_exact_word`
  | `not_contains`;

export interface SearchFilterProps {
  field: string;
  type: SearchFilterTypes;
  operator: SearchFilterOperators;
  value: any;
  iconSrc?: string;
  formatted?: (value: any) => any;
  computed?: (value: any) => any;
}

export class SearchFilter {
  private field: string;
  private type: SearchFilterTypes;
  private operator: SearchFilterOperators;
  private value: any;
  private iconSrc?: string;
  private formatted?: (value: any) => any;
  private computed?: (value: any) => any;

  constructor({ field, type, operator, value, iconSrc, formatted, computed }: SearchFilterProps) {
    this.field = field;
    this.type = type;
    this.operator = operator;
    this.value = value;
    this.iconSrc = iconSrc;
    this.formatted = formatted;
    this.computed = computed;
  }

  public getField(): string {
    return this.field;
  }

  public getType(): SearchFilterTypes {
    return this.type;
  }

  public getOperator(): SearchFilterOperators {
    return this.operator;
  }

  private isValueNeeded(): boolean {
    return (
      this.operator !== `unknown` && this.operator !== `known` && this.operator !== `true` && this.operator !== `false`
    );
  }

  public isValueValid(): boolean {
    if (!this.isValueNeeded()) {
      return true;
    }

    if (this.value === undefined) {
      return false;
    }

    switch (this.type) {
      case `string`:
        return typeof this.value === typeof `abc`;
      case `number`:
        return typeof this.value === typeof 123;
      default:
        return true;
    }
  }

  public setOperator(operator: SearchFilterOperators): void {
    this.operator = operator;
  }

  public isOperator(operator: SearchFilterOperators): boolean {
    return this.operator === operator;
  }

  public getValue(): any {
    if (!this.value) {
      return null;
    }

    switch (this.type) {
      case `date`:
        if (typeof this.value === typeof 123) {
          return this.value;
        }
        return new Date(this.value);
      default:
        return this.value;
    }
  }

  public setValue(value: any): void {
    this.value = value;
  }

  public getIconSrc(): string | undefined {
    return this.iconSrc;
  }

  public formatValue(value: any) {
    this.value = this.formatted && value ? this.formatted(value) : value;
  }

  public getComputedValue(): any {
    const value = this.getValue();
    if (this.computed && value) {
      return this.computed(value);
    }

    return value;
  }
}
