import { SvgIcon } from '@mui/material';

export default function StopReportIcon(props: any) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 18.89 17.61"
      style={{ width: `22px`, height: `22px`, color: `#211915`, ...props.style }}
    >
      <g>
        <path d="M17.75,16.34l-5.1-5.08a6.52,6.52,0,1,0-.71.7l5.11,5.09a.46.46,0,0,0,.36.14.5.5,0,0,0,.34-.85ZM2.31,7A5.46,5.46,0,1,1,7.77,12.5,5.47,5.47,0,0,1,2.31,7Z" />
        <path d="M7.57,11.44a.51.51,0,0,1-.5-.5V3.3a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5v7.64A.5.5,0,0,1,7.57,11.44Z" />
        <path d="M11.4,7.62H3.75a.51.51,0,0,1-.5-.5.5.5,0,0,1,.5-.5H11.4a.5.5,0,0,1,.5.5A.51.51,0,0,1,11.4,7.62Z" />
      </g>
    </SvgIcon>
  );
}
