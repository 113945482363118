import { Location } from './Location';
import { LocationDTO } from './LocationDTO';

export class LocationMapper {
  static toDomain(props: LocationDTO): Location {
    const {
      location_id,
      label,
      country,
      country_code,
      state,
      county,
      city,
      district,
      subdistrict,
      block,
      subblock,
      street,
      house_number,
      postal_code,
      comments,
      lat,
      lng,
      original_input,
      partial_match,
    } = props;

    return Location.create({
      location_id: location_id,
      label,
      country,
      country_code: country_code,
      state,
      county,
      city,
      district,
      subdistrict,
      block,
      subblock,
      street,
      house_number: house_number,
      postal_code: postal_code,
      comments,
      lat,
      lng,
      original_input,
      partial_match,
    });
  }

  static toDTO = (location: Location): LocationDTO => ({
    location_id: location.location_id,
    label: location.label,
    country: location.country,
    country_code: location.country_code,
    state: location.state,
    county: location.county,
    city: location.city,
    district: location.district,
    subdistrict: location.subdistrict,
    block: location.block,
    subblock: location.subblock,
    street: location.street,
    house_number: location.house_number,
    postal_code: location.postal_code,
    comments: location.comments,
    lat: location.lat,
    lng: location.lng,
  });
}
