import { Grid } from '@mui/material';
import { HSpacer } from '@nx-smartmonkey/ui';
import React from 'react';

import { Language } from '@nx-smartmonkey/shared/domain';
import { SearchFilterBoolean, SearchFilterBooleanComponent } from './boolean';
import { SearchFilterDate, SearchFilterDateComponent } from './date';
import { SearchFilterDateTime, SearchFilterDateTimeComponent } from './date_time';
import { SearchFilterList, SearchFilterListComponent } from './list';
import { SearchFilterNumber, SearchFilterNumberComponent } from './number';
import { SearchFilterString, SearchFilterStringComponent } from './string';
import { SearchFilterSurvey, SearchFilterSurveyComponent } from './survey';
import { SearchFilterTime, SearchFilterTimeComponent } from './time';

interface SearchFiltersListProps {
  filters: Array<
    | SearchFilterString
    | SearchFilterBoolean
    | SearchFilterNumber
    | SearchFilterSurvey
    | SearchFilterDate
    | SearchFilterList
  >;
  language?: Language;
  colors: {
    primaryColor: string;
    secondaryColor: string;
  };
  isExampleURL?: boolean;
  onChangeFilter: (
    filter:
      | SearchFilterString
      | SearchFilterBoolean
      | SearchFilterNumber
      | SearchFilterSurvey
      | SearchFilterDate
      | SearchFilterList,
    idx: number
  ) => void;
  onRemoveFilter: (idx: number) => void;
}

export const SearchFiltersList = ({
  filters,
  language,
  colors,
  isExampleURL = false,
  onChangeFilter,
  onRemoveFilter,
}: SearchFiltersListProps) => {
  return (
    <Grid container direction="row">
      {filters.map((filter, idx) => {
        let component: React.ReactElement | null = null;
        switch (filter.getType()) {
          case `string`:
            component = (
              <SearchFilterStringComponent
                filter={filter as SearchFilterString}
                filterPosition={idx}
                colors={colors}
                isExampleURL={isExampleURL}
                onRemoveFilter={() => onRemoveFilter(idx)}
                onChange={(filter) => onChangeFilter(filter, idx)}
              />
            );
            break;
          case `boolean`:
            component = (
              <SearchFilterBooleanComponent
                filter={filter as SearchFilterBoolean}
                filterPosition={idx}
                colors={colors}
                onRemoveFilter={() => onRemoveFilter(idx)}
                onChange={(filter) => onChangeFilter(filter, idx)}
              />
            );
            break;
          case `number`:
            component = (
              <SearchFilterNumberComponent
                filter={filter as SearchFilterNumber}
                filterPosition={idx}
                colors={colors}
                onRemoveFilter={() => onRemoveFilter(idx)}
                onChange={(filter) => onChangeFilter(filter, idx)}
              />
            );
            break;
          case `survey`:
            component = (
              <SearchFilterSurveyComponent
                filter={filter as SearchFilterSurvey}
                filterPosition={idx}
                colors={colors}
                onRemoveFilter={() => onRemoveFilter(idx)}
                onChange={(filter) => onChangeFilter(filter, idx)}
              />
            );
            break;
          case `date`:
            component = (
              <SearchFilterDateComponent
                filter={filter as SearchFilterDate}
                filterPosition={idx}
                colors={colors}
                language={language}
                onRemoveFilter={() => onRemoveFilter(idx)}
                onChange={(filter) => onChangeFilter(filter, idx)}
              />
            );
            break;
          case `date_time`:
            component = (
              <SearchFilterDateTimeComponent
                filter={filter as SearchFilterDateTime}
                filterPosition={idx}
                colors={colors}
                language={language}
                onRemoveFilter={() => onRemoveFilter(idx)}
                onChange={(filter) => onChangeFilter(filter, idx)}
              />
            );
            break;
          case `time`:
            component = (
              <SearchFilterTimeComponent
                filter={filter as SearchFilterTime}
                filterPosition={idx}
                colors={colors}
                onRemoveFilter={() => onRemoveFilter(idx)}
                onChange={(filter) => onChangeFilter(filter, idx)}
              />
            );
            break;
          case `list`:
            component = (
              <SearchFilterListComponent
                filter={filter as SearchFilterList}
                filterPosition={idx}
                colors={colors}
                onRemoveFilter={() => onRemoveFilter(idx)}
                onChange={(filter) => onChangeFilter(filter, idx)}
              />
            );
            break;
          default:
            return null;
        }

        return (
          <>
            <Grid item key={idx}>
              {component}
            </Grid>
            {idx < filters.length - 1 ? <HSpacer small /> : null}
          </>
        );
      })}
    </Grid>
  );
};
