import { Language, LanguageType } from '@nx-smartmonkey/shared/domain';
import { ServiceResponse } from '@nx-smartmonkey/shared/hooks';
import { useMutation } from '@tanstack/react-query';
import { IntlShape } from 'react-intl';

import { sessionService } from '../../application/SessionService';
import { translationsService } from '../../application/TranslationsService';
import { TranslationsActionType } from '../../context/translations/actions';
import { useTranslationsContext } from '../../context/translations/context';
import useErrorMiddleware from '../errorMiddleware/useErrorMiddleware';
import { useShowSnackbarMessage } from '../snackbarMessage/useShowSnackbarMessage';

export const retrieveNavigatorLanguage = () => {
  const languageShort = (language: any) => language.split(/[-_]/)[0];

  switch (languageShort(navigator.language)) {
    case `eu`:
    case `es`:
    case `gl`:
      return Language.create(`es`);
    case `ca`:
      return Language.create(`ca`);
    case `pt`:
      return Language.create(`pt`);
    case `de`:
      return Language.create(`de`);
    case `fr`:
      return Language.create(`fr`);
    default:
      return Language.create(`en`);
  }
};

interface UseRetrieveTranslationProps {
  language: Language;
}

type UseRetrieveTranslationResponse = ServiceResponse<
  ({ language }: UseRetrieveTranslationProps) => void,
  Record<string, IntlShape>
> & {
  activeLanguage?: IntlShape;
};

// Hooks act as 'Adapter' layer.
export const useRetrieveTranslation = (): UseRetrieveTranslationResponse => {
  const { sessionExpiredLogout } = useErrorMiddleware();
  const { showSnackbarMessage } = useShowSnackbarMessage();
  const { translationsState, translationsDispatch } = useTranslationsContext();
  const { isLoading, mutate, isError, isSuccess } = useMutation(
    ({
      language,
    }: UseRetrieveTranslationProps): Promise<{ language: LanguageType; translations: Record<string, string> }> => {
      return translationsService.getTranslation({ language: language.value });
    },
    {
      onError: (err: any) => {
        console.info(`Supervisors Retrieve Translations Error:`, err);
        if (err?.response?.status === 401) {
          sessionExpiredLogout();
          return;
        }
        showSnackbarMessage({
          messageIntlId: `infoerror.${err?.response?.data?.messageId ?? `network_error`}`,
        });
      },
      onSuccess: ({ language, translations }) => {
        const lang = Language.create(language);

        sessionService.setLanguage({ language: lang });

        translationsDispatch({ type: TranslationsActionType.SET_TRANSLATION, payload: { language, translations } });
        translationsDispatch({ type: TranslationsActionType.SET_ACTIVE_LANGUAGE, payload: { language: lang } });
      },
    }
  );

  return {
    error: isError,
    isLoading,
    success: isSuccess,
    run: ({ language }: UseRetrieveTranslationProps) => mutate({ language }),
    activeLanguage: translationsState.activeLanguage,
    data: translationsState.translations,
  };
};
