import { SvgIcon } from '@mui/material';
import { RoutalPalette } from '../../Colors';

export const CrossIcon = (props: any) => (
  <SvgIcon
    {...props}
    viewBox="0 0 19.84 19.84"
    style={{ width: `16px`, height: `16px`, color: RoutalPalette.primary40, ...props.style }}
  >
    <g>
      <path d="m10.35,8.99l6.67-6.68c.44-.44.44-1.16,0-1.6-.44-.42-1.14-.42-1.58.01l-6.69,6.65L2.08.73c-.49-.39-1.21-.31-1.6.18-.33.41-.33,1,0,1.41l6.65,6.68L.48,15.65c-.45.44-.45,1.16-.01,1.61.44.45,1.16.45,1.61.01,0,0,0,0,0,0l6.66-6.67,6.69,6.67c.42.47,1.14.51,1.61.09.47-.42.51-1.14.09-1.61-.03-.03-.06-.06-.09-.09l-6.7-6.66Z" />
    </g>
  </SvgIcon>
);
