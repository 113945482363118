import { Grid, Popover, PopoverOrigin } from '@mui/material';
import { HSpacer, RoutalPalette } from '@nx-smartmonkey/ui';
import { useState } from 'react';

import CrossIcon from '../../../assets/svg/CrossIcon';
import { intl } from '../../../helpers/IntGlobalProvider';
import { Button } from '../../../react-components/Button';
import { Text } from '../../../react-components/Text';
import { SearchFilter } from './SearchFilter';

interface SearchFilterProps {
  filter: SearchFilter;
  buttonId: string;
  popoverContainerId?: string;
  popoverMaxHeight?: string;
  colors: {
    primaryColor: string;
    secondaryColor: string;
  };
  isExampleURL?: boolean;
  onRemoveFilter: (filter: SearchFilter) => void;
  customResult?: (filter: SearchFilter) => React.ReactElement;
}

const defaultProps = {
  anchor: {
    anchorOrigin: {
      vertical: `bottom`,
      horizontal: `left`,
    } as PopoverOrigin,
    transformOrigin: {
      vertical: `top`,
      horizontal: `left`,
    } as PopoverOrigin,
  },
};

export const SearchFilterComponent = ({
  filter,
  buttonId,
  popoverContainerId,
  popoverMaxHeight = `800px`,
  children,
  colors,
  isExampleURL = false,
  onRemoveFilter,
  customResult,
}: React.PropsWithChildren<SearchFilterProps>) => {
  const [open, setOpen] = useState(null);

  return (
    <>
      <Button
        id={buttonId}
        disabled={isExampleURL}
        style={{
          backgroundColor: filter.isValueValid() ? undefined : `${RoutalPalette.error.medium}34`,
          borderColor: colors.primaryColor,
          margin: 0,
          height: `34px`,
          padding: `0px 14px`,
        }}
        variant="flat"
        onClick={(event: any) => {
          event.stopPropagation();
          if (!isExampleURL) {
            setOpen(event.currentTarget);
          }
        }}
      >
        <Grid container direction="row" wrap="nowrap" alignItems="center">
          <Grid
            item
            style={{ display: `flex`, justifyContent: `center`, cursor: !isExampleURL ? `pointer` : `default` }}
          >
            <img src={filter.getIconSrc()} alt="" draggable="false" width="16" />
          </Grid>

          <HSpacer small />

          <Grid item>
            {(customResult && customResult(filter)) || (
              <Text
                style={{
                  fontWeight: `normal`,
                  cursor: !isExampleURL ? `pointer` : `default`,
                  color: filter.isValueValid() ? undefined : RoutalPalette.error.medium,
                }}
                intlId={`search_filters.${filter.getOperator()}.result`}
                intlValues={{
                  field: intl.formatMessage({ id: `search_filters.filter.${filter.getField()}` }),
                  value: filter.isValueValid()
                    ? `"${filter.getComputedValue()}"`
                    : `"${intl.formatMessage({ id: `search_filters.missing_value` })}"`,
                }}
              />
            )}
          </Grid>

          <HSpacer small />

          <Grid item>
            <CrossIcon
              style={{
                cursor: !isExampleURL ? `pointer` : `default`,
                width: `12px`,
                height: `12px`,
                marginTop: `4px`,
                color: filter.isValueValid() ? colors.primaryColor : RoutalPalette.error.medium,
              }}
              onClick={(event: any) => {
                event.stopPropagation();

                if (!isExampleURL) {
                  onRemoveFilter(filter);

                  if (open) {
                    setOpen(null);
                  }
                }
              }}
            />
          </Grid>
        </Grid>
      </Button>

      <Popover
        anchorEl={open}
        keepMounted
        open={Boolean(open)}
        onClose={(event: any) => {
          event.preventDefault();
          event.stopPropagation();
          setOpen(null);
        }}
        style={{
          maxHeight: popoverMaxHeight,
        }}
        anchorOrigin={defaultProps.anchor.anchorOrigin}
        transformOrigin={defaultProps.anchor.transformOrigin}
      >
        {children}

        <Grid
          id={popoverContainerId}
          container
          direction="row"
          justifyContent="center"
          style={{
            padding: `8px`,
            borderTop: `1px solid ${RoutalPalette.neutral00}`,
            cursor: `pointer`,
          }}
          onClick={(event: any) => {
            event.stopPropagation();
            setOpen(null);
          }}
        >
          <Grid item>
            <Text
              isBold
              intlId="done"
              style={{
                color: colors.primaryColor,
                cursor: `pointer`,
              }}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};
