import { StopsActionType } from '../../context/stops/actions';
import { useStopsContext } from '../../context/stops/context';
import { StopsTableColumn } from '../../context/stops/tableColumns';

type UseSetStopsColumnsResponse = {
  run: (tableColumns: Array<StopsTableColumn>) => void;
  tableColumns: Array<StopsTableColumn>;
};

// Hooks act as 'Adapter' layer.
export const useSetStopsColumns = (): UseSetStopsColumnsResponse => {
  const { stopsState, stopsDispatch } = useStopsContext();
  const run = (tableColumns: Array<StopsTableColumn>) => {
    stopsDispatch({
      type: StopsActionType.SET_STOPS_TABLE_COLUMNS,
      payload: tableColumns,
    });
  };

  return {
    run,
    tableColumns: stopsState.stops.tableColumns,
  };
};
