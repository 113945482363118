import { SvgIcon } from '@mui/material';
import React from 'react';

export default function GoodDriver(props: any) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 36.85 36.85"
      style={{ width: `34px`, height: `34px`, color: `#CCCECF`, ...props.style }}
    >
      <g>
        <path d="M18.42,31.57c3.13,0,4.3-1.89,4.42-2.1,.4-.68,.17-1.54-.51-1.94-.22-.13-.46-.19-.71-.19-.49,0-.95,.26-1.19,.69-.52,.55-1.26,.82-2.01,.73-.72,.08-1.44-.16-1.96-.67h0c-.38-.68-1.23-.92-1.91-.55-.68,.38-.92,1.23-.55,1.91h0c.12,.22,1.26,2.12,4.42,2.12" />
        <path d="M35.65,16.56c0-1.41-1.05-2.54-2.75-3.44l.54-4.84c.19-1.75-.82-3.41-2.47-4.03L21.56,.7c-2.15-.81-4.53-.8-6.67,.04L5.78,4.3c-1.6,.63-2.57,2.27-2.34,3.98l.64,4.78c-1.77,.91-2.88,2.06-2.88,3.5,0,1.84,1.78,3.22,4.48,4.2-.23,.95-.34,1.92-.34,2.9,0,7.23,5.86,13.09,13.09,13.1,7.23,0,13.09-5.86,13.1-13.09h0c0-.98-.12-1.96-.35-2.9,2.7-.98,4.48-2.36,4.48-4.2M6.81,6.92L15.91,3.36c1.49-.59,3.15-.6,4.66-.03l9.41,3.55c.45,.17,.72,.62,.67,1.09l-.45,4.03H6.77l-.54-4.09c-.06-.43,.18-.84,.58-.99m11.62,27.02c-5.67,0-10.27-4.6-10.28-10.28,0-.71,.08-1.42,.24-2.12,3.3,.7,6.67,1.04,10.04,1.02,3.38,.03,6.74-.32,10.04-1.02,.15,.7,.23,1.41,.24,2.12,0,5.68-4.61,10.27-10.28,10.28m0-14.19c-9.14,0-14.25-2.24-14.42-3.18,.08-.43,1.15-1.11,3.14-1.74v-.02H29.91c1.85,.61,2.85,1.33,2.93,1.75-.19,.96-5.29,3.18-14.41,3.18" />
      </g>
    </SvgIcon>
  );
}
