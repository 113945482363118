/* eslint-disable camelcase */
import { Grid } from '@mui/material';
import { millisecondsToMMss } from '@nx-smartmonkey/shared/helpers';
import { HSpacer, RoutalDialog } from '@nx-smartmonkey/ui';

import { useIntl } from 'react-intl';
import { Stop } from '../../domain/stop/Stop';
import { saveData } from '../../helpers/excel/Common';
import { exportStopsExcel } from '../../helpers/excel/Stops';
import { useRetrieveProject } from '../../hooks/project/useRetrieveProject';
import { useExportStops } from '../../hooks/stop/useExportStops';
import { useSetStopsColumns } from '../../hooks/stop/useSetStopsColumns';
import { useRetrieveSupervisor } from '../../hooks/supervisor/useRetrieveSupervisor';
import { Text } from '../../react-components/Text';

const LARGE_EXPORT_THRESHOLD = 500;

type ExportStopsDialogProps = {
  open: boolean;
  selectedStopIds: Array<string>;
  onCloseDialog: (...args: any[]) => any;
};

export const ExportStopsDialog = ({ open, selectedStopIds, onCloseDialog }: ExportStopsDialogProps) => {
  const intl = useIntl();
  const totalStops = selectedStopIds.length;
  const { data: supervisor } = useRetrieveSupervisor();
  const { data: project } = useRetrieveProject();
  const { tableColumns } = useSetStopsColumns();
  const { run: exportStops, isLoading, duration } = useExportStops();

  if (!project || !supervisor) return null;

  const onExportStops = async (e: any) => {
    const exportedStops = await exportStops({ stopIds: selectedStopIds });

    const validStops = exportedStops.filter((stop) => stop !== null) as Stop[];

    if (validStops.length) {
      saveData(
        exportStopsExcel({ stops: validStops, project: project!, tableColumns, supervisor: supervisor! }),
        intl.formatMessage({ id: `supervisors.excel.sheets.stops` })
      );
    }
    onCloseDialog(e);
  };

  return (
    <RoutalDialog
      open={open}
      onCloseDialog={(e: any) => {
        if (!isLoading) {
          onCloseDialog(e);
        }
      }}
      dialogProps={{
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        style: {
          minHeight: `400px`,
        },
      }}
      showCrossCloseButton={!isLoading}
      titleContent={<Text variant="h6" intlId="supervisors.stops.export_dialog.title" />}
      cancelButtonText={intl.formatMessage({ id: `supervisors.stops.export_dialog.buttons.cancel` })}
      confirmButtonText={intl.formatMessage({ id: `supervisors.stops.export_dialog.buttons.confirm` })}
      onConfirm={onExportStops}
      // eslint-disable-next-line react/jsx-no-useless-fragment
      footerContent={
        isLoading ? (
          <Grid container direction="row" alignItems="center" justifyContent="flex-end">
            <Text intlId="loading" />
            <HSpacer small />
            {duration && <Text>{millisecondsToMMss(duration)}</Text>}
          </Grid>
        ) : undefined
      }
    >
      <Grid container direction="column">
        <Text
          intlId={`supervisors.stops.export_dialog.${totalStops >= LARGE_EXPORT_THRESHOLD ? `info_large` : `info`}`}
          intlValues={{ total: selectedStopIds.length }}
        />
      </Grid>
    </RoutalDialog>
  );
};
