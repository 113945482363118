import 'animate.css';

import { Grid } from '@mui/material';
import { isMobileOrTablet } from '@nx-smartmonkey/shared/helpers';
import { RoutalPalette, VSpacer } from '@nx-smartmonkey/ui';
import { useEffect, useState } from 'react';

import { NoStopsSupervisors } from '../../assets/svg/NoStopsSupervisors';
import { TableColumnsSortConfig, sortDataByTableColumns } from '../../context/shared/tableColumns';
import { StopsTableColumnKeys } from '../../context/stops/tableColumns';
import { Stop } from '../../domain/stop/Stop';
import { useRetrieveProject } from '../../hooks/project/useRetrieveProject';
import { DetailTabTypes, useRetrieveRightPanelInfo } from '../../hooks/right-panel/useRetrieveRightPanelInfo';
import { useRetrieveFlatStops } from '../../hooks/stop/useRetrieveFlatStops';
import { useSearchStops } from '../../hooks/stop/useSearchStops';
import { useSearchStopsPredicates } from '../../hooks/stop/useSearchStopsPredicates';
import { useSetSelectedStops } from '../../hooks/stop/useSetSelectedStops';
import { useRetrieveSupervisor } from '../../hooks/supervisor/useRetrieveSupervisor';
import { useIsExampleURL } from '../../hooks/useIsExampleURL';
import { Text } from '../../react-components/Text';
import { DataTable } from '../../react-components/data-table/DataTable';
import { getStopsColumns } from './StopsListColumns';

const LIMIT = 20;

export const StopsList = () => {
  const { data: project } = useRetrieveProject();
  const { setRightPanelInfo } = useRetrieveRightPanelInfo();
  const { isExampleURL } = useIsExampleURL();
  const { data: selectedIds, run: setSelectedIds } = useSetSelectedStops();
  const { data: supervisor } = useRetrieveSupervisor();
  const { predicates } = useSearchStopsPredicates();

  const [searchParameters, setSearchParameters] = useState<{ limit: number; offset: number }>({
    offset: 0,
    limit: LIMIT,
  });

  const [sortStopsInfo, setSortStopsInfo] = useState<TableColumnsSortConfig<StopsTableColumnKeys>>({
    orderBy: `label`,
    order: `desc`,
  });

  const { data: flatStops } = useRetrieveFlatStops();

  const {
    data: stopsPagination,
    isLoading,
    tableColumns,
    success: isSuccess,
    run: searchStops,
  } = useSearchStops({
    offset: searchParameters.offset,
    limit: searchParameters.limit,
    sortBy: sortStopsInfo.orderBy,
    sortDirection: sortStopsInfo.order,
  });

  useEffect(() => {
    if (predicates.length > 0) {
      searchStops();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, searchParameters, sortStopsInfo]);

  if (!project || !supervisor || !stopsPagination || !isSuccess) {
    return null;
  }

  if (isLoading || stopsPagination.total > 0) {
    return (
      <div style={{ paddingLeft: `20px`, height: `100%`, width: `calc(100% - 20px)` }}>
        <Grid
          container
          style={{
            height: `100%`,
            backgroundColor: RoutalPalette.neutral00,
            overflowX: `auto`,
          }}
          direction="column"
        >
          <Grid item container style={{ overflowX: `auto`, overflowY: `hidden`, height: `100%`, width: `100%` }}>
            <Grid container style={{ width: `100%`, height: `100%`, position: `relative` }}>
              <div style={{ height: `calc(100% - 24px)`, width: `100%`, overflowX: `auto`, position: `absolute` }}>
                <DataTable
                  docs={sortDataByTableColumns({
                    data: stopsPagination.docs,
                    tableColumns,
                    sortInfo: sortStopsInfo,
                  }).map((stop: Stop) => {
                    return {
                      doc: stop,
                      checkboxIcon: { element: <div style={{ height: `24px`, width: `24px` }} /> },
                    };
                  })}
                  maxHeight="100vh"
                  loading={isLoading}
                  total={stopsPagination.total}
                  limit={stopsPagination.limit}
                  page={stopsPagination.page}
                  pages={stopsPagination.pages}
                  emptyMessage={<Text intlId="no_results" />}
                  selectedIds={selectedIds}
                  showRowCheckboxAlways={isMobileOrTablet()}
                  onMultipleSelection={({ selectedIds: newSelection, fromGlobalCheckbox }) => {
                    if (!isExampleURL) {
                      if (fromGlobalCheckbox) {
                        if (selectedIds.length > 0) {
                          setSelectedIds([]);
                        } else {
                          setSelectedIds((flatStops ?? []).map((fS) => fS.id));
                        }
                      } else {
                        setSelectedIds(newSelection ?? []);
                      }
                    }
                  }}
                  onRowDoubleClick={(stop: Stop) => {
                    if (!isExampleURL) {
                      setRightPanelInfo({
                        detailId: `${stop.id}`,
                        detailTab: null,
                      });
                    }
                  }}
                  onRowSelect={(stop: Stop) => {
                    if (!isExampleURL && isMobileOrTablet()) {
                      setRightPanelInfo({
                        detailId: `${stop.id}`,
                        detailTab: null,
                      });
                    }
                  }}
                  onChangePage={({ offset, limit }) =>
                    setSearchParameters({
                      ...searchParameters,
                      offset,
                      limit,
                    })
                  }
                  sortBy={sortStopsInfo.orderBy}
                  sortDirection={sortStopsInfo.order}
                  onColumnSort={(column: any, order: `asc` | `desc`) => {
                    setSortStopsInfo({ order, orderBy: column });
                  }}
                  columns={getStopsColumns({
                    project,
                    supervisor,
                    tableColumns,
                    ...(!isExampleURL
                      ? {
                          onOpenRightPanel: (e: any, doc: Stop, detailTab?: DetailTabTypes) => {
                            e.stopPropagation();
                            setRightPanelInfo({
                              detailId: `${doc.id}`,
                              detailTab: detailTab ?? null,
                            });
                          },
                        }
                      : {}),
                  })}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ height: `calc(100% - 72px)` }}
      wrap="nowrap"
    >
      <Grid container justifyContent="center">
        <NoStopsSupervisors style={{ width: `400px`, maxWidth: `90vw` }} />
      </Grid>

      <VSpacer large />

      <Grid container direction="column" justifyContent="center" style={{ textAlign: `center` }}>
        <Text variant="h6" isBold intlId="supervisors.stops.no_stops.title" />
      </Grid>
    </Grid>
  );
};
