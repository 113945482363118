import { SearchPredicateDTO } from '@nx-smartmonkey/shared/domain';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import * as routing from '../../constants/Routing';
import { StopsActionType } from '../../context/stops/actions';
import { useStopsContext } from '../../context/stops/context';
import { SearchOperator, SearchPredicate, SearchType } from '../../domain/searchPredicates/SearchPredicate';
import { useRetrieveRightPanelInfo } from '../right-panel/useRetrieveRightPanelInfo';
import { useIsExampleURL } from '../useIsExampleURL';
import { useRouterQuery } from '../useRouterQuery';
import { useSetStopsView } from './useSetStopsView';

export const useSearchStopsPredicates = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isExampleURL } = useIsExampleURL();
  const { stopsState, stopsDispatch } = useStopsContext();
  const urlParams = useRouterQuery();
  const { view } = useSetStopsView();
  const { detailTab, detailId } = useRetrieveRightPanelInfo();

  useEffect(() => {
    // Only adds the predicates base64 string in the url if we are already in one of the planner plans pages.
    if (location.pathname.includes(routing.ROOT)) {
      const predicatesParam = urlParams.get(`predicates`);
      if (predicatesParam !== stopsState.stops.predicatesBase64 && stopsState.stops.predicatesBase64 !== ``) {
        const params = new URLSearchParams();
        params.append(`view`, view);
        if (detailId) params.set(`detailId`, `${detailId}`);
        if (detailTab) params.set(`detailTab`, `${detailTab}`);
        params.append(`predicates`, stopsState.stops.predicatesBase64);
        navigate(`${routing.ROOT}?${params.toString()}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopsState.stops.predicatesBase64]);

  useEffect(() => {
    // The first time, predicates are empty
    if (location.pathname.includes(routing.ROOT) && stopsState.stops.predicates.length === 0) {
      const predicatesParam = urlParams.get(`predicates`);

      if (predicatesParam && predicatesParam !== ``) {
        const base64Decoded = Buffer.from(predicatesParam, `base64`);
        const decoded = JSON.parse(base64Decoded.toString(`utf8`));
        if (Array.isArray(decoded) && stopsState.stops.predicatesBase64 === ``) {
          const retrievedPredicates: Array<SearchPredicate> = decoded.map((sP: SearchPredicateDTO) => {
            let type: SearchType = sP.type as SearchType;
            if (sP.field === `survey`) {
              type = `survey`;
            }
            if (sP.field === `status`) {
              type = `list`;
            }

            return SearchPredicate.create({
              field: sP.field,
              operator: sP.operator as SearchOperator,
              type,
              value: sP.value,
            });
          });

          stopsDispatch({
            type: StopsActionType.SET_SEARCH_STOPS_PREDICATES,
            payload: {
              predicates: retrievedPredicates,
              predicatesBase64: SearchPredicate.predicatesToBase64(retrievedPredicates),
            },
          });
          return;
        }
      }

      stopsDispatch({
        type: StopsActionType.SET_SEARCH_STOPS_PREDICATES,
        payload: {
          predicates: [] as SearchPredicate[],
          predicatesBase64: SearchPredicate.predicatesToBase64([] as SearchPredicate[]),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPredicatesFunc = (newPredicates: Array<SearchPredicate>) => {
    if (!isExampleURL) {
      const base64 = SearchPredicate.predicatesToBase64(newPredicates);
      stopsDispatch({
        type: StopsActionType.SET_SEARCH_STOPS_PREDICATES,
        payload: {
          predicates: newPredicates,
          predicatesBase64: base64,
        },
      });

      const params = new URLSearchParams();
      params.append(`view`, view);
      if (detailId) params.set(`detailId`, `${detailId}`);
      if (detailTab) params.set(`detailTab`, `${detailTab}`);
      if (stopsState.stops.predicatesBase64 !== base64) {
        if (base64 !== ``) {
          params.append(`predicates`, base64);
        }
      } else if (stopsState.stops.predicatesBase64) {
        if (stopsState.stops.predicatesBase64 !== ``) {
          params.append(`predicates`, stopsState.stops.predicatesBase64);
        }
      }
      navigate(`${routing.ROOT}?${params.toString()}`);
    }
  };

  return {
    predicates: stopsState.stops.predicates,
    predicatesBase64: stopsState.stops.predicatesBase64,
    setPredicates: setPredicatesFunc,
  };
};
