import { SvgIcon } from '@mui/material';
import React from 'react';

interface NotificationsProps {
  hasNewNotifications?: boolean;
  color?: string;
  colorNotification?: string;
  style?: React.CSSProperties;
}

export const Notifications = ({
  hasNewNotifications = false,
  color = `#2E3744`,
  colorNotification = `#D62C20`,
  style = {},
  ...props
}: NotificationsProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 30 30" style={{ width: `28px`, height: `28px`, ...style }}>
      <g>
        {hasNewNotifications ? (
          <g>
            <path
              fill={color}
              strokeWidth="0px"
              d="m25,12c-.17,0-.33-.01-.5-.03v14.03H3.5V5s0,0,0,0h15.03c.05-.7.21-1.38.47-2H3.5c-1.1,0-2,.9-2,2v21c0,1.1.9,2,2,2h21c1.1,0,2-.9,2-2v-14.18c-.48.11-.98.18-1.5.18Z"
            />
            <circle fill={colorNotification} stroke={colorNotification} strokeWidth="2px" cx="25" cy="5.5" r="3.5" />
          </g>
        ) : (
          <g>
            <path
              fill={color}
              strokeWidth="0px"
              d="m25,12c-.17,0-.33-.01-.5-.03v14.03H3.5V5s0,0,0,0h15.03c.05-.7.21-1.38.47-2H3.5c-1.1,0-2,.9-2,2v21c0,1.1.9,2,2,2h21c1.1,0,2-.9,2-2v-14.18c-.48.11-.98.18-1.5.18Z"
            />
            <circle fill="none" stroke={color} strokeWidth="2px" cx="25" cy="5.5" r="3.5" />
          </g>
        )}
      </g>
    </SvgIcon>
  );
};
