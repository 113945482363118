import { useEffect, useState } from 'react';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

import { RoutalPalette } from './Colors';

const numberOfColors = 5;
const loaderDash = 63;
const loaderDuration = numberOfColors;
const loaderDurationAlt = loaderDuration / numberOfColors;
const loaderKeyframe = (1 / (numberOfColors * 2)) * 100;

type LoaderContainerProps = {
  colors: Array<string>;
};

const LoaderContainer = styled.div<LoaderContainerProps>`
  ${(props) =>
    `
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .loader {
        z-index: 1;
        animation: loader-turn ${loaderDurationAlt}s linear infinite;
        padding: 1rem;
        width: 100%;

        @keyframes loader-turn {
          50% {
            transform: rotate(180deg);
          }
          100% {
            transform: rotate(720deg);
          }
        }
      }

      .loader__value {
        animation: loader-stroke ${loaderDuration}s linear infinite;
        fill: none;
        stroke-dasharray: ${loaderDash};
        stroke-dashoffset: ${loaderDash};
        stroke-linecap: round;
        stroke-width: 2;
        ${props.colors.map((color: string, index: number) => {
          return `
              &:nth-child(${index + 1}) {
                stroke: ${color};
                animation-delay: ${index * loaderDurationAlt}s;
              }
            `;
        })}
      }

      @keyframes loader-stroke {
        ${loaderKeyframe * 1}% {
          stroke-dashoffset: 0;
        }

        ${loaderKeyframe * 2}%,
        100% {
          stroke-dashoffset: ${loaderDash};
        }
      }
    `}
`;

type LoaderProps = {
  color?: string;
  width?: number;
  strokeWidth?: number;
  style?: React.CSSProperties;
};

const RoutalLoader = ({ color = RoutalPalette.primary40, width = 80, strokeWidth = 2, style = {} }: LoaderProps) => {
  const [colors, setColors] = useState<Array<string>>([]);

  useEffect(() => {
    const tColor = tinycolor(color);

    const newColors = [];

    for (let i = 0; i < numberOfColors; i++) {
      const lightenBackgroundColor = tColor.lighten(8).toString();
      newColors.push(lightenBackgroundColor);
    }

    setColors(newColors);
  }, [color]);

  return (
    <LoaderContainer colors={colors}>
      <svg
        style={{ width: `${width}px`, height: `calc(100% - ${width / 2}px)`, ...style }}
        className="loader"
        viewBox="0 0 24 24"
      >
        <circle style={{ strokeWidth: strokeWidth }} key="1" className="loader__value" cx="12" cy="12" r="10" />
        <circle style={{ strokeWidth: strokeWidth }} key="2" className="loader__value" cx="12" cy="12" r="10" />
        <circle style={{ strokeWidth: strokeWidth }} key="3" className="loader__value" cx="12" cy="12" r="10" />
        <circle style={{ strokeWidth: strokeWidth }} key="4" className="loader__value" cx="12" cy="12" r="10" />
        <circle style={{ strokeWidth: strokeWidth }} key="5" className="loader__value" cx="12" cy="12" r="10" />
        <circle style={{ strokeWidth: strokeWidth }} key="6" className="loader__value" cx="12" cy="12" r="10" />
      </svg>
    </LoaderContainer>
  );
};

export default RoutalLoader;
