type GetTruckSVGIconProps = {
  color: string;
  selectedColor?: string;
  selected?: boolean;
  orientation?: number;
  kind: `truck` | `car` | `pedestrian` | `bicycle` | `scooter` | `avatar`;
  avatarUrl?: string;
};

export const getTransportModeSVGIcon = ({
  color,
  selected,
  orientation,
  kind,
  avatarUrl,
}: GetTruckSVGIconProps): string => {
  const fillColor = selected ? `white` : color;
  const backgroundColor = selected ? color : `white`;

  let content: string;

  switch (kind) {
    case `car`:
      content = [
        // THIS IS THE OLD SVG ICON FOR THE CAR
        // `<g transform="translate(22.5 22.5)">`,
        // `<circle fill="${backgroundColor}" stroke="${fillColor}" stroke-miterlimit="10" stroke-width="2" r="12" />`,
        // `<g fill="${fillColor}" transform="translate(-15 -22)">`,
        // `<circle cx="10.43" cy="26.02" r="1.41"/>`,
        // `<circle cx="19.98" cy="26.02" r="1.41"/>`,
        // `<path d="M9.08,17.57c-.99,0-1.78,.8-1.78,1.78v6.6h1.35c.04-.97,.84-1.75,1.82-1.75s1.79,.78,1.82,1.75h4.33v-8.38h-7.54Z"/>`,
        // `<path d="M20.08,19.37h-2.74v6.58h.83c.04-.97,.83-1.74,1.81-1.74s1.77,.77,1.81,1.74h1.08v-3.45l-2.79-3.13Zm1.07,2.75h-3.11v-1.99h1.69l1.42,1.63v.37Z"/>`,
        // `</g>`,
        // `</g>`,
        `<g transform="translate(22.5 22.5)">`,
        `<circle fill="${backgroundColor}" stroke="${fillColor}" stroke-miterlimit="10" stroke-width="2" r="12" />`,
        `<g fill="${fillColor}" transform="scale(0.65) translate(-12, -8)">`,
        `<path d="M1.92426 7.75562H22.635" fill="none" stroke="${fillColor}" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>`,
        `<path d="M15.0382 1.94946V7.75575" fill="none"  stroke="${fillColor}" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>`,
        `<path fill="none" d="M6.4648 16.0505C7.91332 16.0505 9.08758 14.9365 9.08758 13.5621C9.08758 12.1878 7.91332 11.0737 6.4648 11.0737C5.01627 11.0737 3.84201 12.1878 3.84201 13.5621C3.84201 14.9365 5.01627 16.0505 6.4648 16.0505Z" stroke="${fillColor}" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>`,
        `<path fill="none" d="M16.9559 16.0505C18.4044 16.0505 19.5787 14.9365 19.5787 13.5621C19.5787 12.1878 18.4044 11.0737 16.9559 11.0737C15.5074 11.0737 14.3331 12.1878 14.3331 13.5621C14.3331 14.9365 15.5074 16.0505 16.9559 16.0505Z" stroke="${fillColor}" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>`,
        `<path fill="none" d="M20.3035 3.32472C19.8593 2.48198 18.951 1.94946 17.9578 1.94946H3.67278C2.70672 1.94946 1.92426 2.69184 1.92426 3.6084V12.7326C1.92426 13.1904 2.31592 13.562 2.79852 13.562H3.84199C3.84199 12.1876 5.01612 11.0736 6.46477 11.0736C7.91343 11.0736 9.08756 12.1876 9.08756 13.562H14.3331C14.3331 12.1876 15.5073 11.0736 16.9559 11.0736C18.4046 11.0736 19.5787 12.1876 19.5787 13.562H22.2015C22.6841 13.562 23.0757 13.1904 23.0757 12.7326V8.58522L20.3035 3.32472Z" stroke="${fillColor}" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>`,
        `</g>`,
        `</g>`,
      ].join(``);
      break;
    case `truck`:
      content = [
        `<g transform="translate(22.5 22.5)">`,
        `<circle fill="${backgroundColor}" stroke="${fillColor}" stroke-miterlimit="10" stroke-width="2" r="12" />`,
        `<g fill="${fillColor}" transform="translate(-16 -24) scale(1.1)">`,
        `<path d="M19.1,24.13c-.64,0-1.16,.52-1.16,1.16s.52,1.16,1.16,1.16,1.16-.52,1.16-1.16-.52-1.16-1.16-1.16Z" />`,
        `<path d="M9.57,24.13c-.64,0-1.16,.52-1.16,1.16s.52,1.16,1.16,1.16,1.16-.52,1.16-1.16-.52-1.16-1.16-1.16Z" />`,
        `<path d="M13.68,24.13c-.64,0-1.16,.52-1.16,1.16s.52,1.16,1.16,1.16,1.16-.52,1.16-1.16-.52-1.16-1.16-1.16Z" />`,
        `<path d="M7.58,19.81v5.42h.51c.03-.79,.68-1.42,1.48-1.42s1.44,.63,1.48,1.42h1.16c.03-.79,.68-1.42,1.48-1.42s1.44,.63,1.48,1.42h1.19v-6.88h-7.09c-.93,0-1.68,.66-1.68,1.47Z" />`,
        `<path d="M21.81,22.37l-.87-.59h0l-1.75-1.96h-2.25v5.4h.68c.03-.79,.68-1.43,1.49-1.43s1.45,.63,1.49,1.43h1.84c.09,0,.16-.07,.16-.16v-1.52c0-.5-.37-.9-.78-1.17Zm-1.74-.29h-2.55v-1.64h1.39l1.17,1.34v.3Z" />`,
        `</g>`,
        `</g>`,
      ].join(``);
      break;
    case `bicycle`:
      content = [
        `<g transform="translate(22.5 22.5)">`,
        `<circle fill="${backgroundColor}" stroke="${fillColor}" stroke-miterlimit="10" stroke-width="2" r="12" />`,
        `<g fill="${fillColor}" transform="translate(-15 -22)">`,
        `<circle cx="10.07" cy="24.97" r="2.18" />`,
        `<circle cx="20.03" cy="24.97" r="2.18" />`,
        `<path d="M19.77,18.19c-.09-.26-.37-.4-.63-.31l-1.54,.52c-.13,.04-.24,.14-.29,.26s-.06,.27,0,.39l.3,.74s-.02,0-.03,0l-4.12,1.1-.44-1.02h.59c.28,0,.5-.23,.5-.5s-.23-.5-.5-.5h-1.98c-.28,0-.5,.23-.5,.5s.23,.5,.5,.5h.31l.64,1.5-1.03,1.16c.28,.18,.53,.4,.74,.66l.72-.79,.88,2.08h-1.04c.03,.17,.04,.33,.04,.51s-.01,.33-.04,.49h2.15l3.12-4.38,.57,1.39c.28-.17,.59-.3,.92-.37l-1.2-2.93,1.04-.35c.26-.09,.4-.37,.31-.63Zm-4.97,5.87l-.95-2.23,3.13-.83-2.18,3.07Z" />`,
        `</g>`,
        `</g>`,
      ].join(``);
      break;
    case `scooter`:
      content = [
        `<g transform="translate(22.5 22.5)">`,
        `<circle fill="${backgroundColor}" stroke="${fillColor}" stroke-miterlimit="10" stroke-width="2" r="12" />`,
        `<g fill="${fillColor}" transform="translate(-15 -22)">`,
        `<circle cx="11.99" cy="25.47" r="1.38" />`,
        `<circle cx="20.1" cy="25.47" r="1.38" />`,
        `<path d="M18.53,18.77h-.41c-.04-.06-.09-.11-.15-.16-.17-.12-.39-.15-.63-.08-.1,.03-.2-.03-.23-.12-.03-.1,.03-.2,.12-.23,.36-.11,.7-.06,.95,.13,.16,.12,.27,.28,.34,.45Z" />`,
        `<path d="M18.4,18.25c-.15-.11-.33-.2-.51-.24,0,0,.73-.58,2.19-.58v1.35h-1.23c-.1-.2-.26-.39-.45-.53Z" />`,
        `<g>`,
        `<path d="M9.1,21.35h5.96c.25-.17,.41-.45,.41-.77,0-.54-.43-.97-.97-.97h-4.84c-.52,0-.97,.41-.97,.95,0,.33,.16,.62,.41,.79Z" />`,
        `<path d="M21.37,23.35c-.05-1.54-1.8-4.27-1.8-4.27-.36,0-1.45,0-1.45,0,0,.06-.02,.28-.02,.4-.13,1.64-.67,4.34-1.83,4.34h-.84v-1.25c0-.18-.03-.37-.08-.56h-6.56c-.04,.18-.07,.36-.07,.56v2.69c0,.13,.11,.22,.22,.22h1.31c0-.97,.78-1.75,1.75-1.75s1.75,.78,1.75,1.75h4.61c0-.95,.75-1.72,1.69-1.75h.14s.03,0,.08,0h.97c.14,0,.12-.37,.12-.37Z" />`,
        `</g>`,
        `</g>`,
        `</g>`,
      ].join(``);
      break;
    case `pedestrian`:
      content = [
        `<g transform="translate(22.5 22.5)">`,
        `<circle fill="${backgroundColor}" stroke="${fillColor}" stroke-miterlimit="10" stroke-width="2" r="12" />`,
        `<g fill="${fillColor}" transform="translate(-15 -22)">`,
        `<circle cx="15.92" cy="15.22" r="1.44" />`,
        `<path d="M18.14,28.2l-.22-3.61c-.03-.44-.23-.86-.55-1.16l-1.09-1.04c-.2-.2-.25-.37-.22-.52l.26-2.35,.17-1.42c.08-.64-.39-1.23-1.03-1.3h0c-.25-.03-.49,.01-.7,.11-.02,0-.04,.02-.06,.03h-.02l-2.23,1.29c-.16,.09-.28,.23-.33,.41l-.86,2.86c-.11,.37,.1,.76,.47,.87,.07,.02,.14,.03,.21,.03,.3,0,.57-.2,.66-.5l.78-2.59,.45-.25-.27,2.23c-.01,.11-.02,.21-.01,.32,0,.12,.01,.24,.05,.34,.09,.37,.28,.7,.57,.96l1.46,1.32c.33,.3,.53,.71,.56,1.15l.17,2.96c.03,.48,.43,.84,.9,.84,.52,0,.93-.44,.89-.96Z" />`,
        `<path d="M13.78,22.94l-1.5,4.57c-.16,.49,.07,1.03,.54,1.23,.52,.21,1.1-.07,1.28-.59l1.19-3.63c.03-.09,0-.19-.07-.26" />`,
        `<path d="M16.49,20.39l1.69,1.36c.3,.24,.74,.2,.98-.1,.24-.3,.2-.74-.1-.98l-2.39-1.95" />`,
        `</g>`,
        `</g>`,
      ].join(``);
      break;
    case `avatar`:
      if (!avatarUrl) {
        content = ``;
        break;
      }

      content = [
        `<g transform="translate(22.5 22.5)">`,
        `<circle fill="${backgroundColor}" stroke="${fillColor}" stroke-miterlimit="10" stroke-width="2" r="12" />`,
        `<g transform="translate(-11 -11)">`,
        `<defs>`,
        `<clipPath id="circleView">`,
        `<circle cx="11" cy="11" r="11" fill="#FFFFFF" />`,
        `</clipPath>`,
        `</defs>`,
        `<g>`,
        `<image href="${avatarUrl}" width="22px" height="22px" clip-path="url(#circleView)" />`,
        `</g>`,
        `</g>`,
        `</g>`,
      ].join(``);
      break;
    default:
      content = ``;
      break;
  }

  return [
    `<svg width="64" height="64" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45">`,
    ...(orientation || orientation === 0
      ? [
          `<g style="transform-origin: center; transform:rotate(${orientation}deg);">`,
          `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67 54" style="height: 100%; width: 100%;">`,
          `<path fill="${backgroundColor}" stroke="${fillColor}" stroke-width="2.5" style="transform: translate(8px, 0px);" d="M46.4003 32.9616L46.3961 20.8372L56.8982 26.8957L46.4003 32.9616Z" />`,
          `</svg>`,
          `</g>`,
        ]
      : []),
    `${content}`,
    `</svg>`,
  ].join(``);
};
