import { SearchFilter } from '../filter';
import { SearchFilterProps } from '../filter/SearchFilter';

export type SearchFilterBooleanOperator = `eq` | `unknown` | `known`;

interface SearchFilterBooleanProps extends SearchFilterProps {
  field: string;
  operator: SearchFilterBooleanOperator;

  // - "is unknown" => unknown (value es null)
  // - "has any value" => known (value es null)
}

export class SearchFilterBoolean extends SearchFilter {
  constructor(props: SearchFilterBooleanProps) {
    super({ ...props, type: `boolean` });
  }
}
