import { GPSTrackMapper, HexColor } from '@nx-smartmonkey/shared/domain';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { webSocketsService } from '../../application/WebSocketsService';
import { StopsActionType } from '../../context/stops/actions';
import { useStopsContext } from '../../context/stops/context';
import { DriverInfo } from '../../domain/stop/Stop';
import { StopDTO } from '../../domain/stop/StopDTO';
import { StopMapper } from '../../mappers/stop/StopMapper';
import { useNotifications } from '../notifications/useNotifications';
import { useRetrieveProject } from '../project/useRetrieveProject';
import { useRetrieveRightPanelInfo } from '../right-panel/useRetrieveRightPanelInfo';
import { useRetrieveSupervisor } from './useRetrieveSupervisor';

const getSubscriptionSupervisorPath = (supervisorId: string) => `/supervisor/${supervisorId}`;

// Hooks act as 'Adapter' layer.
export const useSupervisorWebSockets = () => {
  const queryClient = useQueryClient();
  const { setNewNotifications } = useNotifications();
  const { data: supervisor } = useRetrieveSupervisor();
  const { data: project } = useRetrieveProject();
  const { stopsDispatch } = useStopsContext();
  const { detailId, detailTab, cleanRightPanelInfo } = useRetrieveRightPanelInfo();

  useEffect(() => {
    if (!project || !supervisor || webSocketsService.getSubscription(getSubscriptionSupervisorPath(supervisor.id))) {
      return;
    }
    const handleConnect = () => {
      webSocketsService.subscribe(
        getSubscriptionSupervisorPath(supervisor.id),
        async ({ id, created_at, data, meta }) => {
          switch (id) {
            case `smartmonkey.planner.2.planner.stop.deleted`: {
              const updatedStop = StopMapper.toDomain(data as StopDTO, project.custom_fields);
              stopsDispatch({
                type: StopsActionType.STOP_DELETED,
                payload: {
                  stopId: updatedStop.id,
                },
              });

              setNewNotifications([
                {
                  id: uuidv4(),
                  event_id: id,
                  data,
                  date: created_at,
                },
              ]);

              if (updatedStop.id === detailId) {
                cleanRightPanelInfo();
              }
              break;
            }
            case `smartmonkey.planner.2.planner.stop.created`: {
              _.debounce(async () => {
                await queryClient.invalidateQueries([`supervisors_search_stops`]);
                await queryClient.invalidateQueries([`supervisors_flat_stops`]);
              }, 1000)();

              setNewNotifications([
                {
                  id: uuidv4(),
                  event_id: id,
                  data,
                  date: created_at,
                },
              ]);
              break;
            }
            case `smartmonkey.planner.2.planner.stop.rescheduled`:
            case `smartmonkey.planner.2.planner.stop.moved`:
            case `smartmonkey.planner.2.planner.stop.reported`:
            case `smartmonkey.planner.2.planner.stop.updated`: {
              const updatedStop = StopMapper.toDomain(data as StopDTO, project.custom_fields);
              stopsDispatch({
                type: StopsActionType.STOP_UPDATED,
                payload: {
                  stop: updatedStop,
                },
              });

              let notificationId = id;

              if (
                id === `smartmonkey.planner.2.planner.stop.updated` &&
                meta?.new_supervisors?.includes(supervisor.id)
              ) {
                notificationId = `smartmonkey.planner.2.planner.stop.created`;
                _.debounce(async () => {
                  await queryClient.invalidateQueries([`supervisors_search_stops`]);
                  await queryClient.invalidateQueries([`supervisors_flat_stops`]);
                }, 1000)();
              }

              if (
                id === `smartmonkey.planner.2.planner.stop.updated` &&
                meta?.old_supervisors?.includes(supervisor.id)
              ) {
                notificationId = `smartmonkey.planner.2.planner.stop.deleted`;
                _.debounce(async () => {
                  await queryClient.invalidateQueries([`supervisors_search_stops`]);
                  await queryClient.invalidateQueries([`supervisors_flat_stops`]);
                }, 1000)();
              }

              setNewNotifications([
                {
                  id: uuidv4(),
                  event_id: notificationId,
                  data,
                  date: created_at,
                },
              ]);

              if (updatedStop.id === detailId) {
                await queryClient.invalidateQueries([`supervisors_stop`, updatedStop.id]);
              }
              break;
            }
            case `smartmonkey.planner.2.planner.driver.updated`:
            case `smartmonkey.planner.2.drivers.route.finished`:
            case `smartmonkey.planner.2.drivers.route.updated`:
            case `smartmonkey.planner.2.drivers.route.tracked`:
              if (supervisor.isStopFieldEnabled(`driver_info`)) {
                const { id, color, label, last_position: position } = data as any;
                const driverInfo: DriverInfo = {
                  id,
                  label,
                  color: color ? HexColor.create(color) : undefined,
                  position: position ? GPSTrackMapper.toDomain(position) : undefined,
                };

                stopsDispatch({
                  type: StopsActionType.DRIVER_INFO_UPDATED,
                  payload: {
                    driverInfo,
                  },
                });
              }
              break;
            default:
              break;
          }
        }
      );
    };

    webSocketsService.on(`connection_event`, (e: CustomEvent<string>) => {
      handleConnect();
    });

    return () => {
      if (supervisor) {
        webSocketsService.unsubscribe(getSubscriptionSupervisorPath(supervisor.id));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supervisor, detailId, detailTab]);
};
