import { Skeleton, TableCell, TableRow } from '@mui/material';
import { Checkbox, RoutalPalette } from '@nx-smartmonkey/ui';
import { useState } from 'react';

import styled from 'styled-components';
import { Text } from '../Text';
import { DataTableColumnProps } from './DataTable';

const StyledTableRow = styled(TableRow)`
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  box-sizing: border-box;
  min-width: 100%;
  width: 100%;
  padding: 0;
  border-bottom: 1px solid ${RoutalPalette.neutral10};
  '&$selected': {
    background-color: ${RoutalPalette.primary20n} !important;
  }
  '&$hover:hover': {
    background-color: transparent !important;
  }
`;

type DataTableRowProps = {
  columns: Array<DataTableColumnProps>;
  index: number;
  hover: boolean;
  loading?: boolean;
  opacity?: number;
  isSelected: boolean;
  isLastRow: boolean;
  document: any;
  showCheckbox?: boolean;
  checkboxIcon?: {
    element?: React.ReactElement;
    style?: React.CSSProperties;
  };
  showRowCheckboxAlways?: boolean;
  globalCheckboxColumnStyles?: React.CSSProperties;
  handleRowSelect: (...args: any[]) => any;
  onRowDoubleClick: (...args: any[]) => any;
  handleCheckboxSelect: (...args: any[]) => any;
  handleOnContextMenu: (...args: any[]) => any;
  onMouseEnter: (...args: any[]) => any;
  onMouseLeave: (...args: any[]) => any;
};

export const DataTableRow = ({
  columns,
  index,
  hover,
  loading,
  opacity,
  isSelected,
  isLastRow,
  document,
  showCheckbox = true,
  checkboxIcon,
  showRowCheckboxAlways = false,
  globalCheckboxColumnStyles = {},
  handleRowSelect,
  onRowDoubleClick,
  handleCheckboxSelect,
  handleOnContextMenu,
  onMouseEnter,
  onMouseLeave,
}: DataTableRowProps) => {
  const [rowHover, setRowHover] = useState<boolean>(false);

  const [checkboxRowHover, setCheckboxRowHover] = useState<boolean>(false);

  return (
    <StyledTableRow
      onClick={(event: any) => {
        event.stopPropagation();
        handleRowSelect(document);
      }}
      onContextMenu={(e: any) => {
        if (handleOnContextMenu) handleOnContextMenu(e, document);
      }}
      onDoubleClick={() => onRowDoubleClick(document)}
      hover={hover}
      selected={isSelected}
      style={{
        // width: `100%`,
        opacity: opacity ?? 1,
        backgroundColor: isSelected ? RoutalPalette.primary20n : `unset`,
        ...(isLastRow ? { borderBottom: `unset` } : {}),
      }}
      onMouseEnter={() => {
        onMouseEnter(document);
        setRowHover(true);
      }}
      onMouseLeave={() => {
        onMouseLeave(document);
        setRowHover(false);
      }}
    >
      {showCheckbox && (
        <TableCell
          component="div"
          key={`row-checkbox-${document.id || index}`}
          style={{
            padding: `8px`,
            borderBottom: `unset`,
            ...(checkboxRowHover || isSelected ? globalCheckboxColumnStyles : {}),
          }}
          onClick={(event) => {
            event.stopPropagation();
            handleCheckboxSelect(document);
          }}
          onMouseEnter={() => setCheckboxRowHover(true)}
          onMouseLeave={() => setCheckboxRowHover(false)}
        >
          {loading ? (
            <Skeleton variant="rectangular" animation="pulse" height={20} width={20} style={{ margin: `4px 2px` }} />
          ) : (
            <>
              {showRowCheckboxAlways || checkboxRowHover || isSelected ? (
                <Checkbox style={{ ...(checkboxIcon?.style ?? {}) }} checked={isSelected} />
              ) : (
                <>{checkboxIcon?.element}</>
              )}
            </>
          )}
        </TableCell>
      )}
      {columns.map((column: DataTableColumnProps, index2: number) => {
        let justifyContent = null;

        if (column.align === `right`) justifyContent = `flex-end`;
        if (column.align === `left`) justifyContent = `flex-start`;
        if (column.align === `center`) justifyContent = `center`;

        return (
          <TableCell
            component="div"
            key={`row-item-${document.id || index}-${column.accessorName || index2}`}
            align={column.align}
            style={{
              width: `100%`,
              height: `100%`,
              padding: `8px`,
              borderBottom: `unset`,
              display: `flex`,
              flexDirection: `row`,
              alignItems: `center`,

              // ...(mapping[index] && (mapping as any)[index].style !== undefined ? (mapping as any)[index].style : {}),
              // width: entry.width ? `${entry.width}px` : `100%`,
              // minWidth: entry.width ? `${entry.width}px` : `unset`,
              minWidth: column.width
                ? typeof column.width === `string`
                  ? column.width
                  : `${column.width}px`
                : `unset`,
              minHeight: column.height ? `${column.height}px` : `unset`,
              ...(justifyContent ? { justifyContent } : {}),
              ...column.columnStyle,
              ...(loading
                ? {
                    paddingTop: 0,
                    paddingBottom: 0,
                  }
                : {}),
            }}
          >
            {loading ? (
              <>
                {column.loadingContent ?? (
                  <Skeleton animation="pulse" height={`${column.height ?? 34}px`} width="100%" />
                )}
              </>
            ) : (
              <Text
                tagName="span"
                style={{ overflow: `hidden`, textOverflow: `ellipsis`, ...(column.valueCellStyle ?? {}) }}
              >
                <>
                  {(() => {
                    if (column.formatter) {
                      return column.formatter({
                        value: document[column.accessorName ?? ``],
                        doc: document,
                        index,
                        rowHover,
                      });
                    }
                    return document[column.accessorName ?? ``];
                  })()}
                </>
              </Text>
            )}
          </TableCell>
        );
      })}
    </StyledTableRow>
  );
};
