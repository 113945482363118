import { CustomFields, Entity, GPSTrack, HexColor, Location, Report } from '@nx-smartmonkey/shared/domain';
import { TimeWindows } from '@nx-smartmonkey/shared/interfaces';
import { CustomersSurvey } from '../customersSurvey/CustomersSurvey';
import { StopStatus } from './StopStatus';
import { StopTypes } from './StopTypes';

export interface DriverInfo {
  id?: string;
  label?: string;
  color?: HexColor;
  position?: GPSTrack;
}

export interface StopProps {
  type: StopTypes;
  status: StopStatus;
  client_external_id?: string;
  label?: string;
  external_id?: string;
  location?: Location;

  // Info
  location_details?: string;
  comments?: string;
  reference_person?: string;
  phone?: string;
  email?: string;
  url?: string;
  custom_fields?: CustomFields;

  // Constraints
  weight?: number;
  volume?: number;
  time_windows?: TimeWindows;
  requires?: string[];
  cluster?: string;
  reward?: number;
  duration?: number;
  max_delivery_time?: number;

  // Operational
  estimated_arrival_time?: Date;
  estimated_departure_time?: Date;
  planned_arrival_time?: number;
  planned_departure_time?: number;

  driver_info?: DriverInfo;

  report?: Report;
  survey?: CustomersSurvey;
  pin?: string;
  additional_info?: string;
  customer_text_field?: string;
  execution_date?: Date;

  // CRUD
  deleted?: boolean;
  created_by?: string;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

export class Stop extends Entity<StopProps> {
  get id(): string {
    return this._id!;
  }
  get type(): StopTypes {
    return this.props.type;
  }
  isPickup(): boolean {
    return this.props.type === `pickup`;
  }
  get status(): StopStatus {
    return this.props.status;
  }
  get client_external_id(): string | undefined {
    return this.props.client_external_id;
  }
  get label(): string | undefined {
    return this.props.label;
  }
  get external_id(): string | undefined {
    return this.props.external_id;
  }
  get location(): Location | undefined {
    return this.props.location;
  }
  get location_details(): string | undefined {
    return this.props.location_details;
  }
  get comments(): string | undefined {
    return this.props.comments;
  }
  get reference_person(): string | undefined {
    return this.props.reference_person;
  }
  get phone(): string | undefined {
    return this.props.phone;
  }
  get email(): string | undefined {
    return this.props.email;
  }
  get url(): string | undefined {
    return this.props.url;
  }
  get custom_fields(): CustomFields | undefined {
    return this.props.custom_fields;
  }
  get pin(): string | undefined {
    return this.props.pin;
  }
  get additional_info(): string | undefined {
    return this.props.additional_info;
  }
  get customer_text_field(): string | undefined {
    return this.props.customer_text_field;
  }
  get execution_date(): Date | undefined {
    return this.props.execution_date;
  }
  get weight(): number | undefined {
    return this.props.weight;
  }
  get volume(): number | undefined {
    return this.props.volume;
  }
  get time_windows(): TimeWindows | undefined {
    return this.props.time_windows;
  }
  get requires(): string[] | undefined {
    return this.props.requires;
  }
  get cluster(): string | undefined {
    return this.props.cluster;
  }
  get reward(): number | undefined {
    return this.props.reward;
  }
  get duration(): number | undefined {
    return this.props.duration;
  }
  get max_delivery_time(): number | undefined {
    return this.props.max_delivery_time;
  }
  get planned_arrival_time(): number | undefined {
    return this.props.planned_arrival_time;
  }
  get planned_departure_time(): number | undefined {
    return this.props.planned_departure_time;
  }
  get estimated_arrival_time(): Date | undefined {
    return this.props.estimated_arrival_time;
  }
  get estimated_departure_time(): Date | undefined {
    return this.props.estimated_departure_time;
  }
  get driver_info(): DriverInfo | undefined {
    return this.props.driver_info;
  }
  set driver_info(driver_info: DriverInfo | undefined) {
    this.props.driver_info = driver_info;
  }
  get report(): Report | undefined {
    return this.props.report;
  }
  get survey(): CustomersSurvey | undefined {
    return this.props.survey;
  }
  get deleted(): boolean | undefined {
    return this.props.deleted;
  }
  get created_by(): string | undefined {
    return this.props.created_by;
  }
  get created_at(): Date | undefined {
    return this.props.created_at;
  }
  get updated_at(): Date | undefined {
    return this.props.updated_at;
  }
  get deleted_at(): Date | undefined {
    return this.props.deleted_at;
  }

  private constructor(props: StopProps, id: string) {
    super(props, id);
  }

  static create(props: StopProps, id: string) {
    return new Stop(props, id);
  }
}
