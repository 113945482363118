import { createContext, useContext } from 'react';
import { NotificationsActions } from './actions';
import { getInitialState } from './reducer';

export const NotificationsContext = createContext({
  notificationsState: getInitialState(),
  notificationsDispatch: (action: NotificationsActions) => {},
});

NotificationsContext.displayName = `NotificationsContext`;

export const useNotificationsContext = () => useContext(NotificationsContext);
