import { Grid } from '@mui/material';
import { HSpacer } from '@nx-smartmonkey/ui';

import { Language } from '@nx-smartmonkey/shared/domain';
import { FilterTextIcon } from '../../assets/img/filters';
import { SearchFiltersButton } from './SearchFiltersButton';
import { SearchFiltersList } from './SearchFiltersList';
import { SearchFilterString } from './string';
import { SearchFilterStringOperator } from './string/SearchFilterString';

interface SearchFiltersExampleProps {
  language?: Language;
  colors: {
    primaryColor: string;
    secondaryColor: string;
  };
}

export const SearchFiltersExample = ({ language, colors }: SearchFiltersExampleProps) => {
  return (
    <Grid container direction="row" wrap="nowrap">
      <Grid item>
        <SearchFiltersButton isExampleURL onAddFilter={(filter) => {}} />
      </Grid>

      <HSpacer small />

      <SearchFiltersList
        filters={[
          new SearchFilterString({
            field: `label`,
            type: `string`,
            value: `Bar`,
            operator: `not_contains` as SearchFilterStringOperator,
            iconSrc: FilterTextIcon,
          }),
        ]}
        language={language}
        colors={colors}
        isExampleURL
        onChangeFilter={(filter, idx) => {}}
        onRemoveFilter={(idx: number) => {}}
      />
    </Grid>
  );
};
