declare const process: any;

class Configuration {
  SUPERVISORS_BACK_END: string;

  SUPERVISORS_BACK_END_WS: string;

  TRANSLATIONS_BACK_END: string;

  MIXPANEL_API_KEY: string;

  SENTRY_DSN: string;

  CUSTOMERS_SLUG_ENDPOINT: string;

  GOOGLE_MAPS_API_KEY: string;

  ENVIRONMENT: string;

  constructor() {
    this.SUPERVISORS_BACK_END = process.env.NX_SUPERVISORS_BACK_END;
    this.SUPERVISORS_BACK_END_WS = process.env.NX_SUPERVISORS_BACK_END_WS;
    this.TRANSLATIONS_BACK_END = process.env.NX_TRANSLATIONS_BACK_END;
    this.MIXPANEL_API_KEY = process.env.NX_MIXPANEL_API_KEY;
    this.SENTRY_DSN = process.env.NX_SENTRY_DSN;
    this.CUSTOMERS_SLUG_ENDPOINT = process.env.NX_CUSTOMERS_SLUG_ENDPOINT;
    this.GOOGLE_MAPS_API_KEY = process.env.NX_GOOGLE_MAPS_API_KEY;
    this.ENVIRONMENT = process.env.NX_ENVIRONMENT;
  }
}
const configuration = new Configuration();
export default configuration;
