import { ProjectCustomFields } from '../../domain/project/ProjectCustomFields';
import { ProjectBaseAllCustomFields } from '../../domain/project/ProjectDTO';
import { ProjectCustomFieldDefinitionMapper } from './ProjectCustomFieldDefinitionMapper';

export class ProjectCustomFieldsMapper {
  static toDomain(props: ProjectBaseAllCustomFields): ProjectCustomFields {
    const {
      task,
      client,
      vehicle,
      service_report_completed,
      service_report_task_completed,
      service_report_canceled,
      service_report_task_canceled,
    } = props;

    return ProjectCustomFields.create({
      task: task
        ? task.map((customFieldDefinition) => ProjectCustomFieldDefinitionMapper.toDomain(customFieldDefinition))
        : [],
      client: client
        ? client.map((customFieldDefinition) => ProjectCustomFieldDefinitionMapper.toDomain(customFieldDefinition))
        : [],
      vehicle: vehicle
        ? vehicle.map((customFieldDefinition) => ProjectCustomFieldDefinitionMapper.toDomain(customFieldDefinition))
        : [],
      service_report_completed: service_report_completed
        ? service_report_completed.map((customFieldDefinition) =>
            ProjectCustomFieldDefinitionMapper.toDomain(customFieldDefinition)
          )
        : [],
      service_report_task_completed: service_report_task_completed
        ? service_report_task_completed.map((customFieldDefinition) =>
            ProjectCustomFieldDefinitionMapper.toDomain(customFieldDefinition)
          )
        : [],
      service_report_canceled: service_report_canceled
        ? service_report_canceled.map((customFieldDefinition) =>
            ProjectCustomFieldDefinitionMapper.toDomain(customFieldDefinition)
          )
        : [],
      service_report_task_canceled: service_report_task_canceled
        ? service_report_task_canceled.map((customFieldDefinition) =>
            ProjectCustomFieldDefinitionMapper.toDomain(customFieldDefinition)
          )
        : [],
    });
  }

  static toDTO(props: ProjectCustomFields): ProjectBaseAllCustomFields {
    const {
      task,
      client,
      vehicle,
      service_report_completed,
      service_report_task_completed,
      service_report_canceled,
      service_report_task_canceled,
    } = props;

    return {
      task: task
        ? task.map((customFieldDefinition) => ProjectCustomFieldDefinitionMapper.toDTO(customFieldDefinition))
        : [],
      client: client
        ? client.map((customFieldDefinition) => ProjectCustomFieldDefinitionMapper.toDTO(customFieldDefinition))
        : [],
      vehicle: vehicle
        ? vehicle.map((customFieldDefinition) => ProjectCustomFieldDefinitionMapper.toDTO(customFieldDefinition))
        : [],
      service_report_completed: service_report_completed
        ? service_report_completed.map((customFieldDefinition) =>
            ProjectCustomFieldDefinitionMapper.toDTO(customFieldDefinition)
          )
        : [],
      service_report_task_completed: service_report_task_completed
        ? service_report_task_completed.map((customFieldDefinition) =>
            ProjectCustomFieldDefinitionMapper.toDTO(customFieldDefinition)
          )
        : [],
      service_report_canceled: service_report_canceled
        ? service_report_canceled.map((customFieldDefinition) =>
            ProjectCustomFieldDefinitionMapper.toDTO(customFieldDefinition)
          )
        : [],
      service_report_task_canceled: service_report_task_canceled
        ? service_report_task_canceled.map((customFieldDefinition) =>
            ProjectCustomFieldDefinitionMapper.toDTO(customFieldDefinition)
          )
        : [],
    };
  }
}
