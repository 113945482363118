import { SvgIcon } from '@mui/material';

export default function WarningIcon(props: any) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 26.02 26.19"
      style={{ color: `#fff`, width: `22px`, height: `22px`, ...props.style }}
    >
      <g>
        <g>
          <path d="M13.01,26.09C5.84,26.09,.01,20.26,.01,13.09S5.84,.09,13.01,.09s13,5.83,13,13-5.83,13-13,13Zm0-24C6.95,2.09,2.01,7.03,2.01,13.09s4.93,11,11,11,11-4.93,11-11S19.08,2.09,13.01,2.09Z" />
        </g>
        <g>
          <g>
            <path d="M13.01,16.47c-.55,0-1-.45-1-1V5.56c0-.55,.45-1,1-1s1,.45,1,1V15.47c0,.55-.45,1-1,1Z" />
            <circle cx="13.01" cy="19.53" r="1.1" />
          </g>
          <path d="M13.01,26.09C5.85,26.09,.02,20.26,.02,13.09S5.85,.1,13.01,.1s12.99,5.83,12.99,12.99-5.83,12.99-12.99,12.99Zm0-23.98C6.95,2.1,2.02,7.03,2.02,13.09s4.93,10.99,10.99,10.99,10.99-4.93,10.99-10.99S19.07,2.1,13.01,2.1Z" />
        </g>
      </g>
    </SvgIcon>
  );
}
