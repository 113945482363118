import { CircularProgress, Grid } from '@mui/material';
import { IArea, getCroppedImg } from '@nx-smartmonkey/shared/helpers';
import React, { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';

import HSpacer from '../components/HSpacer';
import { RoutalPalette } from './Colors';
import { RoutalButton } from './RoutalButton';
import RoutalDialog from './RoutalDialog';
import { RoutalMobileButton } from './RoutalMobileButton';

type RoutalImageCropDialogProps = {
  image?: string;
  imageType?: string;
  cropShape?: `rect` | `round`;
  open: boolean;
  title?: React.ReactElement | string;
  confirmText?: React.ReactElement | string;
  cancelText?: React.ReactElement | string;
  aspect?: number;
  isMobile?: boolean;
  onCrop: (image: Blob | null) => any;
  onCloseDialog: (...args: any[]) => any;
};

export const RoutalImageCropDialog = ({
  image,
  imageType,
  cropShape = `rect`,
  open,
  title,
  confirmText,
  cancelText,
  aspect,
  isMobile = false,
  onCrop,
  onCloseDialog,
}: RoutalImageCropDialogProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<IArea | undefined>(undefined);

  const onCropComplete = useCallback((_croppedArea: IArea, _croppedAreaPixels: IArea) => {
    setCroppedAreaPixels(_croppedAreaPixels);
  }, []);

  const endCropImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image as string, croppedAreaPixels as IArea, imageType);
      setLoading(false);
      onCrop(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, image, imageType, onCrop]);

  const getFooterContent = () => {
    if (isMobile) {
      return (
        <Grid container justifyContent="flex-end">
          <RoutalMobileButton variant="flat" onClick={onCloseDialog}>
            {cancelText}
          </RoutalMobileButton>

          <HSpacer small />

          <RoutalMobileButton
            onClick={() => {
              setLoading(true);
              endCropImage();
            }}
          >
            <>
              {confirmText}
              {loading ? <CircularProgress style={{ color: RoutalPalette.primary40 }} size={16} /> : null}
            </>
          </RoutalMobileButton>
        </Grid>
      );
    }

    return (
      <Grid container justifyContent="flex-end">
        <RoutalButton size="small" variant="flat" onClick={onCloseDialog}>
          {cancelText}
        </RoutalButton>

        <HSpacer small />

        <RoutalButton
          size="small"
          onClick={() => {
            setLoading(true);
            endCropImage();
          }}
        >
          <>
            {confirmText}
            {loading ? <CircularProgress style={{ color: RoutalPalette.primary40 }} size={16} /> : null}
          </>
        </RoutalButton>
      </Grid>
    );
  };

  return (
    <RoutalDialog
      open={open}
      onCloseDialog={onCloseDialog}
      dialogProps={{
        disableEscapeKeyDown: true,
      }}
      isMobile={isMobile}
      titleContent={title}
      showCrossCloseButton={false}
      showHeader={title !== undefined}
      footerContent={getFooterContent()}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{
          marginTop: `16px`,
          height: `300px`,
          width: `auto`,
          position: `relative`,
          borderRadius: `5px`,
          overflow: `hidden`,
          border: `1px solid ${RoutalPalette.primary20}`,
        }}
      >
        {image ? (
          <Cropper
            aspect={aspect ?? 1}
            image={image}
            cropShape={cropShape}
            crop={crop}
            zoom={zoom}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        ) : null}
      </Grid>
    </RoutalDialog>
  );
};
