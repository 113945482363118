import { LanguageType } from '@nx-smartmonkey/shared/domain';
import { translationsRepository } from '../repositories/translations';
import { TranslationsRepository } from '../repositories/translations/TranslationsRepository';

export class TranslationsService {
  constructor(private translationsRepository: TranslationsRepository) {}

  async getTranslation({
    language,
  }: {
    language: LanguageType;
  }): Promise<{ language: LanguageType; translations: Record<string, string> }> {
    return this.translationsRepository.getTranslation({ language });
  }
}

export const translationsService = new TranslationsService(translationsRepository);
