import { Entity, Location } from '@nx-smartmonkey/shared/domain';
import { ProjectStyle } from '../projectStyle/ProjectStyle';
import { ProjectCustomFields } from './ProjectCustomFields';
import { ProjectUnits } from './ProjectUnits';

export interface ProjectProps {
  organization_id: string;
  public_key: string;
  project_slug: string;
  units: ProjectUnits;
  custom_fields: ProjectCustomFields;
  style?: ProjectStyle;
  location?: Location;
}

export class Project extends Entity<ProjectProps> {
  get id(): string | undefined {
    return this._id;
  }

  get organization_id(): string {
    return this.props.organization_id;
  }

  get public_key(): string {
    return this.props.public_key;
  }

  get project_slug(): string {
    return this.props.project_slug;
  }

  get units(): ProjectUnits {
    return this.props.units;
  }

  get custom_fields(): ProjectCustomFields {
    return this.props.custom_fields;
  }

  get style(): ProjectStyle | undefined {
    return this.props.style;
  }

  get location(): Location | undefined {
    return this.props.location;
  }

  private constructor(props: ProjectProps, id?: string) {
    super(props, id);
  }

  static create(props: ProjectProps, id?: string) {
    return new Project(props, id);
  }
}
