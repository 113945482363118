import { useState } from 'react';
import { stopService } from '../../application/StopService';
import { Stop } from '../../domain/stop/Stop';
import { useRetrieveProject } from '../project/useRetrieveProject';
import { useInterval } from '../useInterval';

type UseExportStopsResponse = {
  run: ({ stopIds }: { stopIds: Array<string> }) => Promise<Array<Stop | null>>;
  isLoading: boolean;
  duration?: number;
};

// Hooks act as 'Adapter' layer.
export const useExportStops = (): UseExportStopsResponse => {
  // const { sessionExpiredLogout } = useErrorMiddleware();
  const { data: project } = useRetrieveProject();
  const [duration, setDuration] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useInterval({
    callback: () => {
      setDuration((duration) => duration! + 1000);
    },
    enabled: isLoading,
    delay: 1000,
  });

  const exportStops = async ({ stopIds }: { stopIds: Array<string> }): Promise<Array<Stop | null>> => {
    setIsLoading(true);
    const allStops = await stopService.fetchSomeStops({
      stopIds,
      project: project!,
    });

    // TODO: COMPROVAR QUÈ PASSA SI MENTRE ES FA EL EXPORT, ES TANCA LA SESSIÓ
    // if (err?.response?.status === 401) {
    //   sessionExpiredLogout();
    // }

    setIsLoading(false);
    return allStops;
  };

  return {
    run: exportStops,
    isLoading,
    duration,
  };
};
