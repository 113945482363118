import { ValueObject } from './ValueObject';

export interface HexColorProps {
  value: string;
}

export class HexColor extends ValueObject<HexColorProps> {
  get value(): string {
    return this.props.value;
  }

  private constructor(props: HexColorProps) {
    super(props);
  }

  static isValidHexColor(color: string) {
    return /^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(color);
  }

  static create(value: string): HexColor {
    if (!this.isValidHexColor(value)) {
      throw new Error(`Color ${value} is not valid. The definition must be an hexadecimal code`);
    } else {
      return new HexColor({ value });
    }
  }
}
