import { SearchFilter } from '../filter';
import { SearchFilterProps } from '../filter/SearchFilter';

export type SearchFilterListOperator = `eq` | `ne`;
export type SearchFilterListValue = string;
export interface SearchFilterListOptionProps {
  value: string;
  intlId: string;
}

interface SearchFilterListProps extends SearchFilterProps {
  field: string;
  operator: SearchFilterListOperator;
  value: SearchFilterListValue;
  listOptions: SearchFilterListOptionProps[];

  // - "is" => eq
  // - "is not" => ne
  // - "starts with" => starts_with
  // - "ends with" => ends_with
  // - "contains" => contains
  // - "contains exact word" => contains_exact_word
  // - "does not contain" => not_contains
  // - "is unknown" => unknown (value es null)
  // - "has any value" => known (value es null)
}

export class SearchFilterList extends SearchFilter {
  private listOptions: SearchFilterListOptionProps[];

  constructor({ listOptions, ...props }: SearchFilterListProps) {
    super({ ...props, type: `list` });
    this.listOptions = listOptions;
  }

  public getListOptions(): SearchFilterListOptionProps[] {
    return this.listOptions;
  }

  public getOptionByValue(): SearchFilterListOptionProps | undefined {
    return this.listOptions.find((option) => option.value === this.getValue());
  }
}
