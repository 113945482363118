import { ValueObject } from '@nx-smartmonkey/shared/domain';
import hash from 'object-hash';
import tinycolor from 'tinycolor2';

type LatLng = { lat: number; lng: number };

export type GeoFencePaths = LatLng[][];

export interface GeoFenceDomainProps {
  id: string;
  paths: GeoFencePaths;
  color?: string;
  selected?: boolean;
  hover?: boolean;
  editable?: boolean;
  eventsUpdateHash?: string;
  onClick?: (event: any) => void;
  onDoubleClick?: (event: any) => void;
  onContextualMenu?: (event: any, geoFenceId: string) => void;
  onMouseEnter?: (event: any) => void;
  onMouseLeave?: (event: any) => void;
  onPathChanged?: (event: any, geoFenceId: string, newPaths: GeoFencePaths) => void;
}

export class GeoFenceDomain extends ValueObject<GeoFenceDomainProps> {
  private hash: string;
  private _googlePolygon?: google.maps.Polygon;

  get id(): string {
    return this.props.id;
  }
  get paths(): GeoFencePaths {
    return this.props.paths;
  }
  get color(): string | undefined {
    return this.props.color;
  }
  get selected(): boolean | undefined {
    return this.props.selected;
  }
  get hover(): boolean | undefined {
    return this.props.hover;
  }
  get editable(): boolean | undefined {
    return this.props.editable;
  }
  get onClick(): ((event: any) => void) | undefined {
    return this.props.onClick;
  }
  get onDoubleClick(): ((event: any) => void) | undefined {
    return this.props.onDoubleClick;
  }
  get onContextualMenu(): ((event: any, geoFenceId: string) => void) | undefined {
    return this.props.onContextualMenu;
  }
  get onMouseEnter(): ((event: any) => void) | undefined {
    return this.props.onMouseEnter;
  }
  get onMouseLeave(): ((event: any) => void) | undefined {
    return this.props.onMouseLeave;
  }
  get onPathChanged(): ((event: any, geoFenceId: string, newPaths: GeoFencePaths) => void) | undefined {
    return this.props.onPathChanged;
  }
  set googlePolygon(googlePolygon: google.maps.Polygon | undefined) {
    this._googlePolygon = googlePolygon;
  }
  get googlePolygon(): google.maps.Polygon | undefined {
    return this._googlePolygon;
  }

  private createPolygon(): google.maps.Polygon {
    const {
      paths,
      color,
      selected,
      hover,
      editable,
      onClick,
      onDoubleClick,
      onContextualMenu,
      onMouseEnter,
      onMouseLeave,
      onPathChanged,
    } = this.props;

    const tColor = tinycolor(color);
    const strokeColor = color;
    const fillColor = tColor.lighten(hover ? 20 : 40).toString();

    const geoFencePolygon = new google.maps.Polygon({
      paths,
      fillColor,
      strokeColor,
      strokeWeight: selected || hover ? 4 : 2,
      editable,
    });

    geoFencePolygon.addListener(`click`, (event: any) => {
      if (onClick) {
        onClick!(event.domEvent);
      }
    });

    // Double click
    if (onDoubleClick) {
      geoFencePolygon.addListener(`dblclick`, (event: any) => {
        onDoubleClick(event.domEvent);
      });
    }

    // Right click
    if (onContextualMenu) {
      geoFencePolygon.addListener(`rightclick`, (event: any) => {
        onContextualMenu(event.domEvent, this.id);
      });
    }

    if (onMouseEnter) {
      geoFencePolygon.addListener(`mouseover`, (event: any) => {
        onMouseEnter(event.domEvent);
      });
    }

    if (onMouseLeave) {
      geoFencePolygon.addListener(`mouseout`, (event: any) => {
        onMouseLeave(event.domEvent);
      });
    }

    if (editable && onPathChanged) {
      const polygonPaths = geoFencePolygon.getPaths();

      // Remove vertex functionality
      geoFencePolygon.addListener(`rightclick`, (event: any) => {
        if (event.vertex !== null && event.path !== null) {
          const vertexIndex = event.vertex;
          const pathIndex = event.path;
          polygonPaths.forEach((path, index) => {
            if (pathIndex === index && path.getLength() > 3) {
              path.removeAt(vertexIndex);
              event.domEvent.stopPropagation();
            }
          });
        }
      });

      polygonPaths.forEach((polygonPath) => {
        google.maps.event.addListener(polygonPath, `set_at`, (event: any) => {
          const newPaths: GeoFencePaths = [];
          geoFencePolygon.getPaths().forEach((path) => {
            const pathData: LatLng[] = [];
            path.forEach((point: google.maps.LatLng) => pathData.push({ lat: point.lat(), lng: point.lng() }));
            newPaths.push(pathData);
          });
          onPathChanged(event, this.id, newPaths);
        });

        google.maps.event.addListener(polygonPath, `insert_at`, (event: any) => {
          const newPaths: GeoFencePaths = [];
          geoFencePolygon.getPaths().forEach((path) => {
            const pathData: LatLng[] = [];
            path.forEach((point: google.maps.LatLng) => pathData.push({ lat: point.lat(), lng: point.lng() }));
            newPaths.push(pathData);
          });
          onPathChanged(event, this.id, newPaths);
        });

        google.maps.event.addListener(polygonPath, `remove_at`, (event: any) => {
          const newPaths: GeoFencePaths = [];
          geoFencePolygon.getPaths().forEach((path) => {
            const pathData: LatLng[] = [];
            path.forEach((point: google.maps.LatLng) => pathData.push({ lat: point.lat(), lng: point.lng() }));
            newPaths.push(pathData);
          });
          onPathChanged(event, this.id, newPaths);
        });
      });
    }

    return geoFencePolygon;
  }

  private constructor(props: GeoFenceDomainProps, hash: string) {
    super(props);
    this.hash = hash;
    this.updateGeoFence();
  }

  removeMap() {
    if (this.googlePolygon) {
      this.googlePolygon.setMap(null);
    }
  }

  setMap(map: google.maps.Map) {
    if (this.googlePolygon) {
      this.googlePolygon.setMap(map);
    }
  }

  updateGeoFence() {
    this.googlePolygon = this.createPolygon();
  }

  private static createHash(props: GeoFenceDomainProps) {
    const geoFenceHash = hash({
      id: props.id,
      paths: props.paths,
      ...(props.color ? { color: props.color } : {}),
      ...(props.selected ? { selected: props.selected } : {}),
      ...(props.hover ? { hover: props.hover } : {}),
      ...(props.editable ? { editable: props.editable } : {}),
      ...(props.eventsUpdateHash ? { onClickHash: props.eventsUpdateHash } : {}),
    });
    return geoFenceHash;
  }

  static create(props: GeoFenceDomainProps): GeoFenceDomain {
    return new GeoFenceDomain(props, this.createHash(props));
  }

  isEqual(other: GeoFenceDomain) {
    return this.hash === other.hash;
  }
}
