import { Grid } from '@mui/material';
import { HSpacer, RoutalPalette, RoutalTooltip, VSpacer } from '@nx-smartmonkey/ui';
import { useEffect, useState } from 'react';

import LocationMarker from '../../../assets/img/location_marker.svg';
import Constraints from '../../../assets/svg/right-panel-tab-icons/ConstraintsIcon';
import Information from '../../../assets/svg/right-panel-tab-icons/InformationIcon';
import StopReportIcon from '../../../assets/svg/right-panel-tab-icons/ReportIcon';
import SurveyIcon from '../../../assets/svg/right-panel-tab-icons/SurveyIcon';
import { Stop } from '../../../domain/stop/Stop';
import { intl } from '../../../helpers/IntGlobalProvider';
import { useRetrieveProject } from '../../../hooks/project/useRetrieveProject';
import { useRetrieveRightPanelInfo } from '../../../hooks/right-panel/useRetrieveRightPanelInfo';
import { useRetrieveSupervisor } from '../../../hooks/supervisor/useRetrieveSupervisor';
import { Text } from '../../../react-components/Text';
import { InfoContainer } from './RightPanel.styled';
import { StopBodyTabs } from './StopBodyTabs';
interface StopBodyProps {
  stop: Stop;
}

export const StopBody = ({ stop }: StopBodyProps) => {
  const { data: project } = useRetrieveProject();
  const { data: supervisor } = useRetrieveSupervisor();
  const { detailTab, setRightPanelInfo } = useRetrieveRightPanelInfo();

  const [tab, setTab] = useState<number | undefined>(undefined);

  useEffect(() => {
    switch (detailTab) {
      case `constraints`:
        setTab(1);
        break;
      case `report`:
        setTab(2);
        break;
      case `survey`:
        setTab(3);
        break;
      case `info`:
      default:
        setTab(0);
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailTab]);

  if (!project || !supervisor) {
    return null;
  }

  return (
    <Grid container direction="column" wrap="nowrap" style={{ height: `100%` }}>
      <InfoContainer>
        {[`label`, `pin`, `external_id`, `location`, `location_details`].map((field) => {
          if (!supervisor.isStopFieldEnabled(field)) {
            return null;
          }

          switch (field) {
            case `location`:
              if (!stop.location) {
                return null;
              }

              return (
                <>
                  <VSpacer small />

                  <Grid container direction="row" wrap="nowrap" alignItems="flex-start">
                    <img width={14} src={LocationMarker} alt="" draggable="false" />

                    <HSpacer small />

                    <Text>{stop.location?.label ?? `-`}</Text>
                  </Grid>

                  <VSpacer small />
                </>
              );
            case `label`:
              return (
                <>
                  <VSpacer small />

                  <Text
                    style={{
                      fontSize: `20px`,
                      fontWeight: 600,
                    }}
                  >
                    {(stop.props as Record<string, any>)[field] ?? `-`}
                  </Text>

                  <VSpacer small />
                </>
              );
            default:
              return (
                <>
                  <Text variant="label" intlId={`stop.${field}`} />

                  <VSpacer small />

                  <Text>{(stop.props as Record<string, any>)[field] ?? `-`}</Text>

                  <VSpacer small />
                </>
              );
          }
        })}
      </InfoContainer>

      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{
          minHeight: `60px`,
          borderTop: `1px solid ${RoutalPalette.neutral10}`,
          borderBottom: `1px solid ${RoutalPalette.neutral10}`,
        }}
      >
        <RoutalTooltip key="stop-information" title={intl.formatMessage({ id: `stop.information` })}>
          <Information
            isSelected={tab === 0}
            style={{
              cursor: tab === 0 ? `default` : `pointer`,
            }}
            onClick={() => {
              setRightPanelInfo({ detailTab: `info` });
            }}
          />
        </RoutalTooltip>

        <HSpacer large />

        <RoutalTooltip key="stop-constraints" title={intl.formatMessage({ id: `stop.constraints` })}>
          <Constraints
            isSelected={tab === 1}
            style={{
              cursor: tab === 1 ? `default` : `pointer`,
            }}
            onClick={() => {
              setRightPanelInfo({ detailTab: `constraints` });
            }}
          />
        </RoutalTooltip>

        <HSpacer large />

        <RoutalTooltip key="stop-report" title={intl.formatMessage({ id: `stop.report` })}>
          <StopReportIcon
            isSelected={tab === 2}
            style={{
              cursor: tab === 2 ? `default` : `pointer`,
            }}
            onClick={() => {
              setRightPanelInfo({ detailTab: `report` });
            }}
          />
        </RoutalTooltip>

        <HSpacer large />

        <RoutalTooltip key="stop-survey" title={intl.formatMessage({ id: `stop.survey` })}>
          <SurveyIcon
            isSelected={tab === 3}
            style={{
              cursor: tab === 3 ? `default` : `pointer`,
            }}
            onClick={() => {
              setRightPanelInfo({ detailTab: `survey` });
            }}
          />
        </RoutalTooltip>
      </Grid>

      <StopBodyTabs stop={stop} project={project} supervisor={supervisor} tab={tab} />
    </Grid>
  );
};
