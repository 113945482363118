export const containsAll = (arr1: Array<string>, arr2: Array<string>) =>
  arr2.every((arr2Item) => arr1.includes(arr2Item));

export const sameMembers = (arr1: Array<string>, arr2: Array<string>) =>
  containsAll(arr1, arr2) && containsAll(arr2, arr1);

export const splitArrayByParts = ({ inputArray, itemsPerChunk }: { inputArray: Array<any>; itemsPerChunk: number }) => {
  const result = inputArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / itemsPerChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return result;
};

export const removeStringDuplicates = (stringsArray: Array<string>) => {
  return stringsArray.filter((element, index) => {
    return stringsArray.indexOf(element) === index;
  });
};
