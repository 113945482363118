import { Stop } from '../../domain/stop/Stop';
import { NotificationsActionType, NotificationsActions } from './actions';

type NotificationsType =
  | `smartmonkey.planner.2.planner.stop.deleted`
  | `smartmonkey.planner.2.planner.stop.created`
  | `smartmonkey.planner.2.planner.stop.rescheduled`
  | `smartmonkey.planner.2.planner.stop.moved`
  | `smartmonkey.planner.2.planner.stop.reported`
  | `smartmonkey.planner.2.planner.stop.updated`
  | `smartmonkey.planner.2.planner.driver.updated`
  | `smartmonkey.planner.2.drivers.route.finished`
  | `smartmonkey.planner.2.drivers.route.updated`
  | `smartmonkey.planner.2.drivers.route.tracked`;

export interface NotificationProps {
  id: string;
  event_id: NotificationsType;
  data: Stop;
  date: Date;
}

export interface NotificationsState {
  notifications: NotificationProps[];
  newNotifications: NotificationProps[];
}

export const getInitialState = (): NotificationsState => ({
  notifications: [],
  newNotifications: [],
});

export const NotificationsReducer = (state: NotificationsState, action: NotificationsActions): NotificationsState => {
  switch (action.type) {
    case NotificationsActionType.SET_NOTIFICATIONS: {
      return {
        ...state,
        newNotifications: [...state.newNotifications, ...action.payload.notifications],
        notifications: [...state.notifications, ...action.payload.notifications],
      };
    }
    case NotificationsActionType.REMOVE_NOTIFICATIONS: {
      return {
        ...state,
        notifications: [],
        newNotifications: [],
      };
    }
    case NotificationsActionType.CLEAR_NEW_NOTIFICATIONS: {
      return {
        ...state,
        newNotifications: [],
      };
    }
    default:
      return state;
  }
};
