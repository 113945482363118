import { useNavigate } from 'react-router-dom';
import { useRouterQuery } from '../useRouterQuery';

export type DetailTabTypes = `info` | `constraints` | `tasks` | `report` | `survey`;

type SetRightPanelInfoProps = {
  detailId?: string | null;
  detailTab?: DetailTabTypes | null;
};

type UseRetrieveRightPanelInfoResponse = {
  detailId?: string;
  detailTab?: DetailTabTypes;
  setRightPanelInfo: (rightPanelProps: SetRightPanelInfoProps) => void;
  cleanRightPanelInfo: () => void;
};

export const useRetrieveRightPanelInfo = (): UseRetrieveRightPanelInfoResponse => {
  const navigate = useNavigate();
  const urlParams = useRouterQuery();

  const detailIdParam = urlParams.get(`detailId`);
  const detailId = detailIdParam !== null ? detailIdParam : undefined;

  const detailTabParam = urlParams.get(`detailTab`);
  const detailTab = detailTabParam !== null ? (detailTabParam as DetailTabTypes) : undefined;

  const setRightPanelInfo = ({ detailId, detailTab }: SetRightPanelInfoProps) => {
    if (detailId !== undefined) {
      if (detailId !== null) {
        urlParams.set(`detailId`, `${detailId}`);
      } else {
        urlParams.delete(`detailId`);
      }
    }

    const storedDetailTab = localStorage.getItem(`DETAIL_TAB`);
    if (detailTab !== undefined) {
      if (detailTab !== null) {
        urlParams.set(`detailTab`, `${detailTab}`);
        localStorage.setItem(`DETAIL_TAB`, `${detailTab}`);
      } else if (storedDetailTab) {
        urlParams.set(`detailTab`, `${storedDetailTab}`);
      } else {
        urlParams.delete(`detailTab`);
      }
    }
    navigate(`${window.location.pathname}?${urlParams.toString()}`);
  };

  const cleanRightPanelInfo = () => {
    setRightPanelInfo({
      detailId: null,
      detailTab: null,
    });
  };

  return {
    detailId,
    detailTab,
    setRightPanelInfo: setRightPanelInfo,
    cleanRightPanelInfo: cleanRightPanelInfo,
  };
};
