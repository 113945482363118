import { SvgIcon } from '@mui/material';
import { RoutalPalette } from '../../../new_components';

interface WaringIconProps {
  color?: string;
  style?: React.CSSProperties;
}

export default function WarningIcon({ color = RoutalPalette.secondary40, style = {} }: WaringIconProps) {
  return (
    <SvgIcon
      // viewBox="0 0 14.17 14.17"
      viewBox="0 0 2 9"
      style={{ width: `16px`, height: `16px`, color, ...style }}
    >
      <g>
        <path
          d="M1.09131 0.795898V5.40141"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.09131 7.5459H1.10045"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>

      {/* <svg width="2" height="9" viewBox="0 0 2 9" fill="none" xmlns="http://www.w3.org/2000/svg"></svg> */}
    </SvgIcon>
  );
}
