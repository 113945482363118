import { decode } from '@mapbox/polyline';
import { TimeAwareEncoder } from 'time-aware-polyline';

import { getColorByIndex } from '../Colors';

const timeAwarePolyline = new TimeAwareEncoder();

const parseRoutePath = (path?: PathProps, timeAwarePath?: TimeAwarePathProps): { lat: number; lng: number }[] => {
  if (path) {
    return (
      typeof path === `string`
        ? decode(path).map((pathPoint: number[]) => ({ lat: pathPoint[0], lng: pathPoint[1] }))
        : path
    ) as { lat: number; lng: number }[];
  }

  if (timeAwarePath) {
    return (
      typeof timeAwarePath === `string`
        ? timeAwarePolyline
            .decodeTimeAwarePolyline(timeAwarePath)
            .map((timeAwarePathPoint: number[]) => ({ lat: timeAwarePathPoint[0], lng: timeAwarePathPoint[1] }))
        : timeAwarePath
    ) as { lat: number; lng: number }[];
  }

  return [];
};

type PathProps =
  | string
  | {
      lat: number;
      lng: number;
    }[];

type TimeAwarePathProps =
  | string
  | {
      lat: number;
      lng: number;
    }[];

export interface RouteProps {
  showArrows?: boolean;
  path?: PathProps;
  timeAwarePath?: TimeAwarePathProps;
  color?: string | number;
  selected?: boolean;
  showIfSelected?: boolean;
  onClick?: (...args: any[]) => any;
  onDoubleClick?: (...args: any[]) => any;
}

export const createRoutePolyline = ({
  path,
  showArrows = true,
  timeAwarePath,
  color,
  selected,
  showIfSelected,
  onClick,
  onDoubleClick,
}: RouteProps): { routePolyline: google.maps.Polyline; transparentRoutePolyline: google.maps.Polyline } => {
  const polyline = parseRoutePath(path, timeAwarePath);

  const transparentRoutePolyline = new google.maps.Polyline({
    path: polyline,
    strokeColor: `#000000`,
    strokeOpacity: 0.0,
    strokeWeight: 24,
    zIndex: selected ? 1 : null,
    visible: !showIfSelected || selected,
  });

  if (onClick) {
    transparentRoutePolyline.addListener(`click`, (evt: any) => {
      onClick!(evt);
    });
  }

  if (onDoubleClick) {
    transparentRoutePolyline.addListener(`dblclick`, (evt: any) => {
      onDoubleClick!(evt);
    });
  }

  const routeColor = typeof color === `string` ? color : getColorByIndex(color ?? 0);

  const routePolyline = new google.maps.Polyline({
    path: polyline,
    strokeColor: routeColor,
    strokeWeight: selected ? 10 : 4,
    zIndex: selected ? 1 : null,
    icons: showArrows
      ? [
          {
            icon: {
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              scale: selected ? 2 : 3,
              fillOpacity: 1,
              fillColor: selected ? `#FFFFFF` : routeColor,
              strokeColor: routeColor,
              strokeOpacity: selected ? 0 : 1,
            },
            repeat: `75px`,
          },
        ]
      : [],
    visible: !showIfSelected || selected,
  });

  if (onClick) {
    routePolyline.addListener(`click`, (evt: any) => {
      onClick!(evt);
    });
  }

  if (onDoubleClick) {
    routePolyline.addListener(`dblclick`, (evt: any) => {
      onDoubleClick!(evt);
    });
  }

  return { routePolyline, transparentRoutePolyline };
};
