import { SvgIcon } from '@mui/material';
import { RoutalPalette } from '../../../Colors';

export const WarningIcon = (props: any) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 23.28 20.69"
      style={{ color: RoutalPalette.white, width: `22px`, height: `22px`, ...props.style }}
    >
      <g>
        <g>
          <g>
            <path d="M3.22,20.34c-.52,0-1.03-.14-1.47-.4-.69-.4-1.19-1.04-1.39-1.81s-.1-1.58.3-2.27L9.08,1.78s.05-.08.09-.12c.24-.36.56-.66.92-.88,1.41-.86,3.25-.41,4.11,1l8.43,14.06c.26.46.4.96.41,1.47,0,.8-.29,1.55-.85,2.12-.56.57-1.31.89-2.1.9H3.22ZM10.73,2.91L2.38,16.87c-.12.22-.16.48-.09.74s.23.47.46.6c.15.08.31.13.48.13h16.83c.25,0,.5-.11.68-.3.18-.19.28-.44.28-.7,0-.17-.05-.34-.13-.49L12.48,2.81c-.28-.46-.89-.61-1.36-.33-.13.08-.25.2-.33.33-.02.03-.04.06-.06.09Z" />
          </g>
          <g>
            <path d="M11.64,12.38c-.55,0-1-.45-1-1v-3.98c0-.55.45-1,1-1s1,.45,1,1v3.98c0,.55-.45,1-1,1Z" />
          </g>
          <g>
            <path d="M11.65,16.36c-.55,0-1-.45-1-1s.44-1,1-1h0c.55,0,1,.45,1,1s-.45,1-1,1Z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
