import { RoutalButton, RoutalButtonProps } from '@nx-smartmonkey/ui';
import React from 'react';
import { useIntl } from 'react-intl';

export interface ButtonProps extends RoutalButtonProps {
  intlId?: string;
  intlValues?: Record<string, string | number | ((chunks: any) => React.ReactElement)>;
}

export const Button = (
  props: React.PropsWithChildren<ButtonProps> &
    React.HTMLAttributes<ButtonProps> &
    React.ButtonHTMLAttributes<ButtonProps>
) => {
  const { children, intlId, intlValues } = props;

  const intl = useIntl();
  let processedChildren;

  // Select the content of the class
  if (intlId) {
    const message = intl.formatMessage({ id: intlId }, intlValues ?? {});
    processedChildren = message;
  } else {
    processedChildren = children;
  }

  return <RoutalButton {...props}>{processedChildren}</RoutalButton>;
};
