import React from 'react';

const styles = {
  large: {
    width: `32px`,
  },
  medium: {
    width: `16px`,
  },
  small: {
    width: `8px`,
  },
};

interface IHSpacerProps {
  small?: boolean;
  medium?: boolean;
  large?: boolean;
}

const HSpacer = ({ small, medium }: IHSpacerProps) => {
  let definedStyle;
  if (small) {
    definedStyle = styles.small;
  } else if (medium) {
    definedStyle = styles.medium;
  } else {
    definedStyle = styles.large;
  }
  return <div style={{ ...definedStyle }} />;
};

export default HSpacer;
