import { ServiceResponse } from '@nx-smartmonkey/shared/hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { supervisorService } from '../../application/SupervisorService';
import { Supervisor, SupervisorUpdateProps } from '../../domain/supervisor/Supervisor';
import useErrorMiddleware from '../errorMiddleware/useErrorMiddleware';
import { useShowSnackbarMessage } from '../snackbarMessage/useShowSnackbarMessage';

type SupervisorUpdateParams = {
  supervisor: Supervisor;
  changes: SupervisorUpdateProps;
};

type UseUpdateSupervisorProps = ServiceResponse<({ supervisor, changes }: SupervisorUpdateParams) => void, void>;

// Hooks act as 'Adapter' layer.
export const useUpdateSupervisor = (): UseUpdateSupervisorProps => {
  const queryClient = useQueryClient();
  const { sessionExpiredLogout } = useErrorMiddleware();
  const { showSnackbarMessage } = useShowSnackbarMessage();

  const { isLoading, isSuccess, isError, mutate } = useMutation<Supervisor, unknown, SupervisorUpdateParams>(
    ({ supervisor, changes }: SupervisorUpdateParams): Promise<Supervisor> => {
      supervisor.update(changes);
      return supervisorService.save(supervisor);
    },
    {
      onMutate: async ({ supervisor, changes }: SupervisorUpdateParams) => {
        supervisor.update(changes);

        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({ queryKey: [`supervisors_supervisor`, supervisor.id] });

        // Snapshot the supervisor previous value
        const previousSupervisor = queryClient.getQueryData<Supervisor>([`supervisors_supervisor`, supervisor.id]);

        // Optimistically update to the new value
        queryClient.setQueryData<Supervisor>([`supervisors_supervisor`, supervisor.id], supervisor);

        // Return a context with the previous and new supervisor
        return { previousSupervisor };
      },
      // If the mutation fails, use the context we returned above
      onError: (err: any, variables: SupervisorUpdateParams, context: any) => {
        if (context?.previousSupervisor) {
          queryClient.setQueryData<Supervisor>(
            [`supervisors_supervisor`, variables.supervisor.id],
            context.previousSupervisor
          );
        }

        console.info(`Update Supervisor Error: `, err);

        if (err?.response?.status === 401) {
          sessionExpiredLogout();
          return;
        }
        showSnackbarMessage({
          messageIntlId: `infoerror.${err?.response?.data?.messageId ?? `network_error`}`,
        });
      },
      // Always refetch after error or success:
      onSettled: (data: Supervisor | undefined, error: any, variables: any, context: any) => {
        if (error) {
          queryClient.invalidateQueries({ queryKey: [`supervisors_supervisor`, variables.supervisor.id] });
        } else {
          if (data) {
            // Optimistically update to the new value
            queryClient.setQueryData<Supervisor>([`supervisors_supervisor`, data.id], data);
          }

          showSnackbarMessage({
            type: `success`,
            messageIntlId: `info.update_supervisor_success`,
          });
        }
      },
    }
  );

  return {
    error: isError,
    isLoading: isLoading,
    success: isSuccess,
    run: ({ supervisor, changes }: SupervisorUpdateParams) => mutate({ supervisor, changes }),
  };
};
