import { PaginatedResult } from '@nx-smartmonkey/shared/interfaces';
import { SearchPredicate } from '../../domain/searchPredicates/SearchPredicate';
import { Stop } from '../../domain/stop/Stop';
import { StopFlat } from '../../domain/stop/StopFlat';
import { StopsActions, StopsActionType } from './actions';
import { getStopsTableColumns, setStopsTableColumns, StopsTableColumn } from './tableColumns';

const defaultPagination = {
  limit: 20,
  offset: 0,
  total: 0,
  docs: [],
} as PaginatedResult<Stop>;

export interface StopsState {
  stops: {
    flat: Array<StopFlat>;
    pagination: PaginatedResult<Stop>;
    tableColumns: Array<StopsTableColumn>;
    selectedStops: Array<string>;
    predicates: Array<SearchPredicate>;
    predicatesBase64: string;
  };
}

export const getInitialState = (): StopsState => ({
  stops: {
    flat: [],
    pagination: defaultPagination,
    tableColumns: getStopsTableColumns(),
    selectedStops: [],
    predicates: [],
    predicatesBase64: ``,
  },
});

export const StopsReducer = (state: StopsState, action: StopsActions): StopsState => {
  switch (action.type) {
    case StopsActionType.SET_SEARCH_STOPS_PREDICATES: {
      const { predicates, predicatesBase64 } = action.payload;
      return {
        ...state,
        stops: {
          ...state.stops,
          predicates,
          predicatesBase64,
        },
      };
    }
    case StopsActionType.SET_SELECTED_STOPS:
      return {
        ...state,
        stops: {
          ...state.stops,
          selectedStops: action.payload.selectedStops,
        },
      };
    case StopsActionType.SET_STOPS_TABLE_COLUMNS:
      setStopsTableColumns(action.payload);
      return {
        ...state,
        stops: {
          ...state.stops,
          tableColumns: action.payload,
        },
      };
    case StopsActionType.SEARCH_STOPS_SUCCESS:
      return {
        ...state,
        stops: {
          ...state.stops,
          pagination: action.payload.stopsPagination,
        },
      };
    case StopsActionType.SEARCH_FLAT_STOPS_SUCCESS:
      return {
        ...state,
        stops: {
          ...state.stops,
          flat: action.payload.flatStops,
        },
      };
    case StopsActionType.DRIVER_INFO_UPDATED: {
      const driverInfo = action.payload.driverInfo;

      return {
        ...state,
        stops: {
          ...state.stops,
          pagination: {
            ...state.stops.pagination,
            docs: state.stops.pagination.docs.map((stop) => {
              if (stop.driver_info?.id !== driverInfo.id) {
                return stop;
              }

              stop.driver_info = driverInfo;
              return stop;
            }),
          },
        },
      };
    }
    case StopsActionType.STOP_UPDATED: {
      const updatedStop = action.payload.stop;
      return {
        ...state,
        stops: {
          ...state.stops,
          flat: state.stops.flat.map((flatStop) => {
            if (flatStop.id !== updatedStop.id) {
              return flatStop;
            }

            flatStop.update({
              label: updatedStop.label,
              status: updatedStop.status,
              lat: updatedStop.location?.lat,
              lng: updatedStop.location?.lng,
              created_at: updatedStop.created_at,
            });

            return flatStop;
          }),
          pagination: {
            ...state.stops.pagination,
            docs: state.stops.pagination.docs.map((stop) => (stop.id === updatedStop.id ? updatedStop : stop)),
          },
        },
      };
    }
    case StopsActionType.STOP_DELETED: {
      const deletedStopId = action.payload.stopId;
      return {
        ...state,
        stops: {
          ...state.stops,
          flat: state.stops.flat.filter((stop) => stop.id !== deletedStopId),
          pagination: {
            ...state.stops.pagination,
            docs: state.stops.pagination.docs.filter((stop) => stop.id !== deletedStopId),
          },
        },
      };
    }
    default:
      return state;
  }
};
