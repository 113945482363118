import {
  CustomFieldCategoricalMultiple,
  CustomFieldCategoricalSingle,
  CustomFieldDefinition,
  CustomFields,
} from '@nx-smartmonkey/shared/domain';
import { has } from 'lodash';
import * as XLSX from 'xlsx';

export interface ExcelEntryFormatter {
  id: string;
  header: string;
  display?: boolean;
  formatter?: (value: any) => string | number | boolean | Date | undefined;
  type?: `b` | `n` | `d` | `s` | `l`;
  order?: number;
}

export const jsonToExcel = (json: { [key: string]: any }[], format: ExcelEntryFormatter[]) => {
  const data = json.map((row: { [key: string]: any }) => {
    const newRow: { [key: string]: any } = {};
    format.forEach((entry) => {
      if (has(entry, `display`) && !entry.display) {
        //
      } else if (has(entry, `formatter`)) {
        newRow[entry.header] = entry.formatter!(row);
      } else {
        newRow[entry.header] = row[entry.id];
      }
    });
    return newRow;
  });

  const headers = format.map((item) => item.header);

  const ws = XLSX.utils.json_to_sheet(data, { header: headers || [] });
  // get ws range of data
  const range = XLSX.utils.decode_range(ws[`!ref`] as string);
  // Iterate for each column

  for (let C = range.s.c; C <= range.e.c; C += 1) {
    // get cell object
    headers.push(ws[XLSX.utils.encode_cell({ c: C, r: 0 } as XLSX.CellAddress)].v);
    // if cell exists
  }
  // iterate over all rows of a single column
  if (format) {
    format.forEach(({ id, type }) => {
      if (type) {
        const column = headers.indexOf(id);
        for (let R = range.s.r + 1; R <= range.e.r; R += 1) {
          const cell = ws[XLSX.utils.encode_cell({ c: column, r: R } as XLSX.CellAddress)];
          if (cell) {
            if (type === `l`) {
              cell.l = { Target: cell.v };
            } else {
              cell.t = type;
            }
          }
        }
      }
    });
  }
  return ws;
};

export const customFieldToFormatter = (customFieldDefinition: CustomFieldDefinition): ExcelEntryFormatter => {
  switch (customFieldDefinition.field_type) {
    case `boolean`:
      return {
        id: customFieldDefinition.custom_id,
        header: customFieldDefinition.label ?? ``,
        formatter: (customField?: CustomFields) => {
          if (!customField || !(customFieldDefinition.custom_id in customField.value)) {
            return undefined;
          }

          const customFieldValue = customField.value[customFieldDefinition.custom_id];
          return customFieldValue as boolean;
        },
        type: `b`,
      };
    case `text`:
      return {
        id: customFieldDefinition.custom_id,
        header: customFieldDefinition.label ?? ``,
        formatter: (customField?: CustomFields) => {
          if (!customField) {
            return ``;
          }

          return customField.value[customFieldDefinition.custom_id] as string;
        },
      };
    case `numerical`:
      return {
        id: customFieldDefinition.custom_id,
        header: customFieldDefinition.label ?? ``,
        formatter: (customField?: CustomFields) => {
          if (!customField) {
            return ``;
          }
          return customField.value[customFieldDefinition.custom_id] as number;
        },
        type: `n`,
      };
    case `categorical`:
      return {
        id: customFieldDefinition.custom_id,
        header: customFieldDefinition.label ?? ``,
        formatter: (customField?: CustomFields) => {
          if (!customField || !customField.value[customFieldDefinition.custom_id]) {
            return ``;
          }

          if (!customFieldDefinition.multiple) {
            const values = customField.value[customFieldDefinition.custom_id] as CustomFieldCategoricalSingle;
            return values.label;
          }

          const values = customField.value[customFieldDefinition.custom_id] as CustomFieldCategoricalMultiple;
          return values
            ? Object.values(values)
                .reduce((acc, value) => [...acc, (value as any).label], [] as string[])
                .join(`, `)
            : ``;
        },
      };
    default:
      throw Error(`Unknown custom field type`);
  }
};
