import { ValueObject } from '../ValueObject';

export interface ReportImageProps {
  id: string;
  url: string;
}

export class ReportImage extends ValueObject<ReportImageProps> {
  get id(): string {
    return this.props.id;
  }
  get url(): string {
    return this.props.url;
  }
  static create(props: ReportImageProps): ReportImage {
    return new ReportImage(props);
  }
}
