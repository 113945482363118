import { Requests, arrayBufferToImageSrcBase64 } from '@nx-smartmonkey/shared/helpers';
import { Project } from '../../domain/project/Project';
import { ProjectMapper } from '../../mappers/project/ProjectMapper';
import { SessionRepository } from '../session/SessionRepository';
import { ProjectRepository } from './ProjectRepository';

export class ProjectRepositoryApi implements ProjectRepository {
  constructor(private requests: Requests, private sessionRepository: SessionRepository) {}
  async findByHost({ host, isExampleURL = false }: { host: string; isExampleURL?: boolean }): Promise<Project> {
    const response = await this.requests.get({
      uri: `/supervisors/v1/project${isExampleURL ? `/example` : ``}`,
      params: { host },
    });
    return ProjectMapper.toDomain(response.data);
  }

  async retrieveFavicon({ project }: { project: Project }): Promise<Project> {
    if (!project.style?.favicon) {
      return project;
    }

    const requests = Requests.create(``);

    const asSupervisor = this.sessionRepository.getAsSupervisor();

    const response = await requests.get({
      uri: project.style.favicon,
      ...(asSupervisor
        ? {
            params: {
              as_supervisor: `${asSupervisor}`,
            },
          }
        : {}),
      responseType: `arraybuffer`,
      headers: this.sessionRepository.getAuthenticationHeader(),
    });

    project.style.favicon = await arrayBufferToImageSrcBase64({
      data: response.data,
      dataType: response.headers[`content-type`].toLowerCase(),
    });

    return project;
  }
}
