import { EventEmitter } from '@nx-smartmonkey/shared/helpers';
import ReconnectingWebSocket from 'reconnecting-websocket';
import configuration from '../services/configuration';

const generateUID = () => {
  const a = new Uint32Array(3);
  window.crypto.getRandomValues(a);
  return (
    performance.now().toString(36) +
    Array.from(a)
      .map((A) => A.toString(36))
      .join(``)
  ).replace(/\./g, ``);
};

export class WebSocketsService extends EventEmitter {
  private socket: ReconnectingWebSocket | undefined;

  // TODO: CREATE A UNIQUE ID
  private clientSocketId = generateUID();

  private version = `2`;

  private connected = false;

  private token: string | undefined;

  private subscriptions: Record<string, (response: any) => void> = {};

  private getMetadata() {
    return {
      id: this.clientSocketId,
      version: this.version,
      auth: {
        headers: {
          authorization: `Bearer ${this.token}`,
          ws_domain: `supervisors`,
        },
      },
    };
  }

  public connect(token: string): void {
    this.token = token;

    this.socket = new ReconnectingWebSocket(configuration.SUPERVISORS_BACK_END_WS, [], { debug: false });

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    // Connection opened
    this.socket.addEventListener(`open`, (event) => {
      that.connected = true;
      that.sendHello();
    });

    // Listen for messages
    this.socket!.onmessage = (event) => {
      const { type, path, message } = JSON.parse(event.data);

      if (type === `ping`) {
        that.sendPing();
        return;
      }
      if (type === `hello`) {
        this.emit(`connection_event`, `connected`);
        return;
      }

      if (type !== `pub`) {
        return;
      }

      if (!that.subscriptions[path]) {
        return;
      }

      const response = JSON.parse(message);

      that.subscriptions[path](response);
    };

    // this.socket!.onclose = (event) => {
    //   console.info(`onclose`, event);
    // };
    // this.socket.onerror = (ev) => {
    //   console.info(`onerror`, ev);
    // };
  }

  private sendPing(): void {
    if (this.connected) {
      this.socket!.send(
        JSON.stringify({
          ...this.getMetadata(),
          type: `ping`,
        })
      );
    }
  }

  private sendHello(): void {
    if (this.connected) {
      this.socket!.send(
        JSON.stringify({
          ...this.getMetadata(),
          type: `hello`,
        })
      );
    }
  }

  public subscribe(path: string, callback: (response: any) => void): void {
    if (this.connected) {
      this.socket!.send(
        JSON.stringify({
          ...this.getMetadata(),
          type: `sub`,
          path,
        })
      );

      this.subscriptions[path] = callback;
    }
  }

  public unsubscribe(path: string): void {
    if (this.subscriptions[path]) {
      if (this.connected) {
        this.socket!.send(
          JSON.stringify({
            ...this.getMetadata(),
            type: `unsub`,
            path,
          })
        );
      }

      delete this.subscriptions[path];
    }
  }

  public getSubscription(path: string): Function | undefined {
    return this.subscriptions[path];
  }
}

export const webSocketsService = new WebSocketsService();
