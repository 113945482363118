export class Cookies {
  hasCookie(name: string) {
    return (
      document.cookie
        .split(`;`)
        .map((s) => s.trim())
        .filter((s) => s.startsWith(`${name}=`) && s.length > 6).length > 0
    );
  }

  getCookie(name: string) {
    if (this.hasCookie(name)) {
      return document.cookie
        .split(`;`)
        .map((s) => s.trim())
        .filter((s) => s.startsWith(`${name}=`) && s.length > 6)[0]
        .substring(`${name}=`.length);
    }
    return undefined;
  }

  deleteCookie(name: string) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  setCookie(name: string, value: string, days: number) {
    let expires = ``;
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires='${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ``}${expires}; path=/`;
  }

  static create(): Cookies {
    return new Cookies();
  }
}
