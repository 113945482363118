/* eslint-disable camelcase */
import { dateFormat, dateFormatShortTime, parseSecondsToHM, secondsToMinutes } from '@nx-smartmonkey/shared/helpers';
import * as XLSX from 'xlsx';

import { CUSTOM_FIELD_COLUMN_ID_PREFIX } from '../../constants';
import { StopsTableColumn, StopsTableColumnKeys } from '../../context/stops/tableColumns';
import { Project } from '../../domain/project/Project';
import { Stop } from '../../domain/stop/Stop';
import { Supervisor } from '../../domain/supervisor/Supervisor';
import configuration from '../../services/configuration';
import { intl } from '../IntGlobalProvider';
import { ExcelEntryFormatter, customFieldToFormatter, jsonToExcel } from './Formatters';
import { parseStringArray, parseTimewindowsToExcel } from './Parsers';

const getStopFormatters = (
  parsedTableColumns: Record<StopsTableColumnKeys, boolean>,
  project: Project,
  supervisor: Supervisor
): ExcelEntryFormatter[] => {
  const volumeIn = project.units!.volume ?? ``;
  const weightIn = project.units!.weight ?? ``;
  const publicKey = project.public_key ?? ``;
  const formatters: ExcelEntryFormatter[] = [];

  if (parsedTableColumns.label && supervisor.isStopFieldEnabled(`label`)) {
    formatters.push({
      header: intl.formatMessage({ id: `stop.label` }),
      id: `label`,
      formatter: (stop: Stop) => stop.label,
    });
  }

  if (parsedTableColumns.vehicle_label && supervisor.isStopFieldEnabled(`driver_info`)) {
    formatters.push({
      header: intl.formatMessage({ id: `stop.vehicle_label` }),
      id: `vehicle_label`,
      formatter: (stop: Stop) => stop.driver_info?.label,
    });
  }

  if (parsedTableColumns.estimated_arrival_time && supervisor.isStopFieldEnabled(`estimated_arrival_time`)) {
    formatters.push({
      header: intl.formatMessage({ id: `stop.estimated_arrival_time` }),
      id: `estimated_arrival_time`,
      formatter: (stop: Stop) =>
        stop.estimated_arrival_time !== undefined ? dateFormatShortTime(stop.estimated_arrival_time) : ``,
    });
  }

  if (parsedTableColumns.estimated_departure_time && supervisor.isStopFieldEnabled(`estimated_departure_time`)) {
    formatters.push({
      header: intl.formatMessage({ id: `stop.estimated_departure_time` }),
      id: `estimated_departure_time`,
      formatter: (stop: Stop) =>
        stop.estimated_departure_time !== undefined ? dateFormatShortTime(stop.estimated_departure_time) : ``,
    });
  }

  if (parsedTableColumns.planned_arrival_time && supervisor.isStopFieldEnabled(`planned_arrival_time`)) {
    formatters.push({
      header: intl.formatMessage({ id: `stop.planned_arrival_time` }),
      id: `planned_arrival_time`,
      formatter: (stop: Stop) =>
        stop.planned_arrival_time !== undefined ? parseSecondsToHM(stop.planned_arrival_time) : ``,
    });
  }

  if (parsedTableColumns.planned_departure_time && supervisor.isStopFieldEnabled(`planned_departure_time`)) {
    formatters.push({
      header: intl.formatMessage({ id: `stop.planned_departure_time` }),
      id: `planned_departure_time`,
      formatter: (stop: Stop) =>
        stop.planned_departure_time !== undefined ? parseSecondsToHM(stop.planned_departure_time) : ``,
    });
  }

  if (parsedTableColumns.pin && supervisor.isStopFieldEnabled(`pin`)) {
    formatters.push({
      header: intl.formatMessage({ id: `stop.pin` }),
      id: `pin`,
      formatter: (stop: Stop) => stop.pin,
    });
  }

  if (parsedTableColumns.additional_info && supervisor.isStopFieldEnabled(`additional_info`)) {
    formatters.push({
      header: intl.formatMessage({ id: `stop.additional_info` }),
      id: `additional_info`,
      formatter: (stop: Stop) => stop.additional_info,
    });
  }

  if (parsedTableColumns.customer_text_field && supervisor.isStopFieldEnabled(`customer_text_field`)) {
    formatters.push({
      header: intl.formatMessage({ id: `stop.customer_text_field` }),
      id: `customer_text_field`,
      formatter: (stop: Stop) => stop.customer_text_field,
    });
  }

  if (parsedTableColumns.execution_date && supervisor.isStopFieldEnabled(`execution_date`)) {
    formatters.push({
      header: intl.formatMessage({ id: `stop.execution_date` }),
      id: `execution_date`,
      formatter: (stop: Stop) => (stop.execution_date !== undefined ? dateFormat(stop.execution_date) : ``),
    });
  }

  if (parsedTableColumns.external_id && supervisor.isStopFieldEnabled(`external_id`)) {
    formatters.push({
      id: `external_id`,
      header: intl.formatMessage({ id: `stop.external_id` }),
      formatter: (stop: Stop) => stop.external_id,
    });
  }

  if (parsedTableColumns.client_external_id && supervisor.isStopFieldEnabled(`client_external_id`)) {
    formatters.push({
      id: `client_external_id`,
      header: intl.formatMessage({ id: `stop.client_external_id` }),
      formatter: (stop: Stop) => stop.client_external_id,
    });
  }

  if (parsedTableColumns.status) {
    formatters.push({
      id: `status`,
      header: intl.formatMessage({ id: `stop.status` }),
      formatter: (stop: Stop) => intl.formatMessage({ id: `stop.status.${stop.status}` }),
    });
  }

  if (parsedTableColumns.location && supervisor.isStopFieldEnabled(`location`)) {
    formatters.push({
      id: `location`,
      header: intl.formatMessage({ id: `stop.location` }),
      formatter: (stop: Stop) => (stop.location ? `${stop.location?.lat}, ${stop.location?.lng}` : ``),
    });
    formatters.push({
      id: `address`,
      header: intl.formatMessage({ id: `stop.address` }),
      formatter: (stop: Stop) => stop.location?.label || ``,
    });
  }

  if (parsedTableColumns.location_details && supervisor.isStopFieldEnabled(`location_details`)) {
    formatters.push({
      id: `address_2`,
      header: intl.formatMessage({ id: `stop.address_2` }),
      formatter: (stop: Stop) => stop.location_details,
    });
  }

  if (parsedTableColumns.time_windows && supervisor.isStopFieldEnabled(`time_windows`)) {
    formatters.push({
      id: `time_windows`,
      header: intl.formatMessage({ id: `stop.time_windows` }),
      formatter: (stop: Stop) => (stop.time_windows ? parseTimewindowsToExcel(stop.time_windows) : ``),
    });
  }

  if (parsedTableColumns.weight && supervisor.isStopFieldEnabled(`weight`)) {
    formatters.push({
      id: `weight`,
      header: `${intl.formatMessage({ id: `stop.weight` })} (${weightIn})`,
      formatter: (stop: Stop) => (stop.weight !== undefined ? stop.weight : undefined),
      type: `n`,
    });
  }

  if (parsedTableColumns.volume && supervisor.isStopFieldEnabled(`volume`)) {
    formatters.push({
      id: `volume`,
      header: `${intl.formatMessage({ id: `stop.volume` })} (${volumeIn})`,
      formatter: (stop: Stop) => (stop.volume !== undefined ? stop.volume : undefined),
      type: `n`,
    });
  }

  if (parsedTableColumns.requires && supervisor.isStopFieldEnabled(`requires`)) {
    formatters.push({
      id: `requires`,
      header: intl.formatMessage({ id: `stop.requires` }),
      formatter: (stop: Stop) => parseStringArray(stop.requires ?? []),
    });
  }

  if (parsedTableColumns.cluster && supervisor.isStopFieldEnabled(`cluster`)) {
    formatters.push({
      id: `cluster`,
      header: intl.formatMessage({ id: `stop.cluster` }),
      formatter: (stop: Stop) => stop.cluster,
    });
  }

  if (parsedTableColumns.duration && supervisor.isStopFieldEnabled(`duration`)) {
    formatters.push({
      id: `duration`,
      header: intl.formatMessage({ id: `stop.duration` }),
      formatter: (stop: Stop) => secondsToMinutes(stop.duration),
      type: `n`,
    });
  }

  if (parsedTableColumns.max_delivery_time && supervisor.isStopFieldEnabled(`max_delivery_time`)) {
    formatters.push({
      id: `max_delivery_time`,
      header: intl.formatMessage({ id: `stop.max_delivery_time` }),
      formatter: (stop: Stop) => stop.max_delivery_time,
      type: `n`,
    });
  }

  if (parsedTableColumns.reward && supervisor.isStopFieldEnabled(`reward`)) {
    formatters.push({
      id: `reward`,
      header: intl.formatMessage({ id: `stop.reward` }),
      formatter: (stop: Stop) => stop.reward,
      type: `n`,
    });
  }

  if (parsedTableColumns.comments && supervisor.isStopFieldEnabled(`comments`)) {
    formatters.push({
      id: `comments`,
      header: intl.formatMessage({ id: `stop.comments` }),
      formatter: (stop: Stop) => stop.comments,
    });
  }

  if (parsedTableColumns.reference_person && supervisor.isStopFieldEnabled(`reference_person`)) {
    formatters.push({
      id: `reference_person`,
      header: intl.formatMessage({ id: `stop.reference_person` }),
      formatter: (stop: Stop) => stop.reference_person,
    });
  }

  if (parsedTableColumns.phone && supervisor.isStopFieldEnabled(`phone`)) {
    formatters.push({
      id: `phone`,
      header: intl.formatMessage({ id: `stop.phone` }),
      formatter: (stop: Stop) => stop.phone,
    });
  }

  if (parsedTableColumns.email && supervisor.isStopFieldEnabled(`email`)) {
    formatters.push({
      id: `email`,
      header: intl.formatMessage({ id: `stop.email` }),
      formatter: (stop: Stop) => stop.email,
    });
  }

  if (parsedTableColumns.url && supervisor.isStopFieldEnabled(`url`)) {
    formatters.push({
      id: `url`,
      header: intl.formatMessage({ id: `stop.url` }),
      formatter: (stop: Stop) => stop.url,
      type: `l`,
    });
  }

  if (parsedTableColumns.type) {
    formatters.push({
      id: `type`,
      header: intl.formatMessage({ id: `stop.type` }),
      formatter: (stop: Stop) => intl.formatMessage({ id: stop.type }),
    });
  }

  if (parsedTableColumns.created_at) {
    formatters.push({
      id: `created_at`,
      header: intl.formatMessage({ id: `stop.created_at` }),
      formatter: (stop: Stop) => dateFormat(stop.created_at),
      type: `d`,
    });
  }

  if (
    parsedTableColumns.report_location &&
    (supervisor.isStopCompletedFieldEnabled(`location`) || supervisor.isStopCanceledFieldEnabled(`location`))
  ) {
    formatters.push({
      id: `report_location`,
      header: intl.formatMessage({ id: `stop.report_location` }),
      formatter: (stop: Stop) => {
        if (!stop.report?.location?.isValid()) {
          return undefined;
        }
        return stop.report.location.toCoordinate().toString();
      },
    });
  }

  if (
    parsedTableColumns.report_created_at &&
    (supervisor.isStopCompletedFieldEnabled(`created_at`) || supervisor.isStopCanceledFieldEnabled(`created_at`))
  ) {
    formatters.push({
      id: `report_created_at`,
      header: intl.formatMessage({ id: `stop.report_created_at` }),
      type: `d`,
      formatter: (stop: Stop) => {
        if (!stop.report) {
          return undefined;
        }
        return stop.report.created_at ? dateFormat(stop.report.created_at) : undefined;
      },
    });
  }

  if (
    parsedTableColumns.report_comments &&
    (supervisor.isStopCompletedFieldEnabled(`comments`) || supervisor.isStopCanceledFieldEnabled(`comments`))
  ) {
    formatters.push({
      id: `report_comments`,
      header: intl.formatMessage({ id: `stop.report.comments` }),
      formatter: (stop: Stop) => {
        if (!stop.report) {
          return undefined;
        }
        return stop.report.comments ?? ``;
      },
    });
  }

  if (parsedTableColumns.report_signature && supervisor.isStopCompletedFieldEnabled(`signature`)) {
    formatters.push({
      id: `report_signature`,
      header: intl.formatMessage({ id: `stop.report.signature` }),
      formatter: (stop: Stop) => {
        if (!stop.report || stop.report.type === `service_report_canceled`) {
          return undefined;
        }
        return !!stop.report.signature;
      },
      type: `b`,
    });
  }

  if (
    parsedTableColumns.report_images &&
    (supervisor.isStopCompletedFieldEnabled(`images`) || supervisor.isStopCanceledFieldEnabled(`images`))
  ) {
    formatters.push({
      id: `report_images`,
      header: intl.formatMessage({ id: `stop.report.images` }),
      formatter: (stop: Stop) => {
        if (!stop.report) {
          return undefined;
        }

        return (stop.report.images ?? [])
          .map((image) => {
            return `${image.url}/public?public_key=${encodeURIComponent(publicKey)}`;
          })
          .join(`, `);
      },
    });
  }

  if (parsedTableColumns.report_cancel_reason && supervisor.isStopCanceledFieldEnabled(`cancel_reason`)) {
    formatters.push({
      id: `report_cancel_reason`,
      header: intl.formatMessage({ id: `stop.report_cancel_reason` }),
      formatter: (stop: Stop) => {
        if (!stop.report || stop.report.type === `service_report_completed`) {
          return undefined;
        }

        if (!stop.report.cancel_reason) {
          return ``;
        }
        return intl.formatMessage({ id: `stop.report.reason.${stop.report.cancel_reason}` });
      },
    });
  }

  if (parsedTableColumns.survey && supervisor.isSurveyFieldEnabled(`rating`)) {
    formatters.push({
      header: intl.formatMessage({ id: `stop.survey` }),
      id: `survey`,
      formatter: (stop: Stop) => {
        if (!stop.survey) {
          return ``;
        }
        return `${stop.survey?.rating ?? 0}/5`;
      },
    });
  }

  if (parsedTableColumns.customers_link) {
    formatters.push({
      id: `customers_link`,
      header: intl.formatMessage({ id: `stop.customers_link` }),
      formatter: (stop: Stop) => {
        return `${configuration.CUSTOMERS_SLUG_ENDPOINT.replace(`{project_slug}`, project.project_slug)}/stop/${
          stop.id
        }`;
      },
      type: `l`,
    });
  }

  return formatters;
};

export const exportStopsExcel = ({
  stops,
  project,
  supervisor,
  tableColumns,
}: {
  stops: Stop[];
  project: Project;
  supervisor: Supervisor;
  tableColumns?: Array<StopsTableColumn>;
}) => {
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: `Stops`,
    Subject: `Stops exported`,
    Author: `Routal`,
    CreatedDate: new Date(),
  };

  const parsedTableColumns = (tableColumns ?? []).reduce(
    (amount, next) => ({
      ...amount,
      [next.identifier]: next.displayable,
    }),
    {} as Record<StopsTableColumnKeys, boolean>
  );

  const stopCustomFieldFormatter = (project.custom_fields?.client ?? [])
    .filter(
      (cf) => cf.enabled && (parsedTableColumns as any)[`${CUSTOM_FIELD_COLUMN_ID_PREFIX}${cf.custom_id}`] === true
    )
    .map((cf) => {
      const formatter = customFieldToFormatter(cf);
      return {
        ...formatter,
        formatter: (stop: Stop) => formatter.formatter && formatter.formatter(stop.custom_fields),
      };
    });

  // CustomFieldFormatters always access {object}.custom_fields,
  // since now we want to deep into {object}.reports[0].custom_fields
  // we need to create a function on top of the original function
  // that will access the right custom_fields
  const reportCustomFieldFormatter: ExcelEntryFormatter[] = [
    ...(project.custom_fields?.service_report_canceled ?? []),
    ...(project.custom_fields?.service_report_completed ?? []),
  ]
    .filter((cf) => cf.enabled)
    .map((cf) => {
      const formatter = customFieldToFormatter(cf);
      return {
        ...formatter,
        formatter: (stop: Stop) => {
          return stop.report && formatter.formatter && formatter.formatter(stop.report.custom_fields);
        },
      };
    });

  const allStopsFormatter = [
    ...getStopFormatters(parsedTableColumns, project, supervisor),
    ...stopCustomFieldFormatter,
    ...reportCustomFieldFormatter,
  ];

  const stopsSheet = jsonToExcel(stops, allStopsFormatter);

  const stopsLabel = intl.formatMessage({ id: `supervisors.excel.sheets.stops` });
  wb.SheetNames.push(stopsLabel);
  wb.Sheets[stopsLabel] = stopsSheet;

  return XLSX.write(wb, { bookType: `xlsx`, type: `binary`, cellDates: true });
};
