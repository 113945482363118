import { SearchFilter } from '../filter';
import { SearchFilterProps } from '../filter/SearchFilter';

export type SearchFilterTimeOperator = `lt` | `eq` | `gt` | `unknown` | `known`;
export type SearchFilterTimeValue = number | string | null;

interface SearchFilterTimeProps extends SearchFilterProps {
  field: string;
  operator: SearchFilterTimeOperator;
  value: SearchFilterTimeValue;

  // RELATIVE

  // - "more than" => lt (el value és un int que son els dies)
  // - "exactly" => eq
  // - "less than" => lt

  // ABSOLUTE

  // - "after" => gt (value es un objecte amb day, month i year)
  // - "on" => eq (value es un objecte amb day, month i year)
  // - "before" => lt (value es un objecte amb day, month i year)
  // - "is unknown" => unknown (value es null)
  // - "has any value" => known (value es null)
}

export class SearchFilterTime extends SearchFilter {
  constructor(props: SearchFilterTimeProps) {
    super({ ...props, type: `time` });
  }
}
