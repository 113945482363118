import { PropsWithChildren } from 'react';

import { Compose } from '../helpers/Compose';
import { InfoErrorContextProvider } from './infoError';
import { NotificationsContextProvider } from './notifications';
import { ProjectContextProvider } from './project';
import { StopsContextProvider } from './stops';
import { TranslationsContextProvider } from './translations';

export const AppContextProvider = ({ children }: PropsWithChildren) => (
  <Compose
    components={[
      InfoErrorContextProvider,
      ProjectContextProvider,
      StopsContextProvider,
      TranslationsContextProvider,
      NotificationsContextProvider,
    ]}
  >
    {children}
  </Compose>
);
