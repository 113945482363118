import { SearchFilter } from '../filter';
import { SearchFilterProps } from '../filter/SearchFilter';

export type SearchFilterSurveyOperator = `gt` | `eq` | `lt`;
export type SearchFilterSurveyValue = number;

interface SearchFilterSurveyProps extends SearchFilterProps {
  field: string;
  operator: SearchFilterSurveyOperator;
  value: SearchFilterSurveyValue;

  // - "greater than" => gt
  // - "is" => eq
  // - "less than" => lt
}

export class SearchFilterSurvey extends SearchFilter {
  constructor(props: SearchFilterSurveyProps) {
    super({ ...props, type: `survey` });
  }
}
