import { useEffect, useState } from 'react';

import darkIcon from '../../favicon_apple.png';
import lightIcon from '../../favicon_apple_white.png';
import { NotificationProps } from '../context/notifications/reducer';
import { intl } from '../helpers/IntGlobalProvider';
import { useNotifications } from './notifications/useNotifications';
import { useRetrieveRightPanelInfo } from './right-panel/useRetrieveRightPanelInfo';

const isNotificationClickable = (notification: NotificationProps) => {
  switch (notification.event_id) {
    case `smartmonkey.planner.2.planner.stop.created`:
    case `smartmonkey.planner.2.planner.stop.rescheduled`:
    case `smartmonkey.planner.2.planner.stop.updated`:
    case `smartmonkey.planner.2.planner.stop.reported`:
      return true;
    case `smartmonkey.planner.2.planner.stop.deleted`:
    default:
      return false;
  }
};

const getIntlByNotificationType = (notification: NotificationProps) => {
  switch (notification.event_id) {
    case `smartmonkey.planner.2.planner.stop.deleted`:
      return `supervisors.notifications.stop.deleted`;
    case `smartmonkey.planner.2.planner.stop.created`:
      return `supervisors.notifications.stop.created`;
    case `smartmonkey.planner.2.planner.stop.rescheduled`:
      return `supervisors.notifications.stop.rescheduled`;
    case `smartmonkey.planner.2.planner.stop.updated`:
      return `supervisors.notifications.stop.updated`;
    case `smartmonkey.planner.2.planner.stop.reported`:
      return `supervisors.notifications.stop.reported.${notification.data.status}`;
    default:
      return ``;
  }
};

const sendNotificationWithPermissions = (
  notification: NotificationProps,
  isDarkMode?: boolean,
  onClick?: (notification: NotificationProps) => void
) => {
  if (!(`Notification` in window)) {
    return;
  }

  if (Notification.permission === `granted`) {
    // Check whether notification permissions have already been granted;
    // if so, create a notification
    sendNotification(notification, isDarkMode, onClick);
  } else if (Notification.permission !== `denied`) {
    // We need to ask the user for permission
    Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === `granted`) {
        sendNotification(notification, isDarkMode, onClick);
      }
    });
  }
};

const sendNotification = (
  notification: NotificationProps,
  isDarkMode?: boolean,
  onClick?: (notification: NotificationProps) => void
) => {
  const not: Notification = new Notification(`Supervisors | Routal`, {
    body: intl.formatMessage({ id: getIntlByNotificationType(notification) }),
    data: notification,
    icon: isDarkMode ? lightIcon : darkIcon,
    // vibrate: 200,
    silent: true,
  });

  if (onClick && isNotificationClickable(notification)) {
    not.onclick = (e) => {
      onClick(notification);
    };
  }
  setTimeout(not.close.bind(not), 5000);
};

export const useBrowserNotifications = () => {
  const { newNotifications } = useNotifications();
  const { setRightPanelInfo } = useRetrieveRightPanelInfo();
  const [alreadyShown, setAlreadyShown] = useState<string[]>([]);

  useEffect(() => {
    if (newNotifications.length === 0) {
      return;
    }

    // Get current color scheme.
    const matcher = window.matchMedia(`(prefers-color-scheme: dark)`);

    for (const notification of newNotifications.filter((notification) => !alreadyShown.includes(notification.id))) {
      sendNotificationWithPermissions(notification, matcher.matches, (notification: NotificationProps) => {
        setRightPanelInfo({
          detailId: notification.data.id,
        });
      });
    }
    setAlreadyShown([...alreadyShown, ...newNotifications.map((notification) => notification.id)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newNotifications]);
};
