export const parseDefaultTableColumns = <T, Q>({
  finalTableColumns,
  defaultTableColumns,
}: {
  finalTableColumns: Array<TableColumn<T, Q>>;
  defaultTableColumns: Array<TableColumn<T, Q>>;
}): Array<TableColumn<T, Q>> => {
  // Here we are reviewing if the constant table columns are present. If not, we set it here.
  defaultTableColumns.forEach((defaultTableColumn) => {
    const found = finalTableColumns.find((tableColumn) => tableColumn.identifier === defaultTableColumn.identifier);
    if (!found) {
      finalTableColumns.push(defaultTableColumn);
    } else {
      // Table column was found but sort things should be the specified in the default list.
      finalTableColumns = finalTableColumns.map((tableColumn) => {
        if (tableColumn.identifier !== defaultTableColumn.identifier) {
          return tableColumn;
        }
        return {
          ...tableColumn,
          sortable: defaultTableColumn.sortable,
          sort: defaultTableColumn.sort,
        };
      });
    }
  });
  return finalTableColumns;
};

export type TableColumn<T, Q> = {
  identifier: T;
  type: `string` | `number` | `Date` | `boolean` | `Location` | `Array`;
  displayable: boolean;
  filterable: boolean;
  sortable: boolean;
  sort?: (a: Q, b: Q) => number;
};

export type TableColumnsSortConfig<T> = {
  order: `asc` | `desc`;
  orderBy: T | undefined;
};

const stringSorter = <Q, T>(a: Q, b: Q, field: T) => {
  const aField = (a as any)[field] || ``;
  const bField = (b as any)[field] || ``;
  if (aField < bField) {
    return -1;
  }
  if (aField > bField) {
    return 1;
  }
  return 0;
};

export const numberSorter = <Q, T>(a: Q, b: Q, field: T) => {
  const aField = (a as any)[field] || 0;
  const bField = (b as any)[field] || 0;

  if (aField < bField) {
    return -1;
  }
  if (aField > bField) {
    return 1;
  }
  return 0;
};

const locationSorter = <Q, T>(a: Q, b: Q, field: T) => {
  return stringSorter((a as any)[field] || {}, (b as any)[field] || {}, `label`);
};

const dateSorter = <Q, T>(a: Q, b: Q, field: T) => {
  const aDate = new Date((a as any)[field] || ``);
  const bDate = new Date((b as any)[field] || ``);
  if (aDate < bDate) {
    return -1;
  }
  if (aDate > bDate) {
    return 1;
  }
  return 0;
};

export const sortDataByTableColumns = <T, Q>({
  data,
  tableColumns,
  sortInfo,
}: {
  data: Q[];
  tableColumns: TableColumn<T, Q>[];
  sortInfo: TableColumnsSortConfig<T>;
}) => {
  const { order, orderBy } = sortInfo;

  if (!orderBy) {
    return data;
  }
  const tableColumn = tableColumns.find((c) => c.identifier === orderBy);

  let sorter;

  if (tableColumn?.sort) {
    sorter = tableColumn?.sort;
  } else {
    if (tableColumn?.type === `number`) {
      sorter = (a: any, b: any) => numberSorter(a, b, orderBy);
    } else if (tableColumn?.type === `string`) {
      sorter = (a: any, b: any) => stringSorter(a, b, orderBy);
    } else if (tableColumn?.type === `Location`) {
      sorter = (a: any, b: any) => locationSorter(a, b, orderBy);
    } else if (tableColumn?.type === `Date`) {
      sorter = (a: any, b: any) => dateSorter(a, b, orderBy);
    }
  }

  const sortedData = [...data].sort(sorter);
  return order === `desc` ? sortedData.reverse() : sortedData;
};
