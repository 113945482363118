import { CustomFieldDefinition, ValueObject } from '@nx-smartmonkey/shared/domain';

export interface ProjectCustomFieldsProps {
  task: CustomFieldDefinition[];
  client: CustomFieldDefinition[];
  vehicle: CustomFieldDefinition[];
  service_report_completed: CustomFieldDefinition[];
  service_report_task_completed: CustomFieldDefinition[];
  service_report_canceled: CustomFieldDefinition[];
  service_report_task_canceled: CustomFieldDefinition[];
}

export class ProjectCustomFields extends ValueObject<ProjectCustomFieldsProps> {
  get task(): CustomFieldDefinition[] {
    return this.props.task;
  }

  get client(): CustomFieldDefinition[] {
    return this.props.client;
  }

  get vehicle(): CustomFieldDefinition[] {
    return this.props.vehicle;
  }

  get service_report_completed(): CustomFieldDefinition[] {
    return this.props.service_report_completed;
  }

  get service_report_task_completed(): CustomFieldDefinition[] {
    return this.props.service_report_task_completed;
  }

  get service_report_canceled(): CustomFieldDefinition[] {
    return this.props.service_report_canceled;
  }

  get service_report_task_canceled(): CustomFieldDefinition[] {
    return this.props.service_report_task_canceled;
  }

  private constructor(props: ProjectCustomFieldsProps) {
    super(props);
  }

  static create(props: ProjectCustomFieldsProps): ProjectCustomFields {
    return new ProjectCustomFields(props);
  }
}
