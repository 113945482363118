import { Grid, Radio } from '@mui/material';

import { intl } from '../../../helpers/IntGlobalProvider';
import { Text } from '../../../react-components/Text';
import { RatingStars } from '../../RatingStars';
import { SearchFilterComponent } from '../filter';
import { SearchFilterOperators } from '../filter/SearchFilter';
import { SearchFilterSurvey } from './SearchFilterSurvey';

interface GetSearchFilterSurveyOptionsProps {
  operator: SearchFilterOperators;
  text: string;
  isSelected: boolean;
}

const getSearchFilterSurveyOptions = (filter: SearchFilterSurvey): GetSearchFilterSurveyOptionsProps[] => [
  {
    operator: `gt`,
    text: intl.formatMessage({ id: `search_filters.gt` }),
    isSelected: filter.isOperator(`gt`),
  },
  {
    operator: `lt`,
    text: intl.formatMessage({ id: `search_filters.lt` }),
    isSelected: filter.isOperator(`lt`),
  },
  {
    operator: `eq`,
    text: intl.formatMessage({ id: `search_filters.eq` }),
    isSelected: filter.isOperator(`eq`),
  },
];

interface SearchFilterSurveyComponentProps {
  filter: SearchFilterSurvey;
  filterPosition: number;
  colors: {
    primaryColor: string;
    secondaryColor: string;
  };
  onChange: (filter: SearchFilterSurvey) => void;
  onRemoveFilter: () => void;
}

export const SearchFilterSurveyComponent = ({
  filter,
  filterPosition,
  colors,
  onChange,
  onRemoveFilter,
}: SearchFilterSurveyComponentProps) => {
  const customResultText = intl.formatMessage({ id: `search_filters.${filter.getOperator()}.result` });
  return (
    <SearchFilterComponent
      filter={filter}
      buttonId={`filter-survey-${filterPosition}`}
      colors={colors}
      onRemoveFilter={onRemoveFilter}
      customResult={(filterResult) => {
        return (
          <Grid container direction="row" wrap="nowrap">
            <Grid item>
              <Text
                style={{
                  fontWeight: `normal`,
                  cursor: `pointer`,
                }}
                intlId={`search_filters.${filterResult.getOperator()}.result`}
                intlValues={{
                  field: intl.formatMessage({ id: `search_filters.filter.${filterResult.getField()}` }),
                  value: (chunks: any) => <span></span>,
                }}
              >
                {customResultText}
              </Text>
            </Grid>

            <div style={{ width: `4px` }} />

            <Grid item>
              <RatingStars
                size={{
                  width: `12px`,
                  height: `12px`,
                }}
                rating={filterResult.getValue() ?? 0}
              />
            </Grid>
          </Grid>
        );
      }}
    >
      {getSearchFilterSurveyOptions(filter).map((option) => {
        return (
          <Grid container direction="row" wrap="nowrap" style={{ padding: `0 16px`, minWidth: `250px` }}>
            <Grid
              item
              container
              direction="row"
              wrap="nowrap"
              alignItems="center"
              style={{
                cursor: `pointer`,
              }}
              onClick={(event: any) => {
                event.stopPropagation();
                filter.setOperator(option.operator);
                onChange(filter);
              }}
            >
              <Grid item>
                <Radio
                  style={{
                    color: colors.primaryColor,
                  }}
                  checked={option.isSelected}
                  onChange={(event: any) => {
                    event.stopPropagation();
                    filter.setOperator(option.operator);
                    onChange(filter);
                  }}
                />
              </Grid>

              <Grid item>
                <Text style={{ cursor: `pointer` }}>{option.text}</Text>
              </Grid>
            </Grid>

            {option.isSelected ? (
              <Grid item container direction="row" wrap="nowrap">
                <RatingStars
                  rating={filter.getValue() ?? 0}
                  onChange={(rating: number) => {
                    filter.setValue(rating);
                    onChange(filter);
                  }}
                  size={{
                    width: `16px`,
                    height: `16px`,
                  }}
                />
              </Grid>
            ) : null}
          </Grid>
        );
      })}
    </SearchFilterComponent>
  );
};
