import { InfoErrorState } from './reducer';

export enum InfoErrorActionType {
  INFOERROR_CLOSE_SNACKBAR = `INFOERROR_CLOSE_SNACKBAR`,
  INFOERROR_SEND_STATUS = `INFOERROR_SEND_STATUS`,
}

export interface InfoErrorCloseSnackbarAction {
  type: InfoErrorActionType.INFOERROR_CLOSE_SNACKBAR;
}

export interface InfoErrorSendStatusAction {
  type: InfoErrorActionType.INFOERROR_SEND_STATUS;
  payload: InfoErrorState;
}

export type InfoErrorActions = InfoErrorCloseSnackbarAction | InfoErrorSendStatusAction;
