export const createStyledMapRoutalDark = () => {
  return new google.maps.StyledMapType(
    [
      {
        stylers: [
          { hue: `#ff1a00` },
          { invert_lightness: true },
          { saturation: -100 },
          { lightness: 33 },
          { gamma: 0.5 },
        ],
      },
      {
        elementType: `labels.icon`,
        stylers: [
          {
            visibility: `off`,
          },
        ],
      },
      {
        featureType: `water`,
        elementType: `geometry`,
        stylers: [{ color: `#2D333C` }],
      },
    ],
    { name: `styled_map_routal_dark` }
  );
};

export const createStyledMapRoutal = () => {
  return new google.maps.StyledMapType(
    [
      { featureType: `administrative`, elementType: `geometry`, stylers: [{ visibility: `on` }] },
      { featureType: `administrative`, elementType: `labels.text.fill`, stylers: [{ color: `#707070` }] },
      { featureType: `administrative.country`, elementType: `labels.text.fill`, stylers: [{ color: `#787878` }] },
      { featureType: `administrative.neighborhood`, elementType: `labels`, stylers: [{ visibility: `on` }] },
      { featureType: `administrative.neighborhood`, elementType: `labels.text.fill`, stylers: [{ color: `#c0bfbc` }] },
      { featureType: `poi`, elementType: `geometry`, stylers: [{ visibility: `off` }] },
      { featureType: `poi`, elementType: `labels`, stylers: [{ visibility: `off` }] },
    ],
    { name: `styled_map_routal` }
  );
};
