import { Grid } from '@mui/material';
import { HSpacer, RoutalPalette, RoutalSelect, RoutalSelectMultiSelectOption } from '@nx-smartmonkey/ui';

import { useRetrieveSupervisor } from '../../hooks/supervisor/useRetrieveSupervisor';
import { Text } from '../../react-components/Text';
import plusIcon from './../../assets/img/plus.svg';
import { getAvailableOptions } from './SearchFiltersAvailableOptions';
import { SearchFilterBoolean } from './boolean';
import { SearchFilterDate } from './date';
import { SearchFilterDateTime } from './date_time';
import { SearchFilterList } from './list';
import { SearchFilterNumber } from './number';
import { SearchFilterString } from './string';
import { SearchFilterSurvey } from './survey';
import { SearchFilterTime } from './time';

interface SearchFiltersButtonProps {
  isExampleURL?: boolean;
  onAddFilter: (
    filter:
      | SearchFilterString
      | SearchFilterBoolean
      | SearchFilterNumber
      | SearchFilterSurvey
      | SearchFilterDate
      | SearchFilterList
  ) => void;
}

export const SearchFiltersButton = ({ isExampleURL = false, onAddFilter }: SearchFiltersButtonProps) => {
  const { data: supervisor } = useRetrieveSupervisor();

  return (
    <RoutalSelect
      id="search-filter-button"
      disabled={isExampleURL}
      style={{
        backgroundColor: RoutalPalette.secondary40,
        color: RoutalPalette.neutral00,
        minWidth: `140px`,
      }}
      hideArrow
      popoverProps={{
        maxHeight: `800px`,
        options: getAvailableOptions(supervisor).map(
          (option): RoutalSelectMultiSelectOption => ({
            key: option.field,
            width: 300,
            content: (
              <Grid container direction="row" wrap="nowrap" alignItems="center">
                <Grid item>
                  <img src={option.iconSrc} alt="" draggable="false" width="16" />
                </Grid>
                <Grid item>
                  <HSpacer small />
                </Grid>
                <Grid item>
                  <Text style={{ cursor: `pointer` }} intlId={option.intlId} />
                </Grid>
              </Grid>
            ),
            onClick: () => {
              switch (option.type) {
                case `string`:
                  onAddFilter(
                    new SearchFilterString({
                      field: option.field,
                      type: `string`,
                      operator: `eq`,
                      value: null,
                      iconSrc: option.iconSrc,
                      formatted: option.formatted,
                      computed: option.computed,
                    })
                  );
                  break;
                case `boolean`:
                  onAddFilter(
                    new SearchFilterBoolean({
                      field: option.field,
                      type: `boolean`,
                      operator: `known`,
                      value: null,
                      iconSrc: option.iconSrc,
                      formatted: option.formatted,
                      computed: option.computed,
                    })
                  );
                  break;
                case `number`:
                  onAddFilter(
                    new SearchFilterNumber({
                      field: option.field,
                      type: `number`,
                      operator: `eq`,
                      value: null,
                      iconSrc: option.iconSrc,
                      formatted: option.formatted,
                      computed: option.computed,
                    })
                  );
                  break;
                case `survey`:
                  onAddFilter(
                    new SearchFilterSurvey({
                      field: option.field,
                      type: `number`,
                      operator: `eq`,
                      value: 3,
                      iconSrc: option.iconSrc,
                      formatted: option.formatted,
                      computed: option.computed,
                    })
                  );
                  break;
                case `date`:
                  onAddFilter(
                    new SearchFilterDate({
                      field: option.field,
                      type: `date`,
                      operator: `eq`,
                      value: 1,
                      iconSrc: option.iconSrc,
                      formatted: option.formatted,
                      computed: option.computed,
                    })
                  );
                  break;
                case `date_time`:
                  onAddFilter(
                    new SearchFilterDateTime({
                      field: option.field,
                      type: `date_time`,
                      operator: `eq`,
                      value: new Date().toString(),
                      iconSrc: option.iconSrc,
                      formatted: option.formatted,
                      computed: option.computed,
                    })
                  );
                  break;
                case `time`:
                  onAddFilter(
                    new SearchFilterTime({
                      field: option.field,
                      type: `time`,
                      operator: `eq`,
                      value: 0,
                      iconSrc: option.iconSrc,
                      formatted: option.formatted,
                      computed: option.computed,
                    })
                  );
                  break;
                case `list`:
                  onAddFilter(
                    new SearchFilterList({
                      field: option.field,
                      type: `string`,
                      operator: `eq`,
                      value: option.listOptions![0].value,
                      iconSrc: option.iconSrc,
                      formatted: option.formatted,
                      computed: option.computed,
                      listOptions: option.listOptions!,
                    })
                  );
                  break;
                default:
                  return;
              }
            },
          })
        ),
      }}
    >
      <Grid container direction="row" justifyContent="center" alignItems="center" wrap="nowrap">
        <img src={plusIcon} alt="" draggable="false" width="14" />
        <HSpacer small />
        <Text intlId="search_filters.add" style={{ cursor: `pointer`, color: RoutalPalette.neutral00 }} />
      </Grid>
    </RoutalSelect>
  );
};
