import './index.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AppContextProvider } from './context';
import { Supervisors } from './pages';

export const App = () => {
  // Create a client
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        networkMode: `offlineFirst`,
      },
      mutations: {
        networkMode: `offlineFirst`,
      },
    },
  });

  console.info(`FORCE DEPLOY - Retrieve translations`);

  return (
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        <Supervisors />
      </AppContextProvider>
    </QueryClientProvider>
  );
};
