import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { RoutalPalette, RoutalText } from '.';

const RoutalAvatarContainer = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
`;

const RoutalAvatarContainerLarge = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
`;

const RoutalAvatarPlaceHolder = styled.div`
  background-color: ${RoutalPalette.secondary60};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export interface RoutalAvatarProps {
  url?: string;
  label?: string;
  large?: boolean;
  containerStyles?: React.CSSProperties;
  labelStyles?: React.CSSProperties;
  getUploadAsBase64?: (url: string) => Promise<string | null>;
}

export const RoutalAvatar = ({
  url,
  label,
  large,
  containerStyles = {},
  labelStyles = {},
  getUploadAsBase64,
}: RoutalAvatarProps) => {
  const [avatar, setAvatar] = useState<string | undefined>(undefined);

  useEffect(() => {
    (async () => {
      if (url && getUploadAsBase64) {
        const userAvatar = await getUploadAsBase64(url);
        if (userAvatar) setAvatar(userAvatar);
      } else {
        setAvatar(undefined);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAvatar, url]);

  const letters = label?.replace(/ /g, ``).substring(0, 2).toUpperCase();

  const XX = large ? RoutalAvatarContainerLarge : RoutalAvatarContainer;

  return (
    <XX style={{ ...containerStyles }}>
      {avatar ? (
        <img draggable="false" src={avatar} alt="user_image" style={{ width: `100%`, height: `auto` }} />
      ) : (
        <RoutalAvatarPlaceHolder>
          <RoutalText variant="body" style={{ color: `white`, fontWeight: 600, cursor: `pointer`, ...labelStyles }}>
            {letters}
          </RoutalText>
        </RoutalAvatarPlaceHolder>
      )}
    </XX>
  );
};
