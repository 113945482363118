import { BrowserTracing, Replay, init, reactRouterV6Instrumentation, setUser } from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import configuration from '../services/configuration';

export class SentryService {
  private initialized = false;

  init() {
    if (configuration.ENVIRONMENT !== `DEVELOPMENT`) {
      init({
        dsn: configuration.SENTRY_DSN,

        environment: configuration.ENVIRONMENT,

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 0.1,

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        ignoreErrors: [
          `ResizeObserver loop limit exceeded`,
          // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/14
          `Non-Error exception captured`,
          `Non-Error promise rejection captured`,
        ],

        integrations: [
          new BrowserTracing({
            routingInstrumentation: reactRouterV6Instrumentation(
              useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes
            ),
          }),
          new Replay({
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,
          }),
        ],
      });
      this.initialized = true;
    }
  }

  setUser(userData: Record<string, any> | null) {
    if (this.initialized) {
      setUser(userData);
    }
  }
}

export const sentryService = new SentryService();
