import { IntlShape } from 'react-intl';
import { useTranslationsContext } from '../../context/translations/context';

type UseRetrieveActiveLanguageResponse = {
  data?: IntlShape;
};

// Hooks act as 'Adapter' layer.
export const useRetrieveActiveLanguage = (): UseRetrieveActiveLanguageResponse => {
  const { translationsState } = useTranslationsContext();
  return {
    data: translationsState.activeLanguage,
  };
};
