import { SvgIcon } from '@mui/material';
import { RoutalPalette } from '../../Colors';

export const DropdownIcon = (props: any) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 16"
    style={{ width: `16px`, height: `16px`, color: RoutalPalette.secondary60, ...props.style }}
  >
    <path
      d="M13.1299 5.6665L8.46322 10.3332L3.79655 5.6665"
      stroke={props.style.color ?? RoutalPalette.secondary60}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
