import {
  CustomFieldsMapper,
  GPSTrackMapper,
  HexColor,
  LocationMapper,
  ReportMapper,
} from '@nx-smartmonkey/shared/domain';
import { ProjectCustomFields } from '../../domain/project/ProjectCustomFields';
import { Stop } from '../../domain/stop/Stop';
import { StopDTO } from '../../domain/stop/StopDTO';
import { CustomersSurveyMapper } from '../customersSurvey/CustomersSurveyMapper';

export class StopMapper {
  static toDomain(props: StopDTO, projectCustomFields: ProjectCustomFields): Stop {
    const {
      id,
      client_external_id,
      label,
      external_id,
      type,
      status,
      location,
      location_details,
      comments,
      reference_person,
      phone,
      email,
      url,
      custom_fields,
      weight,
      volume,
      time_windows,
      requires,
      duration,
      max_delivery_time,
      reward,
      cluster,
      planned_arrival_time,
      planned_departure_time,
      estimated_arrival_time,
      estimated_departure_time,
      driver_info,
      report,
      survey,
      pin,
      customer_text_field,
      execution_date,
      deleted,
      created_by,
      created_at,
      updated_at,
      deleted_at,
    } = props;

    const locationDomain = location ? LocationMapper.toDomain(location) : undefined;

    return Stop.create(
      {
        client_external_id,
        label,
        external_id,
        type,
        status,
        location: locationDomain,
        location_details,
        comments,
        reference_person,
        phone,
        email,
        url,
        custom_fields: custom_fields
          ? CustomFieldsMapper.toDomain(custom_fields, projectCustomFields.client)
          : undefined,
        weight,
        volume,
        time_windows,
        requires,
        duration,
        max_delivery_time,
        reward,
        cluster,
        planned_arrival_time,
        planned_departure_time,
        estimated_arrival_time,
        estimated_departure_time,
        driver_info: driver_info
          ? {
              id: driver_info.id,
              label: driver_info.label,
              color: driver_info.color ? HexColor.create(driver_info.color) : undefined,
              position: driver_info.position ? GPSTrackMapper.toDomain(driver_info.position) : undefined,
            }
          : undefined,
        report: report
          ? ReportMapper.toDomain(report, [
              ...projectCustomFields.service_report_completed,
              ...projectCustomFields.service_report_canceled,
              ...projectCustomFields.service_report_task_completed,
              ...projectCustomFields.service_report_task_canceled,
            ])
          : undefined,
        survey: survey ? CustomersSurveyMapper.toDomain(survey) : undefined,
        pin,
        customer_text_field,
        execution_date,
        deleted,
        created_by,
        created_at,
        updated_at,
        deleted_at,
      },
      id
    );
  }

  static toDTO(stop: Stop): StopDTO {
    return {
      id: stop.id,
      client_external_id: stop.client_external_id,
      label: stop.label,
      external_id: stop.external_id,
      type: stop.type,
      status: stop.status,
      location: stop.location ? LocationMapper.toDTO(stop.location) : undefined,
      location_details: stop.location_details,
      comments: stop.comments,
      reference_person: stop.reference_person,
      phone: stop.phone,
      email: stop.email,
      url: stop.url,
      custom_fields: stop.custom_fields,
      weight: stop.weight,
      volume: stop.volume,
      time_windows: stop.time_windows,
      requires: stop.requires,
      duration: stop.duration,
      max_delivery_time: stop.max_delivery_time,
      reward: stop.reward,
      cluster: stop.cluster,
      planned_arrival_time: stop.planned_arrival_time,
      planned_departure_time: stop.planned_departure_time,
      estimated_arrival_time: stop.estimated_arrival_time,
      estimated_departure_time: stop.estimated_departure_time,
      driver_info: stop.driver_info
        ? {
            id: stop.driver_info.id,
            label: stop.driver_info.label,
            color: stop.driver_info.color?.value,
            position: stop.driver_info.position ? GPSTrackMapper.toDTO(stop.driver_info.position) : undefined,
          }
        : undefined,
      report: stop.report ? ReportMapper.toDTO(stop.report) : undefined,
      survey: stop.survey ? CustomersSurveyMapper.toDTO(stop.survey) : undefined,
      pin: stop.pin,
      customer_text_field: stop.customer_text_field,
      execution_date: stop.execution_date,
      deleted: stop.deleted,
      created_by: stop.created_by,
      created_at: stop.created_at,
      updated_at: stop.updated_at,
      deleted_at: stop.deleted_at,
    };
  }
}
