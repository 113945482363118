import { StopsActionType } from '../../context/stops/actions';
import { useStopsContext } from '../../context/stops/context';

type UseSetSelectedStopsResponse = {
  run: (selectedStops: Array<string>) => void;
  data: Array<string>;
};

// Hooks act as 'Adapter' layer.
export const useSetSelectedStops = (): UseSetSelectedStopsResponse => {
  const { stopsState, stopsDispatch } = useStopsContext();

  const run = (selectedStops: Array<string>) => {
    stopsDispatch({
      type: StopsActionType.SET_SELECTED_STOPS,
      payload: {
        selectedStops,
      },
    });
  };

  return {
    run,
    data: stopsState.stops.selectedStops,
  };
};
