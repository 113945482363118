import { SvgIcon } from '@mui/material';
import { RoutalPalette } from '../../../new_components';

export default function InstructionIcon(props: any) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 14.17 14.17"
      style={{ width: `16px`, height: `16px`, color: RoutalPalette.secondary40, ...props.style }}
    >
      <g>
        <path d="M11.39,6.21c.15-2.09-1.21-3.99-3.23-4.54v-.49c.02-.59-.45-1.09-1.04-1.11s-1.09.45-1.11,1.04c0,.02,0,.05,0,.07v.49c-2.03.54-3.38,2.44-3.23,4.54v3.59l-1.44,1.44v.72h11.49v-.72l-1.44-1.44v-3.59ZM7.09,14.1c.1,0,.19,0,.29-.03.46-.1.85-.41,1.03-.85.07-.18.11-.37.11-.56h-2.87c0,.8.65,1.44,1.45,1.44h0Z" />
        <path d="M13.2,5.74c-.25-.07-.42-.32-.38-.58.19-1.45-.61-2.88-1.95-3.48-.27-.12-.39-.43-.27-.7.12-.27.43-.39.7-.27,1.77.78,2.83,2.67,2.57,4.59-.04.29-.31.5-.6.46-.03,0-.05,0-.07-.02Z" />
        <path d="M.9,5.75c-.29.04-.56-.17-.6-.46C.04,3.37,1.1,1.48,2.87.7c.27-.12.58,0,.7.27.12.27,0,.58-.27.7-1.34.59-2.14,2.02-1.95,3.48.04.27-.13.51-.38.58-.02,0-.05.01-.07.02Z" />
      </g>
    </SvgIcon>
  );
}
