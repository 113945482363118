import { InfoErrorActionType } from '../../context/infoError/actions';
import { useInfoErrorContext } from '../../context/infoError/context';
import { intl } from '../../helpers/IntGlobalProvider';

interface InfoErrorPayload {
  type?: `success` | `error` | `info` | `warning`;
  autoHideDuration?: number;
  messageIntlId?: string;
}

interface UseSnackbarMessageShowResponse {
  showSnackbarMessage: ({ type, autoHideDuration, messageIntlId }: InfoErrorPayload) => void;
}

// Hooks act as 'Adapter' layer.
export const useShowSnackbarMessage = (): UseSnackbarMessageShowResponse => {
  const { infoErrorDispatch } = useInfoErrorContext();

  const showSnackbarMessage = ({ type, autoHideDuration, messageIntlId }: InfoErrorPayload) => {
    infoErrorDispatch({
      type: InfoErrorActionType.INFOERROR_SEND_STATUS,
      payload: {
        type: type ?? `error`,
        message: intl.formatMessage({
          id: `${messageIntlId ?? `infoerror.network_error`}`,
        }),
        autoHideDuration: autoHideDuration ?? 5000,
      },
    });
  };

  return {
    showSnackbarMessage,
  };
};
