import {
  CustomFieldCategoricalMultiple,
  CustomFieldDefinition,
  CustomFieldDefinitionTypes,
  CustomFieldValues,
} from '@nx-smartmonkey/shared/domain';
import { RoutalSelectAutocomplete, RoutalSelectAutocompleteMultiSelectOption } from '@nx-smartmonkey/ui';
import { useIntl } from 'react-intl';
import FilterColumns from '../assets/img/filter_columns.svg';
import { Text } from '../react-components/Text';
import { CustomFieldFormBoolean } from './custom-fields/CustomFieldFormBoolean';
import { CustomFieldFormCategorical } from './custom-fields/CustomFieldFormCategorical';
import CustomFieldFormText from './custom-fields/CustomFieldFormText';

export const customFieldToTableValue = <T,>({
  doc,
  customField,
  customFieldDefinition,
  onOpenRightPanel,
}: {
  doc: T;
  customField: CustomFieldValues;
  customFieldDefinition: CustomFieldDefinition;
  onOpenRightPanel?: (event: any, stop: T) => any;
}) => {
  switch (customFieldDefinition.field_type) {
    case `text`:
    case `numerical`:
      return (
        <CustomFieldFormText
          key={customFieldDefinition.custom_id}
          projectField={customFieldDefinition}
          fieldValue={(customField || ``) as string}
          readOnly
          onlyValues
        />
      );
    case `boolean`:
      return (
        <CustomFieldFormBoolean
          key={customFieldDefinition.custom_id}
          projectField={customFieldDefinition}
          fieldValue={(customField || false) as boolean}
          readOnly
          onlyValues
        />
      );
    case `categorical`:
      if (customField && customFieldDefinition.multiple) {
        return (
          <Text
            tagName="span"
            variant="button"
            intlId="open_to_see"
            onClick={(e) => {
              if (onOpenRightPanel) {
                onOpenRightPanel(e, doc);
              }
            }}
          />
        );
      }
      return (
        <CustomFieldFormCategorical
          key={customFieldDefinition.custom_id}
          projectField={customFieldDefinition}
          fieldValue={(customField ? [customField] : []) as CustomFieldCategoricalMultiple}
          readOnly
          onlyValues
        />
      );
    default:
      return null;
  }
};

export const customFieldTypeToTableFormatter = (fieldType: CustomFieldDefinitionTypes) => {
  switch (fieldType) {
    case `boolean`:
      return `boolean`;
    case `numerical`:
      return `number`;
    case `categorical`:
      return `Array`;
    case `text`:
    default:
      return `string`;
  }
};

export type ColumnField = {
  id: string;
  label?: string;
  intlId?: string;
  customField: boolean;
  enabled: boolean;
};

interface TableColumnsButtonProps {
  id: string;
  disabled?: boolean;
  onChange: (columnField: ColumnField, value: boolean) => void;
  tableColumns: Array<ColumnField>;
}

export const TableColumnsButton = ({ id, disabled, onChange, tableColumns }: TableColumnsButtonProps) => {
  const intl = useIntl();

  /**
   * Disables the las active checkbox. There should be at least one active checkbox.
   * @param checkboxValue
   */
  const checkboxesDisabled = (checkboxValue: boolean) => {
    const enabledValues = tableColumns.filter((columnField: ColumnField) => columnField.enabled);
    return checkboxValue === true && enabledValues.length === 1;
  };

  if (!tableColumns) return null;

  return (
    <RoutalSelectAutocomplete
      id={id}
      disabled={disabled}
      style={{
        backgroundColor: `transparent`,
        paddingLeft: `8px`,
      }}
      hideSeparator
      containerStyle={{
        width: `unset`,
      }}
      searchWidth={250}
      searchPlaceholder={intl.formatMessage({ id: `search_columns` })}
      searchEmptyText={intl.formatMessage({ id: `no_results` })}
      popoverProps={{
        maxHeight: `500px`,
        options: tableColumns.map(
          (columnField: ColumnField): RoutalSelectAutocompleteMultiSelectOption => ({
            key: columnField.id,
            width: 250,
            checked: columnField.enabled,
            hideCheckIcon: false,
            closeOnClick: false,
            searchText: columnField.label ?? intl.formatMessage({ id: columnField.intlId }),
            disabled: (() => checkboxesDisabled(columnField.enabled))(),
            content: (
              <Text
                style={{
                  cursor: `pointer`,
                }}
              >
                {columnField.label ?? intl.formatMessage({ id: columnField.intlId })}
              </Text>
            ),
            onClick: (e: any, value: any) => {
              onChange(columnField, !columnField.enabled);
            },
          })
        ),
      }}
    >
      <img alt="" height="24px" src={FilterColumns} />
    </RoutalSelectAutocomplete>
  );
};
