import { SvgIcon } from '@mui/material';
import React from 'react';

export default function GoodInfo(props: any) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 36.85 36.85"
      style={{ width: `34px`, height: `34px`, color: `#CCCECF`, ...props.style }}
    >
      <path d="M34.38,35.35l-6.6-7.02c6.84-5.4,8.01-15.32,2.61-22.15C25-.66,15.08-1.83,8.24,3.57,1.4,8.96,.23,18.88,5.63,25.72c5.02,6.36,14.04,7.88,20.87,3.52l6.78,7.21c.31,.3,.8,.3,1.1,0s.3-.8,0-1.1h0Zm-16.47-5.12c-7.92,0-14.34-6.4-14.35-14.32S9.96,1.56,17.87,1.56c3.73,0,7.31,1.44,9.99,4.04-.34,.06-.57,.38-.51,.72,.05,.31,.32,.53,.63,.52h1.03c.78,.95,1.43,2,1.95,3.12h-2.33c-.35,.03-.6,.33-.58,.67,.02,.31,.27,.55,.58,.58h2.51c.11,0,.22-.03,.32-.09,.35,.99,.59,2.02,.72,3.06,0,.08,0,.16,.03,.23,.83,7.87-4.87,14.92-12.74,15.75-.51,.05-1.03,.08-1.55,.08Z" />
      <path d="M18.47,16.88c.25,0,.47-.15,.57-.37,.26-.55,.81-.91,1.42-.9,.35,.03,.65-.23,.67-.58,.03-.35-.23-.65-.58-.67-.03,0-.06,0-.1,0-1.1,0-2.1,.64-2.56,1.64-.14,.32,0,.69,.31,.83h0c.08,.04,.17,.06,.26,.06h0Z" />
      <path d="M20.46,19.98h.63c.35-.03,.6-.33,.58-.67-.02-.31-.27-.55-.58-.58h-.63c-.45,0-.88-.2-1.17-.54-.23-.26-.62-.28-.88-.05-.25,.22-.28,.61-.06,.87,.53,.61,1.3,.97,2.11,.97Z" />
      <path d="M10.76,14.34c-.52,0-.94,.42-.94,.94,0,.52,.42,.94,.94,.94,.52,0,.94-.42,.94-.94,0-.25-.1-.49-.27-.66-.18-.18-.42-.28-.67-.28Z" />
      <path d="M15.83,10.24h0c.23,.26,.62,.28,.88,.06,.26-.23,.29-.62,.07-.88-.4-.46-.5-1.12-.24-1.68,.13-.32-.02-.69-.34-.82-.31-.13-.66,0-.8,.3-.46,1-.29,2.18,.44,3.02h0Z" />
      <path d="M22.96,6.83h2.51c.35-.03,.6-.33,.58-.67-.02-.31-.27-.55-.58-.58h-2.51c-.35,.03-.6,.33-.58,.67,.02,.31,.27,.55,.58,.58Z" />
      <path d="M17.96,10.59c0,.35,.28,.63,.63,.63h2.51c.35,0,.63-.28,.63-.63,0-.35-.28-.63-.63-.63h-2.51c-.35,0-.63,.28-.63,.63Z" />
      <path d="M23.59,9.96c-.35,0-.63,.28-.63,.63,0,.35,.28,.63,.63,.63h2.5c.35,0,.63-.28,.63-.63,0-.35-.28-.63-.63-.63h-2.5Z" />
      <path d="M22.96,15.61h2.51c.35-.03,.6-.33,.58-.67-.02-.31-.27-.55-.58-.58h-2.51c-.35,.03-.6,.33-.58,.67,.02,.31,.27,.55,.58,.58Z" />
      <path d="M17.96,6.83h2.5c.35,.03,.65-.23,.67-.58,.03-.35-.23-.65-.58-.67-.03,0-.06,0-.1,0h-2.5c-.35-.03-.65,.23-.67,.58-.03,.35,.23,.65,.58,.67,.03,0,.06,0,.1,0Z" />
      <path d="M27.98,15.61h2.5c.35,0,.63-.28,.63-.63h0c0-.34-.28-.62-.63-.62h-2.5c-.35,0-.63,.28-.63,.63s.28,.63,.63,.63h0Z" />
      <path d="M28.87,19.18c-.29-.19-.68-.11-.87,.18s-.11,.68,.18,.87c.45,.29,.72,.78,.72,1.32,0,.08,0,.15,0,.23h0c-.05,.34,.19,.66,.53,.7h.1c.3,0,.56-.23,.6-.53,.02-.13,.03-.26,.03-.39h0c0-.96-.48-1.86-1.28-2.38Z" />
      <path d="M26.72,19.35c0-.35-.28-.62-.63-.62h-2.5c-.35-.03-.65,.23-.67,.58-.03,.35,.23,.65,.58,.67,.03,0,.06,0,.1,0h2.5c.34,0,.63-.28,.63-.62h0Z" />
      <path d="M17.96,23.11h-2.5c-.35-.03-.65,.23-.67,.58-.03,.35,.23,.65,.58,.67,.03,0,.06,0,.1,0h2.5c.35,0,.63-.28,.63-.63h0c0-.34-.28-.62-.62-.62h0Z" />
      <path d="M22.96,23.11h-2.5c-.35-.03-.65,.23-.67,.58-.03,.35,.23,.65,.58,.67,.03,0,.06,0,.1,0h2.5c.35,0,.63-.28,.63-.63h0c0-.34-.27-.62-.62-.62h-.01Z" />
      <path d="M28.14,24.24c.33-.1,.53-.44,.43-.78h0c-.1-.34-.45-.53-.78-.43h0c-.14,.04-.29,.06-.44,.06h-1.88c-.35,.03-.6,.33-.58,.67,.02,.31,.27,.55,.58,.58h1.88c.26,0,.53-.04,.78-.11h.02Z" />
      <path d="M12.95,23.1h-1.1l3.12-5.39c.87-1.51,.9-3.36,.07-4.89-.77-1.47-2.24-2.43-3.9-2.54-.24-.02-.49-.02-.73,0-1.65,.12-3.13,1.08-3.9,2.54-.83,1.53-.8,3.38,.07,4.89l3.65,6.33h0s.06,.08,.09,.12c.04,.03,.08,.06,.12,.09h.04s.07,.04,.1,.05h.04s.1,0,.15,0h2.2c.35,0,.63-.28,.63-.63s-.28-.63-.63-.63h0l-.02,.06Zm-4.38-7.82h0c0-1.21,.98-2.19,2.19-2.19s2.19,.98,2.19,2.19c0,1.21-.98,2.19-2.19,2.19-.58,0-1.14-.23-1.55-.64-.41-.41-.64-.97-.64-1.55Z" />
    </SvgIcon>
  );
}
