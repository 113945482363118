import { SvgIcon } from '@mui/material';
import { RoutalPalette } from '@nx-smartmonkey/ui';
import React from 'react';

interface RefreshProps {
  color?: string;
  style?: React.CSSProperties;
}

export const Refresh = ({ color = RoutalPalette.primary20, style = {}, ...props }: RefreshProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 15.41 15.05" style={{ width: `15px`, height: `15px`, ...style }}>
      <g>
        <path
          fill={color}
          d="m.22,7.52C.22,3.62,3.38.46,7.28.45c2.58,0,4.95,1.4,6.19,3.66l.12-.26c.18-.42.67-.62,1.09-.44.42.18.62.67.44,1.09,0,.01-.01.03-.02.04l-.94,2.08c-.19.41-.67.6-1.08.42l-1.97-.83c-.43-.16-.64-.65-.48-1.07s.65-.64,1.07-.48c.02,0,.04.01.05.02l.17.07c-1.53-2.56-4.85-3.39-7.41-1.85-2.56,1.53-3.39,4.85-1.85,7.41s4.85,3.39,7.41,1.85c1.18-.71,2.05-1.84,2.42-3.17.1-.45.55-.73.99-.63.45.1.73.55.63.99,0,.03-.01.06-.02.08-1.06,3.76-4.96,5.95-8.72,4.89C2.33,13.47.22,10.69.22,7.52Z"
        />
      </g>
    </SvgIcon>
  );
};
