import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';

import * as Popper from '@popperjs/core';
import ca from 'date-fns/locale/ca';
import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';
import DatePicker, { registerLocale } from 'react-datepicker';
import styled from 'styled-components';

import { LanguageType } from '@nx-smartmonkey/shared/domain';
import { RoutalPalette } from '@nx-smartmonkey/ui';
import { ar, fr } from 'date-fns/locale';
import { forwardRef, useRef } from 'react';
import { Text } from '../Text';

registerLocale(`es`, es);
registerLocale(`es-CA`, ca);
registerLocale(`en-US`, en);
registerLocale(`pt`, pt);
registerLocale(`de`, ar);
registerLocale(`fr`, fr);

interface StyledDatePickerInputProps {
  inputFocusBorderColor?: string;
}

const StyledDatePickerInput = styled.input`
  border-radius: 4px;
  border: 1px solid ${RoutalPalette.grey3};
  outline: none;
  cursor: pointer;
  font-size: 13px;
  padding: 4px 8px;
  font-family: 'Geist Variable';
  min-height: 26px;
  display: block;
  width: calc(100% - 20px);
  margin-top: 4px;
  &:focus {
    ${(props: StyledDatePickerInputProps) =>
      props.inputFocusBorderColor && `border-color: ${props.inputFocusBorderColor};`}
  }
`;

const DatePickerInput = forwardRef<HTMLInputElement, { inputFocusBorderColor?: string }>((props, forwardedRef) => (
  <StyledDatePickerInput type="text" ref={forwardedRef} {...props} />
));

interface InputDatePickerProps {
  portalId?: string;
  dateValue?: Date | null;
  labelIntlId?: string;
  required?: boolean;
  minDate?: Date;
  locale?: LanguageType;
  popperPlacement?: Popper.Placement | undefined;
  hasTime?: boolean;
  inputFocusBorderColor?: string;
  onChange: (date: Date) => void;
}

export const InputDatePicker = ({
  portalId,
  dateValue,
  labelIntlId,
  required = false,
  minDate,
  locale = `es`,
  popperPlacement,
  hasTime,
  inputFocusBorderColor,
  onChange,
}: InputDatePickerProps) => {
  const customInputRef = useRef<HTMLInputElement>(null); // Ref needed for custom input

  const getDatePickerLocale = () => {
    switch (locale) {
      case `en`:
        return `en-US`;
      default:
        return locale;
    }
  };

  const getDatePickerFormat = () => {
    switch (locale) {
      case `en`:
        return `MM/dd/yyyy ${hasTime ? `h:mm aa` : ``}`;
      default:
        return `dd/MM/yyyy ${hasTime ? `h:mm aa` : ``}`;
    }
  };

  return (
    <>
      {labelIntlId ? (
        <Text tagName="span" style={{ marginBottom: `4px` }}>
          <>
            <Text tagName="span" variant="label" intlId={labelIntlId} />
            {required ? (
              <Text tagName="span" variant="body" style={{ cursor: `default`, color: RoutalPalette.error.medium }}>
                *
              </Text>
            ) : null}
          </>
        </Text>
      ) : null}

      <DatePicker
        selected={dateValue}
        onChange={(date) => {
          if (date) {
            onChange(date);
          }
        }}
        minDate={minDate}
        locale={getDatePickerLocale()}
        customInput={<DatePickerInput inputFocusBorderColor={inputFocusBorderColor} ref={customInputRef} />}
        popperPlacement={popperPlacement}
        popperModifiers={[
          {
            name: `arrow`,
            options: {
              padding: ({ popper, reference, placement }) => ({
                right: Math.min(popper.width, reference.width) - 24,
              }),
            },
          },
        ]}
        portalId={portalId}
        dateFormat={getDatePickerFormat()}
        {...(hasTime ? { showTimeInput: true, timeInputLabel: `Time:`, timeFormat: `h:mm aa` } : {})}
        // calendarClassName="custom-date-picker-styles"
      />
    </>
  );
};
