import { Grid } from '@mui/material';
import { validatePhoneNumber } from '@nx-smartmonkey/shared/helpers';
import { HSpacer, RoutalInputPhone, RoutalInputPhoneValue, RoutalPalette, VSpacer } from '@nx-smartmonkey/ui';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';

import PinField from 'react-pin-field';
import LogoSM from '../assets/img/horizontal_logo_black.svg';
import AuthBackground from '../assets/img/login_background_small.png';
import { Refresh } from '../assets/svg/Refresh';
import { LoaderPage } from '../components/LoaderPage';
import * as routing from '../constants/Routing';
import { intl } from '../helpers/IntGlobalProvider';
import { useRetrieveProject } from '../hooks/project/useRetrieveProject';
import { useRetrieveSessionCountry } from '../hooks/session/useRetrieveSessionCountry';
import { useSessionLoginCode } from '../hooks/session/useSessionLoginCode';
import { useSessionLoginPhone } from '../hooks/session/useSessionLoginPhone';
import { Button } from '../react-components/Button';
import { Text } from '../react-components/Text';

interface AuthBackgroundContainerProps {
  image?: string;
}

const AuthBackgroundContainer = styled.div<AuthBackgroundContainerProps>`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  ${(props) => {
    return `background-image: url(${props.image});`;
  }}
`;

const RESEND_CODE_TIMEOUT_SECONDS = 30;

const ResendButton = ({ primaryColor, onClick }: { primaryColor?: string; onClick: any }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!isVisible) {
      setTimeout(() => {
        setIsVisible(true);
      }, RESEND_CODE_TIMEOUT_SECONDS * 1000);
    }
  }, [isVisible]);

  return (
    <Button
      ripple
      variant="text"
      style={{
        border: `none`,
        display: isVisible ? `flex` : `none`,
      }}
      onClick={() => {
        setIsVisible(false);
        onClick();
      }}
    >
      <Refresh color={primaryColor} />
      <HSpacer small />
      <Text variant="button" intlId="auth.resend_code" style={{ color: primaryColor ?? RoutalPalette.secondary40 }} />
    </Button>
  );
};

const StyledPinField = styled(PinField)<{ primaryColor: string }>`
  flex-direction: row;
  align-content: space-around;
  justify-content: center;
  flex-wrap: nowrap;

  width: 30px;
  height: 45px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  margin-right: 3px;
  margin-left: 3px;
  color: ${RoutalPalette.grey11};
  box-shadow: 0px 0px 6px #00000029;
  user-select: none;
  border-radius: 2px;
  text-align: center;

  :focus {
    outline: none;
    box-shadow: inset ${(props) => props.primaryColor ?? RoutalPalette.primary20} 0px 0px 0px 2px !important;
    border-radius: 2px;
    margin-right: 1px;
  }

  @media (max-width: 320px) {
    margin-right: 1px;
    margin-left: 1px;
    width: 27px;
  }
  @media (max-width: 270px) {
    margin-right: 1px;
    margin-left: 1px;
    width: 20px;
  }

  :nth-of-type(1) {
    margin-left: 0px;
  }
  :nth-last-of-type(1) {
    margin-right: 0px;
  }
`;

const formatPhoneNumber = (phone: string) => {
  const newPhone = phone.replace(/[^\d]+/g, ``);
  return `+${newPhone}`;
};

export const Auth = () => {
  const { data: project } = useRetrieveProject();
  const [phone, setPhone] = useState<string>(``);
  const [otp, setOtp] = useState<string>(``);

  const { countryCode } = useRetrieveSessionCountry();

  const {
    isLoading,
    isCodePending,
    run: login,
  } = useSessionLoginPhone({
    projectId: project?.id ?? ``,
    phone: formatPhoneNumber(phone),
  });

  const {
    isLogged,
    isLoading: codeIsLoading,
    error: codeError,
    run: validateCode,
  } = useSessionLoginCode({
    otpCode: otp,
  });
  const handlePaste: React.ClipboardEventHandler = (event) => {
    const data = event.clipboardData.getData(`text`);
    setOtp(data);
  };

  useEffect(() => {
    if (otp.length === 6) {
      validateCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  useEffect(() => {
    if (codeError) {
      setOtp(``);
    }
  }, [codeError]);

  const onResendCode = () => {
    login();
  };

  if (isLogged) return <Navigate to={routing.ROOT} />;

  return (
    <div
      style={{
        position: `absolute`,
        top: 0,
        height: `100%`,
        width: `100%`,
      }}
    >
      <Grid
        container
        style={{
          height: `100%`,
          width: `100%`,
        }}
        wrap="nowrap"
        sx={{ flexDirection: { xs: `column`, md: `row` }, rowGap: { xs: `16px`, md: 0 } }}
      >
        <Grid item style={{ height: `100%`, width: `100%` }}>
          {isLoading || codeIsLoading ? (
            <LoaderPage />
          ) : (
            <Grid item container justifyContent="center" alignItems="center" style={{ height: `100%` }}>
              <Grid
                item
                container
                wrap="nowrap"
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{
                  height: `100%`,
                  zIndex: 1,
                  padding: `0 20px`,
                  maxWidth: `400px`,
                }}
              >
                <Grid item container justifyContent="center" alignItems="center">
                  {project?.style?.logo ? (
                    <img height="70px" src={project?.style?.logo} alt="" draggable="false" />
                  ) : (
                    <img height="40px" src={LogoSM} alt="" draggable="false" />
                  )}
                </Grid>

                <VSpacer large />

                <Grid
                  item
                  container
                  wrap="nowrap"
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    maxHeight: `220px`,
                    padding: `20px`,
                    border: `1px solid ${RoutalPalette.grey7}`,
                    borderRadius: `4px`,
                  }}
                >
                  <Grid item style={{ width: `100%` }}>
                    {!isCodePending ? (
                      <>
                        <Grid container direction="row" justifyContent="center">
                          <RoutalInputPhone
                            countryCallingCodeEditable={false}
                            focusInputOnCountrySelection
                            international
                            style={{
                              width: `100%`,
                            }}
                            numberInputProps={{
                              autoComplete: `off`,
                              required: true,
                              name: `phoneNumber`,
                              autoFocus: true,
                              style: {
                                width: `100%`,
                                height: `34px`,
                                fontSize: `16px`,
                                backgroundColor: RoutalPalette.neutral00,
                                borderTopLeftRadius: `0`,
                                borderBottomLeftRadius: `0`,
                              },
                            }}
                            inputFocusBorderColor={project?.style?.primary_color?.value}
                            placeholder={intl.formatMessage({ id: `auth.phone_ph` })}
                            defaultCountry={
                              project?.location?.country_code
                                ? project?.location?.country_code.toUpperCase()
                                : countryCode
                            }
                            value={phone as RoutalInputPhoneValue}
                            onChange={(value?: RoutalInputPhoneValue) => {
                              setPhone(value ?? ``);
                            }}
                            onKeyPress={(event: { key: string }) => {
                              if (
                                event.key === `Enter` &&
                                !isLoading &&
                                validatePhoneNumber(formatPhoneNumber(phone))
                              ) {
                                login();
                              }
                            }}
                          />
                        </Grid>
                        <VSpacer medium />
                      </>
                    ) : (
                      <>
                        <Grid item style={{ display: `flex`, placeContent: `space-around center` }}>
                          <StyledPinField
                            className="pin-field"
                            validate="0123456789"
                            inputMode="numeric"
                            onPaste={handlePaste}
                            onChange={setOtp}
                            autoComplete="one-time-code"
                            length={6}
                            autoFocus
                            style={{}}
                            primaryColor={project?.style?.primary_color?.value ?? RoutalPalette.primary20}
                          />
                        </Grid>

                        <VSpacer medium />

                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          style={{
                            height: `42px`,
                          }}
                        >
                          <ResendButton
                            primaryColor={project?.style?.primary_color?.value}
                            onClick={() => onResendCode()}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Grid container direction="column" alignItems="center" justifyContent="flex-end">
                    <Grid item style={{ width: `100%` }}>
                      {!isCodePending ? (
                        <Button
                          ripple
                          intlId="auth.login"
                          styleType="secondary"
                          style={{
                            fontSize: `16px`,
                            fontWeight: 600,
                            height: `34px`,
                            padding: `6px 14px`,
                          }}
                          disabled={isLoading || !validatePhoneNumber(formatPhoneNumber(phone))}
                          onClick={() => login()}
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item style={{ height: `100%`, width: `100%` }}>
          <AuthBackgroundContainer image={project?.style?.banner_image ?? AuthBackground}>
            {project?.style?.banner_image ? null : (
              <Text
                intlId="supervisors.auth.welcome"
                style={{
                  color: RoutalPalette.white,
                  width: `100%`,
                  textAlign: `center`,
                  zIndex: 1,
                  padding: `50px`,
                  fontSize: `2.5vh`,
                }}
                isBold
              />
            )}
          </AuthBackgroundContainer>
        </Grid>
      </Grid>
    </div>
  );
};
