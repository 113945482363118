const BASE64_MARKER = `;base64,`;

export const convertDataURIToBinary = (dataURI: string): Uint8Array => {
  const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  const base64 = dataURI.substring(base64Index);
  const raw = window.atob(base64);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i += 1) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
};

/**
 * Returns the size of the file in a human readable format.
 * @param {*} size
 * @param {*} decimals
 */
export function formatBytes(size: number, decimals = 2) {
  if (!+size) return `0 Bytes`;
  const c = 0 > decimals ? 0 : decimals,
    d = Math.floor(Math.log(size) / Math.log(1024));
  return `${parseFloat((size / Math.pow(1024, d)).toFixed(c))} ${
    [`Bytes`, `KiB`, `MiB`, `GiB`, `TiB`, `PiB`, `EiB`, `ZiB`, `YiB`][d]
  }`;
}

/**
 * Resizes an image if needed to fit within the specified maximum width and height.
 * @param blob - The image blob to resize.
 * @param maxWidth - The maximum width of the resized image (default: 1024).
 * @param maxHeight - The maximum height of the resized image (default: 1024).
 * @returns A promise that resolves with the resized image blob.
 */
export function resizeImageIfNeeded(blob: Blob, maxWidth = 1024, maxHeight = 1024): Promise<Blob> {
  return new Promise((resolve, reject) => {
    // Create an img element
    const img = new Image();

    // Create an object URL and set it as the src of the img
    img.src = URL.createObjectURL(blob);

    img.onload = () => {
      // Get the dimensions of the loaded image
      const width = img.width;
      const height = img.height;

      // Calculate the scaling factor to keep aspect ratio
      const scalingFactor = Math.min(maxWidth / width, maxHeight / height);

      // If the blob size is less than 1MB or scaling factor is >= 1, no need to resize
      if (blob.size <= 1024 * 1024 || scalingFactor >= 1) {
        resolve(blob);
        return;
      }

      // Calculate the new dimensions
      const newWidth = width * scalingFactor;
      const newHeight = height * scalingFactor;

      // Create a canvas element and set its dimensions
      const canvas = document.createElement(`canvas`);
      canvas.width = newWidth;
      canvas.height = newHeight;

      // Draw the image onto the canvas
      const ctx = canvas.getContext(`2d`);
      if (ctx === null) {
        reject(new Error(`Failed to create canvas context CTX`));
        return;
      }
      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      // Convert the canvas back to a blob
      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error(`Failed to convert canvas to blob`));
          }
        },
        `image/jpeg`,
        1
      );
    };

    img.onerror = reject;
  });
}

/**
 * Checks if the image must be resized or not. Resizes it if needed using canvas.
 * @param {*} file
 * @param {*} maxSize
 */
export async function resizeImageFile(file: Blob, maxSize: number): Promise<Blob | Uint8Array> {
  // MUST/COULD THIS FUNCTION RETURN A REJECT??
  return new Promise((resolve: any) => {
    if (file.size > maxSize) {
      const img = new Image();
      const compressFactor = maxSize / file.size;
      const urlCreator = window.URL || window.webkitURL;

      img.onload = () => {
        const canvas = document.createElement(`canvas`);
        const ctx = canvas.getContext(`2d`);

        if (img.height > img.width) {
          // Resizing the image width with the compression factor.
          canvas.height = img.height * compressFactor;

          // set size proportional to image
          canvas.width = canvas.height * (img.width / img.height);
        } else {
          // Resizing the image width with the compression factor.
          canvas.width = img.width * compressFactor;

          // set size proportional to image
          canvas.height = canvas.width * (img.height / img.width);
        }

        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Converting to data:base64
        const data = canvas.toDataURL(file.type);

        // Converting to binary Uint8Array
        const binaryFile = convertDataURIToBinary(data);

        resolve(binaryFile);
      };
      img.src = urlCreator.createObjectURL(file);
    } else {
      resolve(file);
    }
  });
}

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener(`load`, () => resolve(image));
    image.addEventListener(`error`, (error) => reject(error));
    image.setAttribute(`crossOrigin`, `anonymous`); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

const getRadianAngle = (degreeValue: number) => {
  return (degreeValue * Math.PI) / 180;
};

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export type IArea = {
  width: number;
  height: number;
  x: number;
  y: number;
};

export const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: IArea,
  imageType?: string,
  rotation = 0
): Promise<Blob | null> => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement(`canvas`);
  const ctx = canvas.getContext(`2d`) as any;

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(file);
    }, imageType ?? `image/jpeg`);
  });
};

export const arrayBufferToImageSrcBase64 = async ({
  data,
  dataType,
}: {
  data: Uint8Array;
  dataType: string;
}): Promise<string> => {
  const image = await arrayBufferToBase64(data);
  return `data:${dataType};base64,${image}`;
};

export const arrayBufferToBase64 = (buffer: any) => {
  let binary = ``;
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const imageBlobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(`${reader.result}`);
    reader.readAsDataURL(blob);
  });
};

/**
 * ShoutOut to Neil McCallion for the base code - https://codepen.io/njmcode/pen/pvLyZq
 *
 * Determines if an image is dark based on its pixel values.
 * @param image - The HTMLImageElement representing the image.
 * @param threshold - The threshold value to determine darkness. Pixels with average brightness below this threshold are considered dark.
 * @param fuzz - The level of fuzziness to consider when calculating average brightness. Higher values increase the range of brightness considered.
 * @param c - The HTMLCanvasElement used for drawing the image.
 * @param ctx - The CanvasRenderingContext2D used for manipulating the canvas.
 * @param height - The height of the canvas, if you want to limit the area of comparison.
 * @param width - The width of the canvas, if you want to limit the area of comparison .
 * @returns True if the image is dark, false otherwise.
 */
export function isImageDark(
  image: HTMLImageElement,
  threshold = 127,
  fuzz = 1,
  c: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  height?: number,
  width?: number
) {
  if (fuzz < 0 || fuzz % 2 !== 0) fuzz = 1;

  const limitHeight = height || image.height;
  const limitWidth = width || image.width;

  c.width = limitWidth;
  c.height = limitHeight;
  ctx.drawImage(image, 0, 0);

  const data = ctx.getImageData(image.width / 2 - c.width, image.height / 2 - c.height, c.width, c.height).data;
  let totalGrey = 0;

  for (let i = 0, ln = data.length, inc = 4 * fuzz; i < ln; i += inc) {
    const r = data[i],
      g = data[i + 1],
      b = data[i + 2],
      grey = Math.floor((r + g + b) / 3);

    totalGrey += grey;
  }

  const area = (c.width * limitHeight) / (2 * fuzz),
    lightLevel = Math.floor(totalGrey / area);
  return lightLevel < threshold;
}
