import { ServiceResponse } from '@nx-smartmonkey/shared/hooks';
import { useQuery } from '@tanstack/react-query';

import { sessionService } from '../../application/SessionService';

type UseSessionLogoutResponseProps = ServiceResponse<() => void, void>;

// Hooks act as 'Adapter' layer.
export const useSessionLogout = (isPublic?: string): UseSessionLogoutResponseProps => {
  const {
    isInitialLoading: isLoading,
    isSuccess,
    isError,
    refetch,
  } = useQuery([`supervisors_session_logout`], () => sessionService.deleteSessionToken(), {
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
  });

  if (!isPublic) {
    return {
      success: false,
      error: false,
      isLoading: false,
      run: refetch,
    };
  }

  return {
    success: isSuccess,
    error: isError,
    isLoading,
    run: () => {
      sessionService.deleteSessionToken();
    },
  };
};
