import { SearchFilter } from '../filter';
import { SearchFilterProps } from '../filter/SearchFilter';

export type SearchFilterNumberOperator = `gt` | `lt` | `eq` | `ne` | `unknown` | `known`;
export type SearchFilterNumberValue = number | null;

interface SearchFilterNumberProps extends SearchFilterProps {
  field: string;
  operator: SearchFilterNumberOperator;
  value: SearchFilterNumberValue;

  // - "greater than" => gt
  // - "less than" => lt
  // - "is" => eq
  // - "is not" => ne
  // - "is unknown" => unknown (value es null)
  // - "has any value" => known (value es null)
}

export class SearchFilterNumber extends SearchFilter {
  constructor(props: SearchFilterNumberProps) {
    super({ ...props, type: `number` });
  }
}
