import styled from 'styled-components';

import { RoutalPalette } from '@nx-smartmonkey/ui';
import React from 'react';
import { Text } from './Text';

interface StyledTimePickerInputProps {
  inputFocusBorderColor?: string;
}

const StyledTimePickerInput = styled.input<StyledTimePickerInputProps>`
  border-radius: 4px;
  border: 1px solid ${RoutalPalette.grey3};
  outline: none;
  cursor: pointer;
  font-size: 13px;
  padding: 4px 8px;
  font-family: 'Geist Variable';
  min-height: 26px;
  display: block;
  width: calc(100% - 20px);
  margin-top: 4px;
  &:focus {
    ${(props: StyledTimePickerInputProps) =>
      props.inputFocusBorderColor && `border-color: ${props.inputFocusBorderColor};`}
  }
`;

interface InputTimePickerProps {
  timeValue?: any;
  labelIntlId?: string;
  required?: boolean;
  inputStyles?: React.CSSProperties;
  inputFocusBorderColor?: string;
  onChange: (time: string) => void;
}

export const InputTimePicker = ({
  timeValue,
  labelIntlId,
  required = false,
  inputStyles = {},
  inputFocusBorderColor,
  onChange,
}: InputTimePickerProps) => {
  return (
    <>
      {labelIntlId ? (
        <Text tagName="span" style={{ marginBottom: `4px` }}>
          <>
            <Text tagName="span" variant="label" intlId={labelIntlId} />
            {required ? (
              <Text tagName="span" variant="body" style={{ cursor: `default`, color: RoutalPalette.error.medium }}>
                *
              </Text>
            ) : null}
          </>
        </Text>
      ) : null}

      <StyledTimePickerInput
        style={inputStyles}
        type="time"
        value={timeValue}
        inputFocusBorderColor={inputFocusBorderColor}
        onChange={(evt: any) => onChange(evt.target.value)}
      />
    </>
  );
};
