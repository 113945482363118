import { Grid } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { RoutalLoader, RoutalTheme } from '@nx-smartmonkey/ui';
import { useEffect, useState } from 'react';
import { RawIntlProvider } from 'react-intl';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { FaviconManager } from '../components/FavIcon';
import { SnackbarComponent } from '../components/SnackbarComponent';
import * as routes from '../constants/Routing';
import { intl } from '../helpers/IntGlobalProvider';
import { useRetrieveProject } from '../hooks/project/useRetrieveProject';
import { retrieveNavigatorLanguage, useRetrieveTranslation } from '../hooks/translations/useRetrieveTranslation';
import { Auth } from './Auth';
import { PrivateComponent } from './PrivateComponent';
import { SupervisorsAdmin } from './SupervisorsAdmin';

export const Supervisors = () => {
  const { activeLanguage, isLoading: isRetrievingTranslation, run: retrieveTranslation } = useRetrieveTranslation();
  const { data: project, isLoading, error: projectError } = useRetrieveProject();
  const [defaultIntl, setDefaultIntl] = useState(intl);

  useEffect(() => {
    if (activeLanguage) {
      setDefaultIntl(activeLanguage);
    }
  }, [activeLanguage]);

  if (projectError) {
    window.open(`https://routal.com/404`, `_self`);
  }

  if (!activeLanguage && !isRetrievingTranslation) {
    const language = retrieveNavigatorLanguage();
    retrieveTranslation({ language });
  }

  return (
    <ThemeProvider theme={RoutalTheme}>
      <RawIntlProvider value={defaultIntl}>
        <FaviconManager project={project!} />

        <SnackbarComponent />
        {!activeLanguage || isLoading ? (
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{
              position: `absolute`,
              top: 0,
              height: `100%`,
              width: `100%`,
            }}
            wrap="nowrap"
          >
            <Grid item container alignItems="center" justifyContent="center">
              <RoutalLoader color={project?.style?.primary_color?.value} />
            </Grid>
          </Grid>
        ) : (
          <Router>
            <Routes>
              <Route path={routes.ADMIN} element={<SupervisorsAdmin />} />
              <Route path={routes.EXAMPLE} element={<PrivateComponent />} />
              <Route path={routes.AUTH} element={<Auth />} />
              <Route path={routes.ROOT} element={<PrivateComponent />} />
              <Route path={routes.LOGOUT} element={<Navigate replace to={routes.AUTH} />} />
              <Route path={routes.NOMATCHES} element={<Navigate replace to={routes.AUTH} />} />
            </Routes>
          </Router>
        )}
      </RawIntlProvider>
    </ThemeProvider>
  );
};
