import { SearchFilter } from '../filter';
import { SearchFilterProps } from '../filter/SearchFilter';

export type SearchFilterDateTimeOperator = `lt` | `eq` | `gt` | `unknown` | `known`;
export type SearchFilterDateTimeValue = string | null;

interface SearchFilterDateTimeProps extends SearchFilterProps {
  field: string;
  operator: SearchFilterDateTimeOperator;
  value: SearchFilterDateTimeValue;

  // - "after" => gt (value es un objecte amb day, month i year)
  // - "on" => eq (value es un objecte amb day, month i year)
  // - "before" => lt (value es un objecte amb day, month i year)
  // - "is unknown" => unknown (value es null)
  // - "has any value" => known (value es null)
}

export class SearchFilterDateTime extends SearchFilter {
  constructor(props: SearchFilterDateTimeProps) {
    super({ ...props, type: `date_time` });
  }

  public isValueValid(): boolean {
    if (this.getValue() === undefined) {
      return false;
    }

    return true;
  }
}
