import { Grid, Radio } from '@mui/material';

import { dateFormatLongDate } from '@nx-smartmonkey/shared/helpers';
import { RoutalPalette } from '@nx-smartmonkey/ui';
import { intl } from '../../../helpers/IntGlobalProvider';
import { InputTimePicker } from '../../../react-components/InputTimePicker';
import { Text } from '../../../react-components/Text';
import { SearchFilterComponent } from '../filter';
import { SearchFilterOperators } from '../filter/SearchFilter';
import { SearchFilterTime } from './SearchFilterTime';

interface GetSearchFilterTimeOptionsProps {
  operator: SearchFilterOperators;
  text: string;
  hasValue: boolean;
  isSelected: boolean;
}

const getSearchFilterTimeOptions = (filter: SearchFilterTime): GetSearchFilterTimeOptionsProps[] => [
  {
    operator: `gt`,
    text: intl.formatMessage({ id: `search_filters.gt` }),
    hasValue: true,
    isSelected: filter.isOperator(`gt`),
  },
  {
    operator: `lt`,
    text: intl.formatMessage({ id: `search_filters.lt` }),
    hasValue: true,
    isSelected: filter.isOperator(`lt`),
  },
  {
    operator: `eq`,
    text: intl.formatMessage({ id: `search_filters.eq` }),
    hasValue: true,
    isSelected: filter.isOperator(`eq`),
  },
  {
    operator: `unknown`,
    text: intl.formatMessage({ id: `search_filters.unknown` }),
    hasValue: false,
    isSelected: filter.isOperator(`unknown`),
  },
  {
    operator: `known`,
    text: intl.formatMessage({ id: `search_filters.known` }),
    hasValue: false,
    isSelected: filter.isOperator(`known`),
  },
];

interface SearchFilterTimeComponentProps {
  filter: SearchFilterTime;
  filterPosition: number;
  colors: {
    primaryColor: string;
    secondaryColor: string;
  };
  onChange: (filter: SearchFilterTime) => void;
  onRemoveFilter: () => void;
}

export const SearchFilterTimeComponent = ({
  filter,
  filterPosition,
  colors,
  onChange,
  onRemoveFilter,
}: SearchFilterTimeComponentProps) => {
  return (
    <SearchFilterComponent
      filter={filter}
      buttonId={`filter-time-${filterPosition}`}
      colors={colors}
      onRemoveFilter={onRemoveFilter}
      customResult={(filter) => {
        return (
          <Text
            style={{
              fontWeight: `normal`,
              cursor: `pointer`,
              color: filter.isValueValid() ? undefined : `${RoutalPalette.error.medium}`,
            }}
            intlId={`search_filters.${filter.getOperator()}.result`}
            intlValues={{
              field: intl.formatMessage({ id: `search_filters.filter.${filter.getField()}` }),
              value: filter.isValueValid()
                ? `"${dateFormatLongDate(filter.getValue())}"`
                : `"${intl.formatMessage({ id: `search_filters.missing_value` })}"`,
            }}
          />
        );
      }}
    >
      {getSearchFilterTimeOptions(filter).map((option) => {
        return (
          <Grid container direction="column" wrap="nowrap" style={{ padding: `0 16px`, minWidth: `250px` }}>
            <Grid
              item
              container
              direction="row"
              wrap="nowrap"
              alignItems="center"
              style={{
                cursor: `pointer`,
              }}
              onClick={(event: any) => {
                event.stopPropagation();
                filter.setOperator(option.operator);
                filter.setValue(new Date());
                onChange(filter);
              }}
            >
              <Grid item>
                <Radio
                  style={{
                    color: colors.primaryColor,
                  }}
                  checked={option.isSelected}
                  onChange={(event: any) => {
                    event.stopPropagation();
                    filter.setOperator(option.operator);
                    filter.setValue(new Date());
                    onChange(filter);
                  }}
                />
              </Grid>

              <Grid item>
                <Text style={{ cursor: `pointer` }}>{option.text}</Text>
              </Grid>
            </Grid>

            {option.isSelected && option.hasValue ? (
              <Grid item>
                <InputTimePicker
                  timeValue={filter.getValue()}
                  onChange={(date: string) => {
                    filter.setValue(date);
                    onChange(filter);
                  }}
                />
              </Grid>
            ) : null}
          </Grid>
        );
      })}
    </SearchFilterComponent>
  );
};
