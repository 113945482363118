/* eslint-disable no-shadow */
import axios, { AxiosInstance, AxiosResponse, ResponseType } from 'axios';

interface FetchProps {
  uri: string;
  responseType?: ResponseType;
  params?: Record<string, any>;
  headers?: Record<string, any>;
  ignoreAsUser?: boolean;
}

interface MutateProps {
  uri: string;
  data?: Record<string, any>;
  headers?: Record<string, any>;
  ignoreAsUser?: boolean;
}

interface DeleteProps {
  uri: string;
  headers?: Record<string, any>;
  ignoreAsUser?: boolean;
}

export interface ErrorMiddleWareProps {
  codes: number[];
  callback: ({ code, error }: { code: number; error: any }) => void;
}

export class Requests {
  private axios: AxiosInstance;
  private asUserId: string | undefined;

  async get({ uri, params, responseType, headers, ignoreAsUser = false }: FetchProps) {
    return this.axios.get(uri, {
      params: { ...(params ?? {}), ...(this.asUserId !== undefined && !ignoreAsUser && { as_user: this.asUserId }) },
      ...(responseType ? { responseType } : {}),
      headers,
    });
  }

  async post({ uri, data, headers, ignoreAsUser = false }: MutateProps) {
    return this.axios.post(uri, data, {
      headers,
      ...(this.asUserId !== undefined && !ignoreAsUser && { params: { as_user: this.asUserId } }),
    });
  }

  async put({ uri, data, headers, ignoreAsUser = false }: MutateProps) {
    return this.axios.put(uri, data, {
      headers,
      ...(this.asUserId !== undefined && !ignoreAsUser && { params: { as_user: this.asUserId } }),
    });
  }

  async delete({ uri, headers, ignoreAsUser = false }: DeleteProps) {
    return this.axios.delete(uri, {
      headers,
      ...(this.asUserId !== undefined && !ignoreAsUser && { params: { as_user: this.asUserId } }),
    });
  }

  async multipleAsyncWait({
    requests = [],
    data = [],
  }: {
    requests?: Array<
      | {
          method: ({ uri, params, responseType, headers }: FetchProps) => Promise<AxiosResponse>;
          params: FetchProps;
        }
      | {
          method: ({ uri, data, headers }: MutateProps) => Promise<AxiosResponse>;
          params: MutateProps;
        }
      | {
          method: ({ uri, headers }: DeleteProps) => Promise<AxiosResponse>;
          params: DeleteProps;
        }
    >;
    data?: any;
  }): Promise<any[]> {
    if (requests instanceof Array && requests.length > 0) {
      try {
        return await requests[0].method(requests[0].params).then(async (response) => {
          const jsonData = response.data;
          return await this.multipleAsyncWait({ requests: requests.slice(1), data: [...data, ...[jsonData]] });
        });
      } catch (error) {
        return await this.multipleAsyncWait({ requests: requests.slice(1), data: [...data, ...[null]] });
      }
    }
    return data;
  }

  // In this case if there is any error, return the error instead of null.
  // At this moment is only used for duplicate plans. Maybe it should replace multipleAsyncWait function.
  async multipleAsyncWaitWithErrors({
    requests = [],
    data = [],
  }: {
    requests?: Array<
      | {
          method: ({ uri, params, responseType, headers }: FetchProps) => Promise<AxiosResponse>;
          params: FetchProps;
        }
      | {
          method: ({ uri, data, headers }: MutateProps) => Promise<AxiosResponse>;
          params: MutateProps;
        }
      | {
          method: ({ uri, headers }: DeleteProps) => Promise<AxiosResponse>;
          params: DeleteProps;
        }
    >;
    data?: any;
  }): Promise<any[]> {
    if (requests instanceof Array && requests.length > 0) {
      try {
        return await requests[0].method(requests[0].params).then(async (response) => {
          const jsonData = response.data;
          return await this.multipleAsyncWait({ requests: requests.slice(1), data: [...data, ...[jsonData]] });
        });
      } catch (error) {
        return await this.multipleAsyncWait({ requests: requests.slice(1), data: [...data, ...[error]] });
      }
    }
    return data;
  }

  setAsUserId(asUserId: string | undefined) {
    this.asUserId = asUserId;
  }

  constructor(baseUrl: string) {
    this.axios = axios.create({ baseURL: baseUrl });
  }

  static create(baseUrl: string): Requests {
    return new Requests(baseUrl);
  }
}
