import { PropsWithChildren, useReducer } from 'react';

import { ProjectContext } from './context';
import { ProjectReducer, getInitialState } from './reducer';

export const ProjectContextProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const initialState = getInitialState();
  const [state, dispatch] = useReducer(ProjectReducer, initialState);
  return (
    <ProjectContext.Provider
      value={{
        projectState: state,
        projectDispatch: dispatch,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
