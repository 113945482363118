import { SvgIcon } from '@mui/material';
import React from 'react';

export default function BadCondition(props: any) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 36.85 36.85"
      style={{ width: `34px`, height: `34px`, color: `#CCCECF`, ...props.style }}
    >
      <g>
        <path d="M34.45,3H2.45C1.33,3,.42,3.91,.42,5.03v5.93c0,1.12,.91,2.03,2.03,2.03h.57V31.96c0,1.12,.91,2.03,2.03,2.03H31.9c1.12,0,2.03-.91,2.03-2.03V13h.52c1.12,0,2.03-.91,2.03-2.03V5.03c0-1.12-.91-2.03-2.03-2.03Zm-2.52,28.96s-.02,.03-.03,.03l-26.88-.03V13H31.93V31.96Zm2.55-21s-.01,.03-.03,.03l-32.04-.03,.03-5.96,32.04,.03v5.93Z" />
        <circle cx="13.05" cy="17.53" r="1.79" />
        <circle cx="23.8" cy="17.53" r="1.79" />
        <path d="M9.25,28.74c-.27,0-.54-.11-.74-.33-.37-.41-.34-1.04,.06-1.41,.65-.59,3.01-2.58,6.26-3.32,2.39-.54,4.9-.54,7.28,.02,2.85,.66,5.12,2.22,6.2,3.35,.38,.4,.37,1.03-.03,1.41-.4,.38-1.03,.37-1.41-.03-.96-1-2.94-2.25-5.21-2.78-2.11-.49-4.26-.5-6.39-.01-2.77,.63-4.8,2.34-5.36,2.84-.19,.18-.43,.26-.67,.26Z" />
      </g>
    </SvgIcon>
  );
}
