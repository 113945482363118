import { TableCell, TableSortLabel } from '@mui/material';
import { withStyles } from '@mui/styles';
import { RoutalPalette } from '@nx-smartmonkey/ui';
import { DataTableColumnProps, SortDirections } from './DataTable';

const styles = () => ({
  tableHead: {
    backgroundColor: RoutalPalette.neutral00,
    fontSize: `14px`,
    textTransform: `capitalize!important` as any,
    color: RoutalPalette.grey7,
    fontWeight: 500,
  },
  tableCell: {
    padding: `6px 12px 6px 12px`,
    zIndex: `unset` as any,
    // whiteSpace: `nowrap` as any,
    // overflow: `hidden`,
    // textOverflow: `ellipsis`,
  },
});

type DataTableHeaderCellProps = {
  classes: {
    tableCell: string;
    tableHead: string;
  };
  column: DataTableColumnProps;
  loading?: boolean;
  active?: boolean;
  sortDirection?: SortDirections;
  onColumnSort?: (column: string, order: `asc` | `desc`) => any;
};

const DataTableHeaderCell = ({
  classes,
  column,
  active,
  sortDirection,
  onColumnSort,
  loading,
}: DataTableHeaderCellProps) => {
  const toggleOrder = () => {
    if (!sortDirection || sortDirection === `asc`) {
      return `desc`;
    }
    return `asc`;
  };
  return (
    <TableCell
      component="div"
      className={classes.tableCell}
      classes={{ head: classes.tableHead }}
      align={column.align}
      style={{
        padding: `8px`,
        ...(column.columnStyle ?? {}),
      }}
    >
      {column.sortable === true ? (
        <TableSortLabel
          active={active ?? false}
          direction={sortDirection}
          disabled={loading}
          onClick={() => {
            if (column.accessorName && onColumnSort) {
              onColumnSort(column.accessorName, toggleOrder());
            }
          }}
        >
          {column.name}
        </TableSortLabel>
      ) : (
        <>{column.name}</>
      )}
    </TableCell>
  );
};

export default withStyles(styles)(DataTableHeaderCell);
