import tinycolor from 'tinycolor2';

// const OLD_colors = [
//   '#2196F3',
//   '#CDDC39',
//   '#d2190b',
//   '#607D8B',
//   '#af94e8',
//   '#795548',
//   '#FFEB3B',
//   '#ace4fe',
//   '#2830C9',
//   '#009688',
//   '#F44336',
//   '#673AB7',
//   '#D39C11',
//   '#9927C9',
//   '#2E8938',
//   '#FFC107',
//   '#02BCD4',
//   '#511414',
//   '#F906CB',
//   '#5B96FF',
//   '#2b387c',
//   '#8BC34A',
//   '#ffb8a2',
//   '#d1d077',
//   '#f4df96',
// ];

type IColorOptions = {
  modifiers?: number[];
  colors?: string[];
};

const defaultColorModifiers = [0, 25, 15, 7, -7, -15, -25];

export const defaultColors = [
  `#00A7FF`,
  // '#A1E000',
  `#D2910F`,
  `#C02178`,
  `#00A5E0`,
  `#776E69`,
  `#B5405B`,
  `#558B87`,
  `#94A33E`,
  `#7B30B0`,
  `#9B5746`,
  `#3E54A3`,
  `#8D3F81`,
  `#4070A0`,
  `#00E0D5`,
  `#BB5C3A`,
  `#5F54A0`,
  `#636C92`,
  `#7B6569`,
];

const defaultOptions = {
  colors: defaultColors,
  modifiers: defaultColorModifiers,
};

export const getColorByIndex = (index: number, options?: IColorOptions) => {
  const { colors, modifiers } = { ...defaultOptions, ...options };

  const colorModifiers = modifiers.length === 0 || modifiers[0] !== 0 ? [0, ...modifiers] : modifiers;

  const moduleIndex = index % (colorModifiers.length * colors.length);

  const colorIndex = moduleIndex % colors.length;
  const modifierIndex = Math.abs(moduleIndex / colors.length);

  const tColor = tinycolor(colors[colorIndex]);

  const cAmount = colorModifiers[modifierIndex];

  const newColor =
    cAmount > 0 ? tColor.darken(Math.abs(cAmount)).toString() : tColor.lighten(Math.abs(cAmount)).toString();

  return newColor;
};

export const SMPalette = {
  label: `#AAAAAA`,
  primaryColor: `#F8671D`,
  secondaryColor: `#393939`,
  button: {
    primary: {
      defaultColor: `#F8671D`,
      hoverColor: `#DB7000`,
    },
    secondary: {
      defaultColor: `#393939`,
      hoverColor: `#292929`,
    },
    danger: {
      defaultColor: `#D2190B`,
      hoverColor: `#D2190B`,
    },
  },
  success: `#70D292`,
  warning: `#FFB300`,
  danger: `#E03030`,
  statusColors: {
    pending: `#F2F2F2`,
    completed: `#70D292`,
    incomplete: `#FFB300`,
    canceled: `#E03030`,
  },
  text: `#393939`,
  white: `#FFFFFF`,
  grey1: `#717679`,
  grey2: `#F2F5F7`,
  grey3: `#CACACA`,
  grey4: `#E0E0E0`,
  grey5: `#D4D1D1`,
  grey6: `#F2F2F2`,
  grey7: `#AAAAAA`,
  grey8: `#D2D2D2`,
  grey9: `#EEEEEE`,
  grey10: `#FBFBFB`,
  grey11: `#777777`,
  grey12: `#DBDBDB`,
  grey13: `#707070`,
};
