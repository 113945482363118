import { GoogleMapProps, MarkerDomainTypes } from '@nx-smartmonkey/ui';
import { memo, useMemo } from 'react';

import { StopFlat } from '../../../domain/stop/StopFlat';
import { useRetrieveActiveLanguage } from '../../../hooks/translations/useRetrieveActiveLanguage';
import { Map } from '../../../react-components/Map';

interface StopsMapMapProps extends Omit<GoogleMapProps, `apiKey`> {
  flatStops?: StopFlat[];
  selectedStopsIds?: string[];
}

const StopsMapMapComponent = ({ flatStops = [], selectedStopsIds = [], ...mapProps }: StopsMapMapProps) => {
  const { data: activeLanguage } = useRetrieveActiveLanguage();
  const getMarkers = useMemo(
    () => [
      ...flatStops
        .filter((flatStop) => {
          if (!flatStop.isValidLocation()) {
            return false;
          }

          return true;
        })
        .map((stop: StopFlat) => ({
          id: stop.id,
          label: stop.label,
          selected: selectedStopsIds.includes(stop.id),
          lat: stop.lat!,
          lng: stop.lng!,
          kind: stop.type === `delivery` ? `default` : (`pickup` as MarkerDomainTypes),
          color: stop.color?.value,
        })),
    ],
    [selectedStopsIds, flatStops]
  );

  return (
    <Map
      height={500}
      center={{
        lat: 41.3952165,
        lng: 2.1258438,
      }}
      zoomLevel={11}
      language={activeLanguage?.locale ?? `en`}
      markers={getMarkers}
      {...mapProps}
    />
  );
};

export const StopsMapMap = memo(StopsMapMapComponent);
