import { Grid } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { RoutalPalette } from './Colors';

type ToggleOptions = `left` | `right`;

const ToggleContainer = styled(Grid)`
  display: flex;
  height: 36px;
  padding: 4px;
  margin: 2px;
  width: fit-content;
  background-color: ${RoutalPalette.neutral00};
  border-radius: 100px;
`;

interface ToggleElementProps {
  selected?: boolean;
  selectedBackground?: string;
}

const ToggleElement = styled(Grid)<ToggleElementProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
  max-width: 50px;
  color: ${(props) => (props.selected ? `white` : `black`)};
  background-color: ${(props) =>
    props.selected ? (props.selectedBackground ? props.selectedBackground : RoutalPalette.secondary60) : `transparent`};
  padding: 6px;
  cursor: ${(props) => (props.selected ? `default` : `pointer`)};
  border-radius: 100px;
`;

interface RoutalToggleProps {
  onChange: (option: ToggleOptions) => void;
  value: ToggleOptions;
  containerStyles?: React.CSSProperties;
  leftChild: React.ReactNode;
  rightChild: React.ReactNode;
}

export const RoutalToggle = ({ onChange, value, containerStyles, leftChild, rightChild }: RoutalToggleProps) => {
  return (
    <ToggleContainer
      style={{
        ...containerStyles,
      }}
    >
      <ToggleElement
        onClick={() => {
          onChange(`left`);
        }}
        selected={value === `left`}
      >
        {leftChild}
      </ToggleElement>

      <div style={{ minWidth: `4px` }} />

      <ToggleElement
        onClick={() => {
          onChange(`right`);
        }}
        selected={value === `right`}
      >
        {rightChild}
      </ToggleElement>
    </ToggleContainer>
  );
};
