import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Snackbar as MuiSnackbar, SnackbarContent as MuiSnackbarContent } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';

import ErrorIcon from './assets/svg/snackbar-icons/ErrorIcon';
import InfoIcon from './assets/svg/snackbar-icons/InfoIcon';
import SuccessIcon from './assets/svg/snackbar-icons/SuccessIcon';
import WarningIcon from './assets/svg/snackbar-icons/WarningIcon';

const styles = (_theme: any) => ({
  snackbar: {
    boxShadow: `none`,
    borderRadius: `2px`,
  },

  error: {
    backgroundColor: `#E03030!important`,
  },

  success: {
    backgroundColor: `#70D292!important`,
  },

  info: {
    backgroundColor: `#393939!important`,
  },

  warning: {
    backgroundColor: `#F7E70A!important`,
    color: `#393939`,
  },

  message: {
    display: `flex`,
    alignItems: `center`,
  },

  flex: {
    flex: 1,
  },
});

export type VariantTypes = `success` | `error` | `info` | `warning`;

const variantIcon = {
  success: SuccessIcon,
  error: ErrorIcon,
  info: InfoIcon,
  warning: WarningIcon,
} as Record<VariantTypes, any>;

interface SnackbarProps {
  open?: boolean;
  message: string | React.ReactElement;
  classes: {
    snackbar: string;
    error: string;
    success: string;
    message: string;
    info: string;
    warning: string;
    flex: string;
  };
  style?: React.CSSProperties;
  variant?: VariantTypes;
  autoHideDuration?: number;
  closeByClickAway?: boolean;
  onClose?: (...args: any[]) => any;
}

const Snackbar = ({
  open,
  message,
  classes,
  style,
  variant = `success`,
  autoHideDuration = 1000,
  closeByClickAway = false,
  onClose,
}: SnackbarProps) => {
  const handleClose = (event: any, reason?: string) => {
    if (reason === `clickaway` && closeByClickAway) return;

    if (onClose) {
      onClose(event);
    }
  };

  const Icon = variantIcon[variant]; // <img height="22" width="22" style={{ marginRight: `8px` }} src={variantIcon[variant]} alt="" draggable="false" />;

  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: `bottom`,
        horizontal: `left`,
      }}
      style={style ?? {}}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <MuiSnackbarContent
        className={classNames(classes.snackbar, classes[variant])}
        classes={{ message: classes.flex }}
        message={
          <span className={classes.message}>
            <Icon style={{ marginRight: `8px`, color: variant === `warning` ? `#393939` : `white` }} />
            {message || `no message provided`}
          </span>
        }
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </MuiSnackbar>
  );
};

export default withStyles(styles)(Snackbar);
