import { Language } from '@nx-smartmonkey/shared/domain';
import { sessionRepository } from '../repositories/session';
import { SessionRepository } from '../repositories/session/SessionRepository';
import { sentryService, SentryService } from './SentryService';

export class SessionService {
  constructor(private sessionRepository: SessionRepository, private sentryService: SentryService) {}

  isLogged(): boolean {
    return this.sessionRepository.isLogged();
  }

  initializeAdmin({ token, asSupervisor }: { token: string; asSupervisor: string }) {
    this.sessionRepository.initializeAdmin({ token, asSupervisor });
  }

  getAsSupervisor(): string | undefined {
    return this.sessionRepository.getAsSupervisor();
  }

  isCodePending(): boolean {
    return this.sessionRepository.isCodePending();
  }

  createSessionToken({ projectId, phone }: { projectId: string; phone: string }): Promise<string> {
    return this.sessionRepository.createSessionToken({ projectId, phone });
  }

  verifySessionToken({ otpCode }: { otpCode: string }): Promise<string> {
    return this.sessionRepository.verifySessionToken({ otpCode });
  }

  deleteSessionToken(): Promise<void> {
    this.sentryService.setUser(null);
    return this.sessionRepository.deleteSessionToken();
  }

  async getCountry(): Promise<string> {
    const countryCode = await this.sessionRepository.getCountry();
    return countryCode;
  }

  setLanguage({ language }: { language: Language }): void {
    this.sessionRepository.setLanguage({ language });
  }

  getLanguage(): Language | undefined {
    return this.sessionRepository.getLanguage();
  }
}

export const sessionService = new SessionService(sessionRepository, sentryService);
