import { createContext, useContext } from 'react';
import { InfoErrorActions } from './actions';
import { InfoErrorState } from './reducer';

export const InfoErrorContext = createContext({
  infoErrorState: {} as InfoErrorState,
  infoErrorDispatch: (action: InfoErrorActions) => {},
});

InfoErrorContext.displayName = `InfoErrorContext`;

export const useInfoErrorContext = () => useContext(InfoErrorContext);
