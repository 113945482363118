import './RoutalInputPhone.css';

import { CountryCode } from 'libphonenumber-js';
import { useEffect } from 'react';
import PhoneInput, { DefaultInputComponentProps, FeatureProps, Value } from 'react-phone-number-input';
import { RoutalPalette } from './Colors';
export { getCountryCallingCode } from 'react-phone-number-input';

const INPUT_ID = `input-phone`;

export type RoutalInputPhoneValue = Value;

type InputPhoneProps = Omit<FeatureProps<DefaultInputComponentProps>, `defaultCountry`> & {
  id?: string;
  value: RoutalInputPhoneValue;
  defaultCountry?: string;
  placeholder?: string;
  inputFocusBorderColor?: string;
  // onCountryChange?: (country: CountryCode | undefined) => void;
  // onBlur?(event: React.FocusEvent<HTMLElement>): void;
  onChange: (value?: RoutalInputPhoneValue) => void;
};

export const RoutalInputPhone = ({
  id = INPUT_ID,
  disabled,
  value,
  defaultCountry,
  placeholder,
  inputFocusBorderColor,
  onCountryChange,
  onBlur,
  onChange,
  ...other
}: InputPhoneProps) => {
  useEffect(() => {
    if (inputFocusBorderColor) {
      const inputPhone = document.getElementById(id);
      if (inputPhone) {
        inputPhone.style.borderColor = inputFocusBorderColor ?? RoutalPalette.secondary00;

        inputPhone.addEventListener(`focus`, function () {
          this.style.borderColor = inputFocusBorderColor ?? RoutalPalette.secondary60;
        });
        inputPhone.addEventListener(`blur`, function () {
          this.style.borderColor = `${RoutalPalette.secondary00}`;
        });
      }
    }
  }, [id, inputFocusBorderColor]);

  return (
    <PhoneInput
      {...other}
      id={id}
      disabled={disabled}
      placeholder={placeholder}
      defaultCountry={defaultCountry ? (defaultCountry.toUpperCase() as CountryCode) : undefined}
      value={value}
      onCountryChange={onCountryChange}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
};
