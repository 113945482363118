import { CustomFieldDefinition } from '@nx-smartmonkey/shared/domain';
import { useEffect, useState } from 'react';
import { ColumnField, TableColumnsButton, customFieldTypeToTableFormatter } from '../../components/TableColumnsButton';
import {
  CUSTOM_FIELD_COLUMN_ID_PREFIX,
  REPORT_CANCELED_CUSTOM_FIELD_COLUMN_ID_PREFIX,
  REPORT_COMPLETED_CUSTOM_FIELD_COLUMN_ID_PREFIX,
} from '../../constants';
import { TableColumn } from '../../context/shared/tableColumns';
import { STOPS_DEFAULT_TABLE_COLUMNS, StopsTableColumn } from '../../context/stops/tableColumns';
import { Stop } from '../../domain/stop/Stop';
import { useRetrieveProject } from '../../hooks/project/useRetrieveProject';
import { useSetStopsColumns } from '../../hooks/stop/useSetStopsColumns';
import { useRetrieveSupervisor } from '../../hooks/supervisor/useRetrieveSupervisor';

type StopsListColumnsButtonProps = {
  id: string;
  disabled?: boolean;
};

export const StopsListColumnsButton = ({ id, disabled }: StopsListColumnsButtonProps) => {
  const { run: setTableColumns, tableColumns } = useSetStopsColumns();
  const { data: project } = useRetrieveProject();
  const { data: supervisor } = useRetrieveSupervisor();
  const [columns, setColumns] = useState<ColumnField[]>([]);

  useEffect(() => {
    const defaultIdentifiers = STOPS_DEFAULT_TABLE_COLUMNS.map((tableColumn) => tableColumn.identifier);

    // Setting all the default column fields (excluding the custom fields).
    const defaultColumns = tableColumns
      .filter((tableColumn: StopsTableColumn) => {
        if (tableColumn.identifier.includes(CUSTOM_FIELD_COLUMN_ID_PREFIX)) {
          return false;
        }

        if (!defaultIdentifiers.includes(tableColumn.identifier)) {
          return false;
        }

        switch (tableColumn.identifier) {
          case `report_location`:
            return (
              supervisor?.isStopCompletedFieldEnabled(`location`) || supervisor?.isStopCanceledFieldEnabled(`location`)
            );
          case `report_created_at`:
            return (
              supervisor?.isStopCompletedFieldEnabled(`created_at`) ||
              supervisor?.isStopCanceledFieldEnabled(`created_at`)
            );
          case `report_comments`:
            return (
              supervisor?.isStopCompletedFieldEnabled(`comments`) || supervisor?.isStopCanceledFieldEnabled(`comments`)
            );
          case `report_images`:
            return (
              supervisor?.isStopCompletedFieldEnabled(`images`) || supervisor?.isStopCanceledFieldEnabled(`images`)
            );
          case `report_signature`:
            return supervisor?.isStopCompletedFieldEnabled(`signature`);
          case `report_cancel_reason`:
            return supervisor?.isStopCanceledFieldEnabled(`cancel_reason`);
          case `survey`:
            return (
              supervisor?.isSurveyFieldEnabled(`rating`) ||
              supervisor?.isSurveyFieldEnabled(`comments`) ||
              supervisor?.isSurveyFieldEnabled(`rating_tag`)
            );
          case `vehicle_label`:
            return supervisor?.isStopFieldEnabled(`driver_info`);
          case `status`:
          case `type`:
          case `customers_link`:
          case `created_at`:
            return true;
          default:
            return supervisor?.isStopFieldEnabled(tableColumn.identifier);
        }
      })
      .map((tableColumn: StopsTableColumn) => ({
        id: tableColumn.identifier,
        customField: false,
        intlId: `stop.${tableColumn.identifier}`,
        enabled: tableColumn.displayable,
      }));

    // SETTING THE PROJECT CUSTOM FIELDS
    const stopCustomFieldsColumns = (project?.custom_fields?.client ?? [])
      .filter((cF) => {
        if (!cF.enabled) {
          return false;
        }
        if (!supervisor?.isStopFieldEnabled(cF.custom_id)) {
          return false;
        }
        return true;
      })
      .map((field: CustomFieldDefinition) => {
        const customFieldId = `${CUSTOM_FIELD_COLUMN_ID_PREFIX}${field.custom_id}`;

        const tableColumn = tableColumns.find(
          (tableColumn: StopsTableColumn) => tableColumn.identifier === customFieldId
        );
        return {
          id: customFieldId,
          label: `${field.label}`,
          customField: true,
          // Looks into the context data if custom field is stored. Otherwise, initializes it to not enabled.
          enabled: tableColumn?.displayable ?? false,
        };
      });

    // SETTING THE PROJECT REPORT COMPLETED CUSTOM FIELDS
    const reportCompletedCustomFieldsColumns = (project?.custom_fields?.service_report_completed ?? [])
      .filter((cF) => {
        if (!cF.enabled) {
          return false;
        }
        if (!supervisor?.isStopCompletedFieldEnabled(cF.custom_id)) {
          return false;
        }
        return true;
      })
      .map((field: CustomFieldDefinition) => {
        const customFieldId = `${REPORT_COMPLETED_CUSTOM_FIELD_COLUMN_ID_PREFIX}${field.custom_id}`;

        const tableColumn = tableColumns.find(
          (tableColumn: StopsTableColumn) => tableColumn.identifier === customFieldId
        );
        return {
          id: customFieldId,
          label: `${field.label}`,
          customField: true,
          // Looks into the context data if custom field is stored. Otherwise, initializes it to not enabled.
          enabled: tableColumn?.displayable ?? false,
        };
      });

    // SETTING THE PROJECT REPORT CANCELED CUSTOM FIELDS
    const reportCanceledCustomFieldsColumns = (project?.custom_fields?.service_report_canceled ?? [])
      .filter((cF) => {
        if (!cF.enabled) {
          return false;
        }
        if (!supervisor?.isStopCanceledFieldEnabled(cF.custom_id)) {
          return false;
        }
        return true;
      })
      .map((field: CustomFieldDefinition) => {
        const customFieldId = `${REPORT_CANCELED_CUSTOM_FIELD_COLUMN_ID_PREFIX}${field.custom_id}`;

        const tableColumn = tableColumns.find(
          (tableColumn: StopsTableColumn) => tableColumn.identifier === customFieldId
        );
        return {
          id: customFieldId,
          label: `${field.label}`,
          customField: true,
          // Looks into the context data if custom field is stored. Otherwise, initializes it to not enabled.
          enabled: tableColumn?.displayable ?? false,
        };
      });

    setColumns([
      ...defaultColumns,
      ...stopCustomFieldsColumns,
      ...reportCompletedCustomFieldsColumns,
      ...reportCanceledCustomFieldsColumns,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setColumns, tableColumns, supervisor, project]);

  return (
    <TableColumnsButton
      id={id}
      disabled={disabled}
      tableColumns={columns}
      onChange={(columnField: ColumnField, value: boolean) => {
        let columnFound = false;

        const newColumnsObject = tableColumns.map((tableColumn: StopsTableColumn) => {
          if (tableColumn.identifier === columnField.id) {
            columnFound = true;
            return {
              ...tableColumn,
              displayable: value,
            };
          }
          return tableColumn;
        }) as TableColumn<any, Stop>[];

        if (!columnFound) {
          const stopCustomFieldDefinition = (project?.custom_fields?.client ?? []).find(
            (customFieldsDefinition) =>
              customFieldsDefinition.custom_id === columnField.id.replace(CUSTOM_FIELD_COLUMN_ID_PREFIX, ``)
          );
          if (stopCustomFieldDefinition) {
            const type = customFieldTypeToTableFormatter(stopCustomFieldDefinition.field_type);
            newColumnsObject.push({
              identifier: columnField.id,
              type,
              displayable: value,
              filterable: false,
              sortable: type !== `Array`,
              // TODO: CREATE THE SORT FUNCTION FOR CASAES OF TYPE CARTEGORICAL
              // sort?: (a: Stop, b: Stop) => {
              //   return
              // };
            });
          }

          const reportCompletedCustomFieldDefinition = (project?.custom_fields?.service_report_completed ?? []).find(
            (customFieldsDefinition) =>
              customFieldsDefinition.custom_id ===
              columnField.id.replace(REPORT_COMPLETED_CUSTOM_FIELD_COLUMN_ID_PREFIX, ``)
          );
          if (reportCompletedCustomFieldDefinition) {
            const type = customFieldTypeToTableFormatter(reportCompletedCustomFieldDefinition.field_type);
            newColumnsObject.push({
              identifier: columnField.id,
              type,
              displayable: value,
              filterable: false,
              sortable: type !== `Array`,
              // TODO: CREATE THE SORT FUNCTION FOR CASAES OF TYPE CARTEGORICAL
              // sort?: (a: Stop, b: Stop) => {
              //   return
              // };
            });
          }

          const reportCanceledCustomFieldDefinition = (project?.custom_fields?.service_report_canceled ?? []).find(
            (customFieldsDefinition) =>
              customFieldsDefinition.custom_id ===
              columnField.id.replace(REPORT_CANCELED_CUSTOM_FIELD_COLUMN_ID_PREFIX, ``)
          );
          if (reportCanceledCustomFieldDefinition) {
            const type = customFieldTypeToTableFormatter(reportCanceledCustomFieldDefinition.field_type);
            newColumnsObject.push({
              identifier: columnField.id,
              type,
              displayable: value,
              filterable: false,
              sortable: type !== `Array`,
              // TODO: CREATE THE SORT FUNCTION FOR CASAES OF TYPE CARTEGORICAL
              // sort?: (a: Stop, b: Stop) => {
              //   return
              // };
            });
          }
        }
        setTableColumns(newColumnsObject);
      }}
    />
  );
};
