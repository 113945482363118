import { parseSecondsToHM } from '@nx-smartmonkey/shared/helpers';

export const parseTimewindowToExcel = (value: [number, number]): string => {
  if (!value) {
    return ``;
  }
  return `${parseSecondsToHM(value[0])}-${parseSecondsToHM(value[1])}`;
};

export const parseTimewindowsToExcel = (value: [number, number][]) => {
  return value.map((tw) => parseTimewindowToExcel(tw)).join(`, `);
};

export const parseStringArray = (array?: string[]) => {
  return (array ?? []).reduce((p: any, n: any, i: any) => (i !== (array ?? []).length - 1 ? `${p + n}, ` : p + n), ``);
};
