import { Project } from '../../domain/project/Project';
import { ProjectActions, ProjectActionType } from './actions';

export interface ProjectState {
  error: boolean;
  isLoading: boolean;
  project?: Project;
}

export const getInitialState = (): ProjectState => ({
  error: false,
  isLoading: false,
  project: undefined,
});

export const ProjectReducer = (state: ProjectState, action: ProjectActions): ProjectState => {
  switch (action.type) {
    case ProjectActionType.FETCH_PROJECT:
      return {
        error: false,
        isLoading: false,
        project: action.payload,
      };
    case ProjectActionType.FETCH_PROJECT_FAILURE:
      return { ...state, error: true, isLoading: false };
    case ProjectActionType.LOADING_PROJECT:
      return { ...state, error: false, isLoading: true };
    default:
      return state;
  }
};
