import { Grid, Pagination, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Checkbox, RoutalPalette } from '@nx-smartmonkey/ui';
import React from 'react';

import DataTableHeaderCell from './DataTableHeaderCell';
import { DataTableRow } from './DataTableRow';

const LOADER_NUM_FAKE_ROWS = 5;

export interface DataTableColumnProps {
  columnStyle?: React.CSSProperties;
  valueCellStyle?: React.CSSProperties;
  width?: number | string;
  height?: number;
  align?: AlignmentTypes;
  sortable?: boolean;
  name?: string | React.ReactElement;
  accessorName?: string;
  flex?: number;
  loadingContent?: React.ReactElement;
  formatter?: ({ value, doc, index, rowHover }: { value: any; doc: any; index: number; rowHover: boolean }) => any;
}

export type SortDirections = `asc` | `desc`;

export type AlignmentTypes = `left` | `right` | `inherit` | `center` | `justify`;

type DocItem = {
  id: string;
  [key: string]: any;
};

type Document = {
  doc: DocItem;
  checkboxIcon?: {
    element?: React.ReactElement;
    style?: React.CSSProperties;
  };
};

type DataTableProps = {
  docs?: Array<Document>;
  noFooter?: boolean;
  loading?: boolean;
  page?: number;
  pages?: number;
  limit?: number;
  total: number;
  sortBy?: string;
  sortDirection?: SortDirections;
  columns: Array<DataTableColumnProps>;
  emptyMessage?: React.ReactElement | string;
  maxHeight?: string;
  selectedIds?: Array<string>;
  hover?: boolean;
  showCheckboxes?: boolean;
  showRowCheckboxAlways?: boolean;
  globalCheckboxColumnStyles?: React.CSSProperties;
  initialSortBy?: string;
  initialSortOrder?: `asc` | `desc`;
  onChangePage?: (props: { offset: number; limit: number }) => void;
  onColumnSort?: (column: string, order: `asc` | `desc`) => any;
  onRowDoubleClick?: (...args: any[]) => any;
  onBodyMouseEnter?: (...args: any[]) => any;
  onBodyMouseLeave?: (...args: any[]) => any;
  onRowMouseEnter?: (...args: any[]) => any;
  onRowMouseLeave?: (...args: any[]) => any;
  handleOnContextMenu?: (...args: any[]) => any;
  onRowSelect?: (...args: any[]) => any;
  onMultipleSelection?: (props: { selectedIds?: Array<string>; fromGlobalCheckbox?: boolean }) => void;
};

export const DataTable = ({
  docs,
  page,
  pages,
  limit,
  total,
  columns,
  loading,
  noFooter,
  emptyMessage,
  maxHeight,
  sortBy,
  sortDirection,
  selectedIds = [],
  hover = false,
  showCheckboxes = true,
  showRowCheckboxAlways = false,
  globalCheckboxColumnStyles,
  initialSortBy,
  initialSortOrder,
  onChangePage,
  onColumnSort,
  onRowDoubleClick,
  onBodyMouseEnter,
  onBodyMouseLeave,
  onRowMouseEnter,
  onRowMouseLeave,
  handleOnContextMenu,
  onRowSelect,
  onMultipleSelection,
}: DataTableProps) => {
  /**
   * Check if an element is already selected
   * @param {*} index
   */
  const isSelected = (doc: DocItem) => selectedIds.includes(doc.id);

  /**
   * Function is called when a single row is selected
   * @param {DocItem} doc
   */
  const handleRowSelect = (doc: DocItem) => {
    if (onRowSelect) {
      onRowSelect(doc);
      if (onMultipleSelection) {
        const newSelection = [doc];
        onMultipleSelection({ selectedIds: newSelection.map((d: DocItem) => d.id) });
      }
    }
  };

  /**
   * This function is called when the checkbox is clicked
   * @param {DocItem} doc
   */
  const handleCheckboxSelect = (doc: DocItem) => {
    let newSelection = [];
    if ((selectedIds ?? []).find((selectedId: string) => selectedId === doc.id) !== undefined) {
      newSelection = (selectedIds ?? []).filter((selectedId: string) => selectedId !== doc.id);
    } else {
      newSelection = [...(selectedIds ?? []), doc.id];
    }
    if (onMultipleSelection) {
      onMultipleSelection({ selectedIds: newSelection });
    }
  };

  return (
    <div
      style={{
        position: `relative`,
        display: `flex`,
        height: `100%`,
        flexDirection: `column` as any,
        ...(maxHeight ? { maxHeight } : {}),
      }}
    >
      <Grid item style={{ height: `100%`, overflowY: `auto` }}>
        <Table
          style={{
            flexDirection: `column` as any,
            height: `100%`,
          }}
          stickyHeader
          component="div"
        >
          <TableHead
            component="div"
            style={{
              display: `flex`,
              flexDirection: `row` as any,
              alignItems: `center`,
            }}
          >
            <TableRow
              component="div"
              style={{
                display: `flex`,
                flexDirection: `row` as any,
                flexWrap: `nowrap` as any,
                alignItems: `center`,
                boxSizing: `border-box` as any,
                minWidth: `100%`,
                width: `100%`,
                padding: 0,
                borderBottom: `1px solid ${RoutalPalette.neutral10}`,
              }}
            >
              {/* RENDER OPTIONAL CHECKBOX COLUMN */}
              {showCheckboxes && (loading || (docs ?? []).length > 0) ? (
                <TableCell
                  component="div"
                  style={{
                    backgroundColor: `transparent`,
                    padding: `8px`,
                    borderBottom: `unset`,
                    ...(globalCheckboxColumnStyles ?? {}),
                  }}
                >
                  {loading ? (
                    <Skeleton variant="rectangular" animation="pulse" height={20} width={20} />
                  ) : (
                    <Checkbox
                      checked={selectedIds.length === total}
                      onChange={() => {
                        if (onMultipleSelection) {
                          onMultipleSelection({ fromGlobalCheckbox: true });
                        }
                      }}
                      indeterminate={false}
                    />
                  )}
                </TableCell>
              ) : null}
              {/* RENDER EVERY COLUMN MAPPED */}
              {columns.map((column: DataTableColumnProps) => {
                let justifyContent = null;

                if (column.align === `right`) justifyContent = `flex-end`;
                if (column.align === `left`) justifyContent = `flex-start`;
                if (column.align === `center`) justifyContent = `center`;

                column.columnStyle = {
                  minWidth: column.width
                    ? typeof column.width === `string`
                      ? column.width
                      : `${column.width}px`
                    : `unset`,
                  minHeight: column.height ? `${column.height}px` : `unset`,
                  width: `100%`,
                  height: `100%`,

                  display: `flex`,
                  flexDirection: `row` as any,
                  alignItems: `center`,
                  ...(justifyContent ? { justifyContent } : {}),
                  ...(column.columnStyle
                    ? { ...column.columnStyle, borderBottom: `unset` }
                    : { borderBottom: `unset` }),
                };

                return (
                  <DataTableHeaderCell
                    loading={loading}
                    key={`table-head-${column.accessorName}`}
                    column={column}
                    onColumnSort={onColumnSort}
                    active={sortBy === column.accessorName}
                    sortDirection={sortBy === column.accessorName ? sortDirection : undefined}
                  />
                );
              })}
            </TableRow>
          </TableHead>
          {(loading || (docs ?? []).length > 0) && (
            <TableBody
              component="div"
              style={{
                display: `flex`,
                flexDirection: `column` as any,
                flex: `1`,
                width: `100%`,
              }}
              onMouseEnter={(event: any) => {
                if (onBodyMouseEnter) onBodyMouseEnter(event);
              }}
              onMouseLeave={(event: any) => {
                if (onBodyMouseLeave) onBodyMouseLeave(event);
              }}
            >
              {(loading ? new Array(LOADER_NUM_FAKE_ROWS).fill({ doc: {} }) : docs ?? []).map(
                ({ doc, checkboxIcon }: Document, index: number) => {
                  // Check if this row is selected
                  return (
                    <DataTableRow
                      index={index}
                      loading={loading}
                      hover={hover}
                      columns={columns}
                      document={doc}
                      opacity={loading ? 1 - index / LOADER_NUM_FAKE_ROWS : 1}
                      key={`row-${doc.id || index}`}
                      isSelected={isSelected(doc)}
                      isLastRow={index === (docs ?? []).length - 1}
                      checkboxIcon={checkboxIcon}
                      showRowCheckboxAlways={showRowCheckboxAlways}
                      globalCheckboxColumnStyles={globalCheckboxColumnStyles}
                      showCheckbox={showCheckboxes}
                      handleCheckboxSelect={handleCheckboxSelect}
                      handleRowSelect={handleRowSelect}
                      handleOnContextMenu={handleOnContextMenu ?? (() => {})}
                      onRowDoubleClick={onRowDoubleClick ?? (() => {})}
                      onMouseEnter={onRowMouseEnter ?? (() => {})}
                      onMouseLeave={onRowMouseLeave ?? (() => {})}
                    />
                  );
                }
              )}
            </TableBody>
          )}
          {!loading && (docs ?? []).length === 0 && emptyMessage !== undefined ? (
            <div
              style={{
                display: `flex`,
                flexDirection: `column` as any,
                alignItems: `center`,
                padding: `20px`,
              }}
            >
              {emptyMessage}
            </div>
          ) : null}
        </Table>
      </Grid>
      {!noFooter && pages !== undefined && pages > 0 && (docs ?? []).length > 0 ? (
        <div
          style={{
            position: `relative`,
            bottom: `0`,
            width: `100%`,
            padding: `8px 0`,
            borderTop: `1px solid ${RoutalPalette.grey4}`,
          }}
        >
          <Grid container justifyContent="center">
            {pages && pages > 0 ? (
              <Pagination
                shape="rounded"
                count={pages ?? 0}
                page={page ?? 0}
                size="small"
                onChange={(_evt: any, newPage: number) => {
                  if (onChangePage) onChangePage({ offset: (limit ?? 0) * (newPage - 1), limit: limit ?? 0 });
                }}
              />
            ) : null}
          </Grid>
        </div>
      ) : null}
    </div>
  );
};
