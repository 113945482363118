import { createContext, useContext } from 'react';
import { StopsActions } from './actions';
import { getInitialState } from './reducer';

export const StopsContext = createContext({
  stopsState: getInitialState(),
  stopsDispatch: (action: StopsActions) => {},
});

StopsContext.displayName = `StopsContext`;

export const useStopsContext = () => useContext(StopsContext);
