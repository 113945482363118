import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';

const styles = {
  root: {
    backgroundColor: `#E9E9E9`,
    // position: "relative",
    // // textAlign: "center",
    // color: "white",
    minHeight: `68px`,
    width: `100%`,
    borderRadius: `12px`,
    columnGap: `10px`,
    padding: `12px`,
  },
  iconContainer: {
    width: `68px`,
  },
  actionsContainer: {
    columnGap: `10px`,
  },
};

type OwnProps = {
  classes: {
    root: string;
    iconContainer: string;
  };
  icon?: React.ReactElement;
  avatar?: string | React.ReactElement;
  info?: string | React.ReactElement;
  actions?: React.ReactElement[];
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Pill.defaultProps;

// @ts-expect-error ts-migrate(7022) FIXME: 'Pill' implicitly has type 'any' because it does n... Remove this comment to see the full error message
const Pill = ({ icon, avatar, info, actions, classes }: Props) => {
  return (
    <Grid className={classes.root} container alignItems="center" wrap="nowrap">
      <Grid item container justifyContent="center" className={classes.iconContainer}>
        {icon || avatar}
      </Grid>

      <Grid item container>
        {info}
      </Grid>

      <Grid item container justifyContent="flex-end" className={classes.actionsContainer}>
        {actions &&
          actions.map((action: any, idx: any) => (
            <Grid key={`pill-action${idx}`} item>
              {action}
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

Pill.defaultProps = {
  icon: undefined,
  info: undefined,
  actions: undefined,
  avatar: undefined,
};

export default withStyles(styles)(Pill);
