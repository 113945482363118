import { Requests, splitArrayByParts } from '@nx-smartmonkey/shared/helpers';
import { PaginatedResult } from '@nx-smartmonkey/shared/interfaces';
import { Project } from '../../domain/project/Project';
import { SearchPredicate } from '../../domain/searchPredicates/SearchPredicate';
import { Stop } from '../../domain/stop/Stop';
import { StopDTO } from '../../domain/stop/StopDTO';
import { StopFlat } from '../../domain/stop/StopFlat';
import { StopFlatDTO } from '../../domain/stop/StopFlatDTO';
import { StopFlatMapper } from '../../mappers/stop/StopFlatMapper';
import { StopMapper } from '../../mappers/stop/StopMapper';
import { SessionRepository } from '../session/SessionRepository';
import { StopRepository } from './StopRepository';

export class StopRepositoryApi implements StopRepository {
  constructor(private requests: Requests, private sessionRepository: SessionRepository) {}

  async fetchStop({ stopId, project }: { stopId: string; project: Project }): Promise<Stop> {
    const asSupervisor = this.sessionRepository.getAsSupervisor();

    const response = await this.requests.get({
      uri: `/supervisors/v1/stop/${stopId}`,
      ...(asSupervisor
        ? {
            params: {
              as_supervisor: `${asSupervisor}`,
            },
          }
        : {}),
      headers: this.sessionRepository.getAuthenticationHeader(),
    });

    const data = response.data as StopDTO;
    return StopMapper.toDomain(data, project.custom_fields);
  }

  async fetchSomeStops({ stopIds, project }: { stopIds: string[]; project: Project }): Promise<Array<Stop | null>> {
    const stopsIdsChunks = splitArrayByParts({ inputArray: stopIds, itemsPerChunk: 500 }) as string[];

    const requests = stopsIdsChunks.map((stopIdsChunk) => {
      return {
        method: this.requests.post.bind(this.requests),
        params: {
          uri: `/supervisors/v1/retrieveStopsById`,
          data: {
            stop_ids: stopIdsChunk,
          },
          headers: this.sessionRepository.getAuthenticationHeader(),
        },
      };
    });

    const response = await this.requests.multipleAsyncWait({
      requests,
    });

    const stops = response.flat(1) as StopDTO[];

    return stops.map((stop: StopDTO | null) => {
      return stop !== null ? StopMapper.toDomain(stop, project.custom_fields!) : null;
    });
  }

  async search({
    project,
    offset,
    limit,
    sort_by,
    sort_direction,
    predicates = [],
  }: {
    project: Project;
    offset?: number;
    limit?: number;
    sort_by?: string;
    sort_direction?: string;
    predicates: Array<SearchPredicate>;
  }): Promise<PaginatedResult<Stop>> {
    const urlParams = new URLSearchParams();
    const asSupervisor = this.sessionRepository.getAsSupervisor();
    if (asSupervisor) urlParams.append(`as_supervisor`, `${asSupervisor}`);
    urlParams.append(`project_id`, `${project.id}`);

    const response = await this.requests.post({
      uri: `/supervisors/v1/stops/search?${urlParams.toString()}`,
      data: {
        offset,
        limit,
        predicates: predicates.filter((p) => p.isValid()).map((p) => p.toRepository()),
        ...(sort_by !== undefined ? { sort_by } : {}),
        ...(sort_direction !== undefined ? { sort_direction } : {}),
      },
      headers: this.sessionRepository.getAuthenticationHeader(),
    });

    const data = response.data as PaginatedResult<StopDTO>;

    return {
      ...data,
      docs: data.docs.map((stop) => StopMapper.toDomain(stop, project.custom_fields)),
    };
  }

  async searchExample(project: Project): Promise<PaginatedResult<Stop>> {
    const response = await this.requests.get({
      uri: `/supervisors/v1/stops/search/example`,
    });

    const data = response.data as PaginatedResult<StopDTO>;

    return {
      ...data,
      docs: data.docs.map((stop) => StopMapper.toDomain(stop, project.custom_fields)),
    };
  }

  async fetchFlatStops({
    projectId,
    predicates = [],
  }: {
    projectId: string;
    predicates: Array<SearchPredicate>;
  }): Promise<Array<StopFlat>> {
    const urlParams = new URLSearchParams();
    const asSupervisor = this.sessionRepository.getAsSupervisor();
    if (asSupervisor) urlParams.append(`as_supervisor`, `${asSupervisor}`);
    urlParams.append(`project_id`, `${projectId}`);

    const response = await this.requests.post({
      uri: `/supervisors/v1/stops/flat?${urlParams.toString()}`,
      data: {
        predicates: predicates.filter((p) => p.isValid()).map((p) => p.toRepository()),
      },
      headers: this.sessionRepository.getAuthenticationHeader(),
    });

    const data = response.data as Array<StopFlatDTO>;

    return data.map(StopFlatMapper.toDomain);
  }

  async fetchExampleFlatStops(): Promise<Array<StopFlat>> {
    const response = await this.requests.get({
      uri: `/supervisors/v1/stops/flat/example`,
    });

    const data = response.data as Array<StopFlatDTO>;

    return data.map(StopFlatMapper.toDomain);
  }
}
