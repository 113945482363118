import { PaginatedResult } from '@nx-smartmonkey/shared/interfaces';
import { SearchPredicate } from '../../domain/searchPredicates/SearchPredicate';
import { DriverInfo, Stop } from '../../domain/stop/Stop';
import { StopFlat } from '../../domain/stop/StopFlat';
import { StopsTableColumn } from './tableColumns';

export enum StopsActionType {
  SET_SEARCH_STOPS_PREDICATES = `SET_SEARCH_STOPS_PREDICATES`,
  SEARCH_STOPS_SUCCESS = `SEARCH_STOPS_SUCCESS`,
  SEARCH_FLAT_STOPS_SUCCESS = `SEARCH_FLAT_STOPS_SUCCESS`,
  SET_STOPS_TABLE_COLUMNS = `SET_STOPS_TABLE_COLUMNS`,
  SET_SELECTED_STOPS = `SET_SELECTED_STOPS`,
  DRIVER_INFO_UPDATED = `DRIVER_INFO_UPDATED`,
  STOP_UPDATED = `STOP_UPDATED`,
  STOP_DELETED = `STOP_DELETED`,
}

export interface SetSearchStopsPredicatesAction {
  type: StopsActionType.SET_SEARCH_STOPS_PREDICATES;
  payload: {
    predicates: Array<SearchPredicate>;
    predicatesBase64: string;
  };
}

export interface SearchStopsSuccessAction {
  type: StopsActionType.SEARCH_STOPS_SUCCESS;
  payload: {
    stopsPagination: PaginatedResult<Stop>;
  };
}

export interface SearchFlatStopsSuccessAction {
  type: StopsActionType.SEARCH_FLAT_STOPS_SUCCESS;
  payload: {
    flatStops: Array<StopFlat>;
  };
}

export interface SetStopsTableColumnsAction {
  type: StopsActionType.SET_STOPS_TABLE_COLUMNS;
  payload: Array<StopsTableColumn>;
}

export interface SetSelectedStopsAction {
  type: StopsActionType.SET_SELECTED_STOPS;
  payload: {
    selectedStops: Array<string>;
  };
}

export interface DriverInfoUpdatedAction {
  type: StopsActionType.DRIVER_INFO_UPDATED;
  payload: {
    driverInfo: DriverInfo;
  };
}

export interface StopUpdatedAction {
  type: StopsActionType.STOP_UPDATED;
  payload: {
    stop: Stop;
  };
}

export interface StopDeletedAction {
  type: StopsActionType.STOP_DELETED;
  payload: {
    stopId: string;
  };
}

export type StopsActions =
  | SetSearchStopsPredicatesAction
  | SearchStopsSuccessAction
  | SearchFlatStopsSuccessAction
  | SetStopsTableColumnsAction
  | SetSelectedStopsAction
  | DriverInfoUpdatedAction
  | StopUpdatedAction
  | StopDeletedAction;
