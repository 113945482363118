import { Add, Close } from '@mui/icons-material';
import { Grid, Tooltip } from '@mui/material';
import {
  CustomFieldCategoricalMultiple,
  CustomFieldCategoricalSingle,
  CustomFieldDefinition,
} from '@nx-smartmonkey/shared/domain';

import { RoutalPalette, RoutalSelect, RoutalSelectMultiSelectOption } from '@nx-smartmonkey/ui';
import { Text } from '../../react-components/Text';

type CustomFieldFormCategoricalProps = {
  fieldValue?: CustomFieldCategoricalMultiple;
  projectField: CustomFieldDefinition;
  readOnly?: boolean;
  onlyValues?: boolean;
  onUpdate?: (values: number[] | null) => any;
};

export const CustomFieldFormCategorical = ({
  fieldValue,
  onUpdate,
  projectField,
  readOnly = false,
  onlyValues = false,
}: CustomFieldFormCategoricalProps) => {
  const isOptionDisabled = (index: number) => {
    return ((fieldValue ?? []) as CustomFieldCategoricalMultiple)
      .map((option: CustomFieldCategoricalSingle) => option.id)
      .includes(index);
  };

  if (projectField.field_type !== `categorical`) return null;

  return (
    <>
      {!onlyValues && (
        <Text variant="label" style={{ textTransform: `capitalize`, marginBottom: `8px` }}>
          {projectField.label}
        </Text>
      )}
      {!projectField.multiple ? (
        readOnly ? (
          <>
            {(fieldValue ?? []).length > 0 ? (
              <Text>{projectField.options![fieldValue![0].id]}</Text>
            ) : (
              <Text intlId="none" />
            )}
          </>
        ) : (
          <RoutalSelect
            id={`${projectField.custom_id}-popover`}
            popoverProps={{
              options: [
                {
                  key: `not-selected`,
                  onClick: () => {
                    if (onUpdate) {
                      onUpdate(null);
                    }
                  },
                  content: <Text intlId="not_selected" style={{ cursor: `pointer` }} />,
                },
                ...projectField.options!.map((option: string, index: number): RoutalSelectMultiSelectOption => {
                  return {
                    key: `option-${index}`,
                    onClick: () => {
                      if (onUpdate) {
                        onUpdate([index]);
                      }
                    },
                    content: <Text style={{ cursor: `pointer` }}>{option ?? ``}</Text>,
                  };
                }),
              ],
            }}
          >
            {(fieldValue ?? []).length > 0 ? (
              <Text variant="body" style={{ cursor: `pointer`, width: `100%`, textAlign: `justify` }}>
                {fieldValue![0].label}
              </Text>
            ) : (
              <Text
                variant="body"
                style={{ cursor: `default`, width: `100%`, textAlign: `justify` }}
                intlId="not_selected"
              />
            )}
          </RoutalSelect>
        )
      ) : (
        <Grid
          container
          direction="row"
          alignItems="center"
          gap={1}
          style={{
            marginTop: `8px`,
          }}
        >
          {!readOnly && (!fieldValue || projectField.options!.length > (fieldValue ?? []).length) ? (
            <RoutalSelect
              id={`${projectField.custom_id}-popover`}
              hideArrow
              containerStyle={{
                width: `100px`,
                height: `28px`,
                marginBottom: `8px`,
              }}
              popoverProps={{
                options: projectField.options!.map((value: string, index: number): RoutalSelectMultiSelectOption => {
                  return {
                    key: `option-${index}`,
                    listItemStyles: {
                      whiteSpace: `normal`,
                      overflow: `auto`,
                    },
                    disabled: isOptionDisabled(index),
                    onClick: () => {
                      if (onUpdate) {
                        onUpdate([
                          ...(fieldValue ?? []).map((option: CustomFieldCategoricalSingle) => option.id),
                          index,
                        ]);
                      }
                    },
                    content: <Text style={{ cursor: isOptionDisabled(index) ? `default` : `pointer` }}>{value}</Text>,
                  };
                }),
              }}
            >
              <Add style={{ marginRight: `2px`, fontSize: `18px`, color: `${RoutalPalette.secondary60}` }} />
              <Text
                intlId="add"
                style={{
                  cursor: `pointer`,
                }}
              />
            </RoutalSelect>
          ) : null}

          {(fieldValue ?? []).length > 0
            ? fieldValue!.map((object: CustomFieldCategoricalSingle) => (
                <div
                  key={object.id}
                  style={{
                    display: `flex`,
                    flexDirection: `row`,
                    alignItems: `center`,
                    padding: `8px`,
                    borderRadius: `10px`,
                    backgroundColor: RoutalPalette.neutral10,
                    height: `20px`,
                  }}
                >
                  <Tooltip title={object.label}>
                    <span
                      style={{
                        fontSize: `14px`,
                        color: `${RoutalPalette.secondary60}`,
                      }}
                    >
                      {object.label}
                    </span>
                  </Tooltip>
                  {!readOnly ? (
                    <Close
                      style={{ cursor: `pointer`, fontSize: `18px`, color: `${RoutalPalette.secondary00}` }}
                      onClick={() => {
                        if (onUpdate) {
                          onUpdate([
                            ...fieldValue!
                              .filter((ob: CustomFieldCategoricalSingle) => ob.id !== object.id)
                              .map((option: CustomFieldCategoricalSingle) => option.id),
                          ]);
                        }
                      }}
                    />
                  ) : null}
                </div>
              ))
            : null}
        </Grid>
      )}
    </>
  );
};
