import { Tooltip, TooltipProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { RoutalPalette } from './Colors';

const generateRandomId = (size: number) =>
  [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join(``);

interface TooltipStyleProps {
  backgroundColor: string;
  visible: boolean;
}

const tooltipClasses = makeStyles({
  tooltip: (props: TooltipStyleProps) => ({
    backgroundColor: props.backgroundColor,
    transitionDuration: `0s !important`,
    visibility: props.visible ? `visible` : `hidden`,
  }),
  arrow: (props: TooltipStyleProps) => ({
    color: props.backgroundColor,
    visibility: props.visible ? `visible` : `hidden`,
  }),
});

export type RoutalTooltipPlacementTypes =
  | `left`
  | `right`
  | `top`
  | `bottom`
  | `topLeft`
  | `topRight`
  | `bottomLeft`
  | `bottomRight`
  | `rightTop`
  | `rightBottom`
  | `leftTop`
  | `leftBottom`;

const placementDictionary: Record<RoutalTooltipPlacementTypes, TooltipProps[`placement`]> = {
  left: `left`,
  right: `right`,
  top: `top`,
  bottom: `bottom`,
  topLeft: `top-start`,
  topRight: `top-end`,
  bottomLeft: `bottom-start`,
  bottomRight: `bottom-end`,
  rightTop: `right-start`,
  rightBottom: `right-end`,
  leftTop: `left-start`,
  leftBottom: `left-end`,
};

type RoutalTooltipProps = {
  id?: string;
  title: string | React.ReactElement;
  placement?: RoutalTooltipPlacementTypes;
  mouseEnterDelay?: number;
  mouseLeaveDelay?: number;
  overlayStyle?: React.CSSProperties;
  overlayInnerStyle?: React.CSSProperties;
  backgroundColor?: string;
  contentStyles?: React.CSSProperties;
  arrowColor?: string;
  visible?: boolean;

  disableFocusListener?: boolean;
  disableHoverListener?: boolean;
  disableTouchListener?: boolean;
  open?: boolean;
};

export const RoutalTooltip = ({
  id,
  children,
  title,
  placement = `top`,
  mouseEnterDelay,
  mouseLeaveDelay,
  overlayStyle = {},
  overlayInnerStyle = {},
  contentStyles = {},
  backgroundColor = RoutalPalette.secondary80,
  visible = true,
  disableFocusListener,
  disableHoverListener,
  disableTouchListener,
  open,
}: React.PropsWithChildren<RoutalTooltipProps>) => {
  const classes = tooltipClasses({ backgroundColor, visible });

  return (
    <Tooltip
      id={`tooltip-${id ?? generateRandomId(16)}`}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: `offset`,
              options: {
                offset: [0, -8],
              },
            },
          ],
        },
      }}
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      enterDelay={mouseEnterDelay ? mouseEnterDelay * 1000 : 0}
      leaveDelay={mouseLeaveDelay ? mouseLeaveDelay * 1000 : 0}
      title={
        <div
          style={{
            borderRadius: `2px`,
            minHeight: `24px`,
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
            zIndex: 1301,
            ...overlayStyle,
          }}
        >
          <div
            style={{
              color: RoutalPalette.neutral00,
              textDecoration: `none`,
              border: `none`,
              fontSize: `14px`,
              ...overlayInnerStyle,
            }}
          >
            {title}
          </div>
        </div>
      }
      placement={placementDictionary[placement]}
      arrow
      disableInteractive
      disableFocusListener={disableFocusListener ?? undefined}
      disableHoverListener={disableHoverListener ?? undefined}
      disableTouchListener={disableTouchListener ?? undefined}
      open={open ?? undefined}
    >
      <span style={contentStyles}>{children}</span>
    </Tooltip>
  );
};
