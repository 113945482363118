import { Grid, Radio } from '@mui/material';

import { RoutalSelect, RoutalSelectMultiSelectOption } from '@nx-smartmonkey/ui';
import { intl } from '../../../helpers/IntGlobalProvider';
import { Text } from '../../../react-components/Text';
import { SearchFilterComponent } from '../filter';
import { SearchFilterOperators } from '../filter/SearchFilter';
import { SearchFilterList } from './SearchFilterList';

interface GetSearchFilterListOptionsProps {
  operator: SearchFilterOperators;
  text: string;
  isSelected: boolean;
}

const getSearchFilterListOptions = (filter: SearchFilterList): GetSearchFilterListOptionsProps[] => [
  {
    operator: `eq`,
    text: intl.formatMessage({ id: `search_filters.eq` }),
    isSelected: filter.isOperator(`eq`),
  },
  {
    operator: `ne`,
    text: intl.formatMessage({ id: `search_filters.ne` }),
    isSelected: filter.isOperator(`ne`),
  },
];

interface SearchFilterListComponentProps {
  filter: SearchFilterList;
  filterPosition: number;
  colors: {
    primaryColor: string;
    secondaryColor: string;
  };
  onChange: (filter: SearchFilterList) => void;
  onRemoveFilter: () => void;
}

export const SearchFilterListComponent = ({
  filter,
  filterPosition,
  colors,
  onChange,
  onRemoveFilter,
}: SearchFilterListComponentProps) => {
  const customResultText = intl.formatMessage({ id: `search_filters.filter.${filter.getField()}` });
  return (
    <SearchFilterComponent
      filter={filter}
      buttonId={`filter-list-${filterPosition}`}
      colors={colors}
      onRemoveFilter={onRemoveFilter}
      customResult={(filterResult) => {
        return (
          <Grid container direction="row" wrap="nowrap">
            <Grid item>
              <Text
                style={{
                  fontWeight: `normal`,
                  cursor: `pointer`,
                }}
                intlId={`search_filters.${filterResult.getOperator()}.result`}
                intlValues={{
                  field: intl.formatMessage({ id: `search_filters.filter.${filterResult.getField()}` }),
                  value: (chunks: any) => <span></span>,
                }}
              >
                {customResultText}
              </Text>
            </Grid>

            <div style={{ width: `4px` }} />

            <Grid item>
              <Text intlId={`search_filters.filter.${filter.getField()}.${filter.getValue()}`} />
            </Grid>
          </Grid>
        );
      }}
    >
      {getSearchFilterListOptions(filter).map((option) => {
        return (
          <Grid container direction="column" wrap="nowrap" style={{ padding: `0 16px`, minWidth: `250px` }}>
            <Grid
              item
              container
              direction="row"
              wrap="nowrap"
              alignItems="center"
              style={{
                cursor: `pointer`,
              }}
              onClick={(event: any) => {
                event.stopPropagation();
                filter.setOperator(option.operator);
                onChange(filter);
              }}
            >
              <Grid item>
                <Radio
                  style={{
                    color: colors.primaryColor,
                  }}
                  checked={option.isSelected}
                  onChange={(event: any) => {
                    event.stopPropagation();
                    filter.setOperator(option.operator);
                    onChange(filter);
                  }}
                />
              </Grid>

              <Grid item>
                <Text style={{ cursor: `pointer` }}>{option.text}</Text>
              </Grid>
            </Grid>

            {option.isSelected ? (
              <Grid item>
                <RoutalSelect
                  id="search-filter-list-select"
                  hideSeparator
                  popoverProps={{
                    options: filter.getListOptions().map(
                      (option): RoutalSelectMultiSelectOption => ({
                        key: option.value,
                        content: <Text intlId={option.intlId} />,
                        onClick: () => {
                          filter.setValue(option.value);
                          onChange(filter);
                        },
                      })
                    ),
                  }}
                >
                  <Text intlId={filter.getOptionByValue()?.intlId} style={{ width: `100%` }} />
                </RoutalSelect>
              </Grid>
            ) : null}
          </Grid>
        );
      })}
    </SearchFilterComponent>
  );
};
