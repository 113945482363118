import { SearchFilter } from '../filter';
import { SearchFilterProps } from '../filter/SearchFilter';

export type SearchFilterStringOperator =
  | `eq`
  | `ne`
  | `starts_with`
  | `ends_with`
  | `contains`
  | `contains_exact_word`
  | `not_contains`
  | `unknown`
  | `known`;

export type SearchFilterStringValue = string | null;

interface SearchFilterStringProps extends SearchFilterProps {
  field: string;
  operator: SearchFilterStringOperator;
  value: SearchFilterStringValue;

  // - "is" => eq
  // - "is not" => ne
  // - "starts with" => starts_with
  // - "ends with" => ends_with
  // - "contains" => contains
  // - "contains exact word" => contains_exact_word
  // - "does not contain" => not_contains
  // - "is unknown" => unknown (value es null)
  // - "has any value" => known (value es null)
}

export class SearchFilterString extends SearchFilter {
  constructor(props: SearchFilterStringProps) {
    super({ ...props, type: `string` });
  }
}
