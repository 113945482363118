import 'non.geist';
import React from 'react';
import styled from 'styled-components';

import { RoutalPalette } from './Colors';

export type VariantTextType =
  | `h1`
  | `h2`
  | `h3`
  | `h4`
  | `h5` // LO QUE ERA H1
  | `h6` // LO QUE ERA H2
  | `h6-light`
  | `body-large` // LO QUE ERA H3
  | `body-medium` // LO QUE ERA H4
  | `body` // LO QUE ERA TEXT
  | `small`
  | `ultra-small`
  | `label`
  | `button`;

export type TagType = `p` | `div` | `span`;

export interface RoutalTextProps {
  id?: string;
  variant?: VariantTextType;
  tagName?: TagType;
  isBold?: boolean;
  style?: React.CSSProperties;
  noWrap?: boolean;
  addDefaultColor?: boolean;
}

const Element = styled.div`
  font-family: Geist Variable;
  cursor: default;
  font-weight: 400;
  ${({ noWrap }: RoutalTextProps) => {
    if (noWrap) {
      return `
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      `;
    }
    return ``;
  }}
  ${({ addDefaultColor }: RoutalTextProps) => {
    if (addDefaultColor) {
      return `
      color: ${RoutalPalette.secondary40};
      `;
    }
    return ``;
  }}
  ${({ variant = `body` }: RoutalTextProps) => {
    switch (variant) {
      case `h1`:
        return `
            font-size: 54px;
            font-weight: 500;
          `;
      case `h2`:
        return `
            font-size: 42px;
            font-weight: 500;
          `;
      case `h3`:
        return `
            font-size: 34px;
            font-weight: 500;            
          `;
      case `h4`:
        return `
            font-size: 28px;
            font-weight: 500;
          `;
      case `h5`: // LO QUE ERA H1
        return `
            font-size: 22px;
            font-weight: 500;
          `;
      case `h6`: // LO QUE ERA H2
        return `
            font-size: 18px;
            font-weight: 500;
          `;
      case `h6-light`:
        return `
            font-size: 18px;
          `;
      case `body-large`: // LO QUE ERA H3
        return `
            font-size: 16px;
          `;
      case `body-medium`: // LO QUE ERA H4
        return `
            font-size: 14px;
          `;
      case `body`: // LO QUE ERA TEXT
        return `
          font-size: 13px;
        `;
      case `small`:
        return `
          font-size: 12px;
          font-weight: 300;
        `;
      case `ultra-small`:
        return `
          font-size: 10px;
          font-weight: 300;
        `;
      case `label`: // COM BODY
        return `
            color: ${RoutalPalette.secondary00};
            font-size: 13px;
          `;
      case `button`:
        return `
            color: ${RoutalPalette.primary40};
            font-size: 13px;
            cursor: pointer;
          `;
      default:
        return ``;
    }
  }}
  ${({ isBold }: RoutalTextProps) => (isBold ? `font-weight: 500;` : ``)}
` as any; // TODO: This should be typed correctly.

/**
 * General purpose text component. Allows different types and tagNames.
 * component.
 */
export const RoutalText = ({
  id,
  variant = `body`,
  tagName = `div`,
  children,
  noWrap = false,
  addDefaultColor = true,
  isBold = false,
  style,
  onClick,
}: React.PropsWithChildren<RoutalTextProps> & React.DOMAttributes<RoutalTextProps>) => {
  return (
    <Element
      id={id}
      as={tagName}
      variant={variant}
      noWrap={noWrap}
      addDefaultColor={addDefaultColor}
      isBold={isBold}
      onClick={onClick}
      style={{ ...style }}
    >
      {children}
    </Element>
  );
};
