import { Language, LanguageType } from '@nx-smartmonkey/shared/domain';

export enum TranslationsActionType {
  SET_TRANSLATION = `SET_TRANSLATION`,
  SET_ACTIVE_LANGUAGE = `SET_ACTIVE_LANGUAGE`,
}

export interface SetTranslationAction {
  type: TranslationsActionType.SET_TRANSLATION;
  payload: { language: LanguageType; translations: Record<string, string> };
}

export interface SetActiveLanguageAction {
  type: TranslationsActionType.SET_ACTIVE_LANGUAGE;
  payload: {
    language: Language;
  };
}

export type TranslationsActions = SetTranslationAction | SetActiveLanguageAction;
