import { ServiceResponse } from '@nx-smartmonkey/shared/hooks';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { stopService } from '../../application/StopService';
import { Stop } from '../../domain/stop/Stop';
import useErrorMiddleware from '../errorMiddleware/useErrorMiddleware';
import { useRetrieveRightPanelInfo } from '../right-panel/useRetrieveRightPanelInfo';

import { useRetrieveProject } from '../project/useRetrieveProject';
import { useShowSnackbarMessage } from '../snackbarMessage/useShowSnackbarMessage';

interface UseRetrieveStopProps {
  showErrorMessage?: boolean;
}

type UseRetrieveStopResponse = ServiceResponse<Function, Stop>;

// Hooks act as 'Adapter' layer.
export const useRetrieveStop = ({ showErrorMessage = true }: UseRetrieveStopProps): UseRetrieveStopResponse => {
  const { data: project } = useRetrieveProject();
  const { showSnackbarMessage } = useShowSnackbarMessage();
  const [stop, setStop] = useState<Stop | undefined>(undefined);
  const { detailId: stopId } = useRetrieveRightPanelInfo();
  const { sessionExpiredLogout } = useErrorMiddleware();

  const {
    isInitialLoading: isLoading,
    refetch,
    isError,
    isSuccess,
  } = useQuery(
    [`supervisors_stop`, stopId],
    (): Promise<Stop> =>
      stopService.fetchStop({
        stopId: stopId!,
        project: project!,
      }),
    {
      enabled: !!stopId,
      retry: false,
      onSuccess: (_stop) => {
        setStop(_stop);
      },
      onError: (err: any) => {
        console.info(`Supervisors Retrieve Stop Error: `, err?.response);

        if (err?.response?.status === 401) {
          sessionExpiredLogout();
          return;
        }

        if (showErrorMessage) {
          showSnackbarMessage({
            messageIntlId: `infoerror.${err?.response?.data?.messageId ?? `network_error`}`,
          });
        }
      },
    }
  );

  return {
    error: isError,
    isLoading,
    success: isSuccess,
    run: refetch,
    data: stop,
  };
};
