import { createIntl, createIntlCache, IntlShape } from 'react-intl';
import { setSelectedIntl } from '../../helpers/IntGlobalProvider';
import { TranslationsActions, TranslationsActionType } from './actions';

export interface TranslationsState {
  translations: Record<string, IntlShape>;
  activeLanguage?: IntlShape;
}

export const getInitialState = (): TranslationsState => ({
  translations: {},
  activeLanguage: undefined,
});

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

export const TranslationsReducer = (state: TranslationsState, action: TranslationsActions) => {
  switch (action.type) {
    case TranslationsActionType.SET_ACTIVE_LANGUAGE:
      setSelectedIntl(state.translations[action.payload.language.value]);
      return {
        ...state,
        activeLanguage: state.translations[action.payload.language.value],
      };
    case TranslationsActionType.SET_TRANSLATION: {
      return {
        ...state,
        translations: {
          ...state.translations,
          [action.payload.language]: createIntl(
            {
              locale: action.payload.language,
              messages: action.payload.translations,
            },
            cache
          ),
        },
      };
    }
    default:
      return state;
  }
};
