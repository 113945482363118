import { InfoErrorActionType, InfoErrorActions } from './actions';

const initialState = {
  type: undefined,
  autoHideDuration: undefined,
  message: undefined,
};

export interface InfoErrorState {
  type?: `success` | `error` | `info` | `warning`;
  autoHideDuration?: number;
  message?: string;
}

export const InfoErrorReducer = (state: InfoErrorState, action: InfoErrorActions): InfoErrorState => {
  switch (action.type) {
    case InfoErrorActionType.INFOERROR_CLOSE_SNACKBAR:
      return { ...initialState };
    case InfoErrorActionType.INFOERROR_SEND_STATUS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
