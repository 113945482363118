import { useEffect, useState } from 'react';

import { RoutalInputText, RoutalInputTextProps } from './RoutalInputText';
import SearchIcon from './assets/img/search_icon.svg';

interface SearchInputProps extends Omit<RoutalInputTextProps, `fieldValue`> {
  fieldValue?: string;
  searchLive?: boolean;
}

export const RoutalSearchInput = ({
  fieldValue,
  searchLive = false,
  inputStyles = {},
  inputContainerStyles = {},
  onChange,
  ...routalInputTextProps
}: SearchInputProps) => {
  const [text, setText] = useState<string>(fieldValue ?? ``);

  useEffect(() => {
    text !== fieldValue && setText(fieldValue ?? ``);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue]);

  const handleChange = (value: any) => {
    setText(value);
    if (onChange !== undefined && searchLive) {
      onChange(value);
    }
  };

  const handleClear = () => {
    setText(``);
    if (onChange !== undefined) onChange(``);
  };

  const handleFind = () => {
    if (onChange !== undefined) onChange(text);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === `Enter`) {
      handleFind();
    }
  };

  return (
    <RoutalInputText
      startAdornment={
        <div
          style={{
            marginLeft: `6px`,
            marginTop: `5px`,
          }}
        >
          <img src={SearchIcon} alt="" draggable="false" />
        </div>
      }
      fieldValue={text}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onDelete={handleClear}
      inputContainerStyles={{
        borderRadius: `5px`,
        ...inputContainerStyles,
      }}
      inputStyles={inputStyles}
      {...routalInputTextProps}
    />
  );
};
