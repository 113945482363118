import { useNavigate } from 'react-router-dom';

import * as routing from '../../constants/Routing';
import { useRouterQuery } from '../useRouterQuery';

export type StopsView = `list` | `map`;

export const useSetStopsView = () => {
  const navigate = useNavigate();
  const urlParams = useRouterQuery();

  return {
    view: urlParams.get(`view`)?.toString() ?? `list`,
    setView: (view: StopsView) => {
      const params = new URLSearchParams();
      params.append(`view`, view);
      navigate(`${routing.ROOT}?${params.toString()}`);
    },
  };
};
