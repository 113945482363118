import { CheckboxProps, Checkbox as MaterialCheckbox } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = (theme: any) => ({
  root: {
    height: `24px`,
    width: `24px`,
    padding: `0`,
    color: theme.palette.secondary.main,
    '&$checked': {
      color: theme.palette.primary,
    },
    '&$disabled': {
      color: `#CACACA`,
    },
  },

  checked: {},
  disabled: {},
});

interface ICheckboxProps extends CheckboxProps {
  classes: any;
}

const Checkbox = ({ classes, ...props }: ICheckboxProps) => {
  return (
    <MaterialCheckbox
      {...props}
      classes={{
        ...(classes !== undefined ? classes : {}),
        root: classes.root,
        checked: classes.checked,
        disabled: classes.disabled,
      }}
    />
  );
};

export default withStyles(styles)(Checkbox);
