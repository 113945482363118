import { LanguageType } from '@nx-smartmonkey/shared/domain';
import { Requests } from '@nx-smartmonkey/shared/helpers';
import { TranslationsRepository } from './TranslationsRepository';

export class TranslationsRepositoryApi implements TranslationsRepository {
  constructor(private requests: Requests) {}

  async getTranslation({
    language,
  }: {
    language: LanguageType;
  }): Promise<{ language: LanguageType; translations: Record<string, string> }> {
    const response = await this.requests.get({
      uri: `/locale`,
      params: {
        language,
        domain: `supervisors`,
      },
    });

    return {
      language,
      translations: response.data,
    };
  }
}
