import { Loader } from '@googlemaps/js-api-loader';
import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

interface GoogleMapsContextProps {
  googleMapsLoaded: boolean;
  googleMapsLibraries: {
    core: google.maps.CoreLibrary | null;
    maps: google.maps.MapsLibrary | null;
    marker: google.maps.MarkerLibrary | null;
    drawing: google.maps.DrawingLibrary | null;
  };
}

const GoogleMapsContext = createContext<GoogleMapsContextProps>({
  googleMapsLoaded: false,
  googleMapsLibraries: {
    core: null,
    maps: null,
    marker: null,
    drawing: null,
  },
});

const MAX_RETRIES = 3;

type LibrariesToLoad =
  | google.maps.CoreLibrary
  | google.maps.MapsLibrary
  | google.maps.MarkerLibrary
  | google.maps.RoutesLibrary
  | google.maps.DrawingLibrary;

const loadLibraries = (loader: Loader, retries = MAX_RETRIES): Promise<LibrariesToLoad[]> => {
  return Promise.allSettled([
    loader.importLibrary(`core`),
    loader.importLibrary(`maps`),
    loader.importLibrary(`marker`),
    loader.importLibrary(`drawing`),
  ]).then((results): LibrariesToLoad[] | Promise<LibrariesToLoad[]> => {
    const loadedLibraries = results
      .filter((result) => result.status === `fulfilled`)
      .map((result) => (result as PromiseFulfilledResult<LibrariesToLoad>).value);
    const failedLibraries = results.filter((result) => result.status === `rejected`);

    if (failedLibraries.length > 0) {
      if (retries === 0) {
        throw new Error(`Failed to load ${failedLibraries.length} libraries after ${MAX_RETRIES} attempts.`);
      } else {
        return loadLibraries(loader, retries - 1);
      }
    }

    return loadedLibraries;
  });
};

interface GoogleMapsProviderProps {
  apiKey: string;
  language: string;
}

let googleMapsLoader: Loader | undefined;

const GoogleMapsProvider = ({ apiKey, language, children }: PropsWithChildren<GoogleMapsProviderProps>) => {
  const [googleMapsLibraries, setGoogleMapsLibraries] = useState<{
    core: google.maps.CoreLibrary | null;
    maps: google.maps.MapsLibrary | null;
    marker: google.maps.MarkerLibrary | null;
    drawing: google.maps.DrawingLibrary | null;
  }>({
    core: null,
    maps: null,
    marker: null,
    drawing: null,
  });

  useEffect(() => {
    let isCancelled = false;

    if (apiKey && googleMapsLoader?.apiKey !== apiKey) {
      const loader = new Loader({
        apiKey,
        version: `weekly`,
        language,
      });

      loadLibraries(loader)
        .then((libs) => {
          if (!isCancelled) {
            setGoogleMapsLibraries({
              core: libs[0] as google.maps.CoreLibrary,
              maps: libs[1] as google.maps.MapsLibrary,
              marker: libs[2] as google.maps.MarkerLibrary,
              drawing: libs[3] as google.maps.DrawingLibrary,
            });
            googleMapsLoader = loader;
          }
        })
        .catch((error) => {
          console.error({
            error,
            message: `GoogleMapsProvider: Error loading Google Maps libraries`,
          });
          googleMapsLoader = undefined;
        });
    }

    return () => {
      isCancelled = true;

      // WARNING!! THIS IS NEEDED DUE TO A BUG IN THE LIBRARY --------- (start)
      // See: https://github.com/googlemaps/js-api-loader/issues/471
      if (googleMapsLoader) {
        // @ts-expect-error
        googleMapsLoader.reset();
        // @ts-expect-error
        Loader.instance = undefined;
      }
      // WARNING!! THIS IS NEEDED DUE TO A BUG IN THE LIBRARY --------- (end)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey]);

  // Això no se perquè ho posava el de l'exemple: https://github.com/googlemaps/js-api-loader/issues/838
  // if (typeof window === `undefined`) {
  //   return children;
  // }

  return (
    <GoogleMapsContext.Provider
      value={{
        googleMapsLoaded: googleMapsLoader !== undefined,
        googleMapsLibraries,
      }}
    >
      {children}
    </GoogleMapsContext.Provider>
  );
};

const useGoogleMapsLibraries = () => {
  return useContext(GoogleMapsContext);
};

export { GoogleMapsProvider, useGoogleMapsLibraries };
