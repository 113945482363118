import { ValueObject } from './ValueObject';

export interface GPSTrackProps {
  lat: number;
  lng: number;
  orientation: number;
  created_at: Date;
}

export class GPSTrack extends ValueObject<GPSTrackProps> {
  get lat(): number {
    return this.props.lat;
  }
  get lng(): number {
    return this.props.lng;
  }
  get orientation(): number {
    return this.props.orientation;
  }
  get created_at(): Date {
    return this.props.created_at;
  }
  private constructor(props: GPSTrackProps) {
    super(props);
  }
  static create(props: GPSTrackProps): GPSTrack {
    return new GPSTrack(props);
  }

  isValid(): boolean {
    return !!(this.lat && this.lng);
  }
}
