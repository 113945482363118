import { Grid } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import { RoutalPalette } from '@nx-smartmonkey/ui';
import CrossIcon from '../../../assets/svg/CrossIcon';

const StyledCloseIcon = styled(CrossIcon)`
  width: 15px !important;
  height: 15px !important;
  font-size: 15px !important;
  color: ${RoutalPalette.secondary40};
  padding: 4px;
  cursor: pointer;
`;

const CloseIcon = ({ onClose }: { onClose?: (...args: any[]) => any }) => {
  return <StyledCloseIcon onClick={onClose} />;
};

interface RightPanelHeaderProps {
  onClose: (...args: any[]) => any;
}

export const RightPanelHeader = ({ onClose, children }: React.PropsWithChildren<RightPanelHeaderProps>) => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      style={{
        padding: `0 22px`,
        minHeight: `62px`,
        borderBottom: `1px solid ${RoutalPalette.neutral10}`,
      }}
      wrap="nowrap"
    >
      {children}
      <CloseIcon onClose={onClose} />
    </Grid>
  );
};

export const InfoContainer = styled(Grid)`
  padding: 12px 20px;
`;
