import { Grid } from '@mui/material';
import { RoutalLoader, RoutalPalette } from '@nx-smartmonkey/ui';

import { useRetrieveProject } from '../hooks/project/useRetrieveProject';
import { Text } from '../react-components/Text';

interface LoaderPageProps {
  showText?: boolean;
}

export const LoaderPage = ({ showText = true }: LoaderPageProps) => {
  const { data: project } = useRetrieveProject();

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" style={{ height: `100%` }}>
      <Grid item>
        <RoutalLoader color={project?.style?.primary_color?.value} />
      </Grid>

      {showText ? (
        <Grid item>
          <Text
            variant="h6"
            intlId="loading"
            style={{ color: project?.style?.primary_color?.value ?? RoutalPalette.primary20 }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};
