import { Supervisor } from '../domain/supervisor/Supervisor';
import { sessionRepository } from '../repositories/session';
import { SessionRepository } from '../repositories/session/SessionRepository';
import { supervisorRepository } from '../repositories/supervisor';
import { SupervisorRepository } from '../repositories/supervisor/SupervisorRepository';
import { webSocketsService } from './WebSocketsService';

class SupervisorService {
  constructor(private supervisorRepository: SupervisorRepository, private sessionRepository: SessionRepository) {}

  retrieveDetails({ isExampleURL }: { isExampleURL?: boolean }): Promise<Supervisor> {
    const supervisor = this.supervisorRepository.retrieveDetails({ isExampleURL: isExampleURL });

    if (!isExampleURL) {
      webSocketsService.connect(this.sessionRepository.getToken()!);
    }

    return supervisor;
  }

  save(supervisor: Supervisor): Promise<Supervisor> {
    return this.supervisorRepository.save(supervisor);
  }
}

export const supervisorService = new SupervisorService(supervisorRepository, sessionRepository);
