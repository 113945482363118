import { OutlinedInput, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import isEqual from 'lodash/isEqual';
import { Component } from 'react';

const styles = (_theme: any) => ({
  container: {
    height: `100%`,
    width: `100%`,
  },

  noPadding: {
    padding: `2px 4px`,
  },

  children: {
    '&:hover': {
      backgroundColor: `#EEEFF2`,
    },
    borderRadius: `2px`,
    padding: `2px 4px`,
    width: `calc(100% - 8px)`,
  },
});

type OwnEditTextFieldProps = {
  classes: {};
  handleEnter: (...args: any[]) => any;
  valueField?: string;
};

type EditTextFieldState = any;

type EditTextFieldProps = OwnEditTextFieldProps & typeof EditTextField.defaultProps;

class EditTextField extends Component<EditTextFieldProps, EditTextFieldState> {
  static defaultProps: any;

  override state = {
    editing: false,
    value: this.props[`valueField`] || ``,
  };

  override componentDidUpdate = (prevProps: any) => {
    const { valueField } = this.props;
    if (!isEqual(prevProps.valueField, valueField)) {
      this.setState({
        value: valueField,
      });
    }
  };

  handleClick = () => {
    this.setState({
      editing: true,
    });
  };

  handleChange = (event: any) => {
    const { value } = event.target;
    this.setState({ value });
  };

  handleKeyPress = (event: any) => {
    const { handleEnter, valueField } = this.props;
    const { value } = this.state;
    switch (event.key) {
      case `Enter`:
        if (handleEnter) {
          handleEnter(value);
        }
        this.setState({ editing: false });
        break;
      case `Escape`:
        this.setState({
          value: valueField,
          editing: false,
        });
        break;
      default:
    }
  };

  handleBlur = () => {
    const { handleEnter } = this.props;
    const { value } = this.state;
    if (handleEnter) {
      handleEnter(value);
    }
    this.setState({ editing: false });
  };

  override render() {
    const { classes, children, tooltip } = this.props;
    const { editing, value } = this.state;
    let textField = (
      <div className={classes.children} onClick={this.handleClick}>
        {children}
      </div>
    );
    if (tooltip) {
      textField = <Tooltip title={tooltip}>{textField}</Tooltip>;
    }
    return (
      <div className={classes.container}>
        {editing ? (
          <OutlinedInput
            onKeyDown={this.handleKeyPress}
            onBlur={this.handleBlur}
            value={value}
            onChange={this.handleChange}
            autoFocus
            fullWidth
            classes={{ input: classes.noPadding }}
          />
        ) : (
          textField
        )}
      </div>
    );
  }
}

EditTextField.defaultProps = {
  valueField: undefined,
};

export default withStyles(styles)(EditTextField);
