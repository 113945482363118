import { ServiceResponse } from '@nx-smartmonkey/shared/hooks';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { projectService } from '../../application/ProjectService';
import { ProjectActionType } from '../../context/project/actions';
import { useProjectContext } from '../../context/project/context';
import { Project } from '../../domain/project/Project';
import { useIsExampleURL } from '../useIsExampleURL';

type UseRetrieveProjectResponseProps = ServiceResponse<void, Project>;

// Hooks act as 'Adapter' layer.
export const useRetrieveProject = (): UseRetrieveProjectResponseProps => {
  const { isExampleURL } = useIsExampleURL();

  const host = window.location.host;
  // const host = `63ed3e9791e5f7f99ebc60ab.smartmonkey.net`;
  const { projectState, projectDispatch } = useProjectContext();

  const { isInitialLoading: isLoading, isSuccess } = useQuery(
    [`supervisors_project`, host],
    () => projectService.findByHost({ host, isExampleURL: isExampleURL }),
    {
      retry: 1,
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        projectDispatch({ type: ProjectActionType.FETCH_PROJECT, payload: res });
      },
      onError: (err: any) => {
        console.info(`Retrieve Project Error: `, err?.response);
        projectDispatch({ type: ProjectActionType.FETCH_PROJECT_FAILURE });
      },
    }
  );

  useEffect(() => {
    if (isLoading) {
      projectDispatch({ type: ProjectActionType.LOADING_PROJECT });
    }
  }, [projectDispatch, isLoading]);

  return {
    success: isSuccess,
    error: projectState.error,
    isLoading: projectState.isLoading,
    data: projectState.project,
    run: undefined,
  };
};
