import { Requests } from '@nx-smartmonkey/shared/helpers';

import { Supervisor } from '../../domain/supervisor/Supervisor';
import { SupervisorMapper } from '../../mappers/supervisor/SupervisorMapper';
import { SessionRepository } from '../session/SessionRepository';
import { SupervisorRepository } from './SupervisorRepository';

export class SupervisorRepositoryApi implements SupervisorRepository {
  constructor(private requests: Requests, private sessionRepository: SessionRepository) {}
  async retrieveDetails({ isExampleURL }: { isExampleURL?: boolean }): Promise<Supervisor> {
    if (isExampleURL) {
      const response = await this.requests.get({
        uri: `/supervisors/v1/example`,
      });
      return SupervisorMapper.toDomain(response.data);
    }

    const asSupervisor = this.sessionRepository.getAsSupervisor();

    const response = await this.requests.get({
      uri: `/supervisors/v1/details`,
      ...(asSupervisor
        ? {
            params: {
              as_supervisor: `${asSupervisor}`,
            },
          }
        : {}),
      headers: this.sessionRepository.getAuthenticationHeader(),
    });
    return SupervisorMapper.toDomain(response.data);
  }

  async save(supervisor: Supervisor): Promise<Supervisor> {
    const urlParams = new URLSearchParams();
    const asSupervisor = this.sessionRepository.getAsSupervisor();
    if (asSupervisor) urlParams.append(`as_supervisor`, `${asSupervisor}`);

    const response = await this.requests.put({
      uri: `/supervisors/v1/supervisor?${urlParams.toString()}`,
      data: SupervisorMapper.toRepository(supervisor),
      headers: this.sessionRepository.getAuthenticationHeader(),
    });
    return SupervisorMapper.toDomain(response.data);
  }
}
