import { PropsWithChildren, useReducer } from 'react';

import { StopsContext } from './context';
import { StopsReducer, getInitialState } from './reducer';

export const StopsContextProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const initialState = getInitialState();
  const [state, dispatch] = useReducer(StopsReducer, initialState);
  return (
    <StopsContext.Provider
      value={{
        stopsState: state,
        stopsDispatch: dispatch,
      }}
    >
      {children}
    </StopsContext.Provider>
  );
};
