import { Report } from '@nx-smartmonkey/shared/domain';
import { RoutalPalette } from '@nx-smartmonkey/ui';

import { StopStatus } from '../domain/stop/StopStatus';

export const STATUS_COLOR_LIGHTEN_AMOUNT = 35;

export const getStopStatusColor = (status: StopStatus, type?: `light` | `medium` | `dark`) => {
  switch (status) {
    case `completed`:
      return RoutalPalette.markerStopColors.completed[type ?? `medium`];
    case `incomplete`:
      return RoutalPalette.markerStopColors.incomplete[type ?? `medium`];
    case `canceled`:
      return RoutalPalette.markerStopColors.canceled[type ?? `medium`];
    default:
      return RoutalPalette.stopStatusColors.pending[type ?? `medium`];
  }
};

export const getReportStatusColor = (report: Report) => {
  let color;
  switch (report.type) {
    case `service_report_completed`:
      color = getStopStatusColor(`completed`, `dark`);
      // TASKS NOT VISIBLE IN SUPERVISORS FOR THE MOMENT
      // if ((report.tasks ?? []).length > 0) {
      //   if (report.tasks!.some((task) => task.status !== `completed`)) {
      //     color = getStopStatusColor(`incomplete`);
      //   }
      // }
      break;
    case `service_report_canceled`:
      color = getStopStatusColor(`canceled`, `dark`);
      break;
  }
  return color;
};
