import { createContext, useContext } from 'react';
import { ProjectActions } from './actions';
import { getInitialState } from './reducer';

export const ProjectContext = createContext({
  projectState: getInitialState(),
  projectDispatch: (action: ProjectActions) => {},
});

ProjectContext.displayName = `ProjectContext`;

export const useProjectContext = () => useContext(ProjectContext);
