import { HexColor } from '@nx-smartmonkey/shared/domain';
import { StopFlat } from '../../domain/stop/StopFlat';
import { StopFlatDTO } from '../../domain/stop/StopFlatDTO';

export class StopFlatMapper {
  static toDomain(props: StopFlatDTO): StopFlat {
    const { id, status, type, label, lat, lng, color, created_at } = props;

    return StopFlat.create(
      {
        label,
        lat,
        lng,
        status,
        type,
        color: color ? HexColor.create(color) : undefined,
        created_at,
      },
      id
    );
  }
}
