import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface StyledRippleProps {
  animate: boolean;
}

const StyledRipple = styled.div<StyledRippleProps>`
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  opacity: 1;
  transform: scale(0);

  ${({ animate }: StyledRippleProps) => (animate ? `animation: ripple 0.5s linear;` : ``)}

  @keyframes ripple {
    100% {
      opacity: 0;
      transform: scale(3);
    }
  }
`;

export interface RoutalCursorPosProps {
  top: number;
  left: number;
  time: number;
}

interface RCRippleProps {
  cursorPos?: RoutalCursorPosProps;
}

interface StateProps {
  animate: boolean;
  width: number;
  height: number;
  top: number;
  left: number;
  lastCursorPos?: RoutalCursorPosProps;
}

const RoutalRipple = ({ cursorPos }: RCRippleProps) => {
  const rippleRef = useRef(null);

  const [state, setState] = useState<StateProps>({
    animate: false,
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    lastCursorPos: undefined,
  });

  useEffect(() => {
    if (cursorPos) {
      // Prevent Component duplicates do ripple effect at the same time
      if (cursorPos?.time !== state.lastCursorPos?.time) {
        // If Has Animated, set state to "false" First
        if (state.animate) {
          setState({ ...state, animate: false });
          return;
        }
        // Else, Do Reppling
        reppling(cursorPos);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursorPos]);

  useEffect(() => {
    if (state.animate && state.lastCursorPos) {
      reppling(state.lastCursorPos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.animate, state.lastCursorPos]);

  const reppling = (pos: RoutalCursorPosProps) => {
    // @ts-expect-error
    const $button = rippleRef.current?.parentElement;

    // const buttonStyle = window.getComputedStyle($button);
    const buttonPos = $button.getBoundingClientRect();

    const buttonWidth = $button.offsetWidth;
    const buttonHeight = $button.offsetHeight;

    // Make a Square Ripple
    const rippleWidthShouldBe = Math.max(buttonHeight, buttonWidth);

    // Make Ripple Position to be center
    const centerize = rippleWidthShouldBe / 2;

    setState({
      ...state,
      animate: true,
      width: rippleWidthShouldBe,
      height: rippleWidthShouldBe,
      top: pos.top - buttonPos.top - centerize,
      left: pos.left - buttonPos.left - centerize,
    });
  };

  return (
    <StyledRipple
      ref={rippleRef}
      animate={state.animate}
      style={{
        top: `${state.top}px`,
        left: `${state.left}px`,
        width: `${state.width}px`,
        height: `${state.height}px`,
      }}
    />
  );
};

export default RoutalRipple;
