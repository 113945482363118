import {
  CustomFieldCategoricalMultiple,
  CustomFieldCategoricalSingle,
  CustomFieldDefinition,
  CustomFieldsMapper,
  CustomFields as CustomFieldsValueObject,
} from '@nx-smartmonkey/shared/domain';
import { VSpacer } from '@nx-smartmonkey/ui';

import { CustomFieldFormBoolean } from './CustomFieldFormBoolean';
import { CustomFieldFormCategorical } from './CustomFieldFormCategorical';
import CustomFieldFormText from './CustomFieldFormText';

type CustomFieldsProps = {
  onUpdate?: (customFields: CustomFieldsValueObject) => void;
  customFields?: CustomFieldsValueObject;
  projectCustomFields: CustomFieldDefinition[];
  readOnly?: boolean;
  onlyValues?: boolean;
};

export const CustomFields = ({
  customFields,
  onUpdate = () => {},
  projectCustomFields,
  readOnly = false,
  onlyValues = false,
}: CustomFieldsProps) => {
  const handleUpdate = (fieldId: string, val: any) => {
    if (onUpdate) {
      if (customFields) {
        customFields.update({ [fieldId]: val });
        onUpdate(CustomFieldsValueObject.create(CustomFieldsMapper.toDTO(customFields), projectCustomFields));
      } else {
        onUpdate(CustomFieldsValueObject.create({ [fieldId]: val }, projectCustomFields));
      }
    }
  };

  return (
    <>
      {projectCustomFields
        .filter((customField) => customField.enabled)
        .map((projectField: CustomFieldDefinition) => {
          switch (projectField.field_type) {
            case `text`:
            case `numerical`:
              return (
                <>
                  <CustomFieldFormText
                    key={projectField.custom_id}
                    projectField={projectField}
                    fieldValue={
                      (customFields?.value[projectField.custom_id]
                        ? customFields?.value[projectField.custom_id]
                        : ``) as string | number
                    }
                    readOnly={readOnly}
                    onlyValues={onlyValues}
                    onUpdate={(value: any) => handleUpdate(projectField.custom_id, value)}
                  />
                  <VSpacer small />
                </>
              );
            case `boolean`:
              return (
                <>
                  <CustomFieldFormBoolean
                    key={projectField.custom_id}
                    projectField={projectField}
                    fieldValue={
                      (customFields?.value[projectField.custom_id]
                        ? customFields?.value[projectField.custom_id]
                        : false) as boolean
                    }
                    readOnly={readOnly}
                    onlyValues={onlyValues}
                    onUpdate={(value: any) => handleUpdate(projectField.custom_id, value)}
                  />
                  <VSpacer small />
                </>
              );
            case `categorical`: {
              let fieldValue: CustomFieldCategoricalSingle | CustomFieldCategoricalMultiple | undefined = customFields
                ?.value[projectField.custom_id]
                ? (customFields?.value[projectField.custom_id] as
                    | CustomFieldCategoricalSingle
                    | CustomFieldCategoricalMultiple)
                : undefined;

              if (fieldValue && !projectField.multiple) {
                fieldValue = fieldValue ? ([fieldValue] as CustomFieldCategoricalMultiple) : undefined;
              }

              return (
                <>
                  <CustomFieldFormCategorical
                    key={projectField.custom_id}
                    projectField={projectField}
                    fieldValue={fieldValue as CustomFieldCategoricalMultiple | undefined}
                    readOnly={readOnly}
                    onlyValues={onlyValues}
                    onUpdate={(values: number[] | null) => {
                      if ((values ?? []).length === 0) {
                        handleUpdate(projectField.custom_id, null);
                      } else {
                        if (!projectField.multiple) {
                          handleUpdate(projectField.custom_id, {
                            id: values![0],
                            label: projectField.options![values![0]],
                          });
                        } else {
                          handleUpdate(
                            projectField.custom_id,
                            values!.map((value) => {
                              return {
                                id: value,
                                label: projectField.options![value],
                              };
                            })
                          );
                        }
                      }
                    }}
                  />
                  <VSpacer small />
                </>
              );
            }
            default:
              return null;
          }
        })}
    </>
  );
};
