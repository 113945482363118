import './styles.css';

import { Add as ZoomInIcon, Remove as ZoomOutIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import _ from 'lodash';
import { RoutalPalette } from '../../new_components';
import MapCrosshair from '../assets/svg/MapCrosshair';
import { GoogleMapsProvider, useGoogleMapsLibraries } from './context/GoogleMapsProvider';
import { GeoFenceDomain, GeoFenceDomainProps } from './geo-fence/GeoFenceDomain';
import { MarkerDomain, MarkerDomainProps } from './marker/MarkerDomain';
import { RouteProps, createRoutePolyline } from './Route';
import { createStyledMapRoutal, createStyledMapRoutalDark } from './StyledMaps';

// Google Maps styles (in 2021/07/9)
const useStyles = makeStyles(() => ({
  iconContainer: {
    backgroundColor: `rgba(255,255,255,1)`,
    borderRadius: `2px`,
    boxShadow: `0 1px 4px rgb(0 0 0 / 30%)`,
    display: `block`,
    width: `24px`,
    height: `24px`,
    cursor: `pointer`,
    marginBottom: `5px`,
    marginRight: `5px`,
    transition: `background-color 0.16s ease-out`,
  },
  // These !important are needed because the Google Maps API adds inline styles to the elements.
  icon: {
    cursor: `pointer!important`,
    color: `#666666!important`,
    margin: `2px!important`,
    width: `20px!important`,
    height: `20px!important`,
    '&:hover': {
      color: `#333333!important`,
    },
  },
}));

export const availableMapStyles = [`roadmap`, `hybrid`, `desert`, `routal_dark`, `routal`];
export type AvailableMapStylesType = (typeof availableMapStyles)[number];

const MAP_MIN_ZOOM = 3;
const MAP_MAX_ZOOM = 19;

const normalMapId = `8750043978b480cc`;

export type LatLng = { lat: number; lng: number };

const defaultMapProps = {
  height: 500,
  center: {
    lat: 41.3952165,
    lng: 2.1258438,
  },
  zoomLevel: 11,
  rebounds: {
    markers: true,
    routes: true,
    geoFences: true,
  },
  //mapType default is in component initialization
  onContextualMenu: (e: any) => {},
};

interface GoogleMapComponentProps {
  center?: LatLng;
  height?: number | string;
  zoomLevel?: number;
  markers?: MarkerDomainProps[];
  routes?: RouteProps[];
  geoFences?: GeoFenceDomainProps[];
  traffic?: boolean;
  scrollWheelZoom?: boolean;
  zoomControl?: boolean;
  keyboard?: boolean;
  rebounds?: {
    markers: boolean;
    routes: boolean;
    geoFences: boolean;
  };
  mapType?: AvailableMapStylesType;
  reboundsControl?: boolean;
  disableDoubleClickZoom?: boolean;
  loadingComponent?: React.ReactElement;
  markerOnClick?: (e: any, id: string, isRouteSpecificMarker: boolean) => void;
  markerOnDoubleClick?: (e: any, id: string, isRouteSpecificMarker: boolean) => void;
  onContextualMenu?: (e: any) => void;
  onDrawableSelection?: (coordinates: { lat?: number; lng?: number }[]) => void;
}

const GoogleMapComponent = ({
  center,
  height,
  zoomLevel,
  markers,
  routes,
  geoFences,
  traffic = false,
  scrollWheelZoom = true,
  zoomControl = true,
  keyboard = true,
  rebounds = defaultMapProps.rebounds,
  reboundsControl,
  disableDoubleClickZoom = false,
  loadingComponent,
  mapType = `routal`,
  markerOnClick,
  markerOnDoubleClick,
  onContextualMenu,
  onDrawableSelection,
}: GoogleMapComponentProps) => {
  const classes = useStyles();
  const { googleMapsLoaded } = useGoogleMapsLibraries();

  const mapRef = useRef<HTMLDivElement | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const [zoom, setZoom] = useState<number>(zoomLevel ?? defaultMapProps.zoomLevel);
  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>(center ?? defaultMapProps.center);

  const [mapMarkers, setMapMarkers] = useState<Record<string, MarkerDomain>>({});
  const [mapGeoFences, setMapGeoFences] = useState<Record<string, GeoFenceDomain>>({});
  const [mapDrawingManager, setMapDrawingManager] = useState<google.maps.drawing.DrawingManager | undefined>(undefined);

  // This markers are the route specific `start_location`, `end_location` and `current_position`
  const [routesPolyline, setRoutesPolyline] = useState<
    Array<{ routePolyline: google.maps.Polyline; transparentRoutePolyline: google.maps.Polyline }>
  >([]);

  const [isFullyLoaded, setIsFullyLoaded] = useState<boolean>(false);
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);

  const trafficLayerRef = useRef<google.maps.TrafficLayer | null>(null);

  const initializeMap = () => {
    if (mapRef.current && googleMapsLoaded) {
      console.info(`NEW GOOGLE MAPS LOADED`);

      const newMap = new google.maps.Map(mapRef.current, {
        mapId: normalMapId,
        disableDefaultUI: true,
        center: center ?? defaultMapProps.center,
        zoom: zoomLevel ?? defaultMapProps.zoomLevel,
        scrollwheel: scrollWheelZoom,
        disableDoubleClickZoom,
        keyboardShortcuts: keyboard,
        maxZoom: MAP_MAX_ZOOM,
        minZoom: MAP_MIN_ZOOM,
        zoomControl: false,
        scaleControl: false,
        rotateControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeId: mapType,
        mapTypeControlOptions: {
          mapTypeIds: availableMapStyles,
        },
      });

      //Associate the styled map with the MapTypeId and set it to display.
      newMap.mapTypes.set(`routal`, createStyledMapRoutal());
      newMap.mapTypes.set(`routal_dark`, createStyledMapRoutalDark());

      google.maps.event.addListener(newMap, `rightclick`, onContextualMenu ?? defaultMapProps.onContextualMenu);

      google.maps.event.addListener(newMap, `zoom_changed`, () => {
        setZoom(newMap!.getZoom()!);
      });

      if (onDrawableSelection) {
        const drawingManager = new google.maps.drawing.DrawingManager();

        if (drawingManager.getMap()) {
          drawingManager.setMap(null);
        }

        drawingManager.setOptions({
          drawingControl: true,
          drawingControlOptions: {
            position: window.google ? google.maps.ControlPosition.RIGHT_BOTTOM : 6.0,
            drawingModes: window.google
              ? [google.maps.drawing.OverlayType.POLYGON, google.maps.drawing.OverlayType.RECTANGLE]
              : [],
          },
          polygonOptions: {
            strokeColor: RoutalPalette.primary40,
            strokeWeight: 4,
            strokeOpacity: 0.4,
            fillColor: RoutalPalette.primary40,
            fillOpacity: 0.2,
          },
          rectangleOptions: {
            strokeColor: RoutalPalette.primary40,
            strokeWeight: 4,
            strokeOpacity: 0.4,
            fillColor: RoutalPalette.primary40,
            fillOpacity: 0.2,
          },
        });

        drawingManager.setMap(newMap);

        google.maps.event.addListener(drawingManager, `polygoncomplete`, polygonEventListener);
        google.maps.event.addListener(drawingManager, `rectanglecomplete`, rectangleEventListener);

        setMapDrawingManager(drawingManager);
      }

      setMap(newMap);
      setIsFullyLoaded(true);
    }
  };

  useEffect(() => {
    initializeMap();
  }, [googleMapsLoaded]);

  useEffect(() => {
    if (mapRef.current && googleMapsLoaded && map) {
      map.setMapTypeId(mapType);
    }
  }, [mapType]);

  useEffect(() => {
    if (mapRef.current && googleMapsLoaded) {
      if (!trafficLayerRef.current) {
        trafficLayerRef.current = new google.maps.TrafficLayer();
      }

      if (traffic) {
        trafficLayerRef.current.setMap(map);
      } else {
        trafficLayerRef.current.setMap(null);
      }
    }
    return () => {
      if (trafficLayerRef.current) {
        trafficLayerRef.current.setMap(null);
      }
    };
  }, [traffic]);

  useEffect(() => {
    if (!isFirstTime && zoomLevel && zoomLevel !== zoom) {
      setZoom(zoomLevel);
      map?.setZoom(zoomLevel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomLevel]);

  useEffect(() => {
    if (!isFirstTime && center && (center.lat !== mapCenter.lat || center.lng !== mapCenter.lng)) {
      setMapCenter(center);
      map?.setCenter(center);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center]);

  const resetDrawingListeners = () => {
    if (mapDrawingManager && onDrawableSelection) {
      google.maps.event.clearListeners(mapDrawingManager, `rectanglecomplete`);
      google.maps.event.addListener(mapDrawingManager, `rectanglecomplete`, rectangleEventListener);

      google.maps.event.clearListeners(mapDrawingManager, `polygoncomplete`);
      google.maps.event.addListener(mapDrawingManager, `polygoncomplete`, polygonEventListener);
    }
  };

  useEffect(() => {
    if (!isFirstTime) {
      drawMarkers();
      resetDrawingListeners();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markers]);

  useEffect(() => {
    if (!isFirstTime) {
      drawGeoFences();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoFences]);

  useEffect(() => {
    if (!isFirstTime) {
      drawRoutes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes, zoom]);

  useEffect(() => {
    if (isFullyLoaded && isFirstTime) {
      drawGeoFences();
      drawRoutes();
      drawMarkers();

      resetDrawingListeners();

      if (isFirstTime) {
        let bounds = getMarkersBounds(undefined);
        bounds = getGeoFencesBounds(bounds);
        if (bounds) {
          map?.fitBounds(bounds);
          setZoom(map?.getZoom() ?? zoom);
        }

        setIsFirstTime(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullyLoaded, routes, markers, geoFences, mapMarkers]);

  const getMarkersBounds = useCallback(
    (bounds: google.maps.LatLngBounds | undefined) => {
      let markersBounds: google.maps.LatLngBounds | undefined = bounds;

      // Fit bounds to markers
      if (isFullyLoaded && (markers ?? []).length > 0) {
        markersBounds = markersBounds ?? new google.maps.LatLngBounds();

        markers!.forEach((marker: MarkerDomainProps) => {
          markersBounds!.extend(new google.maps.LatLng(marker.lat, marker.lng));
        });

        // Here we are extending the bounds because when there is only one marker zoom is too high.
        if (markersBounds.getNorthEast().equals(markersBounds.getSouthWest())) {
          const northEastExtendPoint = new google.maps.LatLng(
            markersBounds.getNorthEast().lat() + 0.05,
            markersBounds.getNorthEast().lng() + 0.05
          );
          markersBounds.extend(northEastExtendPoint);

          const southWestExtendPoint = new google.maps.LatLng(
            markersBounds.getSouthWest().lat() - 0.05,
            markersBounds.getSouthWest().lng() - 0.05
          );
          markersBounds.extend(southWestExtendPoint);
        }
      }

      return markersBounds;
    },
    [isFullyLoaded, markers]
  );

  const getGeoFencesBounds = useCallback(
    (bounds: google.maps.LatLngBounds | undefined) => {
      let geoFencesBounds: google.maps.LatLngBounds | undefined = bounds;

      // Fit bounds to geo fences points
      if (isFullyLoaded && (geoFences ?? []).length > 0) {
        geoFencesBounds = geoFencesBounds ?? new google.maps.LatLngBounds();

        const geoFencesPoints = geoFences!.reduce((points, geoFence) => {
          return points.concat(...geoFence.paths.map((polygon) => polygon));
        }, [] as Array<{ lat: number; lng: number }>);

        geoFencesPoints.forEach((point: { lat: number; lng: number }) => {
          geoFencesBounds!.extend(new google.maps.LatLng(point.lat, point.lng));
        });
      }

      return geoFencesBounds;
    },
    [isFullyLoaded, geoFences]
  );

  const drawRoutes = useCallback(async () => {
    routesPolyline.forEach(({ routePolyline, transparentRoutePolyline }) => {
      routePolyline.setMap(null);
      transparentRoutePolyline.setMap(null);
    });

    if ((routes ?? []).length > 0) {
      setRoutesPolyline(
        routes!.map((route) => {
          const { routePolyline, transparentRoutePolyline } = createRoutePolyline(route);

          routePolyline.setMap(map!);

          transparentRoutePolyline.setMap(map!);

          return { routePolyline, transparentRoutePolyline };
        })
      );
    }
  }, [map, routes, routesPolyline]);

  const drawMarkers = useCallback(() => {
    const newMapMarkersMap: Record<string, MarkerDomain> = {};
    const updatedMapMarkers: Record<string, MarkerDomain> = {};

    for (const markerProps of markers ?? []) {
      const mapMarker = mapMarkers[markerProps.id as string];
      if (!mapMarker) {
        const markerProp = MarkerDomain.create({
          ...markerProps,
          onClick: markerOnClick,
          onDoubleClick: markerOnDoubleClick,
        });
        markerProp.googleMarker!.map = map!;
        newMapMarkersMap[markerProp.id] = markerProp;
      } else if (!mapMarker.hasSameProps(markerProps)) {
        mapMarker.updateProps({
          ...markerProps,
          onClick: markerOnClick,
          onDoubleClick: markerOnDoubleClick,
        });
        mapMarker.updateMarker();
        updatedMapMarkers[mapMarker.id] = mapMarker;
      } else {
        newMapMarkersMap[mapMarker.id] = mapMarker;
      }
    }

    // Add the newMapMarkersMap to the map.
    Object.values(newMapMarkersMap).forEach((mapMarker) => {
      if (!mapMarkers[mapMarker.id]) {
        mapMarker.googleMarker!.map = map!;
      }
    });

    // Update the markers that have changed.
    Object.values(updatedMapMarkers).forEach((mapMarker) => {
      mapMarker.updateMarker();
    });

    // Remove the markers that are not in the new markers.
    Object.values(mapMarkers).forEach((mapMarker) => {
      if (!newMapMarkersMap[mapMarker.id] && !updatedMapMarkers[mapMarker.id]) {
        mapMarker.googleMarker!.map = null;
      }
    });

    setMapMarkers({ ...newMapMarkersMap, ...updatedMapMarkers });
  }, [mapMarkers, markers, markerOnClick, markerOnDoubleClick, map]);

  const drawGeoFences = useCallback(() => {
    Object.values(mapGeoFences).forEach((geoFence) => {
      geoFence.googlePolygon!.setMap(null);
    });

    if ((geoFences ?? []).length > 0) {
      const geoFencesDomains = geoFences!.reduce((amount, geoFence) => {
        const domain = GeoFenceDomain.create(geoFence);
        domain.googlePolygon?.setMap(map!);
        return {
          ...amount,
          [geoFence.id]: domain,
        };
      }, {});
      setMapGeoFences(geoFencesDomains);
    }
  }, [map, geoFences, mapGeoFences]);

  const polygonEventListener = (polygon: any) => {
    if (mapDrawingManager && onDrawableSelection) {
      const path = polygon.getPath();

      const coordinates: { lat?: number; lng?: number }[] = [];
      path.forEach((point: google.maps.LatLng) => coordinates.push({ lat: point.lat(), lng: point.lng() }));

      coordinates.push(coordinates[0]);

      // Removing the drawn polygon
      polygon.setMap(null);

      // Resets the drawing mode selection
      mapDrawingManager?.setOptions({ drawingMode: null });

      if (coordinates.length >= 4) {
        onDrawableSelection(coordinates);
      }
    }
  };

  const rectangleEventListener = (rectangle: any) => {
    if (mapDrawingManager && onDrawableSelection) {
      const rectangleBounds = rectangle.getBounds();
      const sw = rectangleBounds?.getSouthWest();
      const ne = rectangleBounds?.getNorthEast();

      // Removing the drawn rectangle
      rectangle.setMap(null);

      const coordinates: { lat?: number; lng?: number }[] = [
        {
          lat: sw?.lat(),
          lng: sw?.lng(),
        },
        {
          lat: sw?.lat(),
          lng: ne?.lng(),
        },
        {
          lat: ne?.lat(),
          lng: ne?.lng(),
        },
        {
          lat: ne?.lat(),
          lng: sw?.lng(),
        },
        // Last and first position must be equivalent
        {
          lat: sw?.lat(),
          lng: sw?.lng(),
        },
      ];

      // Removing the drawn rectangle
      rectangle.setMap(null);

      // Resets the drawing mode selection
      mapDrawingManager?.setOptions({ drawingMode: null });

      onDrawableSelection(coordinates);
    }
  };

  const resetPosition = () => {
    let bounds: google.maps.LatLngBounds | undefined;

    const selectedMarkers = markers?.filter((m) => m.selected) ?? [];

    // Fit bounds to selected markers
    if (selectedMarkers?.length > 0) {
      if (rebounds.markers && (markers ?? []).filter((m) => !m.selected).length > 0) {
        bounds = bounds ?? new google.maps.LatLngBounds();
        markers!
          .filter((m) => !m.isRouteSpecificMarker)
          .forEach((marker: MarkerDomainProps) => {
            bounds!.extend(new google.maps.LatLng(marker.lat, marker.lng));
          });
      }
    } else {
      // Fit to all content
      if (rebounds.markers) {
        // Fit bounds to markers
        bounds = getMarkersBounds(bounds);
      }
      if (rebounds.geoFences) {
        // Fit bounds to geo fences points
        bounds = getGeoFencesBounds(bounds);
      }

      // Fit bounds to route polyline
      // TODO això no se si funciona!!!
      // if(rebounds.routes && (routesPolyline ?? []).length > 0) {
      //   bounds = bounds ?? new google.maps.LatLngBounds();
      //   routesPolyline.forEach(({ routePolyline }: { routePolyline: google.maps.Polyline; transparentRoutePolyline: google.maps.Polyline; }) => {
      //     routePolyline.getPath().forEach((point: google.maps.LatLng) => bounds!.extend(point));
      //   });
      // }
    }

    if (bounds) {
      map!.fitBounds(bounds);
    }
  };

  return (
    <div
      style={{
        position: `relative`,
        width: `100%`,
        height: height ?? defaultMapProps.height,
      }}
    >
      {loadingComponent && !googleMapsLoaded ? (
        <div
          style={{
            display: `flex`,
            position: `absolute`,
            width: `100%`,
            height: `100%`,
            flexDirection: `column`,
            alignItems: `center`,
            justifyContent: `center`,
            zIndex: 100,
            backgroundColor: `#fcf2e35e`,
          }}
        >
          {loadingComponent}
        </div>
      ) : null}
      <div
        style={{
          position: `absolute`,
          zIndex: 100,
          right: 0,
          bottom: onDrawableSelection ? 48 : 8,
        }}
      >
        {reboundsControl ? (
          <div className={classes.iconContainer} onClick={resetPosition}>
            <MapCrosshair className={classes.icon} />
          </div>
        ) : null}

        {zoomControl ? (
          <div
            style={{
              display: `flex`,
              flexDirection: `column`,
              // Google Maps styles (in 2021/07/9)
              boxShadow: `0px 1px 4px rgb(0 0 0 / 30%)`,
              borderRadius: `2px`,
              backgroundColor: `white`,
              marginBottom: `5px`,
              marginRight: `5px`,
              alignItems: `center`,
            }}
          >
            <div
              style={{
                height: `24px`,
                width: `24px`,
              }}
              onClick={() => {
                const mapZoom = map!.getZoom()!;
                if (mapZoom < MAP_MAX_ZOOM) {
                  const newZoom = mapZoom + 1;
                  map!.setZoom(newZoom);
                }
              }}
            >
              <ZoomInIcon className={classes.icon} />
            </div>
            <div
              style={{
                backgroundColor: `rgb(90%,90%,90%)`,
                height: `1px`,
                width: `19px`,
              }}
            />
            <div
              style={{
                height: `24px`,
                width: `24px`,
              }}
              onClick={() => {
                const mapZoom = map!.getZoom()!;
                if (mapZoom > MAP_MIN_ZOOM) {
                  const newZoom = mapZoom - 1;
                  map!.setZoom(newZoom);
                }
              }}
            >
              <ZoomOutIcon className={classes.icon} />
            </div>
          </div>
        ) : null}
      </div>

      <div
        id="map"
        ref={mapRef}
        style={{
          height: `100%`,
          width: `100%`,
        }}
      />
    </div>
  );
};

const MemoizedGoogleMapComponent = memo(GoogleMapComponent, areEqual);

function areEqual(prevProps: GoogleMapComponentProps, nextProps: GoogleMapComponentProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  if (!_.isEqual(prevProps.center, nextProps.center)) {
    // console.info(`------------- GoogleMap areEqual -> center has changed`);
    return false;
  }
  if (!_.isEqual(prevProps.height, nextProps.height)) {
    // console.info(`------------- GoogleMap areEqual -> height has changed`);
    return false;
  }
  if (!_.isEqual(prevProps.zoomLevel, nextProps.zoomLevel)) {
    // console.info(`------------- GoogleMap areEqual -> zoomLevel has changed`);
    return false;
  }
  if (!_.isEqual(prevProps.markers, nextProps.markers)) {
    // console.info(`------------- GoogleMap areEqual -> markers has changed:`, nextProps.markers);
    return false;
  }
  if (
    !_.isEqual(
      prevProps.routes?.map((route) => _.pick(route, [`color`, `path`, `selected`, `showArrows`])),
      nextProps.routes?.map((route) => _.pick(route, [`color`, `path`, `selected`, `showArrows`]))
    )
  ) {
    return false;
  }
  if (
    !_.isEqual(
      prevProps.geoFences?.map((geoFence) => _.pick(geoFence, [`id`, `color`, `paths`, `eventsUpdateHash`])),
      nextProps.geoFences?.map((geoFence) => _.pick(geoFence, [`id`, `color`, `paths`, `eventsUpdateHash`]))
    )
  ) {
    return false;
  }
  if (!_.isEqual(prevProps.traffic, nextProps.traffic)) {
    console.info(`------------- GoogleMap areEqual -> traffic has changed`);
    return false;
  }
  if (!_.isEqual(prevProps.scrollWheelZoom, nextProps.scrollWheelZoom)) {
    // console.info(`------------- GoogleMap areEqual -> scrollWheelZoom has changed`);
    return false;
  }
  if (!_.isEqual(prevProps.zoomControl, nextProps.zoomControl)) {
    // console.info(`------------- GoogleMap areEqual -> zoomControl has changed`);
    return false;
  }
  if (!_.isEqual(prevProps.keyboard, nextProps.keyboard)) {
    // console.info(`------------- GoogleMap areEqual -> keyboard has changed`);
    return false;
  }
  if (!_.isEqual(prevProps.rebounds, nextProps.rebounds)) {
    // console.info(`------------- GoogleMap areEqual -> rebounds has changed`);
    return false;
  }
  if (!_.isEqual(prevProps.reboundsControl, nextProps.reboundsControl)) {
    // console.info(`------------- GoogleMap areEqual -> reboundsControl has changed`);
    return false;
  }
  if (!_.isEqual(prevProps.disableDoubleClickZoom, nextProps.disableDoubleClickZoom)) {
    // console.info(`------------- GoogleMap areEqual -> disableDoubleClickZoom has changed`);
    return false;
  }
  if (!_.isEqual(prevProps.mapType, nextProps.mapType)) {
    // console.info(`------------- GoogleMap areEqual -> mapType has changed`);
    return false;
  }

  return true;
}

export interface GoogleMapProps extends GoogleMapComponentProps {
  apiKey: string;
  language?: string;
}

export const GoogleMap = ({ apiKey, language = `es`, ...props }: GoogleMapProps) => {
  return (
    <GoogleMapsProvider apiKey={apiKey} language={language}>
      <MemoizedGoogleMapComponent {...props} />
    </GoogleMapsProvider>
  );
};
