import { SvgIcon } from '@mui/material';

export default function ErrorIcon(props: any) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 26.02 26.19"
      style={{ color: `#fff`, width: `22px`, height: `22px`, ...props.style }}
    >
      <g>
        <path d="M13.01,26.09C5.85,26.09,.02,20.26,.02,13.09S5.85,.1,13.01,.1s12.99,5.83,12.99,12.99-5.83,12.99-12.99,12.99Zm0-23.98C6.95,2.1,2.02,7.03,2.02,13.09s4.93,10.99,10.99,10.99,10.99-4.93,10.99-10.99S19.07,2.1,13.01,2.1Z" />
        <path d="M14.36,13.09l3.56-3.92c.37-.41,.34-1.04-.07-1.41-.41-.37-1.04-.34-1.41,.07l-3.44,3.78-3.44-3.78c-.37-.41-1-.44-1.41-.07-.41,.37-.44,1-.07,1.41l3.56,3.92-3.56,3.92c-.37,.41-.34,1.04,.07,1.41,.19,.17,.43,.26,.67,.26,.27,0,.54-.11,.74-.33l3.44-3.78,3.44,3.78c.2,.22,.47,.33,.74,.33,.24,0,.48-.09,.67-.26,.41-.37,.44-1,.07-1.41l-3.56-3.92Z" />
      </g>
    </SvgIcon>
  );
}
