import { CustomFieldDefinition } from '@nx-smartmonkey/shared/domain';

import { RoutalPalette } from '@nx-smartmonkey/ui';
import InputText from '../../react-components/InputText';
import { Text } from '../../react-components/Text';

type CustomFieldFormTextProps = {
  onUpdate?: (...args: any[]) => any;
  fieldValue: string | number;
  projectField: CustomFieldDefinition;
  readOnly?: boolean;
  onlyValues?: boolean;
};

const CustomFieldFormText = ({
  fieldValue,
  onUpdate,
  projectField,
  readOnly,
  onlyValues = false,
}: CustomFieldFormTextProps) => (
  <>
    {onlyValues ? (
      <span>{fieldValue}</span>
    ) : readOnly ? (
      <>
        <Text variant="label" style={{ textTransform: `capitalize`, marginBottom: `8px` }}>
          {projectField.label}
        </Text>
        {fieldValue ? <span>{fieldValue}</span> : <Text intlId="none" />}
      </>
    ) : (
      <>
        <Text variant="label" style={{ textTransform: `capitalize`, marginBottom: `8px` }}>
          {projectField.label}
        </Text>
        <InputText
          key={projectField.custom_id}
          inputContainerStyles={{
            width: `100%`,
            backgroundColor: RoutalPalette.neutral00,
          }}
          inputStyles={{
            color: RoutalPalette.secondary40,
          }}
          type={projectField.field_type === `numerical` ? `number` : `text`}
          fieldValue={fieldValue}
          handleEnter={(val: any) => {
            if (onUpdate) {
              onUpdate(val);
            }
          }}
        />
      </>
    )}
  </>
);

CustomFieldFormText.defaultProps = {
  readOnly: false,
};

export default CustomFieldFormText;
