import 'animate.css';

import { Grid } from '@mui/material';
import { MarkerDomain } from '@nx-smartmonkey/ui';
import lodash from 'lodash';

import { StopFlat } from '../../../domain/stop/StopFlat';
import { useRetrieveRightPanelInfo } from '../../../hooks/right-panel/useRetrieveRightPanelInfo';
import { useSetSelectedStops } from '../../../hooks/stop/useSetSelectedStops';
import { useIsExampleURL } from '../../../hooks/useIsExampleURL';
import { StopsMapMap } from './StopsMapMap';

interface StopsMapProps {
  flatStops: StopFlat[];
}

export const StopsMap = ({ flatStops }: StopsMapProps) => {
  const { isExampleURL } = useIsExampleURL();
  const { setRightPanelInfo } = useRetrieveRightPanelInfo();
  const { data: selectedIds, run: setSelectedIds } = useSetSelectedStops();

  const onSelectionDrawn = (coordinates: { lat?: number; lng?: number }[]) => {
    const multipleSelection = flatStops!.filter((stop) => {
      if (stop.lat && stop.lng) {
        return MarkerDomain.isMarkerInsideCoordinates({
          markerCoords: { lat: stop.lat, lng: stop.lng },
          coordinates,
        });
      }
      return false;
    });
    setSelectedIds(multipleSelection.map((flatStop) => flatStop.id));
  };

  return (
    <Grid container direction="column" style={{ height: `100%`, width: `100%` }} wrap="nowrap">
      <div
        style={{
          position: `relative` as any,
          height: `100%`,
          width: `100%`,
        }}
      >
        <div
          style={{
            position: `absolute` as any,
            top: 0,
            left: 0,
            height: `100%`,
            width: `100%`,
          }}
        >
          <StopsMapMap
            height="100%"
            reboundsControl
            zoomControl
            disableDoubleClickZoom
            onDrawableSelection={onSelectionDrawn}
            flatStops={flatStops}
            markerOnClick={lodash.debounce((e: any, id: string) => {
              const flatStop = flatStops.find((uS) => uS.id === id);

              if (flatStop) {
                if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
                  if (selectedIds.find((selectedId) => selectedId === flatStop.id)) {
                    setSelectedIds(selectedIds.filter((selectedId) => selectedId !== flatStop.id));
                  } else {
                    setSelectedIds([...(selectedIds ?? []), flatStop.id]);
                  }
                  return;
                }

                if (selectedIds.find((selectedId) => selectedId === flatStop.id)) {
                  setSelectedIds([]);
                } else {
                  setSelectedIds([flatStop.id]);
                }
              }
            }, 300)}
            markerOnDoubleClick={(e: any, id: string) => {
              if (!isExampleURL) {
                setRightPanelInfo({
                  detailId: `${id}`,
                  detailTab: null,
                });
              }
            }}
            selectedStopsIds={selectedIds}
          />
        </div>
      </div>
    </Grid>
  );
};
