import { SvgIcon } from '@mui/material';
import React from 'react';

export default function GoodTime(props: any) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 36.85 36.85"
      style={{ width: `34px`, height: `34px`, color: `#CCCECF`, ...props.style }}
    >
      <path d="M16.54,18.19v-4.55c0-.59-.48-1.06-1.06-1.06s-1.06,.48-1.06,1.06v4.55c-1.02,.59-1.36,1.89-.78,2.9s1.89,1.36,2.9,.78c1.02-.59,1.36-1.89,.78-2.9-.19-.32-.45-.59-.78-.78Z" />
      <g>
        <path d="M28.42,17.05c.14,.63,.24,1.26,.29,1.91h-1.02c-.59,0-1.06,.48-1.06,1.06s.48,1.06,1.06,1.06h1.02c-.21,2.79-1.31,5.43-3.13,7.56-.03-.04-.06-.07-.09-.11l-1.5-1.5c-.41-.42-1.09-.42-1.5,0s-.42,1.09,0,1.5l1.5,1.5s.07,.06,.11,.09c-2.12,1.82-4.77,2.92-7.56,3.13v-1.55c0-.59-.48-1.06-1.06-1.06s-1.06,.48-1.06,1.06v1.55c-2.79-.21-5.43-1.31-7.56-3.13,.04-.03,.07-.06,.11-.09l1.5-1.5c.41-.41,.41-1.09,0-1.5-.41-.41-1.09-.41-1.5,0l-1.51,1.5s-.06,.07-.09,.11c-1.82-2.12-2.92-4.77-3.13-7.56h1.02c.59,0,1.06-.48,1.06-1.06s-.48-1.06-1.06-1.06h-1.02c.21-2.79,1.31-5.43,3.13-7.56,.03,.04,.06,.07,.09,.11l1.5,1.5c.41,.42,1.09,.42,1.5,0,.42-.41,.42-1.09,0-1.5l-1.5-1.5s-.07-.06-.11-.09c2.12-1.82,4.77-2.92,7.56-3.13v1.01c0,.59,.48,1.06,1.06,1.06s1.06-.48,1.06-1.06v-1.02c1.94,.15,3.81,.74,5.48,1.69,0-.15-.02-.3-.02-.45,0-.64,.07-1.26,.18-1.86-1.76-.85-3.67-1.37-5.64-1.51v-1.1h2.13c.59,0,1.06-.48,1.06-1.06s-.48-1.06-1.06-1.06h-6.38c-.59,0-1.06,.48-1.06,1.06s.48,1.06,1.06,1.06h2.13v1.1C6.34,5.21,.07,11.92,.07,20.02c0,8.51,6.9,15.41,15.41,15.41,4.09,0,8.01-1.62,10.9-4.51,3.64-3.64,5.07-8.65,4.3-13.37-.78-.07-1.54-.25-2.25-.5Z" />
        <path d="M23.57,13.26c-.38-.59-.7-1.22-.95-1.88l-.13,.13c-.42,.42-.42,1.09,0,1.51,.29,.29,.71,.37,1.08,.25Z" />
      </g>
      <polyline
        fill="none"
        stroke="#CCCECF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
        points="26.05 8.67 29.29 11.86 35.7 5.48"
      />
    </SvgIcon>
  );
}
