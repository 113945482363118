import { Grid } from '@mui/material';

import RatingStar from '../assets/svg/RatingStar';

type LinkProps = {
  rating?: number;
  justifyContent?: `flex-start` | `flex-end` | `center`;
  size?: {
    width: string;
    height: string;
  };
  onChange?: (rating: number) => void;
};

const defaultSize = {
  width: `20px`,
  height: `20px`,
};

export const RatingStars = ({ rating, justifyContent, size = defaultSize, onChange }: LinkProps) => (
  <Grid container direction="row" alignItems="center" justifyContent={justifyContent ?? `flex-start`}>
    {Array(5)
      .fill(0)
      .map((_, index) => {
        const isSelected = index + 1 <= (rating ?? 0);
        return (
          <Grid
            key={`star-${index}`}
            item
            style={{
              ...size,
              cursor: onChange ? `pointer` : `default`,
              marginLeft: index !== 0 ? `4px` : `0px`,
            }}
            onClick={() => {
              if (onChange) {
                onChange(index + 1);
              }
            }}
          >
            <RatingStar
              style={{
                ...size,
              }}
              isSelected={isSelected}
            />
          </Grid>
        );
      })}
  </Grid>
);
