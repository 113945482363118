import { SvgIcon } from '@mui/material';
import React from 'react';

export default function BadInfo(props: any) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 36.85 36.85"
      style={{ width: `34px`, height: `34px`, color: `#CCCECF`, ...props.style }}
    >
      <path d="M33.9,35.35l-6.6-7.02c6.84-5.4,8.01-15.32,2.61-22.15C24.52-.66,14.6-1.83,7.76,3.57,.92,8.96-.25,18.88,5.15,25.72c5.02,6.36,14.04,7.88,20.87,3.52l6.78,7.21c.31,.3,.8,.3,1.1,0s.3-.8,0-1.1h0Zm-16.47-5.12c-7.91-.01-14.31-6.44-14.29-14.35,.01-7.91,6.44-14.31,14.35-14.29,4.28,0,8.33,1.93,11.05,5.24,.78,.95,1.43,2,1.95,3.12,.18,.38,.35,.76,.49,1.16,.35,.99,.59,2.02,.72,3.06,0,.08,0,.16,.03,.23,.83,7.87-4.87,14.92-12.74,15.75-.51,.05-1.03,.08-1.55,.08Z" />
      <path d="M17.96,7.45c-2.31-.09-4.5,1.07-5.73,3.03-.25,.35-.17,.84,.17,1.09l1.44,1.09c.34,.26,.84,.2,1.11-.13,.83-1.04,1.45-1.65,2.76-1.65,1.02,0,2.29,.66,2.29,1.65,0,.75-.62,1.14-1.63,1.7-1.18,.66-2.74,1.48-2.74,3.54v.33c0,.44,.36,.8,.8,.8h2.41c.44,0,.8-.36,.8-.8v-.19c0-1.43,4.17-1.49,4.17-5.35,0-2.91-3.01-5.12-5.84-5.12Zm-.33,12.44c-1.28,0-2.31,1.03-2.31,2.31s1.03,2.31,2.31,2.31,2.31-1.03,2.31-2.31-1.03-2.31-2.31-2.31h0Z" />
    </SvgIcon>
  );
}
