/* eslint-disable camelcase */
import { Coordinate } from './Coordinate';
import { ValueObject } from './ValueObject';

export interface LocationProps {
  location_id?: string;
  label?: string;
  country?: string;
  country_code?: string;
  state?: string;
  county?: string;
  city?: string;
  district?: string;
  subdistrict?: string;
  block?: string;
  subblock?: string;
  street?: string;
  house_number?: string;
  postal_code?: string;
  comments?: string;
  lat?: number;
  lng?: number;
  original_input?: string;
  partial_match?: boolean;
}

export class Location extends ValueObject<LocationProps> {
  get location_id(): string | undefined {
    return this.props.location_id;
  }

  get label(): string | undefined {
    return this.props.label;
  }

  get country(): string | undefined {
    return this.props.country;
  }

  get country_code(): string | undefined {
    return this.props.country_code;
  }

  get state(): string | undefined {
    return this.props.state;
  }

  get county(): string | undefined {
    return this.props.county;
  }

  get city(): string | undefined {
    return this.props.city;
  }

  get district(): string | undefined {
    return this.props.district;
  }

  get subdistrict(): string | undefined {
    return this.props.subdistrict;
  }

  get block(): string | undefined {
    return this.props.block;
  }

  get subblock(): string | undefined {
    return this.props.subblock;
  }

  get street(): string | undefined {
    return this.props.street;
  }

  get house_number(): string | undefined {
    return this.props.house_number;
  }

  get postal_code(): string | undefined {
    return this.props.postal_code;
  }

  get comments(): string | undefined {
    return this.props.comments;
  }

  get lat(): number | undefined {
    return this.props.lat;
  }

  get lng(): number | undefined {
    return this.props.lng;
  }

  get original_input(): string | undefined {
    return this.props.original_input;
  }

  set original_input(originalInput: string | undefined) {
    this.props.original_input = originalInput;
  }

  get partial_match(): boolean | undefined {
    return this.props.partial_match;
  }

  static create(props: LocationProps): Location {
    return new Location(props);
  }

  toCoordinate(): Coordinate {
    return Coordinate.create({ lat: this.lat, lng: this.lng });
  }

  isValid(): boolean {
    return !!(this.lat && this.lng);
  }
}
