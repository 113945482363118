import { SvgIcon } from '@mui/material';
import { RoutalPalette } from '@nx-smartmonkey/ui';
import React from 'react';

interface ConstraintsIconProps extends React.SVGProps<SVGSVGElement> {
  isSelected?: boolean;
}

export default function ConstraintsIcon({ isSelected = false, style, onClick }: ConstraintsIconProps) {
  return (
    <SvgIcon onClick={onClick} viewBox="0 0 33.36 34.61" style={{ width: `24px`, height: `24px`, ...(style ?? {}) }}>
      <g>
        {!isSelected ? (
          <g>
            <path
              fill={RoutalPalette.neutral60}
              d="M28.49,34.52H4.87C2.16,34.52-.04,32.32-.04,29.61V5C-.04,2.29,2.16,.09,4.87,.09H28.49c2.71,0,4.92,2.21,4.92,4.92V29.61c0,2.71-2.21,4.92-4.92,4.92ZM4.87,2.09c-1.61,0-2.92,1.31-2.92,2.92V29.61c0,1.61,1.31,2.92,2.92,2.92H28.49c1.61,0,2.92-1.31,2.92-2.92V5c0-1.61-1.31-2.92-2.92-2.92H4.87Z"
            />
            <g>
              <path
                fill={RoutalPalette.neutral60}
                d="M10.22,10.17c.86,0,1.56,.7,1.56,1.56s-.7,1.56-1.56,1.56-1.56-.7-1.56-1.56,.7-1.56,1.56-1.56m0-2c-1.96,0-3.56,1.59-3.56,3.56s1.59,3.56,3.56,3.56,3.56-1.59,3.56-3.56-1.59-3.56-3.56-3.56h0Z"
              />
              <path
                fill={RoutalPalette.neutral60}
                d="M23.18,21.33c.86,0,1.56,.7,1.56,1.56s-.7,1.56-1.56,1.56-1.56-.7-1.56-1.56,.7-1.56,1.56-1.56m0-2c-1.96,0-3.56,1.59-3.56,3.56s1.59,3.56,3.56,3.56,3.56-1.59,3.56-3.56-1.59-3.56-3.56-3.56h0Z"
              />
              <path
                fill={RoutalPalette.neutral60}
                d="M14.73,22.06H7.49c-.47,0-.86,.39-.86,.86s.39,.86,.86,.86h7.24c.47,0,.86-.39,.86-.86s-.39-.86-.86-.86h0Z"
              />
              <path
                fill={RoutalPalette.neutral60}
                d="M25.23,10.93h-7.24c-.47,0-.86,.39-.86,.86s.39,.86,.86,.86h7.24c.47,0,.86-.39,.86-.86s-.39-.86-.86-.86h0Z"
              />
            </g>
          </g>
        ) : (
          <g>
            <rect fill="none" x=".04" y=".17" width="33.29" height="34.27" rx="4.9" ry="4.9" />
            <rect fill={RoutalPalette.primary40} x="-.04" y=".09" width="33.45" height="34.43" rx="4.92" ry="4.92" />
            <g>
              <g>
                <path
                  fill="#FFF"
                  d="M10.22,14.29c-1.41,0-2.56-1.15-2.56-2.56s1.15-2.56,2.56-2.56,2.56,1.15,2.56,2.56-1.15,2.56-2.56,2.56Z"
                />
                <path
                  fill="#FFF"
                  d="M10.22,10.17c.86,0,1.56,.7,1.56,1.56s-.7,1.56-1.56,1.56-1.56-.7-1.56-1.56,.7-1.56,1.56-1.56m0-2c-1.96,0-3.56,1.59-3.56,3.56s1.59,3.56,3.56,3.56,3.56-1.59,3.56-3.56-1.59-3.56-3.56-3.56h0Z"
                />
              </g>
              <g>
                <path
                  fill="#FFF"
                  d="M23.18,25.44c-1.41,0-2.56-1.15-2.56-2.56s1.15-2.56,2.56-2.56,2.56,1.15,2.56,2.56-1.15,2.56-2.56,2.56Z"
                />
                <path
                  fill="#FFF"
                  d="M23.18,21.32c.86,0,1.56,.7,1.56,1.56s-.7,1.56-1.56,1.56-1.56-.7-1.56-1.56,.7-1.56,1.56-1.56m0-2c-1.96,0-3.56,1.59-3.56,3.56s1.59,3.56,3.56,3.56,3.56-1.59,3.56-3.56-1.59-3.56-3.56-3.56h0Z"
                />
              </g>
              <path
                fill="#FFF"
                d="M14.73,22.06H7.49c-.47,0-.86,.39-.86,.86s.39,.86,.86,.86h7.24c.47,0,.86-.39,.86-.86s-.39-.86-.86-.86h0Z"
              />
              <path
                fill="#FFF"
                d="M25.23,10.93h-7.24c-.47,0-.86,.39-.86,.86s.39,.86,.86,.86h7.24c.47,0,.86-.39,.86-.86s-.39-.86-.86-.86h0Z"
              />
            </g>
          </g>
        )}
      </g>
    </SvgIcon>
  );
}
