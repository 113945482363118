import { Grid, Radio } from '@mui/material';
import { RoutalPalette } from '@nx-smartmonkey/ui';
import { debounce } from 'lodash';
import { intl } from '../../../helpers/IntGlobalProvider';
import InputText from '../../../react-components/InputText';
import { Text } from '../../../react-components/Text';
import { SearchFilterComponent } from '../filter';
import { SearchFilterOperators } from '../filter/SearchFilter';
import { SearchFilterString } from './SearchFilterString';

interface GetSearchFilterStringOptionsProps {
  operator: SearchFilterOperators;
  text: string;
  hasValue: boolean;
  isSelected: boolean;
}

const getSearchFilterStringOptions = (filter: SearchFilterString): GetSearchFilterStringOptionsProps[] => [
  {
    operator: `eq`,
    text: intl.formatMessage({ id: `search_filters.eq` }),
    hasValue: true,
    isSelected: filter.isOperator(`eq`),
  },
  {
    operator: `ne`,
    text: intl.formatMessage({ id: `search_filters.ne` }),
    hasValue: true,
    isSelected: filter.isOperator(`ne`),
  },
  {
    operator: `starts_with`,
    text: intl.formatMessage({ id: `search_filters.starts_with` }),
    hasValue: true,
    isSelected: filter.isOperator(`starts_with`),
  },
  {
    operator: `ends_with`,
    text: intl.formatMessage({ id: `search_filters.ends_with` }),
    hasValue: true,
    isSelected: filter.isOperator(`ends_with`),
  },
  {
    operator: `contains`,
    text: intl.formatMessage({ id: `search_filters.contains` }),
    hasValue: true,
    isSelected: filter.isOperator(`contains`),
  },
  {
    operator: `contains_exact_word`,
    text: intl.formatMessage({ id: `search_filters.contains_exact_word` }),
    hasValue: true,
    isSelected: filter.isOperator(`contains_exact_word`),
  },
  {
    operator: `not_contains`,
    text: intl.formatMessage({ id: `search_filters.not_contains` }),
    hasValue: true,
    isSelected: filter.isOperator(`not_contains`),
  },
  {
    operator: `unknown`,
    text: intl.formatMessage({ id: `search_filters.unknown` }),
    hasValue: false,
    isSelected: filter.isOperator(`unknown`),
  },
  {
    operator: `known`,
    text: intl.formatMessage({ id: `search_filters.known` }),
    hasValue: false,
    isSelected: filter.isOperator(`known`),
  },
];

interface SearchFilterStringComponentProps {
  filter: SearchFilterString;
  filterPosition: number;
  colors: {
    primaryColor: string;
    secondaryColor: string;
  };
  isExampleURL?: boolean;
  onChange: (filter: SearchFilterString) => void;
  onRemoveFilter: () => void;
}

export const SearchFilterStringComponent = ({
  filter,
  filterPosition,
  colors,
  isExampleURL = false,
  onChange,
  onRemoveFilter,
}: SearchFilterStringComponentProps) => {
  return (
    <SearchFilterComponent
      filter={filter}
      buttonId={`filter-string-${filterPosition}`}
      colors={colors}
      isExampleURL={isExampleURL}
      onRemoveFilter={onRemoveFilter}
    >
      {getSearchFilterStringOptions(filter).map((option) => {
        return (
          <Grid container direction="column" wrap="nowrap" style={{ padding: `0 16px`, minWidth: `250px` }}>
            <Grid
              item
              container
              direction="row"
              wrap="nowrap"
              alignItems="center"
              style={{
                cursor: `pointer`,
              }}
              onClick={(event: any) => {
                event.stopPropagation();
                filter.setOperator(option.operator);
                onChange(filter);
              }}
            >
              <Grid item>
                <Radio
                  style={{
                    color: colors.primaryColor,
                  }}
                  checked={option.isSelected}
                  onChange={(event: any) => {
                    event.stopPropagation();
                    filter.setOperator(option.operator);
                    onChange(filter);
                  }}
                />
              </Grid>

              <Grid item>
                <Text style={{ cursor: `pointer` }}>{option.text}</Text>
              </Grid>
            </Grid>

            {option.isSelected && option.hasValue ? (
              <Grid item>
                <InputText
                  autoFocus
                  type="text"
                  inputContainerStyles={{
                    width: `100%`,
                    backgroundColor: RoutalPalette.neutral00,
                  }}
                  focusedBorderColor={colors.primaryColor}
                  inputStyles={{
                    color: RoutalPalette.secondary40,
                  }}
                  fieldValue={filter.getValue() ?? ``}
                  onChange={debounce((text: any) => {
                    filter.setValue(text !== `` ? text : null);
                    onChange(filter);
                  }, 300)}
                />
              </Grid>
            ) : null}
          </Grid>
        );
      })}
    </SearchFilterComponent>
  );
};
