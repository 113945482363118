import { SearchFilter } from '../filter';
import { SearchFilterProps } from '../filter/SearchFilter';

export type SearchFilterDateOperator = `lt` | `eq` | `gt` | `unknown` | `known`;
export type SearchFilterDateValue = number | string | null;

interface SearchFilterDateProps extends SearchFilterProps {
  field: string;
  operator: SearchFilterDateOperator;
  value: SearchFilterDateValue;

  // RELATIVE

  // - "more than" => lt (el value és un int que son els dies)
  // - "exactly" => eq
  // - "less than" => lt

  // ABSOLUTE

  // - "after" => gt (value es un objecte amb day, month i year)
  // - "on" => eq (value es un objecte amb day, month i year)
  // - "before" => lt (value es un objecte amb day, month i year)
  // - "is unknown" => unknown (value es null)
  // - "has any value" => known (value es null)
}

export class SearchFilterDate extends SearchFilter {
  constructor(props: SearchFilterDateProps) {
    super({ ...props, type: `date` });
  }

  public isPositive(): boolean {
    return this.isRelative() && this.getValue() > 0;
  }

  public isRelative(): boolean {
    return typeof this.getValue() === typeof 123;
  }

  public isAbsolute(): boolean {
    return typeof this.getValue() !== typeof 123;
  }
}
