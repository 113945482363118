import { ValueObject } from '@nx-smartmonkey/shared/domain';

export type DistanceType = `km` | `mi`;
export type WeightType = `kg` | `lb`;
export type VolumeType = `l` | `m³` | `u`;
export type CurrencyTypes = `EUR` | `USD` | `MXN`;

interface ProjectUnitsProps {
  distance: DistanceType;
  weight: WeightType;
  volume: VolumeType;
  currency?: CurrencyTypes;
}

export class ProjectUnits extends ValueObject<ProjectUnitsProps> {
  get distance(): DistanceType {
    return this.props.distance;
  }
  set distance(distance: DistanceType) {
    this.props.distance = distance;
  }
  get weight(): WeightType {
    return this.props.weight;
  }
  set weight(weight: WeightType) {
    this.props.weight = weight;
  }
  get volume(): VolumeType {
    return this.props.volume;
  }
  set volume(volume: VolumeType) {
    this.props.volume = volume;
  }
  get currency(): CurrencyTypes | undefined {
    return this.props.currency;
  }
  set currency(currency: CurrencyTypes | undefined) {
    this.props.currency = currency;
  }
  private constructor(props: ProjectUnitsProps) {
    super(props);
  }

  static create(props: ProjectUnitsProps): ProjectUnits {
    return new ProjectUnits(props);
  }

  currencyToSymbol() {
    const settingsCurrency = [
      { code: `EUR`, symbol: `€` },
      { code: `USD`, symbol: `$` },
      { code: `MXN`, symbol: `$` },
    ];
    if (this.currency) {
      const currencyFound = settingsCurrency.find((c: any) => c.code === this.currency);
      if (currencyFound) {
        return `${currencyFound.symbol}`;
      }
    }
    return ``;
  }
}
