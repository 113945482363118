import { HexColor, Language, LanguageType } from '@nx-smartmonkey/shared/domain';
import { Supervisor } from '../../domain/supervisor/Supervisor';
import { SupervisorDTO } from '../../domain/supervisor/SupervisorDTO';
import { SupervisorRepositoryDTO } from '../../domain/supervisor/SupervisorRepositoryDTO';

export class SupervisorMapper {
  static toDomain(raw: SupervisorDTO): Supervisor {
    const {
      id,
      organization_id,
      project_id,
      email,
      short_id,
      name,
      company,
      phone,
      color,
      language,
      stop_fields,
      stop_completed_fields,
      stop_canceled_fields,
      survey_fields,

      // CRUD
      created_by,
      created_at,
      updated_at,
    } = raw;

    const hexColor = color ? HexColor.create(color) : undefined;
    const languageDomain = language ? Language.create(language as LanguageType) : undefined;

    return Supervisor.create(
      {
        organization_id,
        project_id,
        email,
        short_id,
        name,
        company,
        phone,
        color: hexColor,
        language: languageDomain,
        stop_fields,
        stop_completed_fields,
        stop_canceled_fields,
        survey_fields,

        // CRUD
        created_by,
        created_at,
        updated_at,
      },
      id
    );
  }

  static toRepository(supervisor: Supervisor): SupervisorRepositoryDTO {
    const { language } = supervisor;

    return {
      language: language?.value,
    };
  }

  static toDTO(supervisor: Supervisor): SupervisorDTO {
    return {
      id: supervisor.id,
      organization_id: supervisor.organization_id,
      project_id: supervisor.project_id,
      email: supervisor.email,
      short_id: supervisor.short_id,
      name: supervisor.name,
      company: supervisor.company,
      phone: supervisor.phone,
      color: supervisor.color?.value,
      language: supervisor.language?.value,
      stop_fields: supervisor.stop_fields,
      stop_completed_fields: supervisor.stop_completed_fields,
      stop_canceled_fields: supervisor.stop_canceled_fields,
      survey_fields: supervisor.survey_fields,

      // CRUD
      created_by: supervisor.created_by,
      created_at: supervisor.created_at,
      updated_at: supervisor.updated_at,
    };
  }
}
