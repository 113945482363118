import { SvgIcon } from '@mui/material';
import { RoutalPalette } from '@nx-smartmonkey/ui';
import React from 'react';

interface InformationIconProps extends React.SVGProps<SVGSVGElement> {
  isSelected?: boolean;
}

export default function InformationIcon({ isSelected = false, style, onClick }: InformationIconProps) {
  return (
    <SvgIcon onClick={onClick} viewBox="0 0 24 24" style={{ width: `24px`, height: `24px`, ...(style ?? {}) }}>
      <g>
        {isSelected ? (
          <g>
            <path
              d="M11.9999 22.9109C18.0361 22.9109 22.9294 18.0175 22.9294 11.9813C22.9294 5.94509 18.0361 1.05176 11.9999 1.05176C5.96364 1.05176 1.07031 5.94509 1.07031 11.9813C1.07031 18.0175 5.96364 22.9109 11.9999 22.9109Z"
              fill={RoutalPalette.primary40}
            />
            <path
              d="M12.4258 22.9504L12.4065 22.9502L12.3873 22.951C9.22096 23.0857 5.89995 21.577 4.30418 19.5444C4.3335 19.3695 4.38326 19.1189 4.46477 18.8175C4.63314 18.1949 4.933 17.3704 5.45646 16.5512C6.48069 14.9485 8.39692 13.2983 12.0992 13.2983C15.8152 13.2983 17.7534 14.8947 18.7923 16.4332C19.3233 17.2195 19.6309 18.0102 19.8052 18.6062C19.8859 18.882 19.9372 19.1133 19.9686 19.279C18.2404 21.4755 15.4462 22.9769 12.4258 22.9504Z"
              fill={RoutalPalette.neutral00}
              stroke={RoutalPalette.primary40}
              stroke-width="1.5"
            />
            <path
              d="M11.9991 14.2077C9.41626 14.2077 7.30273 12.1033 7.30273 9.51132C7.30273 6.91833 9.40612 4.81494 11.9991 4.81494C14.5921 4.81494 16.6955 6.91833 16.6955 9.51132C16.6955 12.1043 14.5921 14.2077 11.9991 14.2077Z"
              fill={RoutalPalette.neutral00}
              stroke={RoutalPalette.primary40}
              stroke-width="1.5"
            />
            <path
              d="M11.9999 22.9109C18.0361 22.9109 22.9294 18.0175 22.9294 11.9813C22.9294 5.94509 18.0361 1.05176 11.9999 1.05176C5.96364 1.05176 1.07031 5.94509 1.07031 11.9813C1.07031 18.0175 5.96364 22.9109 11.9999 22.9109Z"
              stroke={RoutalPalette.primary40}
              fill="none"
              stroke-width="1.5"
            />
          </g>
        ) : (
          <g>
            <path
              d="M11.9999 22.9109C18.0361 22.9109 22.9294 18.0175 22.9294 11.9813C22.9294 5.94509 18.0361 1.05176 11.9999 1.05176C5.96364 1.05176 1.07031 5.94509 1.07031 11.9813C1.07031 18.0175 5.96364 22.9109 11.9999 22.9109Z"
              fill={RoutalPalette.neutral00}
            />
            <path
              d="M12.4257 22.9298L12.4065 22.9296L12.3874 22.9305C9.22081 23.0649 5.9001 21.5591 4.30433 19.5309C4.33366 19.3564 4.38337 19.1068 4.46467 18.8067C4.633 18.1854 4.93278 17.3626 5.45612 16.5451C6.48006 14.9458 8.39628 13.2983 12.0992 13.2983C15.8158 13.2983 17.7541 14.8921 18.7927 16.4273C19.3235 17.212 19.6311 18.001 19.8053 18.5958C19.8858 18.8704 19.937 19.1007 19.9684 19.2659C18.2404 21.4578 15.4465 22.9563 12.4257 22.9298Z"
              fill={RoutalPalette.neutral00}
              stroke={RoutalPalette.neutral60}
              stroke-width="1.5"
            />
            <path
              d="M11.9991 14.2077C9.41626 14.2077 7.30273 12.1033 7.30273 9.51132C7.30273 6.91833 9.40612 4.81494 11.9991 4.81494C14.5921 4.81494 16.6955 6.91833 16.6955 9.51132C16.6955 12.1043 14.5921 14.2077 11.9991 14.2077Z"
              fill={RoutalPalette.neutral00}
              stroke={RoutalPalette.neutral60}
              stroke-width="1.5"
            />
            <path
              d="M11.9999 22.9109C18.0361 22.9109 22.9294 18.0175 22.9294 11.9813C22.9294 5.94509 18.0361 1.05176 11.9999 1.05176C5.96364 1.05176 1.07031 5.94509 1.07031 11.9813C1.07031 18.0175 5.96364 22.9109 11.9999 22.9109Z"
              stroke={RoutalPalette.neutral60}
              fill="none"
              stroke-width="1.5"
            />
          </g>
        )}
      </g>
    </SvgIcon>
  );
}
