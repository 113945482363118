import { Checkbox } from '@nx-smartmonkey/ui';

import { Text } from '../../react-components/Text';

type CustomFieldFormBooleanProps = {
  onUpdate?: (...args: any[]) => any;
  fieldValue: boolean;
  projectField: any;
  readOnly?: boolean;
  onlyValues?: boolean;
  size?: `medium` | `large`;
};

export const CustomFieldFormBoolean = ({
  fieldValue,
  onUpdate,
  projectField,
  readOnly = false,
  onlyValues = false,
  size = `medium`,
}: CustomFieldFormBooleanProps) => (
  <>
    {!onlyValues && (
      <Text variant="label" style={{ textTransform: `capitalize`, marginBottom: `8px` }}>
        {projectField.label}
      </Text>
    )}
    <Checkbox
      disabled={readOnly}
      checked={fieldValue}
      onChange={(e: any, value: any) => {
        if (onUpdate) {
          onUpdate(value);
        }
      }}
    />
  </>
);
