import { Fade, Grid } from '@mui/material';
import { RoutalLoader, RoutalPalette } from '@nx-smartmonkey/ui';
import { useEffect } from 'react';

import { useRetrieveRightPanelInfo } from '../../../hooks/right-panel/useRetrieveRightPanelInfo';
import { useRetrieveStop } from '../../../hooks/stop/useRetrieveStop';
import { useSetSelectedStops } from '../../../hooks/stop/useSetSelectedStops';
import { StopBody } from './StopBody';
import { StopHeader } from './StopHeader';

export const StopRightPanel = () => {
  const { detailId: stopId, cleanRightPanelInfo } = useRetrieveRightPanelInfo();
  const { data: selectedStops } = useSetSelectedStops();

  const { data: stop, isLoading, error: retrieveStopError } = useRetrieveStop({ showErrorMessage: false });

  const onClose = () => {
    cleanRightPanelInfo();
  };

  const isOpen = () => !!stopId;

  useEffect(() => {
    if (retrieveStopError) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retrieveStopError]);

  return (
    <Grid
      key={stopId}
      container
      style={{
        width: `unset`,
        backgroundColor: RoutalPalette.neutral00,
      }}
      sx={{
        position: { xs: `fixed`, md: `relative` },
        top: { xs: 0, md: `unset` },
        left: { xs: 0, md: `unset` },
        height: { xs: `100%`, md: `unset` },
        zIndex: { xs: 450, md: `unset` },
      }}
    >
      <div
        style={{
          height: `100%`,
          zIndex: 450,
          borderLeft: `1px solid ${RoutalPalette.neutral10}`,
          position: `relative`,
        }}
      >
        <Fade in={isOpen()} mountOnEnter>
          <Grid style={{ height: `100%` }}>
            <Grid
              container
              direction="column"
              style={{
                maxWidth: `400px`,
                height: `100%`,
                padding: `0`,
              }}
              sx={{
                width: { xs: `100vw`, md: `400px` },
              }}
              wrap="nowrap"
            >
              {isLoading ? (
                <Grid
                  container
                  justifyContent="center"
                  style={{ height: `100%`, overflow: `hidden` }}
                  alignItems="center"
                >
                  <RoutalLoader width={60} />
                </Grid>
              ) : (
                <>
                  {/* --- PANEL HEADER */}
                  {stop && (
                    <>
                      <StopHeader stop={stop} onClose={onClose} isSelected={selectedStops.includes(stop.id)} />

                      <Grid item container style={{ height: `100%`, overflowY: `auto` }}>
                        <div style={{ width: `100%`, height: `100%` }}>
                          <StopBody stop={stop} />
                        </div>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Fade>
      </div>
    </Grid>
  );
};
