import { Project } from '../../domain/project/Project';

export enum ProjectActionType {
  FETCH_PROJECT = `FETCH_PROJECT`,
  FETCH_PROJECT_FAILURE = `FETCH_PROJECT_FAILURE`,
  LOADING_PROJECT = `LOADING_PROJECT`,
}

export interface FetchProjectAction {
  type: ProjectActionType.FETCH_PROJECT;
  payload: Project;
}

export interface FetchProjectFailureAction {
  type: ProjectActionType.FETCH_PROJECT_FAILURE;
}

export interface LoadingProjectAction {
  type: ProjectActionType.LOADING_PROJECT;
}

export type ProjectActions = FetchProjectAction | FetchProjectFailureAction | LoadingProjectAction;
