import { makeStyles } from '@mui/styles';
import { DivIcon } from 'leaflet';
import lodash from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Marker as LMarker, Popup } from 'react-leaflet';

import { getColorByIndex, SMPalette } from '../Colors';
import {
  colorsPOI,
  ColorsPoiKinds,
  MarkerIconKinds,
  markerIconPxSize,
  MarkerIconPxSizeKinds,
  markerIconSize,
  MarkerIconSizeKinds,
  svgIcons,
} from './icons';
import { Action } from './Map';

const useStyles = makeStyles((theme: any) => {
  const style: { [key: string]: any } = {
    marker: {
      boxSizing: `border-box` as any,
      height: `25px`,
      width: `25px`,
      border: `2px solid ${SMPalette[`grey2`]}`,
      borderRadius: `50%`,
      boxShadow: `0 0 0 2px white`,
      backgroundColor: `#DFE6EF`,
      fontWeight: `bold` as any,
      fontSize: `10px`,
      textAlign: `center` as any,
    },
  };
  style[`marker-selected`] = {
    ...style[`marker`],
    backgroundColor: theme.palette.primary.main,
  };
  style[`marker-done`] = {
    ...style[`marker`],
    backgroundColor: `#70D292`,
  };
  style[`marker-error`] = {
    ...style[`marker`],
    backgroundColor: `#E03030`,
  };
  style[`marker-color`] = {
    ...style[`marker`],
    backgroundColor: `white`,
    border: (props: { iconColor: any }) =>
      props.iconColor ? `2px solid ${props.iconColor}` : `2px solid ${SMPalette[`grey2`]}`,
  };
  style[`marker-color-selected`] = {
    ...style[`marker`],
    backgroundColor: theme.palette.primary.main,
    border: (props: { iconColor: any }) =>
      props.iconColor ? `2px solid ${props.iconColor}` : `2px solid ${SMPalette[`grey2`]}`,
    zIndex: `10000 !important`,
  };
  style[`marker-color-done`] = {
    ...style[`marker`],
    backgroundColor: `#70D292`,
    border: (props: { iconColor: any }) =>
      props.iconColor ? `2px solid ${props.iconColor}` : `2px solid ${SMPalette[`grey2`]}`,
    zIndex: `10000 !important`,
  };
  style[`marker-color-error`] = {
    ...style[`marker`],
    backgroundColor: `#E03030`,
    border: (props: { iconColor: any }) =>
      props.iconColor ? `2px solid ${props.iconColor}` : `2px solid ${SMPalette[`grey2`]}`,
    zIndex: `10000 !important`,
  };

  // Add small & medium versions
  for (const key in style) {
    if (Object.hasOwn(style, key)) {
      style[`small-${key}`] = {
        ...style[key],
        height: `15px`,
        width: `15px`,
      };
      style[`medium-${key}`] = {
        ...style[key],
        height: `18px`,
        width: `18px`,
      };
      style[`big-${key}`] = {
        ...style[key],
        height: `35px`,
        width: `35px`,
      };
      style[`superbig-${key}`] = {
        ...style[key],
        height: `50px`,
        width: `50px`,
      };
    }
  }

  style[`select-area`] = {
    height: `22px`,
    width: `22px`,
    borderRadius: `50%`,
  };
  style[`small-select-area`] = {
    height: `23px`,
    width: `23px`,
    borderRadius: `50%`,
  };
  style[`medium-select-area`] = {
    height: `26px`,
    width: `26px`,
    borderRadius: `50%`,
  };
  style[`superbig-select-area`] = {
    height: `58px`,
    width: `58px`,
    borderRadius: `50%`,
  };
  style[`big-select-area`] = {
    height: `43px`,
    width: `43px`,
    borderRadius: `50%`,
  };
  return style;
});

const getColor = ({
  kind,
  selected,
  visited,
  color,
}: {
  kind?: MarkerIconKinds;
  selected?: boolean;
  visited?: boolean;
  color?: string | number;
}) => {
  if (
    kind &&
    [`place`, `parking`, `normal`, `fav`, `champ`, `poi`, `bookmark`, `user`, `hangar`, `warning`, `barrow`].includes(
      kind
    )
  ) {
    let iconColor = getColorByIndex(0);
    if (kind === `place`) {
      if (selected) {
        iconColor = colorsPOI.selected;
      } else if (visited) {
        iconColor = colorsPOI.visited;
      } else {
        iconColor = colorsPOI.noSelected;
      }
    } else {
      iconColor = colorsPOI[kind as ColorsPoiKinds];
    }
    return iconColor;
  }
  let iconColor;
  if (selected) {
    iconColor = `#FFFFFF`;
  } else if (color === undefined) {
    iconColor = SMPalette[`grey2`];
  } else if (typeof color === `number`) {
    iconColor = getColorByIndex(color);
  } else {
    iconColor = color;
  }
  return iconColor;
};

interface MarkerIconProps {
  id?: string;
  lat: number;
  lng: number;
  keyMarker?: any;
  color?: string | number;
  label?: string | React.ReactElement;
  kind?: MarkerIconKinds;
  selected?: boolean;
  done?: boolean;
  error?: boolean;
  number?: number;
  visited?: boolean;
  zoomLevel?: number;
  onClick?: (...args: any[]) => any;
  onDoubleClick?: (...args: any[]) => any;
  doNotRefreshBounds?: boolean;
  dispatch?: React.Dispatch<Action>;
}

const MarkerIcon: FunctionComponent<MarkerIconProps> = ({
  id,
  lat,
  lng,
  keyMarker,
  color,
  label,
  kind,
  selected,
  done,
  error,
  number,
  visited,
  zoomLevel,
  onClick,
  onDoubleClick,
  doNotRefreshBounds,
  dispatch,
}) => {
  const [position, setPosition] = useState<[number, number]>([0, 0]);
  const [markerColor, setMarkerColor] = useState<string | number | undefined>();
  const [markerSelected, setMarkerSelected] = useState<boolean>(false);

  const setBounds = useCallback(() => {
    if (lat !== undefined && lng !== undefined) {
      const [minLat, minLng, maxLat, maxLng] = [lat, lng, lat, lng];
      if (dispatch) {
        dispatch({
          type: `ADD_BOUNDS`,
          bounds: [
            [minLat, minLng],
            [maxLat, maxLng],
          ],
          ...(id ? { id } : {}),
        });
      }
    }
  }, [dispatch, id, lat, lng]);

  useEffect(() => {
    if (!doNotRefreshBounds && (!lodash.isEqual(position[0], lat) || !lodash.isEqual(position[1], lng))) {
      setPosition([lat, lng]);
      setBounds();
    }
    if (!lodash.isEqual(markerColor, color)) {
      setMarkerColor(color);
    }
    if (selected && !lodash.isEqual(markerSelected, selected)) {
      setMarkerSelected(selected);
    }
  }, [color, doNotRefreshBounds, lat, lng, markerColor, markerSelected, position, selected, setBounds]);

  const iconColor = getColor({ kind, selected, visited, color: markerColor });
  const stylesClasses = useStyles({ iconColor });

  const handleOnClick = (event: any) => {
    if (onClick) {
      onClick(event);
    }
  };

  const handleOnDoubleClick = (event: any) => {
    if (onDoubleClick) {
      onDoubleClick(event);
    }
  };

  const markerIcon = (_styleClasses: any, _color: any) => {
    const classStyle =
      (kind === undefined && number !== undefined && number > 99 ? `` : markerIconSize[kind as MarkerIconSizeKinds]) +
      (_color !== undefined ? `marker-color` : `marker`) +
      (done ? `-done` : ``) +
      (error ? `-error` : ``) +
      (selected ? `-selected` : ``);
    const style = `padding:0px;width:100%;height:100%;fill:${iconColor}`;
    const icon = new DivIcon({
      html: kind
        ? `<svg viewBox="0 0 24 24" style="${style}"><path d="${svgIcons[kind]}"></path></svg>`
        : `<span style="position: absolute;
              font-weight: bold;
              top: 50%; right: 50%;
              color:${selected || done || error ? `white` : `#333940`};
              transform: translate(50%,-50%);">${number || ``}</span>`,
      className: _styleClasses !== undefined ? _styleClasses[classStyle] : ``,
      iconSize: null as any,
      iconAnchor: [
        markerIconPxSize[kind as MarkerIconPxSizeKinds] / 2,
        markerIconPxSize[kind as MarkerIconPxSizeKinds] / 2,
      ],
    });
    return icon;
  };

  const selectArea = (_styleClasses: any, _iconColor: any) => {
    const style = `padding:0px;width:100%;height:100%;fill:${_iconColor}`;
    let iconHtml = `<span/>`;
    if (kind && ![`normal`, `fav`, `champ`, `poi`, `bookmark`, `user`, `hangar`, `warning`, `barrow`].includes(kind)) {
      iconHtml = `<svg viewBox="0 0 24 24" style="${style}">
        ${
          kind === `parking` &&
          `<path fill="#FFFFFF" d="M21.1,0H2.9C1.4,0,0.1,1.3,0.1,2.8V21c0,1.5,1.2,2.8,2.8,2.8h18.2c1.5,0,2.8-1.2,2.8-2.8V2.8   C23.9,1.3,22.6,0,21.1,0z"/>`
        }
          ${
            kind === `place` &&
            `<path fill="${
              selected ? `#FFFFFF` : `#57637C`
            }" d="m11.9 1.5c-4.5 0-8.1 3.3-8.1 7.8s8.1 13.7 8.1 13.7 8.2-9.1 8.2-13.6-3.7-7.9-8.2-7.9z"/>`
          }
      <path d="${svgIcons[kind]}"></path>
      
      ${
        kind === `parking` &&
        `<path fill="#FFFFFF" d="m13.5 4.1h-6.9v16h2v-5.7h4.9c2.7 0 4.9-2.3 4.9-5.1 0-2.9-2.2-5.2-4.9-5.2zm0 8.2h-5v-6.2h4.9c1.6 0 3 1.4 3 3.1s-1.3 3.1-2.9 3.1z"/>`
      }
      </svg>`;
    }
    if (kind && [`normal`, `fav`, `champ`, `poi`, `bookmark`, `user`, `hangar`, `warning`, `barrow`].includes(kind)) {
      iconHtml = `<svg viewBox="0 0 24 24" style="${style}">
          <circle cx="12" cy="12" r="12" fill="${selected ? `#ffb466` : `white`}"/>
          ${svgIcons[kind]}
          </svg>`;
    }

    let anchor: [number, number] = [
      markerIconPxSize[kind as MarkerIconPxSizeKinds] / 2 + 4,
      markerIconPxSize[kind as MarkerIconPxSizeKinds] / 2 + 4,
    ];
    if (kind === `place`) {
      if (selected) {
        anchor = [50 / 2 + 4, 50 + 4];
      } else {
        anchor = [
          markerIconPxSize[kind as MarkerIconPxSizeKinds] / 2 + 4,
          markerIconPxSize[kind as MarkerIconPxSizeKinds] + 4,
        ];
      }
    }
    const icon = new DivIcon({
      html: iconHtml,
      className:
        selected && kind === `place`
          ? _styleClasses[`${markerIconSize.superbig}select-area`]
          : _styleClasses[`${markerIconSize[kind as MarkerIconSizeKinds]}select-area`],
      iconSize: null as any,
      iconAnchor: anchor,
    });
    return icon;
  };

  if (zoomLevel && zoomLevel < 10 && (kind === `start` || kind === `end` || kind === `parking`)) {
    return null;
  }

  if (
    kind &&
    [`place`, `parking`, `normal`, `fav`, `champ`, `poi`, `bookmark`, `user`, `hangar`, `warning`, `barrow`].includes(
      kind
    )
  ) {
    return (
      <LMarker
        key={keyMarker}
        position={[lat, lng]}
        icon={selectArea(stylesClasses, iconColor)}
        eventHandlers={{
          click: handleOnClick,
        }}
      >
        {label && <Popup>{label}</Popup>}
      </LMarker>
    );
  }

  return (
    <LMarker
      key={keyMarker}
      position={[lat, lng]}
      icon={markerIcon(stylesClasses, markerColor)}
      eventHandlers={{
        click: handleOnClick,
        dblclick: handleOnDoubleClick,
      }}
    >
      {label && <Popup>{label}</Popup>}
    </LMarker>
  );
};

export default MarkerIcon;
