import { CustomFieldDefinition, CustomFieldsMapper } from '../customFields';
import { LocationMapper } from '../LocationMapper';
import { Report } from './Report';
import { ReportDTO } from './ReportDTO';
import { ReportImage } from './ReportImage';
import { ReportTask } from './ReportTask';

export class ReportMapper {
  static toDomain(props: ReportDTO, customFieldDefinitions: CustomFieldDefinition[]): Report {
    const {
      id,
      stop_id,
      driver_id,
      project_id,
      plan_id,
      location,
      type,
      comments,
      images,
      signature,
      cancel_reason,
      custom_fields,
      tasks,
      time_in_stop,
      created_at,
    } = props;

    let reportCustomFieldsDefinitions: CustomFieldDefinition[];

    if (type === `service_report_canceled`) {
      reportCustomFieldsDefinitions = customFieldDefinitions.filter(
        (cf) => cf.field_model === `service_report_canceled`
      );
    } else {
      reportCustomFieldsDefinitions = customFieldDefinitions.filter(
        (cf) => cf.field_model === `service_report_completed`
      );
    }

    const customFields = custom_fields
      ? CustomFieldsMapper.toDomain(custom_fields, reportCustomFieldsDefinitions)
      : custom_fields;

    return Report.create(
      {
        stop_id,
        driver_id,
        project_id,
        plan_id,
        type,
        comments,
        images: (images ?? []).map(ReportImage.create),
        signature: signature ? ReportImage.create(signature) : undefined,
        cancel_reason,
        location: location ? LocationMapper.toDomain(location) : undefined,
        time_in_stop,
        custom_fields: customFields,
        tasks: tasks?.map(({ task_id, status, custom_fields }) => {
          const taskReportCustomFields = CustomFieldsMapper.toDomain(
            custom_fields ?? {},
            status === `completed`
              ? customFieldDefinitions.filter((cf) => cf.field_model === `service_report_task_completed`)
              : customFieldDefinitions.filter((cf) => cf.field_model === `service_report_task_canceled`)
          );
          return ReportTask.create({
            task_id: task_id,
            status: status,
            custom_fields: taskReportCustomFields,
          });
        }),
        created_at,
      },
      id
    );
  }
  static toDTO(report: Report): ReportDTO {
    return {
      id: report.id,
      stop_id: report.stop_id,
      project_id: report.project_id,
      driver_id: report.driver_id, // TODO: DRIVER_ID SHOULD NOT BE OPTIONAL
      plan_id: report.plan_id,
      created_at: report.created_at,
      type: report.type,
      comments: report.comments,
      images: report.images.map((image) => ({
        id: image.id,
        url: image.url,
      })),
      signature: report.signature ? { id: report.signature.id, url: report.signature.url } : undefined,
      cancel_reason: report.cancel_reason,
      location: report.location ? LocationMapper.toDTO(report.location) : undefined,
      time_in_stop: report.time_in_stop,
      custom_fields: report.custom_fields?.value,
      tasks: (report.tasks ?? []).map((task) => ({
        task_id: task.task_id,
        status: task.status,
        custom_fields: task.custom_fields?.value,
      })),
    };
  }
}
