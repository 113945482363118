import { PropsWithChildren, useReducer } from 'react';

import { InfoErrorContext } from './context';
import { InfoErrorReducer } from './reducer';

export const InfoErrorContextProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const [state, dispatch] = useReducer(InfoErrorReducer, {});
  return (
    <InfoErrorContext.Provider
      value={{
        infoErrorState: state,
        infoErrorDispatch: dispatch,
      }}
    >
      {children}
    </InfoErrorContext.Provider>
  );
};
