export const ROOT = `/*`;
export const NOMATCHES = `*`;
export const ADMIN = `/admin`;
export const AUTH = `/auth`;
export const LOGOUT = `/logout`;
export const ERROR = `/error`;
export const EXAMPLE = `/example`;
export const EXTERNAL = {
  LANDING: `https://routal.com/?utm_source=custommer_communication_web&utm_medium=client_webapp&utm_campaign=bottom_banner`,
};
