import './fontNunito.css';

import { adaptV4Theme, createTheme } from '@mui/material/styles';

// Check all possible fields at: https://material-ui.com/customization/default-theme/
export default createTheme(
  adaptV4Theme({
    typography: {
      fontFamily: [`Nunito Sans`].join(`,`),
      h1: {
        color: `#050D1E`,
        fontSize: `52px`,
        lineHeight: `60px`,
        fontWeight: 500,
      },
      h2: {
        color: `#050D1E`,
        fontSize: `28px`,
        lineHeight: `30px`,
        fontWeight: 500,
      },
      h3: {
        color: `#050D1E`,
        fontSize: `24px`,
        lineHeight: `26px`,
        fontWeight: 500,
      },
      h4: {
        color: `#050D1E`,
        fontSize: `22px`,
        lineHeight: `24px`,
        fontWeight: 500,
      },
      h5: {
        color: `#050D1E`,
        fontSize: `20px`,
        fontWeight: 500,
        lineHeight: `22px`,
      },
      h6: {
        color: `#050D1E`,
        fontSize: `18px`,
        fontWeight: 500,
        lineHeight: `20px`,
      },
      body1: {
        fontSize: `14px`,
        lineHeight: `16px`,
        color: `#333940`,
      },
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ fontFamily: string; h1: { color: string; f... Remove this comment to see the full error message
      useNextVariants: true,
    },
    palette: {
      primary: {
        main: `#F8671D`,
      },
      secondary: {
        main: `#57637C`,
      },
      error: {
        main: `#FF2825`,
      },
    },
    button: {
      fontSize: `12px`,
      error: {
        main: `#FF2825`,
      },
    },
    overrides: {
      MuiStepIcon: {
        text: {
          fill: `white`,
        },
      },
      MuiChip: {
        root: {
          backgroundColor: `#f2f2f2`,
        },
      },
    },
  })
);
