import { RoutalText, RoutalTextProps } from '@nx-smartmonkey/ui';
import React from 'react';
import { useIntl } from 'react-intl';

export interface TextProps extends RoutalTextProps {
  intlId?: string;
  intlValues?: Record<string, string | number | Function>;
  defaultMessage?: string;
}

/**
 * General purpose text component. Allows different types and tagNames. An intl-id can be provided to this
 * component.
 * @param props
 */
export const Text = (props: React.PropsWithChildren<TextProps> & React.DOMAttributes<TextProps>) => {
  const intl = useIntl();
  // TODO: Revisar perquè només està funcionant el cas de BR!!
  const defaultValues = {
    br: <br />,
    span: (chunks: any) => <span>{chunks}</span>,
    ul: (chunks: any) => <ul>{chunks}</ul>,
    li: (chunks: any) => <li>{chunks}</li>,
    b: (chunks: any) => <b>{chunks}</b>,
  };

  const { children, intlId, intlValues, defaultMessage } = props;

  if (children !== undefined) return <RoutalText {...props}>{children}</RoutalText>;

  const message = intl.formatMessage(
    {
      id: intlId ?? `no_translation_id_provided`,
      defaultMessage,
    },
    {
      ...defaultValues,
      ...(intlValues ?? {}),
    }
  );

  return <RoutalText {...props}>{message}</RoutalText>;
};
