import 'animate.css';

import { ButtonGroup, Grid, Button as MButton } from '@mui/material';
import { HSpacer, RoutalPalette, RoutalTooltip, VSpacer } from '@nx-smartmonkey/ui';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import ExportIcon from '../../assets/img/export.svg';
import { ListView } from '../../assets/svg/ListView';
import { MapView } from '../../assets/svg/MapView';
import { SearchFilters } from '../../components/search-filters/SearchFilters';
import { SearchFiltersExample } from '../../components/search-filters/SearchFiltersExample';
import * as routing from '../../constants/Routing';
import { useRetrieveProject } from '../../hooks/project/useRetrieveProject';
import { useRetrieveRightPanelInfo } from '../../hooks/right-panel/useRetrieveRightPanelInfo';
import { useRetrieveFlatStops } from '../../hooks/stop/useRetrieveFlatStops';
import { useSearchStopsPredicates } from '../../hooks/stop/useSearchStopsPredicates';
import { useSetSelectedStops } from '../../hooks/stop/useSetSelectedStops';
import { useSetStopsView } from '../../hooks/stop/useSetStopsView';
import { useRetrieveSupervisor } from '../../hooks/supervisor/useRetrieveSupervisor';
import { useIsExampleURL } from '../../hooks/useIsExampleURL';
import { Button } from '../../react-components/Button';
import { Text } from '../../react-components/Text';
import { ExportStopsDialog } from './ExportStopsDialog';
import { StopsList } from './StopsList';
import { StopsListColumnsButton } from './StopsListColumnsButton';
import { StopsMap } from './map';
import { StopRightPanel } from './stop-right-panel/StopRightPanel';

export const Stops = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { isExampleURL } = useIsExampleURL();
  const { data: project } = useRetrieveProject();
  const { data: supervisor } = useRetrieveSupervisor();
  const { detailId, detailTab } = useRetrieveRightPanelInfo();
  const { data: selectedIds } = useSetSelectedStops();
  const { view } = useSetStopsView();
  const { predicatesBase64: stopsPredicatesBase64 } = useSearchStopsPredicates();
  const { data: flatStops } = useRetrieveFlatStops();
  const [isExportStopsDialogOpen, setIsExportStopsDialogOpen] = useState<boolean>(false);

  if (!project) {
    return null;
  }

  const isMapView = () => view === `map`;

  return (
    <>
      <Grid
        container
        style={{
          height: `100%`,
          width: `100%`,
          zIndex: 2,
          backgroundColor: RoutalPalette.neutral00,
          display: `flex`,
          flexDirection: `row`,
        }}
        wrap="nowrap"
      >
        <div
          style={{
            height: `100%`,
            width: `100%`,
          }}
        >
          <Grid container direction="column" wrap="nowrap" style={{ height: `100%` }}>
            <Grid container>
              <Grid container direction="column" style={{ padding: `12px 20px` }}>
                <Grid
                  container
                  item
                  justifyContent="space-between"
                  alignItems="flex-start"
                  direction="row"
                  wrap="nowrap"
                >
                  <Grid container>
                    <Grid
                      item
                      container
                      alignItems="center"
                      sx={{
                        display: { xs: `none`, md: `flex` },
                      }}
                    >
                      {isExampleURL ? (
                        <SearchFiltersExample
                          colors={{
                            primaryColor: project.style?.primary_color?.value ?? RoutalPalette.primary20,
                            secondaryColor: project.style?.secondary_color?.value ?? RoutalPalette.secondary00,
                          }}
                        />
                      ) : (
                        <SearchFilters
                          language={supervisor?.language}
                          colors={{
                            primaryColor: project.style?.primary_color?.value ?? RoutalPalette.primary20,
                            secondaryColor: project.style?.secondary_color?.value ?? RoutalPalette.secondary00,
                          }}
                        />
                      )}
                    </Grid>

                    <Grid item>
                      <VSpacer medium />
                      {selectedIds.length === 0 ? (
                        <Text intlId="supervisors.stops.num_stops" intlValues={{ total: (flatStops ?? []).length }} />
                      ) : (
                        <Text
                          intlId="supervisors.stops.num_selected_stops"
                          intlValues={{ num: selectedIds.length, total: (flatStops ?? []).length }}
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    style={{ width: `unset` }}
                    wrap="nowrap"
                  >
                    <StopsListColumnsButton id="stops-configurable-table-columns" />

                    <HSpacer small />

                    <RoutalTooltip title={intl.formatMessage({ id: `supervisors.stops.export_excel` })}>
                      <Button
                        styleType="secondary"
                        disabled={selectedIds.length === 0}
                        onClick={() => setIsExportStopsDialogOpen(true)}
                        style={{
                          height: `36px`,
                          width: `32px`,
                          padding: `0 4px`,
                          margin: 0,
                          borderRadius: `5px`,
                        }}
                      >
                        <img alt="" height="24px" src={ExportIcon} draggable="false" />
                      </Button>
                    </RoutalTooltip>

                    <HSpacer small />

                    <ButtonGroup
                      disableElevation
                      disableRipple
                      disableFocusRipple
                      variant="contained"
                      style={{
                        height: `36px`,
                        borderRadius: `5px`,
                        border: `1px solid ${project.style?.primary_color?.value ?? RoutalPalette.primary20}`,
                        margin: `6px 0`,
                      }}
                    >
                      <Grid style={{ display: `flex`, padding: `4px` }}>
                        <MButton
                          color="secondary"
                          style={{
                            backgroundColor: !isMapView()
                              ? project.style?.primary_color?.value ?? RoutalPalette.primary20
                              : `transparent`,
                            borderRadius: `5px`,
                            padding: 0,
                            border: `none`,
                            cursor: !isMapView() ? `default` : `pointer`,
                          }}
                          onClick={() => {
                            const params = new URLSearchParams();
                            params.append(`view`, `list`);
                            if (detailId) {
                              params.set(`detailId`, `${detailId}`);
                            }
                            if (detailTab) {
                              params.set(`detailTab`, `${detailTab}`);
                            }
                            if (stopsPredicatesBase64) {
                              params.set(`predicates`, stopsPredicatesBase64);
                            }
                            navigate(`${isExampleURL ? routing.EXAMPLE : routing.ROOT}?${params.toString()}`);
                          }}
                        >
                          <ListView isSelected={!isMapView()} color={project.style?.primary_color?.value} />
                        </MButton>
                        <MButton
                          color="secondary"
                          style={{
                            backgroundColor: isMapView()
                              ? project.style?.primary_color?.value ?? RoutalPalette.primary20
                              : `transparent`,
                            borderRadius: `5px`,
                            padding: 0,
                            border: `none`,
                            cursor: isMapView() ? `default` : `pointer`,
                          }}
                          onClick={() => {
                            const params = new URLSearchParams();
                            params.append(`view`, `map`);
                            if (detailId) {
                              params.set(`detailId`, `${detailId}`);
                            }
                            if (detailTab) {
                              params.set(`detailTab`, `${detailTab}`);
                            }
                            if (stopsPredicatesBase64) {
                              params.set(`predicates`, stopsPredicatesBase64);
                            }
                            navigate(`${isExampleURL ? routing.EXAMPLE : routing.ROOT}?${params.toString()}`);
                          }}
                        >
                          <MapView isSelected={isMapView()} color={project.style?.primary_color?.value} />
                        </MButton>
                      </Grid>
                    </ButtonGroup>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container direction="row" style={{ height: `100%`, position: `relative` }} wrap="nowrap">
              <Grid item container>
                <StopsList />
              </Grid>

              {isMapView() ? (
                <Grid
                  item
                  container
                  sx={{
                    position: { xs: `absolute`, md: `relative` },
                    top: { xs: 0, md: `unset` },
                    left: { xs: 0, md: `unset` },
                    zIndex: { xs: 450, md: `unset` },
                  }}
                  style={{
                    height: `100%`,
                  }}
                >
                  <StopsMap flatStops={flatStops ?? []} />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </div>

        {!isExampleURL && detailId !== undefined ? <StopRightPanel /> : null}
      </Grid>

      <ExportStopsDialog
        open={isExportStopsDialogOpen}
        onCloseDialog={() => setIsExportStopsDialogOpen(false)}
        selectedStopIds={selectedIds}
      />
    </>
  );
};
