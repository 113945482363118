import { SvgIcon } from '@mui/material';
import React from 'react';

interface RatingStarProps {
  isSelected?: boolean;
  style?: React.CSSProperties;
}

export default function RatingStar({ isSelected = false, style = {}, ...props }: RatingStarProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 17.71 16.41" style={{ width: `44px`, height: `44px`, ...style }}>
      <g>
        {!isSelected ? (
          <path
            fill="#cdcfd0"
            d="M13.82,16.35c-.08,0-.16-.02-.23-.06l-4.73-2.49-4.73,2.49c-.17,.09-.37,.07-.53-.04-.15-.11-.23-.3-.2-.49l.9-5.27L.47,6.76c-.14-.13-.19-.33-.13-.51,.06-.18,.22-.31,.4-.34l5.29-.77L8.41,.34C8.57,0,9.13,0,9.3,.34l2.37,4.8,5.29,.77c.19,.03,.34,.16,.4,.34,.06,.18,0,.38-.13,.51l-3.83,3.73,.9,5.27c.03,.19-.04,.38-.2,.49-.09,.06-.19,.1-.29,.1Zm-4.97-3.61c.08,0,.16,.02,.23,.06l4.07,2.14-.78-4.53c-.03-.16,.03-.33,.14-.44l3.29-3.21-4.55-.66c-.16-.02-.3-.13-.38-.27L8.85,1.69l-2.03,4.12c-.07,.15-.21,.25-.38,.27l-4.55,.66,3.29,3.21c.12,.11,.17,.28,.14,.44l-.78,4.53,4.07-2.14c.07-.04,.15-.06,.23-.06Z"
          />
        ) : (
          <path
            fill="#90B1AA"
            d="M17.37,6.25c-.06-.18-.22-.31-.4-.34l-5.29-.77L9.3,.34C9.13,0,8.57,0,8.41,.34l-2.37,4.8L.75,5.91c-.19,.03-.34,.16-.4,.34-.06,.18,0,.38,.13,.51l3.83,3.73-.9,5.27c-.03,.19,.04,.38,.2,.49,.15,.11,.36,.13,.53,.04l4.73-2.49,4.73,2.49c.07,.04,.15,.06,.23,.06,.1,0,.21-.03,.29-.1,.15-.11,.23-.3,.2-.49l-.9-5.27,3.83-3.73c.14-.13,.19-.33,.13-.51Z"
          />
        )}
      </g>
    </SvgIcon>
  );
}
