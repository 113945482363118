import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { RoutalPalette } from './Colors';
import { CrossIcon } from './assets/svg/CrossIcon';
import { RoutalInfoIcon, RoutalSuccessIcon, RoutalWarningIcon } from './assets/svg/snackbar-icons';

export type RoutalSnackbarVariants = `success` | `error` | `info` | `warning`;

const getIconByVariant = (variant: RoutalSnackbarVariants) => {
  switch (variant) {
    case `success`:
      return RoutalSuccessIcon;
    case `error`:
    case `warning`:
      // return ErrorIcon;
      return RoutalWarningIcon;
    case `info`:
    default:
      return RoutalInfoIcon;
  }
};

const RoutalSnackbarContainer = styled.div<{
  variant: RoutalSnackbarVariants;
  showCloseButton?: boolean;
}>`
  position: fixed;
  bottom: 0;
  background-color: transparent;
  min-height: 30px;
  max-height: 150px;
  padding: 12px;
  overflow: auto;
  ${({ showCloseButton }) => (showCloseButton ? `padding-right: 26px;` : ``)}
  min-width: 200px;
  max-width: 300px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  ${({ variant }) => {
    switch (variant) {
      case `success`:
        return `
          background-color: ${RoutalPalette.success.medium};
          & > * {
            color: ${RoutalPalette.white}!important;
          }
        `;
      case `error`:
        return `
          background-color: ${RoutalPalette.error.medium};
          & > * {
            color: ${RoutalPalette.white}!important;
          }
        `;
      case `warning`:
        return `
          background-color: ${RoutalPalette.warning.medium};
          & > * {
            color: ${RoutalPalette.secondary40}!important;
          }
        `;
      default:
        return `
          background-color: ${RoutalPalette.primary40};
          & > * {
            color: ${RoutalPalette.white}!important;
          }
        `;
    }
  }}
`;

interface SnackbarComponentProps {
  open?: boolean;
  variant?: RoutalSnackbarVariants;
  autoHideDuration?: number;
  containerStyle?: React.CSSProperties;
  showCloseButton?: boolean;
  onClose?: (...args: any[]) => any;
}

export const RoutalSnackbar = ({
  open,
  children,
  variant = `info`,
  autoHideDuration = 1000,
  containerStyle = {},
  showCloseButton = false,
  onClose,
}: React.PropsWithChildren<SnackbarComponentProps>) => {
  const [openSnack, setOpenSnack] = useState<boolean>(open ?? false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (open !== undefined && open !== openSnack) {
      setOpenSnack(open);
    }
    if (open) {
      timeout = setTimeout(() => {
        setOpenSnack(false);
        onClose?.();
      }, autoHideDuration);
    }
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const Icon = getIconByVariant(variant);

  if (!open || !openSnack) return null;

  return (
    <RoutalSnackbarContainer variant={variant} style={containerStyle} showCloseButton={showCloseButton}>
      {showCloseButton && (
        <CrossIcon
          onClick={onClose}
          style={{
            position: `absolute`,
            right: `12px`,
            top: `20px`,
            width: `14px`,
            cursor: `pointer`,
            color: RoutalPalette.white,
          }}
        />
      )}
      <Icon style={{ marginRight: `8px`, color: RoutalPalette.white }} />
      {children}
    </RoutalSnackbarContainer>
  );
};
