import { CustomFields } from '../customFields/CustomFields';
import { Entity } from '../Entity';
import { Location } from '../Location';
import { ReportImage } from './ReportImage';
import { ReportTask } from './ReportTask';
import { ReportType } from './ReportType';

export type CancelReasons = `nobody` | `wrong_address` | 'missing_information' | `other`;

interface ReportProps {
  stop_id: string;
  driver_id: string;
  project_id: string;
  plan_id: string;
  type: ReportType;
  comments?: string;
  images: ReportImage[];
  signature?: ReportImage;
  cancel_reason?: CancelReasons;
  location?: Location;
  time_in_stop?: number;
  custom_fields?: CustomFields;
  created_at: Date;
  tasks?: ReportTask[];
}

export class Report extends Entity<ReportProps> {
  get id(): string {
    return this._id!;
  }
  get stop_id(): string {
    return this.props.stop_id;
  }
  get driver_id(): string {
    return this.props.driver_id;
  }
  get project_id(): string {
    return this.props.project_id;
  }
  get plan_id(): string {
    return this.props.plan_id;
  }
  get created_at(): Date {
    return this.props.created_at;
  }
  get type(): ReportType {
    return this.props.type;
  }
  get comments(): string | undefined {
    return this.props.comments;
  }
  get images(): ReportImage[] {
    return this.props.images;
  }
  set images(images: ReportImage[]) {
    this.props.images = images;
  }
  get signature(): ReportImage | undefined {
    return this.props.signature;
  }
  get cancel_reason(): CancelReasons | undefined {
    return this.props.cancel_reason;
  }
  get location(): Location | undefined {
    return this.props.location;
  }
  get time_in_stop(): number | undefined {
    return this.props.time_in_stop;
  }
  get custom_fields(): CustomFields | undefined {
    return this.props.custom_fields;
  }
  get tasks(): ReportTask[] | undefined {
    return this.props.tasks;
  }

  private constructor(props: ReportProps, id: string) {
    super(props, id);
  }

  static create(props: ReportProps, id: string) {
    return new Report(props, id);
  }

  delete() {
    // Do nothing
  }
}
