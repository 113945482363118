import { ServiceResponse } from '@nx-smartmonkey/shared/hooks';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import useErrorMiddleware from '../errorMiddleware/useErrorMiddleware';

import { supervisorService } from '../../application/SupervisorService';
import { Supervisor } from '../../domain/supervisor/Supervisor';
import { useRetrieveProject } from '../project/useRetrieveProject';
import { useShowSnackbarMessage } from '../snackbarMessage/useShowSnackbarMessage';
import { useIsExampleURL } from '../useIsExampleURL';

type UseRetrieveSupervisorResponse = ServiceResponse<Function, Supervisor>;

// Hooks act as 'Adapter' layer.
export const useRetrieveSupervisor = (): UseRetrieveSupervisorResponse => {
  const { isExampleURL } = useIsExampleURL();
  const { showSnackbarMessage } = useShowSnackbarMessage();
  const [supervisor, setSupervisor] = useState<Supervisor | undefined>(undefined);
  const { data: project } = useRetrieveProject();
  const { sessionExpiredLogout } = useErrorMiddleware();

  const {
    isInitialLoading: isLoading,
    refetch,
    isError,
    isSuccess,
  } = useQuery(
    [`supervisors_supervisor`],
    (): Promise<Supervisor> => supervisorService.retrieveDetails({ isExampleURL: isExampleURL }),
    {
      enabled: !!project,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (_supervisor) => {
        setSupervisor(_supervisor);
      },
      onError: (err: any) => {
        console.info(`Supervisors Retrieve Supervisor Error: `, err?.response);

        if (err?.response?.status === 401) {
          sessionExpiredLogout();
          return;
        }

        showSnackbarMessage({
          messageIntlId: `infoerror.${err?.response?.data?.messageId ?? `network_error`}`,
        });
      },
    }
  );

  return {
    error: isError,
    isLoading,
    success: isSuccess,
    run: refetch,
    data: supervisor,
  };
};
