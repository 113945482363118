import { ServiceResponse } from '@nx-smartmonkey/shared/hooks';
import { PaginatedResult } from '@nx-smartmonkey/shared/interfaces';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { stopService } from '../../application/StopService';
import { StopsActionType } from '../../context/stops/actions';
import { useStopsContext } from '../../context/stops/context';
import { StopsTableColumn } from '../../context/stops/tableColumns';
import { Stop } from '../../domain/stop/Stop';
import useErrorMiddleware from '../errorMiddleware/useErrorMiddleware';
import { useRetrieveProject } from '../project/useRetrieveProject';
import { useShowSnackbarMessage } from '../snackbarMessage/useShowSnackbarMessage';
import { useIsExampleURL } from '../useIsExampleURL';

interface UseSearchStopsProps {
  offset?: number;
  limit?: number;
  page?: number;
  sortBy?: string;
  sortDirection?: string;
}

type UseSearchStopsResponse = ServiceResponse<Function, PaginatedResult<Stop>> & {
  tableColumns: Array<StopsTableColumn>;
};

// Hooks act as 'Adapter' layer.
export const useSearchStops = ({
  offset,
  limit,
  page,
  sortBy,
  sortDirection,
}: UseSearchStopsProps): UseSearchStopsResponse => {
  const { isExampleURL } = useIsExampleURL();
  const { data: project } = useRetrieveProject();
  const { showSnackbarMessage } = useShowSnackbarMessage();
  const { stopsState, stopsDispatch } = useStopsContext();
  const { sessionExpiredLogout } = useErrorMiddleware();

  const {
    isInitialLoading: isLoading,
    refetch,
    isError,
    isSuccess,
  } = useQuery(
    [`supervisors_search_stops`, offset, limit, page, sortBy, sortDirection, stopsState.stops.predicatesBase64],
    (): Promise<PaginatedResult<Stop>> => {
      const predicates = [
        ...stopsState.stops.predicates.map((predicate) => {
          if (predicate.field === `vehicle_id` && predicate.value === `unassigned`) {
            predicate.setValue(null);
          }
          return predicate;
        }),
      ];

      return stopService.search({
        project: project!,
        offset,
        limit,
        sort_by: sortBy,
        sort_direction: sortDirection,
        predicates,
        isExampleURL,
      });
    },
    {
      retry: false,
      enabled: project !== undefined,
      // enabled:
      //   project !== undefined && stopsState.stops.predicatesBase64 !== `` && stopsState.stops.predicates.length > 0,
      staleTime: Infinity,
      onSuccess: (stopsPagination) => {
        stopsDispatch({
          type: StopsActionType.SEARCH_STOPS_SUCCESS,
          payload: {
            stopsPagination,
          },
        });
      },
      onError: (err: any) => {
        console.info(`Supervisors Search Stops Error: `, err?.response);

        if (err?.response?.status === 401) {
          sessionExpiredLogout();
          return;
        }

        showSnackbarMessage({
          messageIntlId: `infoerror.${err?.response?.data?.messageId ?? `network_error`}`,
        });
      },
    }
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopsState.stops.predicates]);

  return {
    error: isError,
    isLoading,
    success: isSuccess,
    run: refetch,
    data: stopsState.stops.pagination,
    tableColumns: stopsState.stops.tableColumns,
  };
};
