import { NotificationsActionType } from '../../context/notifications/actions';
import { useNotificationsContext } from '../../context/notifications/context';
import { NotificationProps } from '../../context/notifications/reducer';

type UseSessionLoginCodeResponseProps = {
  notifications: NotificationProps[];
  newNotifications: NotificationProps[];
  setNewNotifications: (notifications: NotificationProps[]) => void;
  removeNotifications: () => void;
  clearNewNotifications: () => void;
};

// Hooks act as 'Adapter' layer.
export const useNotifications = (): UseSessionLoginCodeResponseProps => {
  const { notificationsState, notificationsDispatch } = useNotificationsContext();

  const setNewNotifications = (notifications: NotificationProps[]) => {
    notificationsDispatch({
      type: NotificationsActionType.SET_NOTIFICATIONS,
      payload: {
        notifications,
      },
    });
  };

  const removeNotifications = () => {
    notificationsDispatch({
      type: NotificationsActionType.REMOVE_NOTIFICATIONS,
    });
  };

  const clearNewNotifications = () => {
    notificationsDispatch({
      type: NotificationsActionType.CLEAR_NEW_NOTIFICATIONS,
    });
  };

  return {
    notifications: notificationsState.notifications,
    newNotifications: notificationsState.newNotifications,
    setNewNotifications,
    removeNotifications,
    clearNewNotifications,
  };
};
