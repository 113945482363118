import { createContext, useContext } from 'react';
import { TranslationsActions } from './actions';
import { getInitialState } from './reducer';

export const TranslationsContext = createContext({
  translationsState: getInitialState(),
  translationsDispatch: (action: TranslationsActions) => {},
});

TranslationsContext.displayName = `TranslationsContext`;

export const useTranslationsContext = () => useContext(TranslationsContext);
