import { ServiceResponse } from '@nx-smartmonkey/shared/hooks';
import { useQuery } from '@tanstack/react-query';

import { sessionService } from '../../application/SessionService';
import { useShowSnackbarMessage } from '../snackbarMessage/useShowSnackbarMessage';

interface UseSessionLoginPhoneProps {
  projectId: string;
  phone: string;
}

type UseSessionLoginPhoneResponseProps = ServiceResponse<Function, void> & {
  isCodePending: boolean;
};

// Hooks act as 'Adapter' layer.
export const useSessionLoginPhone = ({
  projectId,
  phone,
}: UseSessionLoginPhoneProps): UseSessionLoginPhoneResponseProps => {
  const { showSnackbarMessage } = useShowSnackbarMessage();

  const {
    isInitialLoading: isLoading,
    isRefetching,
    isError,
    isSuccess,
    refetch,
  } = useQuery(
    [`supervisors_session_login_phone`, projectId, phone],
    () => sessionService.createSessionToken({ projectId, phone: phone.replace(/ /g, ``) }),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: 2,
      onError: (err: any) => {
        console.info(`Supervisor Session Login Phone Error: `, err?.response);

        showSnackbarMessage({
          messageIntlId: `infoerror.supervisors.login.error`,
        });
      },
    }
  );

  return {
    error: isError,
    success: isSuccess,
    isLoading: isLoading || isRefetching,
    isCodePending: sessionService.isCodePending(),
    run: refetch,
  };
};
